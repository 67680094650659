import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ISurveyDoneRequest, UserService } from '../../users/services/user.service';
import { EvaPlusUser } from '../../store/signal-analytics/reducer/eva-plus-user.state';
import { Subscription } from 'rxjs';

/**
 * Component used as a dialog for showing a link to the user's survey
 * We are showing this dialog when user is logged
 */
@Component({
    selector: 'app-user-survey-dialog',
    templateUrl: './user-survey-dialog.component.html',
    styleUrls: ['./user-survey-dialog.component.scss'],
})
export class UserSurveyDialogComponent implements OnDestroy {
    /**
     * Form control value for the show survey checkbox
     */
    showSurveyControl = new FormControl(false);

    /**
     * Currently logged-in user
     */
    currentUser: EvaPlusUser = JSON.parse(localStorage.getItem('currentUser'));

    /**
     * Stores all subscriptions
     */
    private destroyed: Subscription | null = null;

    constructor(
        private dialogRef: MatDialogRef<UserSurveyDialogComponent>,
        private userApiServices: UserService
    ) {}

    onButtonClicked(): void {
        // Check if showSurvey is checked, and then send a request to the server
        if (this.showSurveyControl.value === true) {
            const request: ISurveyDoneRequest = {
                id: this.currentUser.id,
                survey_done: true,
            };

            this.destroyed = this.userApiServices.setSurveyState(request).subscribe(() => {
                this.dialogRef.close();
            });
        } else {
            this.dialogRef.close();
        }
    }

    ngOnDestroy(): void {
        this.destroyed?.unsubscribe();
    }
}
