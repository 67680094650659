import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { environment } from '../environments/environment';

const oauthRoutes: Routes = [
    {
        path: 'acquire-token',
        loadChildren: () => import('./auth/acquire-token/acquire-token.module').then((m) => m.AcquireTokenModule),
    },
    {
        path: 'email/unsubscribe',
        loadChildren: () =>
            import('./auth/email-unsubscribe/email-unsubscribe.module').then(
                (m) => m.EmailUnsubscribeModule
            ),
    },
    {
        path: 'service-unavailable',
        loadChildren: () =>
            import('./mongodb-error/mongodb-error.module').then((m) => m.MongodbErrorModule),
    },
    {
        path: '',
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
        canActivate: [AuthGuard],
    },
];

const regularRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'acquire-token',
        loadChildren: () => import('./auth/acquire-token/acquire-token.module').then((m) => m.AcquireTokenModule),
    },
    {
        path: 'password-reset',
        loadChildren: () =>
            import('./auth/password-reset/password-reset.module').then(
                (m) => m.PasswordResetModule
            ),
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import('./auth/password-reset-authorised/password-reset-authorised.module').then(
                (m) => m.PasswordResetAuthorisedModule
            ),
    },
    {
        path: 'email/unsubscribe',
        loadChildren: () =>
            import('./auth/email-unsubscribe/email-unsubscribe.module').then(
                (m) => m.EmailUnsubscribeModule
            ),
    },
    /* {
        path: 'ioms',
        loadChildren: () =>
            import('./ioms-module/ioms.module').then((m) => m.IomsModule),
        canActivate: [AuthGuard],
    }, */
    {
        path: 'service-unavailable',
        loadChildren: () =>
            import('./mongodb-error/mongodb-error.module').then((m) => m.MongodbErrorModule),
    },
    {
        path: '',
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
        canActivate: [AuthGuard],
    },
];

const routes = environment.oauthSupportEnabled ? oauthRoutes : regularRoutes;

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
