import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IChartDataComplete, ICriteriaDataItem, IEventData, ILastTs } from '.';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/journeyService/';

@Injectable({ providedIn: 'root' })
export class JourneyService {
    constructor(private http: EvaPlusHttpService) {}

    //http://localhost:8080/EvaPlusRest/journeyService/getRouteList
    getRouteList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getRouteList');
    }

    //http://localhost:8080/EvaPlusRest/journeyService/getVehicleList
    getVehicleList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getVehicleRecorderList');
    }

    //http://localhost:8080/EvaPlusRest/journeyService/getRailMLList
    getRailMLList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getRailMLList');
    }

    //http://localhost:8080/EvaPlusRest/journeyService/getRailMap
    getRailMap(mapString: string): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getRailMap', { params: { map: mapString } });
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getLastDateForVehicle?vehicleId=357006_A
    lastDateForVehicle(vehicleId: string[]): Observable<ILastTs> {
        let content1 = JSON.stringify({ vehicleId: vehicleId });
        return this.http.post(baseUrl + 'getLastDateForVehicle', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getJourneyEvents?vehicleId=''&from=''&to=''
    getJourneyEvents(
        vehicleId: string[],
        timefrom: string,
        timeto: string,
        routes: string[]
    ): Observable<IEventData[]> {
        let content1 = JSON.stringify({
            vehicleId: vehicleId,
            timefrom: timefrom,
            timeto: timeto,
            routes: routes,
        });
        return this.http.post(baseUrl + 'getJourneyEvents', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getSegmentEvents?vehicleId=''&from=''&to=''&journeyRef=''
    getSegmentEvents(
        journeyid: string[],
        vehicleId: string[],
        timefrom: string,
        timeto: string,
        eventTypes: string[],
        scale: string,
        fromInterval: number,
        toInterval: number
    ): Observable<IEventData[]> {
        let content1 = JSON.stringify({
            journeyid: journeyid,
            vehicleId: vehicleId,
            timefrom: timefrom,
            timeto: timeto,
            eventTypes: eventTypes,
            scale: scale,
            fromInterval: fromInterval,
            toInterval: toInterval,
        });
        return this.http.post(baseUrl + 'getSegmentEvents', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getEvents?vehicleId=''&from=''&to=''&journeyRef=''
    getEvents(
        journeyid: string[],
        vehicleId: string[],
        timefrom: string,
        timeto: string,
        eventTypes: string[],
        scale: string,
        fromInterval: number,
        toInterval: number,
        qrVehicleId: string,
        qrTimeFrom: number,
        qrTimeDuration: number,
        qrTimeStr: string
    ): Observable<IEventData[]> {
        let content1 = JSON.stringify({
            journeyid: journeyid,
            vehicleId: vehicleId,
            timefrom: timefrom,
            timeto: timeto,
            eventTypes: eventTypes,
            scale: scale,
            fromInterval: fromInterval,
            toInterval: toInterval,
            qrVehicleId: qrVehicleId,
            qrFromInterval: qrTimeFrom,
            qrDuration: qrTimeDuration,
            qrTimeStr: qrTimeStr,
        });
        return this.http.post(baseUrl + 'getEvents', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getEvents?vehicleId=''&from=''&to=''&journeyRef=''
    getEventsQr(
        vehicleId: string,
        fromInterval: number,
        toInterval: number
    ): Observable<IEventData[]> {
        let content1 = JSON.stringify({
            vehicleId: vehicleId,
            fromInterval: fromInterval,
            toInterval: toInterval,
        });
        return this.http.post(baseUrl + 'getEvents', content1);
    }

    //http://localhost:8080/EvaPlusRest/timeTableService/getTimeTable?vehicleId=730&from=2018-08-23
    getTimeTable(vehicleId: string, timeFrom: string): Observable<IEventData[]> {
        return this.http.get(baseUrl + 'getTimeTable', { params: { vehicleId, from: timeFrom } });
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getSignalsQueryFulljourneyList=''&journeyRef''&&scale=''&fromInterval=''&toInterval=''
    getChartData(
        journeyId: string[],
        refjourneyid: string[],
        scale: string,
        fromInterval: number,
        toInterval: number,
        distanceFromStart: number
    ): Observable<IChartDataComplete> {
        let content = JSON.stringify({
            journeyList: journeyId,
            journeyRef: refjourneyid,
            scale: scale,
            fromInterval: fromInterval,
            toInterval: toInterval,
            distanceFromStart: distanceFromStart,
        });
        return this.http.post(baseUrl + 'getSignalsQueryFull', content);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getTemplateList
    getTemplateList() {
        return this.http.get(baseUrl + 'getTemplateList');
    }

    // http://localhost:8080/EvaPlusRest/journeyService/getTemplate
    getTemplate(name: string) {
        return this.http.get(baseUrl + 'getTemplate', { params: { name } });
    }

    // http://localhost:8080/EvaPlusRest/journeyService/saveTemplate
    saveTemplate(
        name: string,
        vehicles: string[],
        timefrom: number,
        timeto: number,
        scale: string,
        journeys: string[],
        refjourneys: string[],
        segments: string[],
        refsegments: string[],
        interval: number,
        selectedEventTypes: string[],
        routes: string[],
        eventColorMapping: Map<string, string>
    ) {
        const eventColorMappingObj = {};
        for (let key of Array.from(eventColorMapping.keys())) {
            eventColorMappingObj[key] = eventColorMapping.get(key);
        }
        let content1 = JSON.stringify({
            name: name,
            vehicles: vehicles,
            time_from: timefrom,
            time_to: timeto,
            scale: scale,
            journeys: journeys,
            ref_journeys: refjourneys,
            segments: segments,
            ref_segments: refsegments,
            interval: interval,
            event_types: selectedEventTypes,
            routes: routes,
            eventColorMapping: eventColorMappingObj,
        });
        return this.http.post(baseUrl + 'saveTemplate', content1);
    }

    //http://localhost:8080/EvaPlusRest/journeyService/saveAsTemplate
    saveAsTemplate(
        name: string,
        vehicles: string[],
        timefrom: number,
        timeto: number,
        scale: string,
        journeys: string[],
        refjourneys: string[],
        segments: string[],
        refsegments: string[],
        interval: number,
        selectedEventTypes: string[],
        routes: string[]
    ) {
        let content1 = JSON.stringify({
            name: name,
            vehicles: vehicles,
            time_from: timefrom,
            time_to: timeto,
            scale: scale,
            journeys: journeys,
            ref_journeys: refjourneys,
            segments: segments,
            ref_segments: refsegments,
            interval: interval,
            event_types: selectedEventTypes,
            routes: routes,
        });
        return this.http.post(baseUrl + 'saveAsTemplate', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/deleteTemplate?name='test'
    deleteTemplate(name: string) {
        return this.http.get(baseUrl + 'deleteTemplate', { params: { name } });
    }

    // http://localhost:8080/EvaPlusRest/pivottemplates/templateExistsInDb?name='test'
    templateExistsInDb(name: string): Observable<any> {
        return this.http.get(baseUrl + 'templateExistsInDb', { params: { name } });
    }

    // http://localhost:8080/EvaPlusRest/journeyService/setAsDefaultTemplate?name='test'
    setAsDefaultTemplate(name: string) {
        return this.http.get(baseUrl + 'setAsDefaultTemplate', { params: { name } });
    }

    // http://localhost:8080/EvaPlusRest/journeyService/generateSpeedLimits
    generateSpeedLimits(
        journeys: string[],
        step: number,
        min: number,
        max: number,
        generateCurve: boolean,
        generateLower: boolean,
        generateUpper: boolean,
        useRailMl: boolean,
        useRailMlExtracted: boolean,
        routeCode: string,
        offset: number
    ) {
        let content1 = JSON.stringify({
            journeys: journeys,
            step: step,
            min: min,
            max: max,
            generateCurve: generateCurve,
            generateLower: generateLower,
            generateUpper: generateUpper,
            useRailMl: useRailMl,
            useRailMlExtracted: useRailMlExtracted,
            routeCode: routeCode,
            offset: offset,
        });
        return this.http.post(baseUrl + 'generateSpeedLimits', content1);
    }

    // http://localhost:8080/EvaPlusRest/journeyService/saveSpeedLimits
    saveSpeedLimits(journeys: string[], limits: string[]) {
        let content1 = JSON.stringify({ journeys: journeys, limits: limits });
        return this.http.post(baseUrl + 'saveSpeedLimits', content1);
    }

    //http://localhost:8080/EvaPlusRest/journeyService/getEventTypeList
    getEventTypeList(): Observable<any[]> {
        return this.http.get(baseUrl + 'getEventTypeList');
    }

    getQrPocFlag(): Observable<any> {
        return this.http.get(baseUrl + 'getQrPocFlag');
    }

    generateEvents(eventArray: any): Observable<IEventData[]> {
        let content1 = JSON.stringify({ data: eventArray });
        return this.http.post(baseUrl + 'generateEvents', content1);
    }
}
