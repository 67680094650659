// Modules
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxsModule } from '@ngxs/store';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgGridModule } from 'ag-grid-angular';
import { Angulartics2Module } from 'angulartics2';
import { AngularSlickgridModule, GridOdataService, ResizerService } from 'angular-slickgrid';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DialogsModule } from './dialogs/dialogs.module';

// Components
import { AppComponent } from './app.component';
import {
    GoogleMapsConfig,
    ImageFormatterComponent,
    ImageFormatterComponentVehicles,
} from './dashboard-fleet-location/fleet-location-map/fleet-location-map.component';
import { ReceiverStatusTableCellNotifications } from './dashboard-notification-module/receiver-settings/receiver-settings.component';
import { MatProgressBarComponent } from './components/mat-progress-bar/mat-progress-bar.component';
import { WarningIconFormatterComponent } from './dashboard-ioms/ongoing/dialog-report-preview/dialog-report-preview.component';
import { ImageFormatterComponentPerson } from './dashboard-user-monitoring/user-activities/user-activities.component';

// Providers
import { USER_PROVIDER, USERS_API } from './users/services/user.service';
import { DatePipe } from '@angular/common';
import { FilterPipeInfrastructure } from './dashboard-fleet-location/fleet-location-map/filter-infrastructure.pipe';
import { MomentTimezoneDatePipe } from './pipes/moment-timezone-date/moment-timezone-date.pipe';
import { ManualUploadInterceptor } from '../services/ManualUploadIntecreptor';
import { MultilevelMenuService } from 'ng-material-multilevel-menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthGuard, RoleGuard } from '../services/auth.guard';

// State
import { EvaPlusUserState } from './store/signal-analytics/reducer/eva-plus-user.state';
import { SaToolbarState } from './store/signal-analytics/reducer/toolbar.state';
import { environment, extModules } from '../environments/environment';
import { SaCriteriaState } from './store/signal-analytics/reducer/criteria.state';
import { SaGridState } from './store/signal-analytics/reducer/grid.state';
import { SaSidebarState } from './store/signal-analytics/reducer/sidebar.state';
import { SaCanvasState } from './store/signal-analytics/reducer/canvas.state';
import { SaDrawingToolState } from './store/signal-analytics/reducer/draw-tool.state';
import { SignalAnalyticsModuleState } from './store/signal-analytics/reducer/signal-analytics-module.state';
import { SignalBookmarksState } from './store/signal-analytics/reducer/signal-bookmarks.state';
import { SignalSettingsState } from './store/signal-analytics/reducer/signal-settings.state';
import { SignalChartDrawingOptionsState } from './store/signal-analytics/reducer/signal-chart-drawing-options.state';
import { NotificationMessageState } from './store/signal-analytics/reducer/notification-message.state';

import 'flatpickr/dist/flatpickr.css';
import { MatNativeDateModule } from '@angular/material/core';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { DefaultOAuthInterceptor } from '../config/interceptors/oAuth.interceptor';
import { RequestInterceptor } from '../config/interceptors/request.interceptor';
import { MarkdownModule } from 'ngx-markdown';
import { SpinnerInterceptorService } from './ioms-module/general/intercepter/spinner-intercept/spinner-interceptor.service';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { oauthAllowedUrls, sacheronOauthAllowedUrls } from '../config/sso.config';

export function getAPI(): string {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    let port = window.location.port;
    if (port == '4200') {
        port = '8080';
    }
    return protocol + '//' + hostname + ':' + port;
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CalendarModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        DialogsModule,
        // AgmCoreModule.forRoot({
        //  apiKey: 'AIzaSyCkPJMQ3I8_eHH6wCeqltZj6AywM2pgnpA'
        // apiKey: 'AIzaSyDK86fHHdWSpUxbCn_bvHiH5hwPRVgQlh4'
        // apiKey: JSON.parse(localStorage.getItem('googleApikey')) == null ? "noApiKey": swapStr(JSON.parse(localStorage.getItem('googleApikey')).apiKey,3,5)
        // }),
        AngularSlickgridModule.forRoot(),
        AgmCoreModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AgGridModule.withComponents([
            ImageFormatterComponent,
            ImageFormatterComponentVehicles,
            MatProgressBarComponent,
            ImageFormatterComponentPerson,
            ReceiverStatusTableCellNotifications,
            WarningIconFormatterComponent,
        ]),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: environment.buildFor === 'sacheron' ? sacheronOauthAllowedUrls : oauthAllowedUrls,
                sendAccessToken: false,
            },
        }),
        Angulartics2Module.forRoot(),
        NgxsModule.forRoot([
            SaToolbarState,
            SaCriteriaState,
            SaSidebarState,
            SaCanvasState,
            SaGridState,
            SaDrawingToolState,
            SignalAnalyticsModuleState,
            SignalSettingsState,
            SignalBookmarksState,
            SignalChartDrawingOptionsState,
            NotificationMessageState,
            EvaPlusUserState,
        ]),
        FlatpickrModule.forRoot(),
        MatNativeDateModule,
        CovalentDialogsModule,
        MarkdownModule.forRoot(),
        extModules,
    ], // modules needed to run this module
    providers: [
        Title,
        DatePipe,
        MomentTimezoneDatePipe,
        MatSnackBar,
        AuthGuard,
        FilterPipeInfrastructure,
        AuthGuard,
        RoleGuard,
        GridOdataService,
        ResizerService,
        MultilevelMenuService,
        USER_PROVIDER,
        {
            provide: USERS_API,
            useFactory: getAPI,
        },
        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
        { provide: HTTP_INTERCEPTORS, useClass: ManualUploadInterceptor, multi: true },
        OAuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: environment.oauthSupportEnabled ? DefaultOAuthInterceptor : RequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptorService,
            multi: true,
        },
    ], // additional providers needed for this module
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
