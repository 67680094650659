import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerLoadingService } from '../services/spinner-service/spinner-loading.service';
import { finalize } from 'rxjs/operators';
import { iomsApiStartPoint, iomsEndpoints } from 'app/ioms-module/constnats/apiEndpoints';

@Injectable({
    providedIn: 'root',
})
export class SpinnerInterceptorService implements HttpInterceptor {
    constructor(private spinner: SpinnerLoadingService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req?.url?.toString();
        if (url.includes(iomsApiStartPoint) && !url.includes(iomsEndpoints.health)) {
            this.spinner.setLoading(true, req.url);
            return next.handle(req).pipe(
                finalize(() => {
                    this.spinner.setLoading(false, req.url);
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
