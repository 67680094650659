import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { EvaPlusUser } from '../app/store/signal-analytics/reducer/eva-plus-user.state';
import { OauthTokenExtractorService } from './oauth/oauth-token-extactor.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private oauthTokenExtractorService: OauthTokenExtractorService
    ) {}

    canActivate(): boolean {
        /**
         * we will check if user is logged in,
         * if it is we will return true
         * if it is not we will navigate to login page*/
        if (environment.oauthSupportEnabled) {
            const hasValidAccessToken = this.oauthService.hasValidAccessToken();
            if (hasValidAccessToken && !localStorage.getItem('currentUser')) {
                const currentUser: EvaPlusUser = this.oauthTokenExtractorService.createEvaPlusUserInfoFromToken();
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
            return hasValidAccessToken;
        } else {
            if (localStorage.getItem('currentUser')) {
                // logged in so return true
                return true;
            }

            // not logged in so redirect to login page
            // this.router.navigate(['/login']);
            this.router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {
    currentRoleActions: string;
    allActions: boolean;
    constructor(private router: Router, private _snackBarService: MatSnackBar) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.currentRoleActions = currentUser && currentUser.actions;
            if (this.currentRoleActions != undefined) {
                this.allActions = this.currentRoleActions.indexOf('ALL') != -1;

                // basic
                if (state.url == '/dashboard') {
                    return this.checkIfPossible('APPL_DASH_ACCESS');
                } else if (state.url == '/announcement-list') {
                    return this.checkIfPossible('AS_MODULE_ACCESS');
                } else if (state.url == '/signal') {
                    return this.checkIfPossible('SA_ACCESS');
                } else if (state.url == '/signals-etcs2') {
                    return this.checkIfPossible('SA_ACCESS');
                } else if (state.url == '/event') {
                    return this.checkIfPossible('EA_ACCESS');
                } else if (state.url == '/event-old') {
                    return this.checkIfPossible('EA_ACCESS');
                } else if (state.url == '/teloc-file-management') {
                    return this.checkIfPossible('TF_ACCESS');
                } else if (state.url == '/notification') {
                    return this.checkIfPossible('LD_ACCESS');
                }
                // modules
                else if (state.url == '/fleet-location') {
                    return this.checkIfPossible('FL_ACCESS');
                } else if (state.url == '/fleet-management') {
                    return this.checkIfPossible('FM_ACCESS');
                } else if (state.url == '/teloc') {
                    return this.checkIfPossible('DM_ACCESS');
                } else if (state.url == '/data-coverage') {
                    return this.checkIfPossible('DC_ACCESS');
                } else if (state.url == '/user-dashboard') {
                    return this.checkIfPossible('EA_SEE_DASH');
                } else if (state.url == '/journey-analytics') {
                    return this.checkIfPossible('JA_ACCESS');
                } else if (state.url == '/search') {
                    return this.checkIfPossible('SR_ACCESS');
                } else if (state.url == '/event-generator') {
                    return this.checkIfPossible('EG_MOD_ACCESS');
                } else if (state.url == '/brush') {
                    return this.checkIfPossible('C73_ACCESS');
                } else if (state.url == '/ioms') {
                    return this.checkIfPossible('IOMS_MODULE_ACCESS');
                } else if (state.url == '/data-management') {
                    return this.checkIfPossible('DMM_ACCESS');
                } else if (state.url == '/das') {
                    return this.checkIfPossible('DAS_ACCESS');
                }
                // settings
                else if (state.url == '/users') {
                    return this.checkIfPossible('US_ACCESS');
                } else if (state.url == '/all-code') {
                    return this.checkIfPossible('CS_ACCESS');
                } else if (state.url == '/notification-module') {
                    return this.checkIfPossible('NS_ACCESS');
                } else if (state.url == '/settings-apikey') {
                    return this.checkIfPossible('GOOGLE_API_KEY_MOD');
                } else if (state.url == '/tableau-reports') {
                    return this.checkIfPossible('TABLEAU_ACCESS');
                } else {
                    return true;
                }
            }
        }
    }

    checkIfPossible(role: string) {
        if (this.allActions || this.currentRoleActions.indexOf(role) != -1 ? true : false) {
            return true;
        } else {
            this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                duration: 3000,
            });
            this.router.navigate(['/']);
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentRoleActions = currentUser && currentUser.actions;
        if (this.currentRoleActions != undefined) {
            this.allActions = this.currentRoleActions.indexOf('ALL') != -1;

            /**Event Analytics child routes guard START */
            if (state.url == '/event/event-overview') {
                if (this.allActions || this.currentRoleActions.indexOf('EA_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/event') {
                if (this.allActions || this.currentRoleActions.indexOf('EA_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/event/event-config') {
                if (
                    this.allActions ||
                    (this.currentRoleActions.indexOf('EG_ACCESS') != -1 &&
                        this.currentRoleActions.indexOf('EA_ACCESS') != -1)
                ) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }
            /**Event Analytics child routes guard END */

            /** Teloc Files child routes guard Start */
            if (state.url == '/teloc-file-management') {
                if (
                    this.allActions ||
                    (this.currentRoleActions.indexOf('TF_ACCESS') != -1 &&
                        this.currentRoleActions.indexOf('TF_MAN_UPL') != -1)
                ) {
                    return true;
                } else {
                    this.router.navigate(['/teloc-file-management/processing-progress']);
                    return false;
                }
            }

            if (state.url == '/teloc-file-management/processing-progress') {
                if (this.allActions || this.currentRoleActions.indexOf('TF_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/teloc-file-management/processed') {
                if (this.allActions || this.currentRoleActions.indexOf('TF_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/teloc-file-management/teloc-driver') {
                if (this.allActions || this.currentRoleActions.indexOf('TF_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/teloc-file-management/teloc-route') {
                if (this.allActions || this.currentRoleActions.indexOf('TF_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/teloc-file-management/teloc-event') {
                if (this.allActions || this.currentRoleActions.indexOf('TF_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }
            /**Teloc Files child routes guard END */

            /**Device Manager TELOC child routes guard START */
            if (state.url == '/teloc') {
                if (
                    this.allActions ||
                    (this.currentRoleActions.indexOf('DM_ACCESS') != -1 &&
                        this.currentRoleActions.indexOf('DM_OVRW') != -1)
                ) {
                    return true;
                } else {
                    this.router.navigate(['/teloc/details']);
                    return false;
                }
            }

            if (state.url == '/teloc/details') {
                if (this.allActions || this.currentRoleActions.indexOf('DM_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }
            /**Device Manager TELOC child routes guard END */

            /**Fleet Management TELOC child routes guard START */
            if (state.url == '/fleet-management') {
                if (
                    this.allActions ||
                    (this.currentRoleActions.indexOf('FM_ACCESS') != -1 &&
                        this.currentRoleActions.indexOf('VD_ACCESS') != -1)
                ) {
                    return true;
                } else {
                    this.router.navigate(['/fleet-management/vehicle-dashboard']);
                    return false;
                }
            }

            if (state.url == '/fleet-management/vehicle-dashboard') {
                if (this.allActions || this.currentRoleActions.indexOf('FM_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }
            /**Fleet Management TELOC child routes guard END */

            /**Event Generator Module child routes guard START */
            if (state.url == '/event-generator') {
                if (this.allActions || this.currentRoleActions.indexOf('EG_MOD_ACCESS') != -1) {
                    return true;
                } else {
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/event-generator/create') {
                if (this.allActions || this.currentRoleActions.indexOf('EG_MOD_ACCESS') != -1) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }

            if (state.url == '/event-generator/file') {
                if (
                    this.allActions ||
                    (this.currentRoleActions.indexOf('EG_MOD_ACCESS') != -1 &&
                        this.currentRoleActions.indexOf('EG_FILE_ACCESS') != -1)
                ) {
                    return true;
                } else {
                    this._snackBarService.open('Access forbidden-based on user role', 'Ok', {
                        duration: 3000,
                    });
                    this.router.navigate(['/']);
                    return false;
                }
            }
            /**Event Generator Module child routes guard END */

            return true;
        }
    }
}
