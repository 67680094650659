import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    text: string;
    name: string;
}

@Component({
    selector: 'draw-text-dialog.component',
    templateUrl: 'draw-text-dialog.component.html',
})
export class DialogDrawText {
    constructor(
        public dialogRef: MatDialogRef<DialogDrawText>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
