import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'checkbox-disabled-renderer',
    template: `
        <input disabled type="checkbox" (click)="checkedHandler($event)" [checked]="params.value" />
    `,
})
export class CheckboxDisabledRenderer implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }

    refresh(params: any): boolean {
        this.params = params;
        return true;
    }
}
