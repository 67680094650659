import { Injectable } from '@angular/core';
// import { Response } from '@angular/http';
// import { HttpInterceptorService } from '@covalent/http';
import { EvaPlusHttpService } from '../services/http/eva-http.service';
import { Observable } from 'rxjs';

export class IomsTeamLevel {
    mongoId: string;
    id: string;
    name: string;
    parent: string;
    location: string;
}

export class IomsDeviceLevelTypes {
    name: string;
    allowedDepth: string;
}

export class IomsTeam {
    id: string;
    teamLevelId: string;
    name: string;
    email: string;
    location: string;
    position: string;
}

const baseUrl = '/EvaPlusRest/iomsTeamService/';

@Injectable({ providedIn: 'root' })
export class IomsTeamManagerService {
    constructor(private _http: EvaPlusHttpService) {}

    // levels...

    // is ioms team level in db
    iomsTeamLevelExistsInDb(level: string): Observable<any> {
        return this._http.get(baseUrl + 'iomsTeamLevel/existsInDb/' + level);
    }

    // add ioms team level to db
    addIomsTeamLevel(
        id: string,
        name: string,
        parentId: string,
        location: string
    ): Observable<any> {
        let payload = JSON.stringify({
            id: id,
            name: name,
            parentId: parentId,
            location: location,
        });
        return this._http.post(baseUrl + 'iomsTeamLevel/addLevel', payload);
    }

    // copy ioms team level
    copyIomsTeamLevel(
        selectedLevelId: string,
        copyLevelId: string,
        name: string,
        parentId: string,
        location: string
    ): Observable<any> {
        let payload = JSON.stringify({
            selectedLevelId: selectedLevelId,
            copyLevelId: copyLevelId,
            name: name,
            parentId: parentId,
            location: location,
        });
        return this._http.post(baseUrl + 'iomsTeamLevel/copyLevel', payload);
    }

    // edit ioms team level in db
    editIomsTeamLevel(
        oldId: string,
        updatedId: string,
        name: string,
        parentId: string,
        location: string
    ): Observable<any> {
        let payload = JSON.stringify({
            oldId: oldId,
            updatedId: updatedId,
            name: name,
            parentId: parentId,
            location: location,
        });
        return this._http.post(baseUrl + 'iomsTeamLevel/editLevel', payload);
    }

    // delete ioms team level in db
    deleteIomsTeamLevel(id: string): Observable<any> {
        let payload = JSON.stringify({ id: id });
        return this._http.post(baseUrl + 'iomsTeamLevel/deleteLevel', payload);
    }

    // load team levels
    getTeamLevelList(): Observable<IomsTeamLevel[]> {
        let uriString = '/EvaPlusRest/iomsTeamService/teamLevelList';
        return this._http.get(uriString);
    }

    // teams...
    // is ioms team member in db
    iomsTeamMemberExistsInDb(id: string): Observable<any> {
        return this._http.get(baseUrl + 'iomsTeam/existsInDb/' + id);
    }

    // add ioms team member to db
    addIomsTeamMember(
        teamLevelId: string,
        peopleId: string,
        name: string,
        email: string,
        phone: string,
        location: string,
        position: string
    ): Observable<any> {
        let payload = JSON.stringify({
            teamLevelId: teamLevelId,
            peopleId: peopleId,
            name: name,
            email: email,
            phone: phone,
            location: location,
            position: position,
        });
        return this._http.post(baseUrl + 'iomsTeam/addTeam', payload);
    }

    // edit ioms team member to db
    editIomsTeamMember(
        mongoId: string,
        teamLevelId: string,
        id: string,
        name: string,
        email: string,
        phone: string,
        location: string,
        position: string
    ): Observable<any> {
        let payload = JSON.stringify({
            mongoId: mongoId,
            teamLevelId: teamLevelId,
            id: id,
            name: name,
            email: email,
            phone: phone,
            location: location,
            position: position,
        });
        return this._http.post(baseUrl + 'iomsTeam/editTeam', payload);
    }

    // delete ioms team memmber in db
    deleteIomsTeamMember(id: string): Observable<any> {
        let payload = JSON.stringify({ id: id });
        return this._http.post(baseUrl + 'iomsTeam/deleteTeam', payload);
    }

    // load teams
    getTeams(): Observable<IomsTeam[]> {
        return this._http.get(baseUrl + 'teams');
    }

    // // manual import team memberes check duplicates
    // checkDuplicatesAndSaveDataCsv(file: File):Observable<any> {
    //     let uriString = this.getMockApi() + '/EvaPlusRest/iomsTeamService/checkDuplicatesCsv';
    //     let formData = new FormData();
    //     formData.append('file', file, file.name);
    //     return this._http.post(uriString, formData).pipe(
    //         map((res: Response) => {
    //             return res.json();
    //             })
    //         );
    // }
    // manual import team memberes check duplicates
    checkDuplicatesCsv(file: File): Observable<any> {
        let formData = new FormData();
        formData.append('file', file, file.name);
        return this._http.post(baseUrl + 'checkDuplicatesCsv', formData);
    }

    // manual import (single file)
    fileUpload(file: File, teamLevelId: string): Observable<any> {
        let formData = new FormData();
        formData.append('file', file, file.name);
        // formData.append('teamLevelId', teamLevelId);
        return this._http.post(baseUrl + 'fileUpload/' + teamLevelId, formData);
    }
}
