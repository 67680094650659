import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IomsSingleAction } from '../../../../services/iomsAction.service';
import {
    IomsActionType,
    IomsDevice,
    IomsDeviceManagerService,
} from '../../../../services/iomsdevicemanger.service';
import {
    DialogScheduleAction,
    DialogScheduleActionDataI,
} from '../dialog/dialog-schedule-action/dialog-schedule-action.component';
import { deviceTreeI } from './load-devices.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleActionService {
    constructor(
        public _dialog: MatDialog,
        private _iomsDeviceManagementService: IomsDeviceManagerService, //SCHEDULE ACTION
        private _snackBar: MatSnackBar //SCHEDULE ACTION
    ) {}

    /**
     * Schedules a new action
     * @param data to save
     * @returns true if success, false otherwise
     */
    public async scheduleAction(
        action: IomsSingleAction,
        user: any,
        actionTypes: IomsActionType[],
        lines: deviceTreeI[],
        devices: IomsDevice[]
    ): Promise<boolean> {
        const data: DialogScheduleActionDataI = {
            // Initial selected data in dialog
            start: new Date(action.start),
            end: new Date(action.end),
            duration: isNaN(+`0${action.duration}`) ? 0 : +action.duration,
            description: action.description,
            user,
            actionType: action.actionType,
            device: action.deviceLevelId,
            equipment: action.equipmentIds,
            workInstructionsIds: action.selectedWorkInstructionsIds,
            // General Information
            actionTypes,
            lines,
            devices,
            isSeries: false,
        };
        const dialog = this._dialog.open(DialogScheduleAction, {
            height: '700px',
            width: '900px',
            autoFocus: false,
            data,
            // disableClose: true,
        });
        const dialogData = await dialog.afterClosed().toPromise();

        if (dialogData) {
            let toSave: IomsSingleAction[] = [
                {
                    mongoId: action.mongoId,
                    userId: action.userId,
                    seriesActionNumber: Number(action.seriesActionNumber),
                    actionNumber: action.actionNumber,
                    creationDateMills: action.creationDateMills,
                    start: new Date(dialogData.start).getTime(),
                    end: new Date(dialogData.end).getTime(),
                    duration: dialogData.duration.toString(),
                    actionType: dialogData.actionType,
                    deviceLevelId: dialogData.device,
                    description: dialogData.description,
                    reportId: '',
                    location: dialogData.location,
                    selectedEquipmentIds: dialogData.equipment,
                    selectedWorkInstructionsIds: dialogData.workInstructionsIds,
                    equipmentIds: dialogData.equipmentIds,
                    workInstructionsIds: dialogData.workInstructionsIds,
                    actionReportId: action.actionReportId,
                    assigned: false, // Reschedule loose validations
                    status: '', // Reschedule loose validations
                },
            ];
            let res: string;
            try {
                if (action.mongoId) {
                    // Existing action
                    res = await this._iomsDeviceManagementService
                        .editIndividualScheduleEvents(toSave)
                        .toPromise();
                } else {
                    // New action
                    res = await this._iomsDeviceManagementService
                        .saveIndividualScheduleEvents(toSave)
                        .toPromise();
                }
                this._snackBar.open('Action saved', 'Ok', { duration: 5000 });
                return true;
            } catch (e) {
                this._snackBar.open('Error while saving!', 'Ok', { duration: 5000 });
            }
        }
        return false;
    }
}
