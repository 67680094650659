import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/pivottemplates/';

export interface IEventTemplateEntity {
    user: string;
    name: string;
    content: string;
    vehicles: string[];
    events: string[];
    isDefault: boolean;
    timefromrelative: string;
    timetorelative: string;
    paramforlabel: string;
    usedimensionlabel: boolean;
    markertransparencymapview: string;
    markersize: number;
    selecteddimensionmarkersize: string;
    usedimensionsize: boolean;
    labelson: boolean;
    defaulttab: string;
    mapLat: number;
    mapLon: number;
    mapZoom: number;
    usedimensiontransparency: boolean;
    selecteddimensiontransparency: string;
    transparencyinvert: boolean;
    sizeinvert: boolean;
    templateIsPublic: boolean;
}

@Injectable({ providedIn: 'root' })
export class PivotTableServices {
    constructor(private http: EvaPlusHttpService) {}

    getTemplateListSrv(userName: string) {
        return this.http.get(baseUrl + 'list/' + userName);
    }

    saveAsTemplateSrv(name: string, content: string, isDefault: boolean, user: string) {
        let content1 = JSON.stringify({
            name: name,
            content: content,
            isDefault: isDefault,
            user: user,
        });
        return this.http.post(baseUrl + 'saveas', content1);
    }

    saveTemplateSrv(id: string, name: string, content: string, isDefault: boolean, user: string) {
        let content1 = JSON.stringify({
            id: id,
            name: name,
            content: content,
            isDefault: isDefault,
            user: user,
        });
        return this.http.post(baseUrl + 'save', content1);
    }

    templateExistsSrv(name: string, user: string): Observable<any> {
        return this.http.get(baseUrl + 'nameexists', { params: { name, user } });
    }

    deleteTemplateSrv(id: string, user: string) {
        return this.http.get(baseUrl + 'delete', { params: { id, user } });
    }

    setAsDefaultSrv(id: string, user: string) {
        return this.http.get(baseUrl + 'setasdefault', { params: { id, user } });
    }

    getTemplateListEA2Srv(userName: string): Observable<IEventTemplateEntity[]> {
        return this.http.get(baseUrl + 'listEA2/' + userName);
    }

    saveTemplateEA2Srv(
        name: string,
        content: string,
        user: string,
        vehicles: string[],
        events: string[],
        timefromrelative: string,
        timetorelative: string,
        selectedForLabel: string,
        useDimensionForLabel: boolean,
        markertransparencymapview: string,
        markersize: number,
        useDimensionForSize: boolean,
        selectedDimensionForMarkerSize: string,
        labelsOn: boolean,
        tabOpened: string,
        mapLat: number,
        mapLon: number,
        mapZoom: number,
        usedimensiontransparency: boolean,
        selecteddimensiontransparency: string,
        transparencyinvert: boolean,
        sizeinvert: boolean,
        checkedAsPublicTemplate: boolean
    ) {
        // *** private or public template ***
        let content1 = JSON.stringify({
            name: name,
            content: content,
            user: user,
            vehicles: vehicles,
            events: events,
            timefromrelative: timefromrelative,
            timetorelative: timetorelative,
            paramforlabel: selectedForLabel,
            usedimensionlabel: useDimensionForLabel,
            markertransparencymapview: markertransparencymapview,
            markersize: markersize,
            usedimensionsize: useDimensionForSize,
            selecteddimensionmarkersize: selectedDimensionForMarkerSize,
            labelson: labelsOn,
            defaulttab: tabOpened,
            maplat: mapLat,
            maplon: mapLon,
            mapzoom: mapZoom,
            usedimensiontransparency: usedimensiontransparency,
            selecteddimensiontransparency: selecteddimensiontransparency,
            transparencyinvert: transparencyinvert,
            sizeinvert: sizeinvert,
            publictemplate: checkedAsPublicTemplate,
        }); // *** private or public template ***
        return this.http.post(baseUrl + 'saveTemplateEA2', content1);
    }

    saveAsTemplateEA2Srv(
        name: string,
        content: string,
        vehicles: string[],
        events: string[],
        user: string,
        timefromrelative: string,
        timetorelative: string,
        selectedForLabel: string,
        useDimensionForLabel: boolean,
        markertransparencymapview: string,
        markersize: number,
        useDimensionForSize: boolean,
        selectedDimensionForMarkerSize: string,
        labelsOn: boolean,
        tabOpened: string,
        mapLat: number,
        mapLon: number,
        mapZoom: number,
        usedimensiontransparency: boolean,
        selecteddimensiontransparency: string,
        transparencyinvert: boolean,
        sizeinvert: boolean,
        checkedAsPublicTemplate: boolean
    ) {
        // *** private or public template ***
        let content1 = JSON.stringify({
            name: name,
            content: content,
            user: user,
            vehicles: vehicles,
            events: events,
            timefromrelative: timefromrelative,
            timetorelative: timetorelative,
            paramforlabel: selectedForLabel,
            usedimensionlabel: useDimensionForLabel,
            markertransparencymapview: markertransparencymapview,
            markersize: markersize,
            usedimensionsize: useDimensionForSize,
            selecteddimensionmarkersize: selectedDimensionForMarkerSize,
            labelson: labelsOn,
            defaulttab: tabOpened,
            maplat: mapLat,
            maplon: mapLon,
            mapzoom: mapZoom,
            usedimensiontransparency: usedimensiontransparency,
            selecteddimensiontransparency: selecteddimensiontransparency,
            transparencyinvert: transparencyinvert,
            sizeinvert: sizeinvert,
            publictemplate: checkedAsPublicTemplate,
        }); // *** private or public template ***
        return this.http.post(baseUrl + 'saveasTemplateEA2', content1);
    }

    deleteTemplateEA2Srv(name: string, user: string) {
        return this.http.get(baseUrl + 'deleteEA2', { params: { name, user } });
    }

    templateExistsEA2Srv(name: string, user: string): Observable<any> {
        return this.http.get(baseUrl + 'nameexistsEA2', { params: { name, user } });
    }

    publicTemplateExistsEA2Srv(name: string): Observable<any> {
        return this.http.get(baseUrl + 'publicexistsEA2', { params: { name } });
    }

    publicTemplateExists2EA2Srv(name: string, user: string): Observable<any> {
        return this.http.get(baseUrl + 'publicexists2EA2', { params: { name, user } });
    }

    setAsDefaultEA2Srv(id: string, user: string) {
        return this.http.get(baseUrl + 'setasdefaultEA2', { params: { name: id, user } });
    }

    importTemplates(file: File): Observable<any> {
        let uriString = baseUrl + 'importtemplates';

        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(uriString, formData, {
            disableAutoContentTypeHader: true,
        });
    }

    exportTemplate(templateId: string) {
        return this.http.get(baseUrl + 'exporttemplate/' + templateId);
    }

    exportAllTemplates() {
        return this.http.get(baseUrl + 'exportalltemplates');
    }
}
