import { Injectable } from '@angular/core';
import { EvaPlusHttpService } from './http/eva-http.service';
import { Observable } from 'rxjs';

export class IomsDeviceLevel {
    mongoId: string;
    id: string;
    name: string;
    parent: string;
    location: string;
    type: string;
    serialNumber: string;
    workInstructions: string;
    cubicleType: string;
    description: string;
}

export class IomsDeviceLevelTypes {
    name: string;
    allowedDepth: string;
    allowedDevices: boolean;
    isCubicle: boolean;
}

export class IomsDeviceType {
    id: string;
    name: string;
    description: string;
    workInstructions: string;
    workInstructionNameAndId: WorkInstructionNameAndId[];
    workInstructionNames: string = '';
}

export class WorkInstructionNameAndId {
    name: string;
    objectId: string;
}

export class IomsDevice {
    mongoId: string;
    id: string;
    typeId: string;
    levelId: string;
    sn: string;
    description: string;
    workInstructions: string;
    workInstructionNameAndId: WorkInstructionNameAndId[];
    workInstructionNames: string = '';
    location: string;
}

export interface IomsActionType {
    mongoId: string;
    actionType: string;
}

@Injectable({ providedIn: 'root' })
export class IomsDeviceManagerService {
    constructor(private _http: EvaPlusHttpService) {}

    // levels...

    // is ioms level in db
    isIomsDeviceLevelInDb(level: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicelevel/existsInDb/' + level;
        return this._http.get(uriString);
    }

    // add ioms device level to db
    addIomsDeviceLevel(
        id: string,
        name: string,
        parentId: string,
        location: string,
        type: string,
        serialNumber?: string,
        workInstructions?: string[],
        cubicleType?: string,
        description?: string
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicelevel/addlevel';
        let payload = JSON.stringify({
            id: id,
            name: name,
            parentId: parentId,
            location: location,
            type: type,
            serialNumber: serialNumber,
            workInstructions: workInstructions,
            cubicleType: cubicleType,
            description: description,
        });
        return this._http.post(uriString, payload);
    }

    // edit ioms device level in db
    editIomsDeviceLevel(
        oldId: string,
        updatedId: string,
        name: string,
        parentId: string,
        location: string,
        type: string
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicelevel/editlevel';
        let payload = JSON.stringify({
            oldId: oldId,
            updatedId: updatedId,
            name: name,
            parentId: parentId,
            location: location,
            type: type,
        });
        return this._http.post(uriString, payload);
    }

    // copy ioms device level
    copyIomsDeviceLevel(
        selectedLevelId: string,
        copyLevelId: string,
        name: string,
        parentId: string,
        location: string,
        type: string
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicelevel/copylevel';
        let payload = JSON.stringify({
            selectedLevelId: selectedLevelId,
            copyLevelId: copyLevelId,
            name: name,
            parentId: parentId,
            location: location,
            type: type,
        });
        return this._http.post(uriString, payload);
    }

    // delete ioms device level in db
    deleteIomsDeviceLevel(id: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicelevel/deletelevel';
        let payload = JSON.stringify({ id: id });
        return this._http.post(uriString, payload);
    }

    // load device levels
    loadDeviceLevelsSrv(): Observable<IomsDeviceLevel[]> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/devicelevellist';
        return this._http.get(uriString);
    }

    // load device level types
    loadDeviceLevelTypesSrv(): Observable<IomsDeviceLevelTypes[]> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/deviceleveltypelist';
        return this._http.get(uriString);
    }

    // types...

    // is ioms type in db
    isIomsDeviceTypeInDb(typeName: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicetype/existsInDb/' + typeName;
        return this._http.get(uriString);
    }

    // add ioms device type to db
    addIomsDeviceType(
        name: string,
        description: string,
        workInstructions: string[]
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevicetype/addtype';
        let payload = JSON.stringify({
            name: name,
            description: description,
            workInstructions: workInstructions,
        });
        return this._http.post(uriString, payload);
    }

    // edit ioms device type in db
    editIomsDeviceType(
        id: string,
        oldName: string,
        name: string,
        description: string,
        workInstructions: string[]
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/edittype';
        let payload = JSON.stringify({
            id: id,
            oldName: oldName,
            name: name,
            description: description,
            workInstructions: workInstructions,
        });
        return this._http.post(uriString, payload);
    }

    // delete ioms device type in db
    deleteIomsDeviceType(typeName: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/deletetype';
        let payload = JSON.stringify({ typeName: typeName });
        return this._http.post(uriString, payload);
    }

    // load device types
    loadDeviceTypesSrv(): Observable<IomsDeviceType[]> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/deviceTypeList';
        return this._http.get(uriString);
    }

    // devices...
    // is ioms device in db
    isIomsDeviceInDb(sn: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/existsInDb/' + sn;
        return this._http.get(uriString);
    }

    // add ioms device to db
    addIomsDevice(
        levelId: string,
        id: string,
        typeId: string,
        sn: string,
        description: string,
        location: string,
        workInstructions: string[]
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/adddevice';
        let payload = JSON.stringify({
            levelId: levelId,
            id: id,
            typeId: typeId,
            sn: sn,
            description: description,
            location: location,
            workInstructions: workInstructions,
        });
        return this._http.post(uriString, payload);
    }

    // edit ioms device to db
    editIomsDevice(
        mongoId: string,
        levelId: string,
        id: string,
        typeId: string,
        sn: string,
        description: string,
        workInstructions: string[],
        location: string
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/editdevice';
        let payload = JSON.stringify({
            mongoId: mongoId,
            levelId: levelId,
            id: id,
            typeId: typeId,
            sn: sn,
            description: description,
            workInstructions: workInstructions,
            location: location,
        });
        return this._http.post(uriString, payload);
    }

    // delete ioms device in db
    deleteIomsDevice(mongoId: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/deletedevice';
        let payload = JSON.stringify({ mongoId: mongoId });
        return this._http.post(uriString, payload);
    }

    // load devices
    loadDevicesSrv(): Observable<IomsDevice[]> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/deviceslist';
        return this._http.get(uriString);
    }

    csvImportSrv(fileContent: any): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/csvImport';
        let payload = JSON.stringify({ fileContent: fileContent });
        let headers = new Headers();
        headers.append('Content-Type', 'text/csv'); // ???
        return this._http.post(uriString, payload);
    }

    // get action types
    getActionTypesSrv(): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/getActionTypes';
        return this._http.get(uriString);
    }

    // save scheduled action to db
    saveActionSeries(
        //SCHEDULE ACTION
        userId: string,
        actionNumber: number,
        creationDateMills: number,
        eventStart: number,
        eventEnd: number,
        selectedDuration: string,
        rrule: any,
        actionType: string,
        deviceLevelId: string,
        briefDescription: string,
        reportId: string,
        location: string,
        selectedEquipmentIds: string[],
        selectedWorkInstructionsIds: string[],
        selectedRepeatOption: number
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/saveScheduleAction';
        let payload = JSON.stringify({
            userId: userId,
            actionNumber: actionNumber,
            creationDateMills: creationDateMills,
            eventStart: eventStart,
            eventEnd: eventEnd,
            selectedDuration: selectedDuration,
            rrule: rrule,
            actionType: actionType,
            deviceLevelId: deviceLevelId,
            briefDescription: briefDescription,
            reportId: reportId,
            location: location,
            selectedEquipmentIds: selectedEquipmentIds,
            selectedWorkInstructionsIds: selectedWorkInstructionsIds,
            selectedRepeatOption: selectedRepeatOption,
        });
        return this._http.post(uriString, payload);
    }

    // edit scheduled action to db
    editScheduleAction(
        //SCHEDULE ACTION
        mongoId: string,
        userId: string,
        actionNumber: number,
        creationDateMills: number,
        eventStart: number,
        eventEnd: number,
        selectedDuration: string,
        rrule: any,
        actionType: string,
        deviceLevelId: string,
        briefDescription: string,
        reportId: string,
        location: string,
        selectedEquipmentIds: string[],
        selectedWorkInstructionsIds: string[],
        selectedRepeatOption: number,
        dateLimit: number
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/editScheduleAction';
        let payload = JSON.stringify({
            userId: userId,
            mongoId: mongoId,
            actionNumber: actionNumber,
            creationDateMills: creationDateMills,
            eventStart: eventStart,
            eventEnd: eventEnd,
            selectedDuration: selectedDuration,
            rrule: rrule,
            actionType: actionType,
            deviceLevelId: deviceLevelId,
            briefDescription: briefDescription,
            reportId: reportId,
            location: location,
            selectedEquipmentIds: selectedEquipmentIds,
            selectedWorkInstructionsIds: selectedWorkInstructionsIds,
            selectedRepeatOption: selectedRepeatOption,
            dateLimit: dateLimit,
        });
        return this._http.post(uriString, payload);
    }

    // get scheduled actions
    getScheduledActionsSrv(): Observable<any[]> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/getScheduleActions';
        return this._http.get(uriString);
    }

    // delete ioms event series
    deleteEventSeries(seriesActionNumber: number): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/deleteEventSeries';
        let payload = JSON.stringify({ seriesActionNumber: seriesActionNumber });
        return this._http.post(uriString, payload);
    }

    // delete ioms event series
    deleteEventSeriesNew(seriesActionNumber: number, dateLimit: number): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/deleteEventSeriesNew';
        let payload = JSON.stringify({
            seriesActionNumber: seriesActionNumber,
            dateLimit: dateLimit,
        });
        return this._http.post(uriString, payload);
    }

    // save scheduled action to db
    saveIndividualScheduleEvents(
        //SCHEDULE ACTION
        events: any
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/saveIndividualEvents';
        let payload = JSON.stringify({ events: events });
        return this._http.post(uriString, payload);
    }

    // get scheduled actions
    getSingleActionsSrv(): Observable<any[]> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/getSingleActions';
        return this._http.get(uriString);
    }

    // get current user info
    getUserFromDbSrv(userName: number): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/getUser';
        let payload = JSON.stringify({ userName: userName });
        return this._http.post(uriString, payload);
    }

    // get all users info
    getAllUsersSrv(): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/getAllUsers';
        return this._http.get(uriString);
    }

    // edit scheduled action to db
    assignIndividualAction(
        //SCHEDULE ACTION
        mongoId: string,
        teamId: string,
        assignedBy: string,
        teamName: string
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/assignIndividualAction';
        let payload = JSON.stringify({
            mongoId: mongoId,
            teamId: teamId,
            assignedBy: assignedBy,
            teamName: teamName,
        });
        return this._http.post(uriString, payload);
    }

    // edit single action
    editIndividualScheduleEvents(
        //SCHEDULE ACTION
        event: any
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/editIndividualEvents';
        let payload = JSON.stringify({ event: event });
        return this._http.post(uriString, payload);
    }

    // delete single action
    deleteSingleAction(mongoId: string): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/iomsdeviceservice/iomsdevice/deleteSingleAction';
        let payload = JSON.stringify({ mongoId: mongoId });
        return this._http.post(uriString, payload);
    }
}
