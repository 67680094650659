import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EvaPlusHttpService {
    constructor(private http: HttpClient, private router: Router) {}

    public get(url: string, options?: any): Observable<any> {
        return this.http
            .get(this.getAPIBaseUrl() + url, this.requestOptions(options))
            .pipe(catchError((error) => this.handleError(error, this.getAPIBaseUrl() + url)));
    }

    public post(url: string, payload: any, options?: any): Observable<any> {
        if (options?.cancelSubject) {
            return this.http.post(this.getAPIBaseUrl() + url, payload, this.requestOptions(options)).pipe(
                takeUntil(options.cancelSubject),
                catchError((error) => this.handleError(error, this.getAPIBaseUrl() + url))
            );
        } else {
            return this.http
                .post(this.getAPIBaseUrl() + url, payload, this.requestOptions(options))
                .pipe(catchError((error) => this.handleError(error, this.getAPIBaseUrl() + url)));
        }
    }

    public put(url: string, payload: any, options?: any): Observable<any> {
        return this.http
            .put(this.getAPIBaseUrl() + url, payload, this.requestOptions(options))
            .pipe(catchError((error) => this.handleError(error, this.getAPIBaseUrl() + url)));
    }

    public delete(url: string, options?: any): Observable<any> {
        return this.http
            .delete(this.getAPIBaseUrl() + url, this.requestOptions(options))
            .pipe(catchError((error) => this.handleError(error, this.getAPIBaseUrl() + url)));
    }

    public getAsset(url: string, options?: any): Observable<any> {
        if (options?.cancelSubject) {
            return this.http.get(url, this.requestOptions(options)).pipe(
                takeUntil(options.cancelSubject),
                catchError((error) => this.handleError(error, url))
            );
        } else {
            return this.http
                .get(url, this.requestOptions(options))
                .pipe(catchError((error) => this.handleError(error, url)));
        }
    }

    protected requestOptions(options: any): any {
        let reqestOptions: any = { ...options };

        if (reqestOptions.params) {
            reqestOptions.params = new HttpParams({ fromObject: reqestOptions.params });
        }

        reqestOptions.headers = new HttpHeaders(this.compileHeaders(options));

        return reqestOptions;
    }

    protected compileHeaders(options: any) {
        let headers = options && options.disableAutoContentTypeHader ? {} : { 'Content-Type': 'application/json' };

        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = token;
        }

        if (options?.headers) {
            Object.keys(options.headers).forEach((key) => {
                headers[key] = options.headers[key];
            });
        }

        return headers;
    }

    protected getAPIBaseUrl(): string {
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;

        var port = window.location.port;

        if (port == '4200') {
            port = '8080';
        }

        var url = protocol + '//' + hostname + ':' + port;
        return url;
    }

    protected handleError(error: HttpErrorResponse, url: string) {
        if (error.status === 403 || error.status === 401) {
            this.router.navigate(['/login']);
        }

        if (isDevMode()) {
            console.log('HTTP ERROR OBJECT: ', error, ' TYPE: ', typeof error, ' URL: ', url);
            console.error(
                'Server returned code for URL: ',
                error.url,
                ' ERROR: ',
                error.error,
                ' NAME: ',
                error.name,
                ' MESSAGE: ',
                error.message,
                ' STATUS: ',
                error.status,
                ' STATUS TEXT: ',
                error.statusText,
                ' TYPE: ',
                error.type
            );
        }

        // Return an observable with a user-facing error message.
        return throwError(error);
    }
}
