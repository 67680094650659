import { Injectable } from '@angular/core';
import { EvaPlusHttpService } from './http/eva-http.service';

@Injectable({ providedIn: 'root' })
export class DisFileService {
    constructor(private http: EvaPlusHttpService) {}

    getTel2DisFilesList(vehicleid: string, timeFrom: string, timeto: string) {
        const queryParams = {
            vehicleid: vehicleid,
            timefrom: timeFrom,
            timeto: timeto,
        };

        return this.http.get('/EvaPlusRest/disfile/getTel2DisFilesList', {
            params: queryParams,
        });
    }

    reprocessFiles(vehicleId: string, files: string[]) {
        let payload = JSON.stringify({ vehicleId: vehicleId, files: files });

        console.log('reprocessFiles payload: ' + payload);
        return this.http.post('/EvaPlusRest/disfile/reprocessFiles', payload);
    }
}
