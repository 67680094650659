import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TENANT } from '../config/api.config';
import { EvaPlusHttpService } from './http/eva-http.service';
import { ICriteriaDataItem } from './signal.service';

export interface IProcessingFile {
    vehicle_id: String;
    start_time: string | number;
    end_time: string | number;
    duration: String;
    name: String;
    status: String;
    memory: String;
    memoryProcessed: String;
    fileSize: number;
    processedSize: number;
    progresPercentage: number;
    message: String;
    data_from: String;
    data_to?: String;
    countingStatus?: String;

    // configIssue?: string;
}

export interface IFileInfo {
    vehicleId: string;
    time: number;
    progresPercentage: number;
}

@Injectable({ providedIn: 'root' })
export class ProcessingStatus {
    constructor(private http: EvaPlusHttpService) {}

    // FILES PROCESSING
    getAllFilesStatusProcessing(): Observable<IProcessingFile[]> {
        var uriString = '/EvaPlusRest/processingstatus/processing?tenant=' + TENANT;
        return this.http.get(uriString);
    }

    // FILES REPROCESSING
    getAllFilesStatusReprocessing(): Observable<IProcessingFile[]> {
        var uriString = '/EvaPlusRest/processingstatus/reprocessing?tenant=' + TENANT;
        return this.http.get(uriString);
    }

    // FILES PROCESSING
    getAllManualFilesStatusProcessing(): Observable<IProcessingFile[]> {
        var uriString = '/EvaPlusRest/processingstatus/manualstatus?tenant=' + TENANT;
        return this.http.get(uriString);
    }

    // FILES PROCESSING
    getRailFilesList(): Observable<IProcessingFile[]> {
        var uriString = '/EvaPlusRest/manualimport/railFileList';
        return this.http.get(uriString);
    }
    // FILES PROCESSED FILTERED
    getFilteredFilesStatusProcessedSrv(
        vehicleid: string,
        timefrom: string,
        timeto: string,
        reprocessFlag: boolean,
        page?: number
    ): Observable<IProcessingFile[]> {
        var uriString =
            '/EvaPlusRest/processingstatus/processed?tenant=' +
            TENANT +
            '&vehicleid=' +
            vehicleid +
            '&timefrom=' +
            timefrom +
            '&timeto=' +
            timeto +
            '&reprocessing=' +
            reprocessFlag +
            '&page=' +
            page;
        return this.http.get(uriString);
    }

    // FILES PROCESSED FILTERED COUNT
    getFilteredFilesStatusProcessedCountSrv(
        vehicleid: string,
        timefrom: string,
        timeto: string,
        reprocessFlag: boolean
    ): Observable<any> {
        var uriString =
            '/EvaPlusRest/processingstatus/processedcount?tenant=' +
            TENANT +
            '&vehicleid=' +
            vehicleid +
            '&timefrom=' +
            timefrom +
            '&timeto=' +
            timeto +
            '&reprocessing=' +
            reprocessFlag;
        return this.http.get(uriString);
    }

    // GET VEHICLES IDs
    getListVehicleIdsSrv(): Observable<ICriteriaDataItem[]> {
        var uriString = '/EvaPlusRest/processingstatus/vehicleids?tenant=' + TENANT;
        return this.http.get(uriString);
    }

    // Muiltiple teloc files download (Not in use)
    downloadMultipleTelocFiles(urlSufix: string): Observable<any> {
        let path = '/EvaPlusRest/telocfile/downloadMultipleTelocFiles?' + urlSufix;
        // var path = '/EvaPlusRest/processingstatus/downloadMultiTelocFiles' ;
        return this.http.get(path).pipe(
            map((res: Response) => {
                return res.arrayBuffer();
            })
        );
    }

    getConfigurationIssuePerVehicle(): Observable<any> {
        let path = '/EvaPlusRest/processingstatus/configurationIssuePerVehicle';
        return this.http.get(path);
    }

    // http://localhost:8080/EvaPlusRest/processingstatus/lastManualUploadedFileStatus
    getLastManualUploadedFileStatus(): Observable<IFileInfo> {
        let path = '/EvaPlusRest/processingstatus/lastManualUploadedFileStatus';
        return this.http.get(path);
    }

    // // FILES PROCESSED ALL
    // getAllFilesStatusProcessed():Observable<IProcessingFile[]>{
    //   var uriString = '/EvaPlusRest/processingstatus/processed?tenant=' + TENANT;
    //   return this.http.get(uriString).map((res:Response) => {
    //           return res.json();
    //         });
    // }

    // http://localhost:8080/EvaPlusRest/telocfile/generateFileRecordCount?fileName=imeFajla&filePath=Putanja&vehicleId=3030&recorderId=17041213&dataFrom=1533619258000&dataTo=1533622436000
    generateFileRecordCount(urlSufix: string) {
        let path = '/EvaPlusRest/telocfile/generateFileRecordCount?' + urlSufix;
        return this.http.get(path);
    }

    // http://localhost:8080/EvaPlusRest/telocFileService/downloadFileRecordCount?fileName=imeFajla
    downloadFileRecordCount(fileName: string) {
        let path = '/EvaPlusRest/telocfile/downloadFileRecordCount?' + 'fileName=' + fileName;
        return this.http.get(path, { observe: 'response', responseType: 'blob' });
    }

    getDataReprocessingParams() {
        let path = '/EvaPlusRest/telocfile/getDataReprocessingParams';
        return this.http.get(path);
    }

    // FILES COUNT
    getFilesCountSrv(
        vehicleid: string,
        timefrom: string,
        timeto: string,
        reprocessFlag: boolean,
        recorded: boolean
    ): Observable<any> {
        var uriString =
            '/EvaPlusRest/processingstatus/count?tenant=' +
            TENANT +
            '&vehicleid=' +
            vehicleid +
            '&recorded=' +
            recorded +
            '&timefrom=' +
            timefrom +
            '&timeto=' +
            timeto +
            '&reprocessing=' +
            reprocessFlag;
        return this.http.get(uriString);
    }

    // FILES STATUS
    getFilesStatusSrv(
        vehicleid: string,
        timefrom: string,
        timeto: string,
        reprocessFlag: boolean,
        recorded: boolean,
        page?: number
    ): Observable<IProcessingFile[]> {
        var uriString =
            '/EvaPlusRest/processingstatus/status?tenant=' +
            TENANT +
            '&vehicleid=' +
            vehicleid +
            '&timefrom=' +
            timefrom +
            '&timeto=' +
            timeto +
            '&reprocessing=' +
            reprocessFlag +
            '&recorded=' +
            recorded +
            '&page=' +
            page;
        return this.http.get(uriString);
    }
}
