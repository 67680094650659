import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import {
    ArrowObject,
    RectangleResponse,
    TextObject,
} from '../../../components/hr-draw-tool/hr-draw-tool.component';
import {
    ResetStoreDrawingTool,
    SetBookmarkComment,
    SetSelectDrawPane,
    SetArrowSelected,
    SetLatestEnterdText,
    SetBookmarkMarker,
    SetMultipleMarkers,
    SetRectangleSelected,
    SetTextDrawSelected,
    SetSelectedDrawTool,
    SetSelectedSingnalsText,
    SetDTCanvasId,
    AddSelectedSingnalsText,
    RemoveSelectedSingnalsText,
    SetLastDrawnArrow,
    SetLastDrawnRectangle,
} from '../action/draw-tool.actions';
import { SetSelectedSignalBookmark } from '../action/signal-bookmarks.actions';

export interface SaDrawingToolStateModel {
    bookmarkComment: string;
    canvasId: string;
    selectDrawPane: boolean;
    arrowSelected: boolean;
    latestEnterdText: TextObject;
    bookmarkMarker: boolean;
    multipleMarkers: boolean;
    rectangleSelected: boolean;
    textDrawSelected: boolean;
    selectedDrawTool: string;
    selectedSingnalsText: string[];
    lastDrawnArrow: ArrowObject;
    lastDrawnRectangle: RectangleResponse;
}

const defaultState: SaDrawingToolStateModel = {
    bookmarkComment: '',
    canvasId: '',
    selectDrawPane: false,
    arrowSelected: false,
    latestEnterdText: undefined,
    bookmarkMarker: false,
    multipleMarkers: false,
    rectangleSelected: false,
    textDrawSelected: false,
    selectedDrawTool: 'None',
    selectedSingnalsText: [],
    lastDrawnArrow: undefined,
    lastDrawnRectangle: undefined,
};

@Injectable()
@State<SaDrawingToolStateModel>({
    name: 'saDrawTool',
    defaults: defaultState,
})
export class SaDrawingToolState {
    @Action(SetBookmarkComment)
    setBookmarComment(ctx: StateContext<SaDrawingToolStateModel>, action: SetBookmarkComment) {
        ctx.patchState({ bookmarkComment: action.bookmarkComment });
    }

    @Action(SetSelectedSignalBookmark)
    setSelectedBookmark(
        ctx: StateContext<SaDrawingToolStateModel>,
        { selectedBookmark }: SetSelectedSignalBookmark
    ) {
        if (selectedBookmark) {
            ctx.patchState({ bookmarkComment: selectedBookmark.bookmarkName });
        }
    }

    @Action(SetDTCanvasId)
    setDTCanvasId(ctx: StateContext<SaDrawingToolStateModel>, action: SetDTCanvasId) {
        ctx.patchState({ canvasId: action.canvasId });
    }

    @Action(SetSelectDrawPane)
    setSelectDrawPane(ctx: StateContext<SaDrawingToolStateModel>, action: SetSelectDrawPane) {
        ctx.patchState({ selectDrawPane: action.selectDrawPane });
    }

    @Action(SetArrowSelected)
    setArrowSelected(ctx: StateContext<SaDrawingToolStateModel>, action: SetArrowSelected) {
        ctx.patchState({ arrowSelected: action.arrowSelected });
    }

    @Action(SetLatestEnterdText)
    setLatestEnterdText(ctx: StateContext<SaDrawingToolStateModel>, action: SetLatestEnterdText) {
        ctx.patchState({ latestEnterdText: action.latestEnterdText });
    }

    @Action(SetBookmarkMarker)
    setBookmarkMarker(ctx: StateContext<SaDrawingToolStateModel>, action: SetBookmarkMarker) {
        ctx.patchState({ bookmarkMarker: action.bookmarkMarker });
    }

    @Action(SetMultipleMarkers)
    setMultipleMarkers(ctx: StateContext<SaDrawingToolStateModel>, action: SetMultipleMarkers) {
        ctx.patchState({ multipleMarkers: action.multipleMarkers });
    }

    @Action(SetRectangleSelected)
    setRectangleSelected(ctx: StateContext<SaDrawingToolStateModel>, action: SetRectangleSelected) {
        ctx.patchState({ rectangleSelected: action.rectangleSelected });
    }

    @Action(SetTextDrawSelected)
    setTextDrawSelected(ctx: StateContext<SaDrawingToolStateModel>, action: SetTextDrawSelected) {
        ctx.patchState({ textDrawSelected: action.textDrawSelected });
    }

    @Action(SetSelectedDrawTool)
    setSelectedDrawTool(ctx: StateContext<SaDrawingToolStateModel>, action: SetSelectedDrawTool) {
        ctx.patchState({ selectedDrawTool: action.selectedDrawTool });
    }

    @Action(SetSelectedSingnalsText)
    setSelectedSingnalsText(
        ctx: StateContext<SaDrawingToolStateModel>,
        action: SetSelectedSingnalsText
    ) {
        ctx.patchState({ selectedSingnalsText: action.selectedSingnalsText });
    }

    @Action(AddSelectedSingnalsText)
    addSelectedSingnalsText(
        ctx: StateContext<SaDrawingToolStateModel>,
        action: AddSelectedSingnalsText
    ) {
        ctx.setState(
            patch({ selectedSingnalsText: append<string>([action.selectedSingnalsText]) })
        );
    }

    @Action(RemoveSelectedSingnalsText)
    removeSelectedSingnalsText(
        ctx: StateContext<SaDrawingToolStateModel>,
        action: RemoveSelectedSingnalsText
    ) {
        ctx.setState(
            patch({ selectedSingnalsText: removeItem((it) => it == action.selectedSingnalsText) })
        );
    }

    @Action(SetLastDrawnArrow)
    setLastDrawnArrow(ctx: StateContext<SaDrawingToolStateModel>, action: SetLastDrawnArrow) {
        ctx.patchState({ lastDrawnArrow: action.lastDrawnArrow });
    }

    @Action(SetLastDrawnRectangle)
    setLastDrawnRectangle(
        ctx: StateContext<SaDrawingToolStateModel>,
        action: SetLastDrawnRectangle
    ) {
        ctx.patchState({ lastDrawnRectangle: action.lastDrawnRectangle });
    }

    @Action(ResetStoreDrawingTool)
    resetStoreCanvas(ctx: StateContext<SaDrawingToolStateModel>, action: ResetStoreDrawingTool) {
        ctx.setState(defaultState);
    }
}
