import { SingleSignalSettingStateModel } from '../reducer/single-signal-setting.state';

export class ApplySingleSignalSettings {
    static readonly type = '[SignalSettings] Apply Single Signal Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class SetSettingsView {
    static readonly type = '[SignalSettings] Set Settings View';
    constructor(public settingsView: string) {}
}

export class SetLoadSignalSettings {
    static readonly type = '[SignalSettings] Set Load Signal Settings';
    constructor(public load: boolean, public settingType: string) {}
}

export class WaitingSignalDataToApplySettings {
    static readonly type = '[SignalSettings] Waiting Signal Data To Apply Settings';
}

export class SignalSettingsLoaded {
    static readonly type = '[SignalSettings]  Signal Settings Loaded';
}

export class SetApplyUserSettingsPressed {
    static readonly type = '[SignalSettings] Set Apply User Settings Pressed';
    constructor(public pressed: boolean) {}
}

export class SaveTemplateSignalSettings {
    static readonly type = '[SignalSettings] Save Template Signal Settings';
    constructor(
        public templateId: string,
        public signalsSettings: SingleSignalSettingStateModel[]
    ) {}
}

export class SaveAllChangedTemplateSignalSettingsInState {
    static readonly type = '[SignalSettings] Save All Changed Template Signal Settings';
    constructor(public templateId: string) {}
}

export class SaveUserSignalSettings {
    static readonly type = '[SignalSettings] Save User Signal Settings';
    constructor(
        public userName: string,
        public signalSettings: SingleSignalSettingStateModel,
        public saveFromTemplate: boolean = false
    ) {}
}

export class SetTemplateSignalSettingsFilter {
    static readonly type = '[SignalSettings] Set Template Signal Settings Filter';
    constructor(public signalFilter: string) {}
}

export class SetUserSignalSettingsFilter {
    static readonly type = '[SignalSettings] Set User Signal Settings Filter';
    constructor(public signalFilter: string) {}
}

export class LoadTemplateSignalSettings {
    static readonly type = '[SignalSettings] Load Template Signal Settings';
    constructor(public templateId: string) {}
}

export class LoadUserSignalSettings {
    static readonly type = '[SignalSettings] Load User Signal Settings';
    constructor(public userName: string) {}
}

export class AddTemplateSignalSettings {
    static readonly type = '[SignalSettings] Add Template Signal Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class RemoveTemplateSignalSettings {
    static readonly type = '[SignalSettings] Remove Template Signal Settings';
    constructor(public signalSettingsId: string) {}
}

export class SetTemplateSignalsSettings {
    static readonly type = '[SignalSettings] Set Template Signals Settings';
    constructor(public templateSignalsSettings: SingleSignalSettingStateModel[]) {}
}

export class AddUserSignalSettings {
    static readonly type = '[SignalSettings] Add User Signal Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class RemoveUserSignalSettings {
    static readonly type = '[SignalSettings] Remove User Signal Settings';
    constructor(public userName: string, public signalSettingsId: string) {}
}

export class RemoveAllUserSignalSettings {
    static readonly type = '[SignalSettings] Remove All User Signal Settings';
    constructor(public userName: string) {}
}

export class SetUserSignalsSettings {
    static readonly type = '[SignalSettings] Set User Signals Settings';
    constructor(public userSignalsSettings: SingleSignalSettingStateModel[]) {}
}

export class SetSelectedTemplateSignalsSettings {
    static readonly type = '[SignalSettings] Set Selected Template Signals Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class SetSelectedUserSignalsSettings {
    static readonly type = '[SignalSettings] Set Selected User Signals Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class SetSelectedTemplateSignalSettings {
    static readonly type = '[SignalSettings] Set Selected Template Signals Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class SetSelectedUserSignalSettings {
    static readonly type = '[SignalSettings] Set Selected User Signals Settings';
    constructor(public signalSettings: SingleSignalSettingStateModel) {}
}

export class ResetTemplateSignalSettings {
    static readonly type = '[SignalSettings] Reset Template Signals Settings';
    constructor(public signalSettingsId: string) {}
}

export class ResetUserSignalSettings {
    static readonly type = '[SignalSettings] Reset User Signals Settings';
    constructor(public signalSettingsId: string) {}
}

export class ResetSignalSettings {
    static readonly type = '[SignalSettings] Reset Signal Settings';
    constructor() {}
}
