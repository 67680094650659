import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
    AddCurrItemRequireMatchAnalog,
    AddCurrItemRequireMatchDigital,
    AddCurrItemRequireMatchEtcs,
    AddCurrItemRequireMatchEvent,
    AddCurrItemRequireMatchOther,
    AddItemAnalog,
    AddItemDigital,
    AddItems,
    AddItemsAnalog,
    AddItemsDigital,
    AddItemsOther,
    AddNodeMemory,
    ChipOperation,
    ChipType,
    ClearNodesEvent,
    ClearNodesMemory,
    ClearSignalsUpdated,
    GetCriteriaData,
    ModifyChip,
    // ModifyEtcsChip,
    ModifyEventChip,
    NewVehicleHasSameSignals,
    RemoveCurrItemRequireMatchAnalog,
    RemoveCurrItemRequireMatchDigital,
    RemoveCurrItemRequireMatchEtcs,
    RemoveCurrItemRequireMatchEvent,
    RemoveCurrItemRequireMatchOther,
    ResetItemsRequireMatch,
    ResetNodes,
    ResetStoreCriteria,
    SetCurrItemsRequireMatchAnalog,
    SetCurrItemsRequireMatchDigital,
    SetCurrItemsRequireMatchEtcs,
    SetCurrItemsRequireMatchEvent,
    SetCurrItemsRequireMatchOther,
    SetCurrItemsRequireMatchStanding,
    SetItemsAnalog,
    SetItemsDigital,
    SetItemsEtcs,
    SetItemsEvent,
    SetItemsOther,
    SetItemsRequireMatchAnalog,
    SetItemsRequireMatchDigital,
    SetItemsRequireMatchEtcs,
    SetItemsRequireMatchEvent,
    SetItemsRequireMatchOther,
    SetItemsRequireMatchStanding,
    SetItemsStanding,
    SetNodeChips,
    SetNodeEvent,
    SetNodesMemory,
    SetSignalsFull,
    SetSignalsUpdated,
    UpdateAllChips,
    UpdateNodeMemory,
    UpdateNodeMemorySignal,
    UpdatePreviousAnalogSignals,
    UpdatePreviousDigitalSignals,
    UpdatePreviousEtcsSignals,
    UpdatePreviousEventSignals,
    UpdatePreviousOtherSignals,
    UpdateSignalsUsed,
} from '../action/criteria.actions';
import { append, patch, removeItem } from '@ngxs/store/operators';
import produce from 'immer';
import { ISignalItem, SignalEtcsService } from '../../../../services';
import { ICriteriaData, ISelectedSignals, ISignalsChanged } from '../../../../services/signal.service';
import { SetSelectedSignalBookmark } from '../action/signal-bookmarks.actions';
import * as _ from 'lodash';
import { SaToolbarState } from './toolbar.state';
import { LoadTemplateData, TemplateDataLoaded } from '../action/toolbar.actions';
import { GetData, OpenSaForParams } from '../action/signal-analytics-module.actions';
import { tap } from 'rxjs/operators';
import { RemoveMarkers } from '../action/canvas.actions';

export interface SignalTreeItem {
    id: number;
    name: string;
    signalId: string;
    icon: string;
    issignal: string;
    updated: boolean;
    children: SignalTreeItem[];
}

export interface SaCriteriaStateModel {
    itemsRequireMatchAnalog: string[];
    itemsRequireMatchDigital: string[];
    itemsRequireMatchOther: string[];
    itemsRequireMatchEtcs: string[];
    itemsRequireMatchEvent: string[];
    itemsRequireMatchStanding: string[];
    currItemsRequireMatchAnalog: string[];
    currItemsRequireMatchDigital: string[];
    currItemsRequireMatchOther: string[];
    currItemsRequireMatchEtcs: string[];
    currItemsRequireMatchEvent: string[];
    currItemsRequireMatchStanding: string[];
    itemsRequireMatchAnalogPrevious: string[];
    itemsRequireMatchDigitalPrevious: string[];
    itemsRequireMatchOtherPrevious: string[];
    itemsRequireMatchEtcsPrevious: string[];
    itemsRequireMatchEventPrevious: string[];
    nodesChips: SignalTreeItem[];
    nodesMemory: SignalTreeItem[];
    nodesEvent: SignalTreeItem[];
    itemsEtcs: string[];
    itemsEvent: string[];
    itemsStanding: string[];
    itemsOther: string[];
    itemsAnalog: string[];
    itemsDigital: string[];
    signalsFull: ISignalItem[];
    signalsUpdated: ISignalsChanged;
    newVehicleHasSameSignals: boolean;

    skippedMemoryFiles: string[];
    memorySizeLimit: string;
}

const defaultState = {
    itemsRequireMatchAnalog: [],
    itemsRequireMatchDigital: [],
    itemsRequireMatchOther: [],
    itemsRequireMatchEtcs: [],
    itemsRequireMatchEvent: [],
    itemsRequireMatchStanding: [],
    currItemsRequireMatchAnalog: [],
    currItemsRequireMatchDigital: [],
    currItemsRequireMatchOther: [],
    currItemsRequireMatchEtcs: [],
    currItemsRequireMatchEvent: [],
    currItemsRequireMatchStanding: [],
    itemsRequireMatchAnalogPrevious: [],
    itemsRequireMatchDigitalPrevious: [],
    itemsRequireMatchOtherPrevious: [],
    itemsRequireMatchEtcsPrevious: [],
    itemsRequireMatchEventPrevious: [],
    nodesChips: [],
    nodesMemory: [],
    nodesEvent: [],
    itemsEtcs: [],
    itemsEvent: [],
    itemsStanding: [],
    itemsOther: [],
    itemsAnalog: [],
    itemsDigital: [],
    signalsFull: [],
    skippedMemoryFiles: [],
    memorySizeLimit: '',
    signalsUpdated: null,
    newVehicleHasSameSignals: false,
};

@Injectable()
@State<SaCriteriaStateModel>({
    name: 'saCriteria',
    defaults: defaultState,
})
export class SaCriteriaState {
    constructor(private _signalServiceNew: SignalEtcsService, private store: Store) {}

    @Action(SetItemsEtcs)
    setItemsEtcs(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsEtcs) {
        ctx.patchState({ itemsEtcs: action.items });
    }

    @Action(SetItemsEvent)
    setItemsEvent(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsEvent) {
        ctx.patchState({ itemsEvent: action.items });
    }

    @Action(SetItemsOther)
    setItemsOther(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsOther) {
        ctx.patchState({ itemsOther: action.items });
    }

    @Action(SetItemsAnalog)
    SetItemsAnalog(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsAnalog) {
        ctx.patchState({ itemsAnalog: action.items });
    }

    @Action(SetItemsDigital)
    setItemsDigital(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsDigital) {
        ctx.patchState({ itemsDigital: action.items });
    }

    @Action(SetItemsStanding)
    setItemsStanding(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsStanding) {
        ctx.patchState({ itemsStanding: action.items });
    }

    @Action(SetSignalsFull)
    setSignalsFull(ctx: StateContext<SaCriteriaStateModel>, action: SetSignalsFull) {
        ctx.patchState({ signalsFull: action.signalsFull });
    }

    @Action(AddItemAnalog)
    addItemAnalog(ctx: StateContext<SaCriteriaStateModel>, { item }: AddItemAnalog) {
        ctx.setState(
            patch({
                itemsAnalog: append([item]),
            })
        );
    }

    @Action(AddItemDigital)
    addItemDigital(ctx: StateContext<SaCriteriaStateModel>, { item }: AddItemDigital) {
        ctx.setState(
            patch({
                itemsDigital: append([item]),
            })
        );
    }

    @Action(AddItemsAnalog)
    addItemsAnalog(ctx: StateContext<SaCriteriaStateModel>, { items }: AddItemsAnalog) {
        ctx.setState(
            patch({
                itemsAnalog: append(items),
            })
        );
    }

    @Action(AddItemsDigital)
    addItemsDigital(ctx: StateContext<SaCriteriaStateModel>, { items }: AddItemsDigital) {
        ctx.setState(
            patch({
                itemsDigital: append(items),
            })
        );
    }

    @Action(AddItemsOther)
    addItemsOther(ctx: StateContext<SaCriteriaStateModel>, { items }: AddItemsOther) {
        ctx.setState(
            patch({
                itemsOther: append(items),
            })
        );
    }

    @Action(AddItems)
    addItems(ctx: StateContext<SaCriteriaStateModel>, { analog, digital, other }: AddItems) {
        ctx.setState(
            patch({
                itemsOther: append(other),
                itemsAnalog: append(analog),
                itemsDigital: append(digital),
            })
        );
        this.sortItemsOther(ctx);
    }

    sortItemsOther(ctx: StateContext<SaCriteriaStateModel>) {
        ctx.setState(
            produce((draft) => {
                draft.itemsOther.sort(function (a, b) {
                    let nameA = a.toLowerCase(),
                        nameB = b.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            })
        );
    }

    @Action(SetItemsRequireMatchEtcs)
    setItemsRequireMatchEtcs(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchEtcs) {
        ctx.patchState({ itemsRequireMatchEtcs: action.items });
    }

    @Action(SetItemsRequireMatchEvent)
    setItemsRequireMatchEvent(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchEvent) {
        ctx.patchState({ itemsRequireMatchEvent: action.items });
    }

    @Action(SetItemsRequireMatchOther)
    setItemsRequireMatchOther(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchOther) {
        ctx.patchState({ itemsRequireMatchOther: action.items });
    }

    @Action(SetItemsRequireMatchAnalog)
    SetItemsRequireMatchAnalog(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchAnalog) {
        ctx.patchState({ itemsRequireMatchAnalog: action.items });
    }

    @Action(SetItemsRequireMatchDigital)
    setItemsRequireMatchDigital(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchDigital) {
        ctx.patchState({ itemsRequireMatchDigital: action.items });
    }

    @Action(SetItemsRequireMatchStanding)
    setItemsRequireMatchStanding(ctx: StateContext<SaCriteriaStateModel>, action: SetItemsRequireMatchStanding) {
        ctx.patchState({ itemsRequireMatchStanding: action.items });
    }

    @Action(SetSelectedSignalBookmark)
    setSelectedBookmark(ctx: StateContext<SaCriteriaStateModel>, { selectedBookmark }: SetSelectedSignalBookmark) {
        if (selectedBookmark) {
            ctx.patchState({
                currItemsRequireMatchAnalog: selectedBookmark.itemsRequireMatchAnalog,
                currItemsRequireMatchDigital: selectedBookmark.itemsRequireMatchDigital,
                currItemsRequireMatchOther: selectedBookmark.itemsRequireMatchOther,
                currItemsRequireMatchEtcs: selectedBookmark.itemsRequireMatchEtcs,
                currItemsRequireMatchEvent: selectedBookmark.itemsRequireMatchEvent,
            });
        }
    }

    @Action(GetCriteriaData)
    async getCriteriaData(ctx: StateContext<SaCriteriaStateModel>, action: GetCriteriaData) {
        let selectedVehicle: string = this.store.selectSnapshot(SaToolbarState.vehicle);
        //for initial request to return generic list of signals
        if (!selectedVehicle) {
            selectedVehicle = '';
        }

        // criteriaQuery api also loads list of all trains, all memories, some default signalEvent for some reason
        // when user select vehicles all signals for that vehice are loaded again through criteriaQuery API
        const criteriaData: ICriteriaData = await this._signalServiceNew.criteriaQuery(selectedVehicle).toPromise();

        ctx.patchState({
            itemsAnalog: criteriaData.signalsAnalog,
            itemsDigital: criteriaData.signalsDigital,
            itemsOther: criteriaData.signalsOther,
            itemsEtcs: criteriaData.signalsEtcs,
            itemsEvent: criteriaData.signalsEvent,
            itemsStanding: criteriaData.signalsStanding,
            signalsFull: criteriaData.signalsFull,
            skippedMemoryFiles: criteriaData.skippedMemoryFiles,
            memorySizeLimit: criteriaData.memorySizeLimit,
        });
    }

    @Action(OpenSaForParams)
    async openSaForParams(ctx: StateContext<SaCriteriaStateModel>, action: OpenSaForParams) {
        const criteriaData: ICriteriaData = await this._signalServiceNew.criteriaQuery(action.vehicleId).toPromise();
        ctx.patchState({
            itemsAnalog: criteriaData.signalsAnalog,
            itemsDigital: criteriaData.signalsDigital,
            itemsOther: criteriaData.signalsOther,
            itemsEtcs: criteriaData.signalsEtcs,
            itemsEvent: criteriaData.signalsEvent,
            itemsStanding: criteriaData.signalsStanding,
            signalsFull: criteriaData.signalsFull,
        });
    }

    @Action(NewVehicleHasSameSignals)
    newVehicleHasSameSignals(ctx: StateContext<SaCriteriaStateModel>, action: NewVehicleHasSameSignals) {
        let newVehicleHasSame = this.checkVehicleHasSameSignals(ctx);
        ctx.patchState({ newVehicleHasSameSignals: newVehicleHasSame });
    }

    @Action(LoadTemplateData)
    loadTemplateData(ctx: StateContext<SaCriteriaStateModel>, { templateData }: LoadTemplateData) {
        if (templateData) {
            let eventSignals = [];
            if (templateData.eventSignals != null) {
                eventSignals = templateData.eventSignals;
            }
            if (!ctx.getState().newVehicleHasSameSignals) {
                ctx.patchState({
                    itemsRequireMatchAnalog: templateData.analogSignals,
                    itemsRequireMatchDigital: templateData.digitalSignals,
                    itemsRequireMatchOther: templateData.otherSignals,
                    itemsRequireMatchEtcs: templateData.etcsSignals,
                    itemsRequireMatchEvent: eventSignals,
                });
            } else {
                const currState = ctx.getState();
                ctx.patchState({
                    itemsRequireMatchAnalog: currState.itemsRequireMatchAnalogPrevious,
                    itemsRequireMatchDigital: currState.itemsRequireMatchDigitalPrevious,
                    itemsRequireMatchOther: currState.itemsRequireMatchOtherPrevious,
                    itemsRequireMatchEtcs: currState.itemsRequireMatchEtcsPrevious,
                    itemsRequireMatchEvent: eventSignals,
                });
            }
        }
    }

    checkVehicleHasSameSignals(ctx: StateContext<SaCriteriaStateModel>) {
        if (
            ctx.getState().itemsRequireMatchAnalog.length > 0 ||
            ctx.getState().itemsRequireMatchDigital.length > 0 ||
            ctx.getState().itemsRequireMatchOther.length > 0 ||
            ctx.getState().itemsRequireMatchEtcs.length > 0 ||
            ctx.getState().itemsRequireMatchEtcs.length > 0
        ) {
            // analog
            var hasAllSelectedAnalog: boolean = false;
            var matchCountAnalog: number = 0;
            for (var i = 0; i < ctx.getState().itemsRequireMatchAnalogPrevious.length; i++) {
                for (var j = 0; j < ctx.getState().itemsAnalog.length; j++) {
                    if (ctx.getState().itemsRequireMatchAnalogPrevious[i] == ctx.getState().itemsAnalog[j]) {
                        matchCountAnalog++;
                        break;
                    }
                }
            }
            if (matchCountAnalog == ctx.getState().itemsRequireMatchAnalogPrevious.length) {
                hasAllSelectedAnalog = true;
            }

            // digital
            var hasAllSelectedDigital: boolean = false;
            var matchCountDigital: number = 0;
            for (var i = 0; i < ctx.getState().itemsRequireMatchDigitalPrevious.length; i++) {
                for (var j = 0; j < ctx.getState().itemsDigital.length; j++) {
                    if (ctx.getState().itemsRequireMatchDigitalPrevious[i] == ctx.getState().itemsDigital[j]) {
                        matchCountDigital++;
                        break;
                    }
                }
            }
            if (matchCountDigital == ctx.getState().itemsRequireMatchDigitalPrevious.length) {
                hasAllSelectedDigital = true;
            }

            // other
            var hasAllSelectedOther: boolean = false;
            var matchCountOther: number = 0;
            for (var i = 0; i < ctx.getState().itemsRequireMatchOtherPrevious.length; i++) {
                for (var j = 0; j < ctx.getState().itemsOther.length; j++) {
                    if (ctx.getState().itemsRequireMatchOtherPrevious[i] == ctx.getState().itemsOther[j]) {
                        matchCountOther++;
                        break;
                    }
                }
            }
            if (matchCountOther == ctx.getState().itemsRequireMatchOtherPrevious.length) {
                hasAllSelectedOther = true;
            }

            // etcs
            var hasAllSelectedEtcs: boolean = false;
            var matchCountEtcs: number = 0;
            for (var i = 0; i < ctx.getState().itemsRequireMatchEtcsPrevious.length; i++) {
                for (var j = 0; j < ctx.getState().itemsEtcs.length; j++) {
                    if (ctx.getState().itemsRequireMatchEtcsPrevious[i] == ctx.getState().itemsEtcs[j]) {
                        matchCountEtcs++;
                        break;
                    }
                }
            }
            if (matchCountEtcs == ctx.getState().itemsRequireMatchEtcsPrevious.length) {
                hasAllSelectedEtcs = true;
            }

            // event
            var hasAllSelectedEvent: boolean = false;
            var matchCountEvent: number = 0;
            for (var i = 0; i < ctx.getState().itemsRequireMatchEventPrevious.length; i++) {
                for (var j = 0; j < ctx.getState().itemsEvent.length; j++) {
                    if (ctx.getState().itemsRequireMatchEventPrevious[i] == ctx.getState().itemsEvent[j]) {
                        matchCountEvent++;
                        break;
                    }
                }
            }
            if (matchCountEvent == ctx.getState().itemsRequireMatchEventPrevious.length) {
                hasAllSelectedEvent = true;
            }
            if (
                hasAllSelectedAnalog == true &&
                hasAllSelectedDigital == true &&
                hasAllSelectedOther == true &&
                hasAllSelectedEtcs == true &&
                hasAllSelectedEvent == true
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    @Action(SetCurrItemsRequireMatchEtcs)
    setCurrItemsRequireMatchEtcs(ctx: StateContext<SaCriteriaStateModel>, action: SetCurrItemsRequireMatchEtcs) {
        ctx.patchState({ currItemsRequireMatchEtcs: action.items });
    }

    @Action(SetCurrItemsRequireMatchEvent)
    setCurrItemsRequireMatchEvent(ctx: StateContext<SaCriteriaStateModel>, action: SetCurrItemsRequireMatchEvent) {
        ctx.patchState({ currItemsRequireMatchEvent: action.items });
    }

    @Action(SetCurrItemsRequireMatchOther)
    setCurrItemsRequireMatchOther(ctx: StateContext<SaCriteriaStateModel>, action: SetCurrItemsRequireMatchOther) {
        ctx.patchState({ currItemsRequireMatchOther: action.items });
    }

    @Action(SetCurrItemsRequireMatchAnalog)
    setCurrItemsRequireMatchAnalog(ctx: StateContext<SaCriteriaStateModel>, action: SetCurrItemsRequireMatchAnalog) {
        ctx.patchState({ currItemsRequireMatchAnalog: action.items });
    }

    @Action(SetCurrItemsRequireMatchDigital)
    setCurrItemsRequireMatchDigital(ctx: StateContext<SaCriteriaStateModel>, action: SetCurrItemsRequireMatchDigital) {
        ctx.patchState({ currItemsRequireMatchDigital: action.items });
    }

    @Action(SetCurrItemsRequireMatchStanding)
    setCurrItemsRequireMatchStanding(
        ctx: StateContext<SaCriteriaStateModel>,
        action: SetCurrItemsRequireMatchStanding
    ) {
        ctx.patchState({ currItemsRequireMatchStanding: action.items });
    }

    @Action(AddCurrItemRequireMatchOther)
    addCurrItemRequireMatchOther(ctx: StateContext<SaCriteriaStateModel>, action: AddCurrItemRequireMatchOther) {
        ctx.setState(patch({ currItemsRequireMatchOther: append([action.item]) }));
    }

    @Action(AddCurrItemRequireMatchAnalog)
    addCurrItemRequireMatchAnalog(ctx: StateContext<SaCriteriaStateModel>, action: AddCurrItemRequireMatchAnalog) {
        ctx.setState(patch({ currItemsRequireMatchAnalog: append([action.item]) }));
    }

    @Action(AddCurrItemRequireMatchDigital)
    addCurrItemRequireMatchDigital(ctx: StateContext<SaCriteriaStateModel>, action: AddCurrItemRequireMatchDigital) {
        ctx.setState(patch({ currItemsRequireMatchDigital: append([action.item]) }));
    }

    @Action(AddCurrItemRequireMatchEtcs)
    addCurrItemRequireMatchEtcs(ctx: StateContext<SaCriteriaStateModel>, action: AddCurrItemRequireMatchEtcs) {
        ctx.setState(patch({ currItemsRequireMatchEtcs: append([action.item]) }));
    }

    @Action(AddCurrItemRequireMatchEvent)
    addCurrItemRequireMatchEvent(ctx: StateContext<SaCriteriaStateModel>, action: AddCurrItemRequireMatchEvent) {
        ctx.setState(patch({ currItemsRequireMatchEvent: append([action.item]) }));
    }

    @Action(RemoveCurrItemRequireMatchOther)
    removeCurrItemRequireMatchOther(ctx: StateContext<SaCriteriaStateModel>, action: RemoveCurrItemRequireMatchOther) {
        ctx.setState(patch({ currItemsRequireMatchOther: removeItem((it) => it == action.item) }));
    }

    @Action(RemoveCurrItemRequireMatchAnalog)
    removeCurrItemRequireMatchAnalog(
        ctx: StateContext<SaCriteriaStateModel>,
        action: RemoveCurrItemRequireMatchAnalog
    ) {
        ctx.setState(patch({ currItemsRequireMatchAnalog: removeItem((it) => it == action.item) }));
    }

    @Action(RemoveCurrItemRequireMatchDigital)
    removeCurrItemRequireMatchDigital(
        ctx: StateContext<SaCriteriaStateModel>,
        action: RemoveCurrItemRequireMatchDigital
    ) {
        ctx.setState(patch({ currItemsRequireMatchDigital: removeItem((it) => it == action.item) }));
    }

    @Action(RemoveCurrItemRequireMatchEtcs)
    removeCurrItemRequireMatchEtcs(ctx: StateContext<SaCriteriaStateModel>, action: RemoveCurrItemRequireMatchEtcs) {
        ctx.setState(patch({ currItemsRequireMatchEtcs: removeItem((it) => it == action.item) }));
    }

    @Action(RemoveCurrItemRequireMatchEvent)
    removeCurrItemRequireMatchEvent(ctx: StateContext<SaCriteriaStateModel>, action: RemoveCurrItemRequireMatchEvent) {
        ctx.setState(patch({ currItemsRequireMatchEvent: removeItem((it) => it == action.item) }));
    }

    @Action(ClearNodesEvent)
    clearNodesEvent(ctx: StateContext<SaCriteriaStateModel>, action: ClearNodesEvent) {
        ctx.patchState({ nodesEvent: [] });
    }

    @Action(SetNodeEvent)
    setNodeEvent(ctx: StateContext<SaCriteriaStateModel>, action: SetNodeEvent) {
        ctx.setState(
            patch({
                nodesEvent: action.nodes,
            })
        );
    }

    @Action(AddNodeMemory)
    addNodeMemory(ctx: StateContext<SaCriteriaStateModel>, action: AddNodeMemory) {
        ctx.setState(
            patch({
                nodesMemory: append([action.node]),
            })
        );
    }

    @Action(UpdateNodeMemory)
    updateNodeMemory(ctx: StateContext<SaCriteriaStateModel>, { nodeMemory, nodeSignalType }: UpdateNodeMemory) {
        ctx.setState(
            produce((draft) => {
                let node = draft.nodesMemory.find((nd) => nd.id == nodeMemory.id);
                if (node != undefined && node != null) {
                    node.children.push(nodeSignalType);
                }
            })
        );
    }

    @Action(UpdateNodeMemorySignal)
    updateNodeMemorySignal(
        ctx: StateContext<SaCriteriaStateModel>,
        { nodeMemory, nodeSignalType, nodeSignal }: UpdateNodeMemorySignal
    ) {
        ctx.setState(
            produce((draft) => {
                let node = draft.nodesMemory.find((nd) => nd.id == nodeMemory.id);
                if (node != undefined) {
                    let nodeType = node.children.find((nd) => nd.id == nodeSignalType.id);
                    if (nodeType != undefined) {
                        nodeType.children.push(nodeSignal);
                    }
                }
            })
        );
    }

    @Action(ClearNodesMemory)
    clearNodesMemory(ctx: StateContext<SaCriteriaStateModel>, action: ClearNodesMemory) {
        ctx.patchState({ nodesMemory: [] });
    }

    @Action(SetNodesMemory)
    setNodesMemory(ctx: StateContext<SaCriteriaStateModel>, action: SetNodesMemory) {
        ctx.patchState({ nodesMemory: action.nodes });
    }

    @Action(SetNodeChips)
    addNodeChips(ctx: StateContext<SaCriteriaStateModel>, action: SetNodeChips) {
        ctx.patchState({ nodesChips: [action.node] });
    }

    @Action(ResetItemsRequireMatch)
    resetItemsRequireMatch(ctx: StateContext<SaCriteriaStateModel>, action: ResetItemsRequireMatch) {
        ctx.patchState({
            itemsRequireMatchAnalog: [],
            itemsRequireMatchDigital: [],
            itemsRequireMatchOther: [],
            itemsRequireMatchEtcs: [],
            itemsRequireMatchEvent: [],
            currItemsRequireMatchAnalog: [],
            currItemsRequireMatchDigital: [],
            currItemsRequireMatchOther: [],
            currItemsRequireMatchEtcs: [],
            currItemsRequireMatchEvent: [],
            itemsRequireMatchAnalogPrevious: [],
            itemsRequireMatchDigitalPrevious: [],
            itemsRequireMatchOtherPrevious: [],
            itemsRequireMatchEtcsPrevious: [],
            itemsRequireMatchEventPrevious: [],
        });
    }

    @Action(ResetNodes)
    resetNodes(ctx: StateContext<SaCriteriaStateModel>, action: ResetNodes) {
        this.resetSignalNodes(ctx);
    }

    @Action(TemplateDataLoaded)
    templateDataLoaded(ctx: StateContext<SaCriteriaStateModel>, action: TemplateDataLoaded) {
        this.resetSignalNodes(ctx);
        this.updateChips(ctx);
    }

    resetSignalNodes(ctx: StateContext<SaCriteriaStateModel>) {
        ctx.setState(
            produce((draft) => {
                for (let nodeLevelOne of draft.nodesChips) {
                    for (let nodeLevelTwo of nodeLevelOne.children) {
                        for (let nodeLevelThree of nodeLevelTwo.children) {
                            nodeLevelThree.icon = 'label_outline';
                        }
                    }
                }

                for (let nodeSignalTypeMem of draft.nodesMemory) {
                    for (let nodeMemoryMem of nodeSignalTypeMem.children) {
                        for (let nodeSignalMem of nodeMemoryMem.children) {
                            nodeSignalMem.icon = 'label_outline';
                        }
                    }
                }

                for (let nodeLevelOne of draft.nodesEvent) {
                    nodeLevelOne.icon = 'label_outline';
                }
            })
        );
    }

    @Action(GetData)
    getData(ctx: StateContext<SaCriteriaStateModel>, action: GetData) {
        this.updateSignals(ctx);
    }

    @Action(UpdateSignalsUsed)
    updateSignalsUsed(ctx: StateContext<SaCriteriaStateModel>, action: UpdateSignalsUsed) {
        this.updateSignals(ctx);
    }

    updateSignals(ctx: StateContext<SaCriteriaStateModel>) {
        ctx.patchState({
            itemsRequireMatchAnalog: ctx.getState().currItemsRequireMatchAnalog,
            itemsRequireMatchDigital: ctx.getState().currItemsRequireMatchDigital,
            itemsRequireMatchOther: ctx.getState().currItemsRequireMatchOther,
            itemsRequireMatchEtcs: ctx.getState().currItemsRequireMatchEtcs,
            itemsRequireMatchEvent: ctx.getState().currItemsRequireMatchEvent,
            currItemsRequireMatchAnalog: _.cloneDeep(ctx.getState().currItemsRequireMatchAnalog),
            currItemsRequireMatchDigital: _.cloneDeep(ctx.getState().currItemsRequireMatchDigital),
            currItemsRequireMatchOther: _.cloneDeep(ctx.getState().currItemsRequireMatchOther),
            currItemsRequireMatchEtcs: _.cloneDeep(ctx.getState().currItemsRequireMatchEtcs),
            currItemsRequireMatchEvent: _.cloneDeep(ctx.getState().currItemsRequireMatchEvent),
            currItemsRequireMatchStanding: _.cloneDeep(ctx.getState().currItemsRequireMatchStanding),
        });
    }

    @Action(UpdateAllChips)
    updateAllChips(ctx: StateContext<SaCriteriaStateModel>, action: UpdateAllChips) {
        this.updateChips(ctx);
    }

    updateChips(ctx: StateContext<SaCriteriaStateModel>) {
        ctx.setState(
            produce((draft) => {
                for (let chipName of draft.itemsRequireMatchAnalog) {
                    this.modifyChipIcon(draft, ChipType.ANALOG, chipName, ChipOperation.ADD);
                }
                for (let chipName of draft.itemsRequireMatchDigital) {
                    this.modifyChipIcon(draft, ChipType.DIGITAL, chipName, ChipOperation.ADD);
                }
                for (let chipName of draft.itemsRequireMatchOther) {
                    this.modifyChipIcon(draft, ChipType.OTHER, chipName, ChipOperation.ADD);
                }
                for (let chipName of draft.itemsRequireMatchEtcs) {
                    this.modifyChipIcon(draft, ChipType.ETCS, chipName, ChipOperation.ADD);
                }
                for (let chipName of draft.itemsRequireMatchEvent) {
                    this.modifyEventChipIcon(draft, chipName, ChipOperation.ADD);
                }

                draft.currItemsRequireMatchAnalog = _.cloneDeep(draft.itemsRequireMatchAnalog);
                draft.currItemsRequireMatchDigital = _.cloneDeep(draft.itemsRequireMatchDigital);
                draft.currItemsRequireMatchOther = _.cloneDeep(draft.itemsRequireMatchOther);
                draft.currItemsRequireMatchEtcs = _.cloneDeep(draft.itemsRequireMatchEtcs);
                draft.currItemsRequireMatchEvent = _.cloneDeep(draft.itemsRequireMatchEvent);

                draft.itemsRequireMatchEtcsPrevious = draft.currItemsRequireMatchEtcs;
                draft.itemsRequireMatchEventPrevious = draft.currItemsRequireMatchEvent;
                draft.itemsRequireMatchOtherPrevious = draft.currItemsRequireMatchOther;
                draft.itemsRequireMatchAnalogPrevious = draft.currItemsRequireMatchAnalog;
                draft.itemsRequireMatchDigitalPrevious = draft.currItemsRequireMatchDigital;
            })
        );
    }

    // modifyEtcsChipIcon(draft, signal, operation) {
    //     for (let nodeSignalType of draft.nodesChips[0].children) {
    //         if (nodeSignalType.name == 'ETCS') {
    //             for (let nodeSignal of nodeSignalType.children) {
    //                 if (nodeSignal.name == signal) {
    //                     nodeSignal.icon = this.getIcon(operation);
    //                     break;
    //                 }
    //             }
    //             break;
    //         }
    //     }
    // }

    // @Action(ModifyEtcsChip)
    // modifyEtcsChip(ctx: StateContext<SaCriteriaStateModel>, action: ModifyEtcsChip) {
    //     ctx.setState(
    //         produce((draft) => {
    //             this.modifyEtcsChipIcon(draft, action.chip, action.chipOperation);
    //         })
    //     );
    // }

    getIcon(operation: ChipOperation): string {
        return operation == ChipOperation.REMOVE ? 'label_outline' : 'label';
    }

    modifyEventChipIcon(draft, signal, operation) {
        for (let nodeLevelOne of draft.nodesEvent) {
            if (nodeLevelOne.name == signal) {
                nodeLevelOne.icon = this.getIcon(operation);
                break;
            }
        }
    }

    @Action(ModifyEventChip)
    modifyEventChip(ctx: StateContext<SaCriteriaStateModel>, action: ModifyEventChip) {
        ctx.setState(
            produce((draft) => {
                this.modifyEventChipIcon(draft, action.chip, action.chipOperation);
            })
        );
    }

    removeSerialNumber(signalName: string) {
        if (signalName.indexOf(':') != signalName.lastIndexOf(':')) {
            return signalName.substring(0, signalName.lastIndexOf(':'));
        } else {
            return signalName;
        }
    }

    removeSignalTranslation(signalName: string) {
        if (signalName.indexOf('[') != -1 && signalName.indexOf(']') != -1) {
            let tran = signalName.substring(signalName.indexOf('['), signalName.indexOf(']') + 1);
            return signalName.replace(tran, '');
        } else {
            return signalName;
        }
    }

    modifyChipIcon(draft, signalType, signal, operation) {
        let memoryType: string = '';
        let signalId: string = '';
        signal = this.removeSerialNumber(signal);
        //signal = this.removeSignalTranslation(signal);
        // if (signal.indexOf(':') != -1 && signalType != ChipType.ETCS) {
        if (signal.indexOf(':') != -1) {
            memoryType = signal.substring(signal.lastIndexOf(':') + 1).trim();
            signalId = signal.substring(0, signal.lastIndexOf(':')).trim();
        }
        //  else if (signal != 'POI' && signal != 'Signal description') {
        //     memoryType = 'ETCS_ORIGIN';
        //     signalId = signal.trim();
        // }

        if (memoryType) {
            for (let nodeSignalMemory of draft.nodesMemory) {
                if (nodeSignalMemory.name == memoryType) {
                    for (let nodeSignalType of nodeSignalMemory.children) {
                        if (nodeSignalType.name == signalType) {
                            for (let nodeSignalId of nodeSignalType.children) {
                                if (nodeSignalId.name == signalId || nodeSignalId.tooltip == signalId) {
                                    nodeSignalId.icon = this.getIcon(operation);
                                    break;
                                }
                            }
                        }
                    }
                    break;
                }
            }
        } else {
            for (let nodeSignalType of draft.nodesChips[0].children) {
                if (nodeSignalType.name == signalType) {
                    for (let nodeSignal of nodeSignalType.children) {
                        if (nodeSignal.tooltip == signal || nodeSignal.name == signal) {
                            nodeSignal.icon = this.getIcon(operation);
                            break;
                        }
                    }
                    break;
                }
            }
        }
    }

    @Action(ModifyChip)
    modifyChip(ctx: StateContext<SaCriteriaStateModel>, action: ModifyChip) {
        ctx.setState(
            produce((draft) => {
                this.modifyChipIcon(draft, action.chipType, action.chip, action.chipOperation);
            })
        );
    }

    @Action(UpdatePreviousAnalogSignals)
    updatePreviousAnalogSignals(ctx: StateContext<SaCriteriaStateModel>, action: UpdatePreviousAnalogSignals) {
        ctx.patchState({
            itemsRequireMatchAnalogPrevious: ctx.getState().currItemsRequireMatchAnalog,
        });
    }

    @Action(UpdatePreviousDigitalSignals)
    updatePreviousDigitalSignals(ctx: StateContext<SaCriteriaStateModel>, action: UpdatePreviousDigitalSignals) {
        ctx.patchState({
            itemsRequireMatchDigitalPrevious: ctx.getState().currItemsRequireMatchDigital,
        });
    }

    @Action(UpdatePreviousOtherSignals)
    updatePreviousOtherSignals(ctx: StateContext<SaCriteriaStateModel>, action: UpdatePreviousOtherSignals) {
        ctx.patchState({
            itemsRequireMatchOtherPrevious: ctx.getState().currItemsRequireMatchOther,
        });
    }

    @Action(UpdatePreviousEtcsSignals)
    updatePreviousEtcsSignals(ctx: StateContext<SaCriteriaStateModel>, action: UpdatePreviousEtcsSignals) {
        ctx.patchState({ itemsRequireMatchEtcsPrevious: ctx.getState().currItemsRequireMatchEtcs });
    }

    @Action(UpdatePreviousEventSignals)
    updatePreviousEventSignals(ctx: StateContext<SaCriteriaStateModel>, action: UpdatePreviousEventSignals) {
        ctx.patchState({
            itemsRequireMatchEventPrevious: ctx.getState().currItemsRequireMatchEvent,
        });
    }

    @Action(SetSignalsUpdated)
    setSignalsUpdated(ctx: StateContext<SaCriteriaStateModel>, action: SetSignalsUpdated) {
        return this._signalServiceNew.signalsChanged(action.vehicle, action.timefromSignals, action.timetoSignals).pipe(
            tap((signalsUpdated) => {
                ctx.setState(
                    produce((draft) => {
                        if (draft.signalsUpdated == null && signalsUpdated == null) {
                            return;
                        }
                        draft.signalsUpdated = signalsUpdated;

                        for (let nodeLevelOne of draft.nodesChips) {
                            for (let nodeLevelTwo of nodeLevelOne.children) {
                                for (let nodeLevelThree of nodeLevelTwo.children) {
                                    if (
                                        signalsUpdated &&
                                        this.signalPresent(nodeLevelThree.signalId, signalsUpdated.signals)
                                    ) {
                                        nodeLevelThree.updated = true;
                                    } else {
                                        nodeLevelThree.updated = false;
                                    }
                                }
                            }
                        }

                        for (let nodeSignalTypeMem of draft.nodesMemory) {
                            for (let nodeMemoryMem of nodeSignalTypeMem.children) {
                                for (let nodeSignalMem of nodeMemoryMem.children) {
                                    if (
                                        signalsUpdated &&
                                        this.signalWithMemoryPresent(
                                            nodeSignalMem.signalId,
                                            nodeSignalTypeMem.name,
                                            signalsUpdated.signals
                                        )
                                    ) {
                                        nodeSignalMem.updated = true;
                                    } else {
                                        nodeSignalMem.updated = false;
                                    }
                                }
                            }
                        }

                        for (let nodeLevelOne of draft.nodesEvent) {
                            if (signalsUpdated && this.eventPresent(nodeLevelOne.name, signalsUpdated.events)) {
                                nodeLevelOne.updated = true;
                            } else {
                                nodeLevelOne.updated = false;
                            }
                        }
                    })
                );
            })
        );
    }

    @Action(ClearSignalsUpdated)
    clearSignalsUpdated(ctx: StateContext<SaCriteriaStateModel>, action: ClearSignalsUpdated) {
        ctx.patchState({ signalsUpdated: null });
    }

    @Action(RemoveMarkers)
    removeMarkers(ctx: StateContext<SaCriteriaStateModel>, action: RemoveMarkers) {
        ctx.patchState({ signalsUpdated: null });
    }

    signalPresent(signalName: string, signals: Map<string, string[]>): boolean {
        let present = false;

        for (let mem in signals) {
            let signalsList = signals[mem];
            if (!present && signalsList.filter((signal) => signal == signalName).length != 0) {
                present = true;
            }
        }
        return present;
    }

    signalWithMemoryPresent(signalName: string, memory: string, signals: Map<string, string[]>): boolean {
        let signalsForMemory = signals[memory];
        if (signalsForMemory) {
            return signalsForMemory.filter((signal) => signal == signalName).length != 0;
        } else {
            return false;
        }
    }

    eventPresent(eventName: string, eventList: string[]): boolean {
        return eventList.filter((event) => event == eventName).length != 0;
    }

    @Action(ResetStoreCriteria)
    resetStoreCriteria(ctx: StateContext<SaCriteriaStateModel>, action: ResetStoreCriteria) {
        ctx.setState(defaultState);
    }

    @Selector()
    static selectedSignals(state: SaCriteriaStateModel): ISelectedSignals {
        return {
            analog: state.currItemsRequireMatchAnalog,
            digital: state.currItemsRequireMatchDigital,
            other: state.currItemsRequireMatchOther,
            standing: state.currItemsRequireMatchStanding,
            etcs: state.currItemsRequireMatchEtcs,
            event: state.currItemsRequireMatchEvent,
        };
    }

    @Selector()
    static signalSettingsSignals(state: SaCriteriaStateModel) {
        return {
            itemsAnalog: state.itemsAnalog,
            itemsDigital: state.itemsDigital,
            itemsOther: state.itemsOther,
            itemsEtcs: state.itemsEtcs,
        };
    }
}
