import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { SignalType } from '../../../../services/signal-data.service';
import {
    AddNewSignalChartDrawingOption,
    SetAnalogSignalChartDrawingOptions,
    SetDigitalSignalChartDrawingOptions,
    SetEventSignalChartDrawingOptions,
    SetPreviewSignalChartDrawingOptions,
} from '../action/signal-chart-drawing-options.actions';

export interface SingleSignalChartDrawingOption {
    signalId: '';
    signalColor: '';
    labelColor: '';
    labelFontSize: 12;
}

export interface SignalChartDrawingOptionsStateModel {
    previewChartOptions: SingleSignalChartDrawingOption[];
    analogChartOptions: SingleSignalChartDrawingOption[];
    digitalChartOptions: SingleSignalChartDrawingOption[];
    eventChartOptions: SingleSignalChartDrawingOption[];
    noviParam: string;
}

const defaultState = {
    previewChartOptions: [],
    analogChartOptions: [],
    digitalChartOptions: [],
    eventChartOptions: [],
    noviParam: '',
};

@Injectable()
@State<SignalChartDrawingOptionsStateModel>({
    name: 'signalChartDrawingOptions',
    defaults: defaultState,
})
export class SignalChartDrawingOptionsState {
    constructor(private store: Store) {}

    @Action(AddNewSignalChartDrawingOption)
    addNewSignalChartDrawingOption(
        ctx: StateContext<SignalChartDrawingOptionsStateModel>,
        action: AddNewSignalChartDrawingOption
    ) {
        ctx.patchState({
            analogChartOptions: [
                action.chartDrawingOption,
                action.chartDrawingOption,
                action.chartDrawingOption,
                action.chartDrawingOption,
            ],
        });

        /*** 
        switch(action.signalType) {
            case SignalType.Analog:

                ctx.patchState({noviParam: 'NOVI PARAM: ' + action.chartDrawingOption.signalId});

                ctx.patchState({
                    analogChartOptions: [
                        //...ctx.getState().analogChartOptions.filter(c => c.signalId !== action.chartDrawingOption.signalId), action.chartDrawingOption
                        action.chartDrawingOption,
                        action.chartDrawingOption,
                        action.chartDrawingOption,
                        action.chartDrawingOption
                    ]
                });

            break;
        }
        ***/
    }

    @Action(SetPreviewSignalChartDrawingOptions)
    setPreviewSignalChartDrawingOptions(
        ctx: StateContext<SignalChartDrawingOptionsStateModel>,
        action: SetPreviewSignalChartDrawingOptions
    ) {
        ctx.patchState({ previewChartOptions: action.chartOptions });
    }

    @Action(SetAnalogSignalChartDrawingOptions)
    setAnalogSignalChartDrawingOptions(
        ctx: StateContext<SignalChartDrawingOptionsStateModel>,
        action: SetAnalogSignalChartDrawingOptions
    ) {
        ctx.patchState({ analogChartOptions: action.chartOptions });
    }

    @Action(SetDigitalSignalChartDrawingOptions)
    setDigitalSignalChartDrawingOptions(
        ctx: StateContext<SignalChartDrawingOptionsStateModel>,
        action: SetDigitalSignalChartDrawingOptions
    ) {
        ctx.patchState({ digitalChartOptions: action.chartOptions });
    }

    @Action(SetEventSignalChartDrawingOptions)
    setEventSignalChartDrawingOptions(
        ctx: StateContext<SignalChartDrawingOptionsStateModel>,
        action: SetEventSignalChartDrawingOptions
    ) {
        ctx.patchState({ eventChartOptions: action.chartOptions });
    }
}
