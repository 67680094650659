import { Injectable } from '@angular/core';
import { EvaPlusHttpService } from './http/eva-http.service';

@Injectable({ providedIn: 'root' })
export class AlertsService {
    constructor(private http: EvaPlusHttpService) {}

    query(): any {
        return this.http.get('/EvaPlusRest/dashboards/getLast5Events', {
            params: { event: 'ALL' },
        });
    }

    /*get(id: string): any {
   return this._http.get('http://localhost:8080/EvaPlusRest/dashboards/getLast5Events?event=ALL')
   .map((res: Response) => {
     let item: any;
     res.json().forEach((s: any) => {
       if (s.item_id === id) {
         item = s;
       }
     });
     return item;
   });
  }*/
}
