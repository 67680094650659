import { Injectable, Optional } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OAuthStorage, OAuthService, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
    constructor(
        private authStorage: OAuthStorage,
        private oauthService: OAuthService,
        @Optional() private moduleConfig: OAuthModuleConfig
    ) {}

    private checkUrl(url: string): boolean {
        let found = false;
        if (this.moduleConfig?.resourceServer && this.moduleConfig?.resourceServer.allowedUrls) {
            found = !!this.moduleConfig.resourceServer.allowedUrls.find((u) => url.startsWith(u));
        }
        return found;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url.toLowerCase();

        if (url.includes('oauth/config')) {
            req = req.clone({ headers: req.headers.set('X-OC-TOKEN', window.btoa(this.ocToken)) });
        }

        if (!this.moduleConfig?.resourceServer && !this.moduleConfig.resourceServer.allowedUrls) {
            return next.handle(req);
        }
        if (!this.checkUrl(url)) {
            return next.handle(req);
        }

        let token = this.authStorage.getItem('access_token');
        if (token) {
            if (token && environment.buildFor === 'secheron') {
                req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
            } else {
                req = req.clone({ headers: req.headers.set('auth-token', `Bearer ${token}`) });
            }
        }
        return next.handle(req).pipe(
            tap({
                next: () => {},
                error: (error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status !== 401) {
                            return;
                        }
                        this.oauthService.refreshToken().catch((error) => {
                            if (error.status === 400) {
                                this.oauthService.logOut();
                            }
                        });
                    }
                },
            })
        );
    }

    private get ocToken(): string {
        const timestamp = Date.now();
        return `${this.tenant}_${timestamp}`;
    }

    private get tenant(): string {
        if (window.location.hostname === 'localhost') {
            return 'sso';
        }
        return window.location.host.split(".")[0];
    }
}
