import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';

export class SetNodeCounter {
    static readonly type = '[Grid] Set Node Counter';
    constructor(public num: number) {}
}

export class SetTableDataLength {
    static readonly type = '[Grid] Set Table Data Length';
    constructor(public num: number) {}
}

export class SetColumnState {
    static readonly type = '[Grid] Set Column State';
    constructor(public columnState: ColumnState[]) {}
}

export class SetFilterModel {
    static readonly type = '[Grid] Set Filter Model';
    constructor(public filterModel: any) {}
}

export class SetLatestRecordFromGrid {
    static readonly type = '[Grid] Set Latest Record From Grid';
    constructor(public latestRecordFromGrid: any) {}
}

export class ClearRecordForDetails {
    static readonly type = '[Grid] Clear Record For Details';
}

export class SetRecordForDetails {
    static readonly type = '[Grid] Set Record For Details';
    constructor(public items: any[]) {}
}

export class SetEtcsRowSelected {
    static readonly type = '[Grid] Set Etcs Row Selected';
    constructor(public etcsRowSelected: boolean) {}
}

export class SetSelectedNewGrid {
    static readonly type = '[Grid] Set Selected New Grid';
    constructor(public selectedNewGrid: any[]) {}
}

export class SetAndSortSelectedNewGrid {
    static readonly type = '[Grid] Set and Sort Selected New Grid';
    constructor(public selectedNewGrid: any[]) {}
}

export class SetRowAgGridData {
    static readonly type = '[Grid] Set Row Ag Grid Data';
    constructor(public rowAgGridData: any[]) {}
}

export class SetTableData {
    static readonly type = '[Grid] Set Table Data';
    constructor(public tableData: any[]) {}
}

export class SetTableColumns {
    static readonly type = '[Grid] Set Table Columns';
    constructor(public tableColumns: any[]) {}
}

export class ResetStoreGrid {
    static readonly type = '[Grid] Reset Store Grid';
}
