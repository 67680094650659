import { NgModule } from '@angular/core';
import { ZoomDialog } from './zoom-dialog/zoom-dialog';
import { DialogUserConsent } from './user-consent-dialog/user-consent-dialog';
import { PreviewClickDialog } from './preview-click-dialog/preview-click-dialog';
import { DialogBookmark } from './hr-bookmarks-dialog/hr-bookmarks-dialog.component';
import { DialogDrawText } from './draw-text-dialog/draw-text-dialog.component';
import { ConfirmationDialog } from './confirmation-dialog/confirmation-dialog';
import { DialogChooseNew } from '../dashboard-ioms/schedule/services/create-new.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { DialogChangeLanguage } from './change-language-dialog/change-language-dialog.component';
import { UserSurveyDialogComponent } from './user-survey-dialog/user-survey-dialog.component';
import { DialogNotificationThreshold } from './add-notif-threshold-dialog/add-notif-threshold-dialog.component';
import { HrVehicleFleetModule } from '../components/hr-vehicle-fleet/hr-vehicle-fleet.module';
import { HrVehicleMemoryModule } from '../components/hr-vehicle-memory/hr-vehicle-memory.module';

@NgModule({
    declarations: [
        ZoomDialog,
        DialogUserConsent,
        PreviewClickDialog,
        DialogBookmark,
        DialogDrawText,
        ConfirmationDialog,
        DialogChooseNew,
        DialogChangeLanguage,
        UserSurveyDialogComponent,
        DialogNotificationThreshold,
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        FormsModule,
        MatIconModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        ReactiveFormsModule,
        HrVehicleFleetModule,
        HrVehicleMemoryModule,
    ],
})
export class DialogsModule {}
