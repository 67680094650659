import { Injectable } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

export interface IUserDashboard {
    allUsers: boolean;
    user: String;
    userDashboardName: String;
    isDefault: boolean;
    gridsterItems: Array<GridsterItem>;
}

export interface ITemplateNameAndIdForUser {
    id: string;
    templateName: string;
}

export interface IEventTemplateNameAndTimestamps {
    eventTemplateName: string;
    eventTemplateUser: string;
    // eventsTimeFrom: string;
    // eventsTimeTo: string;
}

export interface IEventTemplateName {
    name: string;
    eventTemplateNames: boolean;
}

// tree structure event template names
export interface IEventTemplateGroup {
    name: string;
    eventTemplateGroupValue: IEventTemplateGroupMember[];
}

export interface IEventTemplateGroupMember {
    eventTemplateName: string;
    eventTemplateUser: string;
}

// tree structure
// export interface IDashboardGroup {
//   name: string;
//   dashboardGroupValue: IDashboardGroupValue[] ;
// }

// export interface IDashboardGroupValue {
//   value: IDashboardValue;
//   viewValue: string;
// }

// export interface IDashboardValue {
//   dashboardName: string;
//   username: string;
//   isDefault: boolean;
// }

// tree structure dashboard names
export interface IDashboardGroup {
    name: string;
    dashboardGroupValue: IDashboardGroupValue[];
}

export interface IDashboardGroupValue {
    dashboardName: string;
    username: string;
    isDefault: boolean;
    allUsers: boolean;
    // uuid: string;
}

const baseUrl = '/EvaPlusRest/userDashboard/';

@Injectable({ providedIn: 'root' })
export class UserDashboardService {
    constructor(private http: EvaPlusHttpService) {}

    getUserDashboardConfiguration(
        user: string,
        userDashboardName: string
    ): Observable<IUserDashboard> {
        return this.http.get(baseUrl + 'getUserDashboard', {
            params: {
                user,
                userDashboardName,
            },
        });
    }

    changeTemplateName(
        user: String,
        userDashboardName: String,
        gridsterItemId: String,
        eventTemplateName: String,
        eventTemplateUser: string,
        widgetTitle: string,
        isDefaultTitleValue: boolean,
        widgetSubtitle: string,
        isDefaultSubtitleValue: boolean
    ) {
        let content = JSON.stringify({
            user: user,
            userDashboardName: userDashboardName,
            gridsterItemId: gridsterItemId,
            eventTemplateName: eventTemplateName,
            eventTemplateUser: eventTemplateUser,
            widgetTitle: widgetTitle,
            isDefaultTitleValue: isDefaultTitleValue,
            widgetSubtitle: widgetSubtitle,
            isDefaultSubtitleValue: isDefaultSubtitleValue,
        });
        return this.http.post(baseUrl + 'changeTemplateName', content);
    }

    getTemplateNamesForUser(user: string): Observable<IEventTemplateGroup[]> {
        return this.http.get(baseUrl + 'templateNamesForUser', {
            params: { user },
        });
    }

    saveUserDashboardConfiguration(gridsterData: IUserDashboard) {
        let content = JSON.stringify(gridsterData);
        return this.http.post(baseUrl + 'save', content);
    }

    addNewUserDashboard(gridsterData: IUserDashboard) {
        let content = JSON.stringify(gridsterData);
        return this.http.post(baseUrl + 'save', content);
    }

    getTemplateNamesAndIdsForUserAndUserDashboard(
        user: string,
        userDashboardName: string
    ): Observable<ITemplateNameAndIdForUser[]> {
        return this.http.get(baseUrl + 'templateNamesAndIdsForUserAndUserDashboard', {
            params: {
                user,
                userDashboardName,
            },
        });
    }

    updateUserDashboard(
        user: string,
        userDashboardName: string,
        isDefault: string,
        gridsterItem: GridsterItem
    ) {
        let uriString = baseUrl + 'update';
        let content = JSON.stringify({
            user: user,
            userDashboardName: userDashboardName,
            isDefault: isDefault,
            gridsterItem: gridsterItem,
        });
        return this.http.put(uriString, content);
    }

    getUserDashboardTemplateNamesForUser(user: string): Observable<IDashboardGroup[]> {
        return this.http.get(baseUrl + 'userDashboardTemplateNamesForUser', {
            params: { user },
        });
    }

    removeGridsterItem(
        user: string,
        userDashboardName: string,
        gridsterItemId: string
    ): Observable<any> {
        let content = JSON.stringify({
            user: user,
            userDashboardName: userDashboardName,
            gridsterItemId: gridsterItemId,
        });
        return this.http.put(baseUrl + 'removeGridsterItem', content);
    }

    deleteUserDashboard(user: string, userDashboardName: string): Observable<any> {
        let content = JSON.stringify({
            user,
            userDashboardName,
        });
        return this.http.put(baseUrl + 'deleteUserDashboard', content);
    }

    setAsDefault(user: string, userDashboardName: string): Observable<any> {
        let content = JSON.stringify({
            user,
            userDashboardName,
        });
        return this.http.put(baseUrl + 'setAsDefault', content);
    }

    updateAllUsersField(
        user: string,
        userDashboardName: string,
        allUsers: boolean
    ): Observable<any> {
        let content = JSON.stringify({
            user: user,
            userDashboardName: userDashboardName,
            allUsers: allUsers,
        });
        return this.http.put(baseUrl + 'updateAllUsersField', content);
    }

    getDefaultTemplateNameAndTimestampsForUser(
        user: string
    ): Observable<IEventTemplateNameAndTimestamps> {
        return this.http.get(baseUrl + 'getDefaultEventTemplateNameAndTimestampsForUser', {
            params: { user },
        });
    }

    saveAs(gridsterData: IUserDashboard) {
        let content = JSON.stringify(gridsterData);
        return this.http.post(baseUrl + 'saveAs', content);
    }
}
