import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { TreeNode } from 'primeng/api';
import {
    ResetStoreSidebar,
    SetAnalogSignalHover,
    SetConfigurationDetails,
    SetEtcsProperties,
    SetEtcsPropertiesStoragge,
    SetHeightMap,
    SetLatAndLot,
} from '../action/sidebar.actions';
import produce from 'immer';
import { SetVehicle } from '../action/toolbar.actions';

export interface SaSidebarStateModel {
    lat: number;
    lon: number;
    heightMap: number;
    etcsProperties: TreeNode[];
    etcsPropertiesStoragge: TreeNode[];
    analogSignalHover: any[];
    configurationDetails: any[];
}

const defaultState = {
    lat: 0,
    lon: 0,
    heightMap: 200,
    etcsProperties: undefined,
    etcsPropertiesStoragge: undefined,
    analogSignalHover: [],
    configurationDetails: [],
};

@Injectable()
@State<SaSidebarStateModel>({
    name: 'saSidebar',
    defaults: defaultState,
})
export class SaSidebarState {
    @Action(SetLatAndLot)
    setLatAndLot(ctx: StateContext<SaSidebarStateModel>, action: SetLatAndLot) {
        ctx.patchState({ lat: action.lat, lon: action.lot });
    }

    @Action(SetHeightMap)
    setHeightMap(ctx: StateContext<SaSidebarStateModel>, action: SetHeightMap) {
        ctx.patchState({ heightMap: action.heightMap });
    }

    @Action(SetEtcsProperties)
    setEtcsProperties(ctx: StateContext<SaSidebarStateModel>, action: SetEtcsProperties) {
        ctx.setState(
            produce((draft) => {
                draft.etcsProperties = action.etcsProperties;
                if (
                    action.etcsProperties != undefined &&
                    action.etcsProperties.length > 0 &&
                    draft.etcsPropertiesStoragge !== undefined
                ) {
                    this.expandLikePrevious(draft.etcsPropertiesStoragge, draft.etcsProperties);
                    draft.etcsPropertiesStoragge = action.etcsProperties;
                }
            })
        );
    }

    expandLikePrevious(oldNodes: TreeNode[], newNodes: TreeNode[]) {
        for (var i = 0; i < Math.min(oldNodes.length, newNodes.length); i++) {
            this.iterateNodesRecursive1(oldNodes[i], newNodes[i], 0);
        }
    }

    iterateNodesRecursive1(oldNode: TreeNode, newNode: TreeNode, depth: number) {
        if (oldNode.children && newNode.children) {
            newNode.expanded = oldNode.expanded;
            for (
                var i = 0, len = Math.min(oldNode.children.length, newNode.children.length);
                i < len;
                i++
            ) {
                this.iterateNodesRecursive1(oldNode.children[i], newNode.children[i], depth + 1);
            }
        }
    }

    @Action(SetVehicle)
    setVehicle(ctx: StateContext<SaSidebarStateModel>, action: SetVehicle) {
        ctx.patchState({ etcsProperties: [] });
    }

    @Action(SetEtcsPropertiesStoragge)
    setEtcsPropertiesStoragge(
        ctx: StateContext<SaSidebarStateModel>,
        action: SetEtcsPropertiesStoragge
    ) {
        ctx.patchState({ etcsPropertiesStoragge: action.etcsPropertiesStoragge });
    }

    @Action(SetAnalogSignalHover)
    setAnalogSet(ctx: StateContext<SaSidebarStateModel>, action: SetAnalogSignalHover) {
        ctx.patchState({ analogSignalHover: action.analogSignalHover });
    }

    @Action(SetConfigurationDetails)
    setConfigDetails(ctx: StateContext<SaSidebarStateModel>, action: SetConfigurationDetails) {
        ctx.patchState({ configurationDetails: action.configurationDetails });
    }

    @Action(ResetStoreSidebar)
    resetStoreToolbar(ctx: StateContext<SaSidebarStateModel>, action: ResetStoreSidebar) {
        ctx.setState(defaultState);
    }

    @Selector()
    static analogSignalHover(state: SaSidebarStateModel) {
        return state.analogSignalHover;
    }

    @Selector()
    static configurationDetails(state: SaSidebarStateModel) {
        return state.configurationDetails;
    }
}
