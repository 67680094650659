import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogsServices } from '../../../services/dialogs.service';
import { UserService } from '../../users/services/user.service';

@Component({
    selector: 'user-consent-dialog',
    templateUrl: 'user-consent-dialog.html',
})
export class DialogUserConsent {
    chartOptions: any;
    chb1: boolean = true;
    chb2: boolean = true;
    chb3: boolean = true;
    consentText: string = 'Consent text here';
    userDetails: any;
    mailFrom: string = 'mailto:';
    anonymizedCheckboxText: string = '';
    newsLetterText: string = '';

    constructor(
        public dialogRef: MatDialogRef<DialogUserConsent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog,
        private dialogServices: DialogsServices,
        private _userService: UserService
    ) {}

    ngAfterViewInit() {
        this.getMailFrom();
        this.getConsentText();
        this.userDetails = JSON.parse(localStorage.getItem('currentUser'));

        if (this.data.openedFrom === 'MAIN_COMPONENT') {
            this.getCurrentUserObject();
        }
    }

    onNoClick(): void {
        this.dialogRef.close({ data: 'canceled' });
    }

    save() {
        this.dialogRef.close({ data: 'confirmed' });
        this.sendUserConsentAgreement();
    }

    getCurrentChartOptions(co: any) {
        this.chartOptions = co;
    }

    getConsentText() {
        this.dialogServices.getConsentMessage().subscribe((response: any) => {
            this.consentText = response.consentMessage.replaceAll('"', '');
        });
    }

    getMailFrom() {
        this.dialogServices.getMailFromParameter().subscribe((response: any) => {
            this.mailFrom =
                this.mailFrom +
                response.mailFrom +
                '?subject=User consent - tenant: ' +
                response.tenant +
                ' user: ' +
                JSON.parse(localStorage.getItem('currentUser')).name;
            this.anonymizedCheckboxText = response.anonymizedDataText;
            this.newsLetterText = response.newsLetterText;
        });
    }

    sendUserConsentAgreement() {
        var agree = '';
        if (this.chb1) {
            agree = 'true';
        } else {
            agree = 'false';
        }
        let userConsentData = new ConsentAgreementData(null, null, null);
        userConsentData.operational_cost = this.chb1;
        userConsentData.anonymized_data = this.chb2;
        userConsentData.news_letter = this.chb3;

        this.dialogServices
            .sendUserConsentAgreement(userConsentData, this.userDetails)
            .subscribe((response: any) => {});
    }

    downloadLastTenDaysData() {
        // this.dialogServices.downloadLastTehnDaysUserData(this.userDetails.name).subscribe((response: any) => {
        // });
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        let port = window.location.port;
        if (port == '4200') {
            port = '8080';
        }
        const url = protocol + '//' + hostname + ':' + port;
        const path =
            url +
            '/EvaPlusRest/usermonitoring/downloadLastTehnDaysUserData?&userName=' +
            this.userDetails.name;

        window.open(path);
    }

    getCurrentUserObject() {
        this._userService.getUser(this.userDetails.id).subscribe((response: any) => {
            if (response['user_consent']) {
                let userConsentTemp = JSON.parse(response.user_consent);
                this.chb2 = userConsentTemp.anonymized_data;
                this.chb3 = userConsentTemp.news_letter;
            }
        });
    }
}

export interface DialogData {
    width: string;
    height: string;
    data: { chartData: any };
    comment: string;
    openedFrom: string;
}

export class ConsentAgreementData {
    operational_cost: boolean;
    anonymized_data: boolean;
    news_letter: boolean;

    constructor(operational_cost: boolean, anonymized_data: boolean, news_letter: boolean) {
        this.operational_cost = operational_cost;
        this.anonymized_data = anonymized_data;
        this.news_letter = news_letter;
    }
}
