import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Frequency, Options } from 'rrule';
import { IomsActionType, IomsDevice } from '../../../../../services/iomsdevicemanger.service';
import { deviceTreeI } from '../../services/load-devices.service';
export interface DialogScheduleActionDataI {
    // Initial selected data in dialog
    start: Date;
    end: Date;
    duration: number;
    description: string;
    user: any;
    actionType: string;
    device: string; // Contains line_substation_cubicle
    equipment: string[];
    workInstructionsIds: string[];
    location?: string;
    // General Information
    actionTypes: IomsActionType[];
    lines: deviceTreeI[];
    devices: IomsDevice[];
    isSeries: boolean;
    rrule?: string;
}

@Component({
    selector: 'app-dialog-schedule-action',
    styleUrls: ['./dialog-schedule-action.component.scss'],
    templateUrl: './dialog-schedule-action.component.html',
})
export class DialogScheduleAction implements OnInit {
    // Template variables
    thisForm: FormGroup;
    location: string;
    eventStart: Date;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: DialogScheduleActionDataI
    ) {}

    ngOnInit() {
        this.createForms();
    }
    createForms() {
        let fields: any = {
            schedule: [''],
            work: [''],
        };
        if (this.data.isSeries) {
            fields.rrule = [''];
        }
        this.thisForm = this.fb.group(fields);
    }

    dateChange(event) {
        this.eventStart = event.value;
    }
    locationChange(event) {
        this.location = event.value;
    }

    save() {
        let val = this.thisForm.value;
        // Create device (deviceLevelId) information
        let cubicles: string[] = (<string[]>val.work.cubicle)
            .map((c) => c.split('_')[2]) // Take third component in text Line_substation_cubicle
            .slice(1); // Discard first (will be included in next line)
        cubicles.unshift(val.work.cubicle[0]); // Insert first Line_substation_cubicle
        let device: string = cubicles.join(';'); // join Line_substation_cubicle;cubicle;cubicle
        // Equipment: for every selected val.equipment, find its corresponding mongoId in data.devices
        let equipment = val.work.equipment.map((eq) => {
            let f = eq.split('_');
            let id = (this.data.devices.find((d) => d.levelId === eq) || <any>{}).mongoId;
            return `${f[2]};${id}`;
        });

        let ret: DialogScheduleActionDataI = {
            // data from dialog
            start: val.schedule.dates.eventStart,
            end: val.schedule.dates.eventEnd,
            duration: val.schedule.duration,
            description: val.work.description,
            user: this.data.user.id,
            actionType: val.schedule.actionType,
            device,
            equipment,
            workInstructionsIds: val.work.workInstructions,
            location: this.location,
            //
            actionTypes: undefined,
            lines: undefined,
            devices: undefined,
            isSeries: this.data.isSeries,
        };
        if (this.data.isSeries) {
            // Sanitize rule before stringify
            let rule: Partial<Options> = {
                freq: val.rrule.freq,
                interval: val.rrule.interval,
            };
            if (val.rrule.freq === Frequency.YEARLY) {
                if (val.rrule.bysetpos) {
                    rule.byweekday = val.rrule.byweekday;
                    rule.bysetpos = val.rrule.bysetpos;
                    rule.bymonth = val.rrule.bymonth;
                } else {
                    rule.bymonth = val.rrule.bymonth;
                    rule.bymonthday = val.rrule.bymonthday;
                }
            } else if (val.rrule.freq === Frequency.MONTHLY) {
                if (val.rrule.bysetpos) {
                    rule.byweekday = val.rrule.byweekday;
                    rule.bysetpos = val.rrule.bysetpos;
                } else {
                    rule.bymonthday = val.rrule.bymonthday;
                }
            }
            ret.rrule = JSON.stringify(rule);
        }
        this.dialogRef.close(ret);
    }
    cancel() {
        this.dialogRef.close(undefined);
    }
}
