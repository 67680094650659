import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as momentTimezone from 'moment-timezone';

/**
 * A moment timezone pipe to support parsing based on time zone abbreviations
 * covers all cases of offset variation due to daylight saving.
 *
 * Same API as DatePipe with additional timezone abbreviation support
 * Official date pipe dropped support for abbreviations names from Angular V5
 */

@Pipe({
    name: 'momentTimezoneDate',
})
export class MomentTimezoneDatePipe extends DatePipe implements PipeTransform {
    /***
  (value: string | number | Date, format: string, timezone: string, locale?: string) => string
  (value: string | number | Date, format?: string, timezone?: string, locale?: string): string; 
  (value: null, format?: string, timezone?: string, locale?: string): null; 
  (value: string | number | Date, format?: string, timezone?: string, locale?: string): string;
  ***/

    transformToString(
        value: Date | string | number,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null {
        const timezoneOffset = momentTimezone(value)
            .tz(timezone || '')
            .format('Z');
        return super.transform(value, format, timezoneOffset, locale);
    }

    transformDate(value: number | Date | string, format: string, timezone: string): Date {
        const timezoneOffset = momentTimezone(value).tz(timezone).format('Z');

        let dateString = super.transform(value, format, timezoneOffset);

        return new Date(dateString);
    }
}
