import { Pipe, PipeTransform } from '@angular/core';
import { IMarker } from './fleet-location-map.component';

@Pipe({
    name: 'filter1',
})
export class FilterPipe implements PipeTransform {
    transform(items: IMarker[], searchText: string): IMarker[] {
        if (!items) return [];
        if (!searchText) return [];
        searchText = searchText.toLowerCase();
        return items.filter((it) => {
            return it.vehicleId.toLowerCase().includes(searchText);
        });
    }
}
