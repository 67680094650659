import { TreeNode } from 'primeng/api';

export class SetLatAndLot {
    static readonly type = '[Sidebar] Set Lat and Lon';
    constructor(public lat: number, public lot: number) {}
}

export class SetHeightMap {
    static readonly type = '[Sidebar] Set Height Map';
    constructor(public heightMap: number) {}
}

export class SetEtcsProperties {
    static readonly type = '[Sidebar] Set Etcs Properties';
    constructor(public etcsProperties: TreeNode[]) {}
}
export class SetAnalogSignalHover {
    static readonly type = '[Sidebar] Set Analog Signal Hover';
    constructor(public analogSignalHover: any[]) {}
}
export class SetConfigurationDetails {
    static readonly type = '[Sidebar] Set Configuration Details';
    constructor(public configurationDetails: any[]) {}
}

export class SetEtcsPropertiesStoragge {
    static readonly type = '[Sidebar] Set Etcs Properties Storagge';
    constructor(public etcsPropertiesStoragge: TreeNode[]) {}
}

export class ResetStoreSidebar {
    static readonly type = '[Sidebar] Reset Store Sidebar';
}
