import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ITreeOptions, TreeComponent, TreeNode } from '@circlon/angular-tree-component';

@Component({
    selector: 'hr-vehicle-memory',
    templateUrl: './hr-vehicle-memory.component.html',
    styleUrls: ['./hr-vehicle-memory.component.scss'],
})
export class HrVehicleMemoryComponent implements OnInit {
    @Input() multiple: boolean;
    @Input() vehicles: string;
    @Input() set vehicleMemories(data: any[]) {
        this.nodes = data;
    }

    @Output() vehicleFleetSelected = new EventEmitter();

    @ViewChild('tree2', { static: false }) tree2: TreeComponent;
    @ViewChild('multitoolbar', { static: false }) multitoolbar: ElementRef<HTMLElement>;

    iconVehicle: string = 'memory';
    isLine: boolean = false;
    changeBySet: boolean = false;
    placeholderText = '';
    markedToBeCleared: boolean = false;

    keyboardArrow: boolean = false;
    showMulti: boolean = false;
    filterValue = '';
    nodes = [];
    data: any;
    readyData: any = [];
    init: boolean = false;
    gotFocus: boolean = false;

    optionsMulti: ITreeOptions = {
        useCheckbox: true,
        actionMapping: {
            mouse: {
                click: (tree, node: TreeNode, $event) => {
                    $event.stopPropagation();
                    if (node.children != null) {
                        node.children
                            .filter((child: TreeNode) => !!child) //Remove null, undefined, 0, false or an empty string values
                            .forEach((child: TreeNode) => {
                                child.isSelected
                                    ? child.setIsSelected(false)
                                    : child.setIsSelected(true);
                            });
                    } else {
                        node.isSelected ? node.setIsSelected(false) : node.setIsSelected(true);
                    }
                },
                checkboxClick: (tree, node: TreeNode, $event) => {
                    $event.stopPropagation();
                    if (node.children != null) {
                        node.children
                            .filter((child: TreeNode) => !!child)
                            .forEach((child: TreeNode) => {
                                child.isSelected
                                    ? child.setIsSelected(false)
                                    : child.setIsSelected(true);
                            });
                    } else {
                        node.isSelected ? node.setIsSelected(false) : node.setIsSelected(true);
                    }
                },
            },
        },
    };

    ngOnInit() {
        if (this.multiple == undefined) {
            this.multiple = false;
        }
        if (this.vehicles == undefined) {
            this.vehicles = '';
        }

        if (typeof this.multiple == 'string') {
            this.multiple = true;
        }
        this.isLine = true;

        if (this.multiple) {
            this.placeholderText = 'Vehicle memory';
        }
    }

    getHowBig() {
        if (this.vehicles == undefined) {
            return '1';
        }
        if (this.vehicles.length > 68) {
            return '3';
        }
        return this.vehicles.length > 19 ? '2' : '1';
    }

    showToolbar() {
        this.keyboardArrow = !this.keyboardArrow;
        if (this.multiple) {
            this.showMulti = !this.showMulti;
            if (this.showMulti) {
                setTimeout(() => {
                    this.multitoolbar.nativeElement.focus();
                }, 10);
            }
            return;
        }
    }

    hideToolbar($event) {
        this.gotFocus = false;
        setTimeout(() => {
            if (!this.gotFocus) {
                this.clearFilters(this.tree2);
                this.showMulti = false;
                this.keyboardArrow = false;
            }
        }, 200);
    }

    focusOnToolbar($event) {
        this.gotFocus = true;
    }

    showInputField($event) {
        this.gotFocus = true;
    }

    hideInputField($event) {
        this.gotFocus = false;
    }

    showTree($event) {
        this.gotFocus = true;
    }

    hideTree($event) {
        this.gotFocus = false;
    }

    onTreeEvent($event) {
        this.gotFocus = true;
    }

    clickMultiSelected() {
        let selectedVehicles: string[] = this.tree2.treeModel.selectedLeafNodes
            .filter((node: TreeNode) => node.level > 2 && node.data.name)
            .map((node: TreeNode) => node.parent.data.name + '::' + node.data.name);

        // There is a possibility that ALL vehicle memories are selected
        if (selectedVehicles.length == 0 && this.tree2.treeModel.selectedLeafNodes.length > 0) {
            selectedVehicles = this.tree2.treeModel.selectedLeafNodes[0].data.name;
        }

        this.vehicles = selectedVehicles.toString();
        this.vehicleFleetSelected.emit(selectedVehicles);

        this.changeBySet = true;
        this.clearFilters(this.tree2);
        this.keyboardArrow = false;
        this.showMulti = false;
    }

    private clearFilters(tree: TreeComponent) {
        if (tree) {
            tree?.treeModel.clearFilter();
        }
        this.filterValue = '';
    }
}
