import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/tableau/';

@Injectable({ providedIn: 'root' })
export class TableauService {
    constructor(private http: EvaPlusHttpService) {}

    //http://localhost:8080/EvaPlusRest/tableau/getReports
    getReports(): Observable<TableauReport[]> {
        return this.http.get(baseUrl + 'getReports');
    }

    //http://localhost:8080/EvaPlusRest/tableau/addReport
    addReport(report: TableauReport): Observable<any> {
        return this.http.post(baseUrl + 'addReport', report);
    }

    //http://localhost:8080/EvaPlusRest/tableau/deleteReport
    deleteReport(reportId: string): Observable<any> {
        return this.http.delete(baseUrl + `deleteReport?id=${reportId}`);
    }

    //http://localhost:8080/EvaPlusRest/tableau/updateReport
    updateReport(report: TableauReport): Observable<any> {
        return this.http.post(baseUrl + 'updateReport', report);
    }

    //http://localhost:8080/EvaPlusRest/tableau/getJwt
    getJwt(): Observable<any> {
        return this.http.get(baseUrl + 'getJwt');
    }

    //http://localhost:8080/EvaPlusRest/tableau/apiTokenGeneratedAt
    getTokenGeneratedAt(): Observable<any> {
        return this.http.get(baseUrl + 'apiTokenGeneratedAt');
    }

    //http://localhost:8080/EvaPlusRest/tableau/invalidateApiToken
    invalidateApiToken(): Observable<any> {
        return this.http.post(baseUrl + 'invalidateApiToken', {});
    }
}

export interface TableauReport {
    name: string;
    link: string;
    id: string;
}
