export const oauthAllowedUrls = [
    'https://lner.evaplus.com',
    'https://alstomngt.evaplus.com',
    'https://sso.evaplus.com',
    'http://localhost:8080'
]

export const sacheronOauthAllowedUrls = [
    'https://login.ioms-shg.com',
    'http://localhost:9080',
    'https://test.ioms-shg.com',
    'https://demo.ioms-shg.com',
    'https://brl-cptm.ioms-shg.com',
]