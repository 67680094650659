import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, } from '@angular/core';
import { DatePipe } from '@angular/common'; //SCHEDULE ACTION
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IomsDeviceManagerService, WorkInstructionNameAndId, } from '../../../../services/iomsdevicemanger.service'; //SCHEDULE ACTION
import { TdDialogService } from '@covalent/core/dialogs'; //SCHEDULE ACTION
import { IomsActionService } from '../../../../services/iomsAction.service';
import { CheckboxRenderer } from '../../../components/checkbox-renderer-ag-grid/checkbox-renderer.component';
import { CheckboxDisabledRenderer } from '../../../components/checkbox-renderer-ag-grid/checkbox-disabled-renderer.component';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

// IOMSIMAGE

export interface Tile {
    cols: number;
    rows: number;
    imageNumber: number;
}

@Component({
    selector: 'app-dialog-report-preview',
    templateUrl: './dialog-report-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./dialog-report-preview.component.scss'],
})
export class DialogReportPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    readOnlyUser: boolean = false;

    currentActionReportId: string;
    selectedWorkInstructionsIdsArray: string[];

    //@Input() actionReportId: string;
    //@Input() selectedWorkInstructionsIdsArray: string[];

    currentPath: string; //sb1
    toSave: boolean = false; //sb1

    //substations = ["substation1", "substation2", "substation3"];
    columnsReferenceDocumentsAgGridHeight = 300;
    checklistAgGridHeight = 400;

    agGridWidth = 1087;

    iomsWorkInstFull: IomsWorkInstructionFull = new IomsWorkInstructionFull();
    iomsWorkInstFullList: IomsWorkInstructionFull[] = [];

    columnsReferenceDocumentsAgGrid = [
        { headerName: 'Document name', field: 'name', width: 200, headerTooltip: '' },
        {
            headerName: 'Link',
            field: 'link',
            width: 497,
            headerTooltip: '',
            cellRenderer: this.createHyperLink.bind(this),
        },
    ];

    rowsReferenceDocumentsAgGrid = [
        // {"name": "Document name", "link": "https://secheron.com/wp-content/uploads/docs/SG847023BEN_A01_Brochure_STELLA-SEPCOS-PRISM_05-2020.pdf"}
    ];

    isEditable: boolean = true;

    columnsChecklistAgGrid = [];

    columnDefsReplacedItems = [];

    columnsDefTechnicians = [];

    gridOptions: GridOptions = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    frameworkComponents: {
        checkboxRenderer: typeof CheckboxRenderer;
        checkboxDisabledRenderer: typeof CheckboxDisabledRenderer;
        warningIconFormatterComponent: typeof WarningIconFormatterComponent;
    };

    actionNumber: string;
    reportId: string;
    startDateTimestamp: string;
    endDateTimestamp: string;
    lineName: string;
    substation: string;
    personInCharge: string;
    description: string;
    workInstructionsIdsStringGlobal: string;
    custRepName: string = '';
    permitToWorkNum: string = '';
    remarksText: string = '';

    technicians: Technician[] = [];
    techniciansAgGridHeight = 250;

    subGetToolboxData: Subscription = new Subscription();
    subConfirmFileDelete: Subscription = new Subscription();
    subConfirmExit: Subscription = new Subscription();

    pdfLogoPath: string = 'assets/images/pdf_logo.png';

    referenceDocumentsGridApi: GridApi;

    devices: any[] = [];

    allUsers: any[] = [];

    printButtonDisabled: boolean = true;

    showSpinner: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private _iomsActionService: IomsActionService,
        private _datePipe: DatePipe,
        public dialogRef: MatDialogRef<DialogReportPreviewComponent>,
        public _dialog: MatDialog,
        private _dialogService: TdDialogService,
        @Inject(MAT_DIALOG_DATA) public dataDialog: any
    ) {
        this.frameworkComponents = {
            checkboxRenderer: CheckboxRenderer,
            checkboxDisabledRenderer: CheckboxDisabledRenderer,
            warningIconFormatterComponent: WarningIconFormatterComponent,
        };

        this.reportId = dataDialog.reportId;
        this.actionNumber = dataDialog.actionNumber;
        this.allUsers = dataDialog.allUsers;
        this.currentActionReportId = dataDialog.currentActionReportId;
        this.selectedWorkInstructionsIdsArray = dataDialog.selectedWorkInstructionsIdsArray;
        this.currentPath = dataDialog.currentPath; //sb1

        // this.dialogRef.afterClosed().subscribe((dialogData) => {
        // });

        this.readOnlyUser = this.isReadOnlyRole();

        let checkboxRndr: string;
        if (this.readOnlyUser) {
            checkboxRndr = 'checkboxDisabledRenderer';
        } else {
            checkboxRndr = 'checkboxRenderer';
        }

        this.columnsChecklistAgGrid = [
            {
                headerName: 'Nr',
                field: 'number',
                cellRenderer: 'warningIconFormatterComponent',
                width: 95,
                headerTooltip: '',
                rowDrag: true,
            },
            {
                headerName: 'Item',
                field: 'item',
                width: 450,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
            {
                headerName: 'Pass',
                field: 'ok',
                width: 70,
                headerTooltip: '',
                cellRenderer: checkboxRndr,
            },
            {
                headerName: 'Fail',
                field: 'fail',
                width: 70,
                headerTooltip: '',
                cellRenderer: checkboxRndr,
            },
            {
                headerName: 'Comment',
                field: 'comment',
                width: 400,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
        ];

        this.columnDefsReplacedItems = [
            { headerName: 'Nr', field: 'number', width: 95, headerTooltip: '', rowDrag: true },
            {
                headerName: 'Component',
                field: 'component',
                width: 200,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
            {
                headerName: 'Changed',
                field: 'changed',
                width: 120,
                headerTooltip: '',
                cellRenderer: checkboxRndr,
            },
            {
                headerName: 'Old serial nbr.',
                field: 'oldSn',
                width: 150,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
            {
                headerName: 'New serial nbr.',
                field: 'newSn',
                width: 150,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
            {
                headerName: 'Comments',
                field: 'comments',
                width: 400,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
        ];

        this.columnsDefTechnicians = [
            { headerName: 'Nr', field: 'tableOrder', width: 70, headerTooltip: '', rowDrag: true },
            {
                headerName: 'Name',
                field: 'name',
                width: 350,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
            {
                headerName: 'Designation',
                field: 'designation',
                width: 600,
                headerTooltip: '',
                editable: this.isEditable,
                cellStyle: { textAlign: 'left' },
            },
        ];
    }

    isReadOnlyRole(): boolean {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentRoleActions = currentUser && currentUser.actions;
        if (currentRoleActions != undefined) {
            if (
                currentRoleActions.indexOf('ALL') != -1 ||
                currentRoleActions.indexOf('IOMS_MODIFICATION_ACCESS') != -1
            ) {
                this.isEditable = true;
                return false;
            }
        }
        this.isEditable = false;
        return true;
    }

    close() {
        if (this.readOnlyUser) {
            this.onClosing();
            return;
        }

        if (this.currentPath == 'current') {
            let message: string;
            message = 'Please be sure that all changes are saved before closing.';
            if (this.hasUncheckedFields()) {
                message =
                    'Please be sure that all changes are saved before closing. Report contains unchecked fields.';
            }
            this.subConfirmExit = this._dialogService
                .openConfirm({
                    message: message,
                    acceptButton: 'Close',
                    title: 'Confirm close',
                })
                .afterClosed()
                .subscribe((accept: boolean) => {
                    if (accept) {
                        this.onClosing();
                    }
                });
        } else {
            this.onClosing();
        }
    }

    onClosing() {
        this.dialogRef.close(this); //sb1

        this.dataDialog['toolBoxTalkData'] = {
            custRepName: this.custRepName,
            permitToWorkNum: this.permitToWorkNum,
            remarksText: this.remarksText,
            technicians: this.technicians,
        };
    }

    save() {
        //sb1
        this.toSave = true;
        this.dataDialog['toolBoxTalkData'] = {
            custRepName: this.custRepName,
            permitToWorkNum: this.permitToWorkNum,
            remarksText: this.remarksText,
            technicians: this.technicians,
        };
        this.dialogRef.close(this);
    }

    findUserById(id: string): any {
        for (let i = 0; i < this.allUsers.length; i++) {
            if (id == this.allUsers[i].id) {
                return this.allUsers[i];
            }
        }
    }

    addTechnician() {
        let newTechnician: Technician = {
            tableOrder: this.technicians.length + 1,
            name: '',
            designation: '',
        };

        this.technicians.push(newTechnician);
        this.technicians = this.technicians.slice();
    }

    onRowDragTechnicians(event) {
        let tempObj = this.technicians[event.node.id];
        this.technicians[event.node.id] = this.technicians[event.node.rowIndex];
        this.technicians[event.node.rowIndex] = tempObj;

        for (let i = 0; i < this.technicians.length; i++) {
            this.technicians[i].tableOrder = i + 1;
        }
        this.technicians = this.technicians.slice();
    }

    addPreRequirementsRow(index) {
        let newRow: any = {
            comment: '',
            fail: false,
            item: '',
            number: this.iomsWorkInstFullList[index].preRequirementsObj.length + 1,
            ok: false,
        };

        this.iomsWorkInstFullList[index].preRequirementsObj.push(newRow);
        this.iomsWorkInstFullList[index].preRequirementsObj =
            this.iomsWorkInstFullList[index].preRequirementsObj.slice();
    }

    addProceduresRow(index) {
        let newRow: any = {
            comment: '',
            fail: false,
            item: '',
            number: this.iomsWorkInstFullList[index].proceduresObj.length + 1,
            ok: false,
        };

        this.iomsWorkInstFullList[index].proceduresObj.push(newRow);
        this.iomsWorkInstFullList[index].proceduresObj =
            this.iomsWorkInstFullList[index].proceduresObj.slice();
    }

    addReplacedItemsRow(index) {
        let newRow: any = {
            comment: '',
            fail: false,
            item: '',
            number: this.iomsWorkInstFullList[index].replacedItemsObj.length + 1,
            ok: false,
        };

        this.iomsWorkInstFullList[index].replacedItemsObj.push(newRow);
        this.iomsWorkInstFullList[index].replacedItemsObj =
            this.iomsWorkInstFullList[index].replacedItemsObj.slice();
    }

    onRowDragEndPreRequirements(index, event) {
        let tempObj = this.iomsWorkInstFullList[index].preRequirementsObj[event.node.id];
        this.iomsWorkInstFullList[index].preRequirementsObj[event.node.id] =
            this.iomsWorkInstFullList[index].preRequirementsObj[event.node.rowIndex];
        this.iomsWorkInstFullList[index].preRequirementsObj[event.node.rowIndex] = tempObj;

        for (let i = 0; i < this.iomsWorkInstFullList[index].preRequirementsObj.length; i++) {
            this.iomsWorkInstFullList[index].preRequirementsObj[i].number = i + 1;
        }
        this.iomsWorkInstFullList[index].preRequirementsObj =
            this.iomsWorkInstFullList[index].preRequirementsObj.slice();
    }

    onRowDragEndProcedures(index, event) {
        let tempObj = this.iomsWorkInstFullList[index].proceduresObj[event.node.id];
        this.iomsWorkInstFullList[index].proceduresObj[event.node.id] =
            this.iomsWorkInstFullList[index].proceduresObj[event.node.rowIndex];
        this.iomsWorkInstFullList[index].proceduresObj[event.node.rowIndex] = tempObj;

        for (let i = 0; i < this.iomsWorkInstFullList[index].proceduresObj.length; i++) {
            this.iomsWorkInstFullList[index].proceduresObj[i].number = i + 1;
        }
        this.iomsWorkInstFullList[index].proceduresObj =
            this.iomsWorkInstFullList[index].proceduresObj.slice();
    }

    onRowDragEndReplacedItems(index, event) {
        let tempObj = this.iomsWorkInstFullList[index].replacedItemsObj[event.node.id];
        this.iomsWorkInstFullList[index].replacedItemsObj[event.node.id] =
            this.iomsWorkInstFullList[index].replacedItemsObj[event.node.rowIndex];
        this.iomsWorkInstFullList[index].replacedItemsObj[event.node.rowIndex] = tempObj;

        for (let i = 0; i < this.iomsWorkInstFullList[index].replacedItemsObj.length; i++) {
            this.iomsWorkInstFullList[index].replacedItemsObj[i].number = i + 1;
        }
        this.iomsWorkInstFullList[index].replacedItemsObj =
            this.iomsWorkInstFullList[index].replacedItemsObj.slice();
    }

    hasUncheckedFields(): boolean {
        for (let i = 0; i < this.iomsWorkInstFullList.length; i++) {
            for (let j = 0; j < this.iomsWorkInstFullList[i].preRequirementsObj.length; j++) {
                if (
                    this.iomsWorkInstFullList[i].preRequirementsObj[j].ok == false &&
                    this.iomsWorkInstFullList[i].preRequirementsObj[j].fail == false
                ) {
                    return true;
                }
            }
            for (let j = 0; j < this.iomsWorkInstFullList[i].proceduresObj.length; j++) {
                if (
                    this.iomsWorkInstFullList[i].proceduresObj[j].ok == false &&
                    this.iomsWorkInstFullList[i].proceduresObj[j].fail == false
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    getAllUsedWorkInstructionNames(): string {
        let res: string = '';
        let tempArr: string[] = [];
        for (let i = 0; i < this.iomsWorkInstFullList.length; i++) {
            if (!tempArr.includes(this.iomsWorkInstFullList[i].name)) {
                tempArr.push(this.iomsWorkInstFullList[i].name);
                res = res + this.iomsWorkInstFullList[i].name + '<br>';
            }
        }
        return res;
    }

    getAllEquipmentSerialNumbers(): string {
        let res: string = '';
        let tempArr: string[] = [];
        for (let i = 0; i < this.iomsWorkInstFullList.length; i++) {
            if (!tempArr.includes(this.iomsWorkInstFullList[i].sn)) {
                tempArr.push(this.iomsWorkInstFullList[i].sn);
                res = res + this.iomsWorkInstFullList[i].sn + '<br>';
            }
        }
        return res;
    }

    async getReportData() {
        this.showSpinner = true;

        // first table in report data
        let reportData = await this._iomsActionService
            .getReportData(this.currentActionReportId)
            .toPromise();

        this.actionNumber = reportData.actionNumber;
        this.startDateTimestamp = this._datePipe.transform(reportData.startTime, 'dd/MM/yyy HH:mm');
        this.endDateTimestamp = this._datePipe.transform(reportData.endTime, 'dd/MM/yyy HH:mm');
        this.lineName = reportData.lineName;
        this.substation = reportData.substation;

        if (this.findUserById(reportData.personInCharge) != undefined) {
            this.personInCharge = this.findUserById(reportData.personInCharge).name;
        } else {
            this.personInCharge = '';
        }

        this.description = reportData.description;

        // create string of device ids separated by ","
        reportData.equipment = reportData.equipment || '';
        let deviceCubicleAndIdsArr: string[] = reportData.equipment.split(',');
        let deviceIds: string = '';
        for (let i = 0; i < deviceCubicleAndIdsArr.length; i++) {
            if (i == deviceCubicleAndIdsArr.length - 1) {
                deviceIds += deviceCubicleAndIdsArr[i].split(';')[1];
            } else {
                deviceIds += deviceCubicleAndIdsArr[i].split(';')[1] + ',';
            }
        }

        // get tollbox talk data
        let toolBoxTalkData = await this._iomsActionService
            .getToolBoxTalkData(this.actionNumber)
            .toPromise();

        if (toolBoxTalkData.custRepName != undefined) {
            this.custRepName = toolBoxTalkData.custRepName;
        }

        if (toolBoxTalkData.permitToWorkNum != undefined) {
            this.permitToWorkNum = toolBoxTalkData.permitToWorkNum;
        }

        if (toolBoxTalkData.remarksText != undefined) {
            this.remarksText = toolBoxTalkData.remarksText;
        }

        if (toolBoxTalkData.technicians == undefined) {
            this.technicians = [];
        } else {
            this.technicians = toolBoxTalkData.technicians;
        }

        // getting devices data
        this.devices = await this._iomsActionService.getDevices(deviceIds).toPromise();

        // setting of the name and id objects (workInstructionNameAndId) and work instructions names string
        for (let i = 0; i < this.devices.length; i++) {
            this.devices[i].workInstructionNameAndId = [];
            this.devices[i].workInstructionNames = '';
            this.devices[i].workInstructionIdsArray = [];

            let wi: string[] = this.devices[i].workInstructions.split('&');
            for (let j = 0; j < wi.length; j++) {
                let wiTemp: WorkInstructionNameAndId = new WorkInstructionNameAndId();

                wiTemp.name = wi[j].split(';')[0];
                wiTemp.objectId = wi[j].split(';')[1];

                this.devices[i].workInstructionIdsArray.push(wiTemp.objectId);

                this.devices[i].workInstructionNameAndId.push(wiTemp);
                if (j < wi.length - 1) {
                    this.devices[i].workInstructionNames =
                        this.devices[i].workInstructionNames + wiTemp.name + ', ';
                } else {
                    this.devices[i].workInstructionNames =
                        this.devices[i].workInstructionNames + wiTemp.name;
                }
            }
        }

        // create array of instruction ids from devices
        let arrOfSelectedInstructionIds: string[] = [];
        for (let i = 0; i < this.devices.length; i++) {
            // ???? what if user selected some device, but did not select its instruction ?????
            // only add instruction ids that are selected in the dropdown menu on frontend
            if (this.devices[i].workInstructionIdsArray.length > 0) {
                for (let j = 0; j < this.devices[i].workInstructionIdsArray.length; j++) {
                    if (
                        this.selectedWorkInstructionsIdsArray.indexOf(
                            this.devices[i].workInstructionIdsArray[j]
                        ) != -1
                    ) {
                        arrOfSelectedInstructionIds.push(
                            this.devices[i].workInstructionIdsArray[j]
                        );
                    }
                }
            }
        }

        // create string of instruction ids separated by ","
        let workInstructionsIdsString = '';
        const arrayLength = arrOfSelectedInstructionIds.length;
        arrOfSelectedInstructionIds.forEach((value, index) => {
            if (index !== arrayLength - 1) {
                workInstructionsIdsString = workInstructionsIdsString + value + ',';
            } else {
                workInstructionsIdsString = workInstructionsIdsString + value;
            }
        });

        let pathArray = window.location.pathname.split('/');
        let secondLevelLocation = pathArray[1];
        if (secondLevelLocation !== '') {
            secondLevelLocation = '/' + secondLevelLocation;
        }

        // reference documents table data
        await this._iomsActionService
            .getWorkInstructionsInfoPost(workInstructionsIdsString)
            .toPromise()
            .then((resWiInfo) => {
                // getting data for the table
                this.rowsReferenceDocumentsAgGrid = resWiInfo.map((refDocInfo) => {
                    return {
                        name: refDocInfo.name,
                        link: '/knowledge-base?contentItemId=' + refDocInfo.mongoId,
                    };
                });

                this.referenceDocumentsGridApi.setRowData(this.rowsReferenceDocumentsAgGrid);
            });

        // data in the work instruction tables
        this.workInstructionsIdsStringGlobal = workInstructionsIdsString;

        let res1 = await this._iomsActionService
            .getSavedWorkInstructionByIdPost(
                this.workInstructionsIdsStringGlobal,
                this.actionNumber
            )
            .toPromise();
        this.iomsWorkInstFullList = res1 as IomsWorkInstructionFull[];

        this.setDeviceDescription();

        this.dataDialog['maintenanceReportData'] = this.iomsWorkInstFullList;

        if (this.iomsWorkInstFullList === undefined || this.iomsWorkInstFullList.length == 0) {
            let res2 = await this._iomsActionService
                .getWorkInstructionsFullById(this.workInstructionsIdsStringGlobal)
                .toPromise();

            let iomsWorkInstFullListTemp = res2 as IomsWorkInstructionFull[];

            // for each working step, init files
            for (let i = 0; i < iomsWorkInstFullListTemp.length; i++) {
                iomsWorkInstFullListTemp[i].files = [];
            }

            // create working steps
            for (let i = 0; i < this.devices.length; i++) {
                for (let j = 0; j < this.devices[i].workInstructionIdsArray.length; j++) {
                    for (let m = 0; m < iomsWorkInstFullListTemp.length; m++) {
                        if (
                            iomsWorkInstFullListTemp[m]._id.$oid ==
                            this.devices[i].workInstructionIdsArray[j]
                        ) {
                            this.iomsWorkInstFullList.push(
                                JSON.parse(JSON.stringify(iomsWorkInstFullListTemp[m]))
                            );
                        }
                    }
                }
            }

            // connect each working step to equipment
            for (let i = 0; i < this.devices.length; i++) {
                for (let j = 0; j < this.devices[i].workInstructionIdsArray.length; j++) {
                    for (let m = 0; m < this.iomsWorkInstFullList.length; m++) {
                        if (
                            this.iomsWorkInstFullList[m]._id.$oid ==
                            this.devices[i].workInstructionIdsArray[j]
                        ) {
                            if (this.iomsWorkInstFullList[m].sn == undefined) {
                                this.iomsWorkInstFullList[m].sn = this.devices[i].serialNumber;
                                this.iomsWorkInstFullList[m].cubicle =
                                    this.devices[i].levelId.split('_')[2];
                                this.iomsWorkInstFullList[m].deviceDescription =
                                    this.devices[i].description;
                                break;
                            }
                        }
                    }
                }
            }

            this.dataDialog['maintenanceReportData'] = this.iomsWorkInstFullList;
        }

        this.printButtonDisabled = false;
        this.showSpinner = false;
        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.getReportData();
    }

    ngOnDestroy() {
        this.subGetToolboxData.unsubscribe();
        this.subConfirmFileDelete.unsubscribe();
        this.subConfirmExit.unsubscribe();
    }

    setDeviceDescription() {
        for (let i = 0; i < this.iomsWorkInstFullList.length; i++) {
            for (let j = 0; j < this.devices.length; j++) {
                if (this.iomsWorkInstFullList[i].sn == this.devices[j].serialNumber) {
                    this.iomsWorkInstFullList[i].deviceDescription = this.devices[j].description;
                }
            }
        }
    }

    setDeviceDescriptionPrint(data: IomsWorkInstructionFull[]) {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.devices.length; j++) {
                if (data[i].sn == this.devices[j].serialNumber) {
                    data[i].deviceDescription = this.devices[j].description;
                }
            }
        }
    }

    // original onInit
    ngOnInit1() {
        this._iomsActionService.getReportData(this.currentActionReportId).subscribe((res) => {
            this.actionNumber = res.actionNumber;
            this.startDateTimestamp = this._datePipe.transform(res.startTime, 'dd/MM/yyy HH:mm');
            this.endDateTimestamp = this._datePipe.transform(res.endTime, 'dd/MM/yyy HH:mm');
            this.lineName = res.lineName;
            this.substation = res.substation;
            this.personInCharge = this.findUserById(res.personInCharge).name;
            this.description = res.description;
            this.cdr.detectChanges();
        });

        let workInstructionsIdsString = '';
        const arrayLength = this.selectedWorkInstructionsIdsArray.length;
        this.selectedWorkInstructionsIdsArray.forEach((value, index) => {
            if (index !== arrayLength - 1) {
                workInstructionsIdsString += workInstructionsIdsString + value + ',';
            } else {
                workInstructionsIdsString += workInstructionsIdsString + value;
            }
        });

        this._iomsActionService
            .getWorkInstructionsInfoPost(workInstructionsIdsString)
            .subscribe((res) => {
                this.rowsReferenceDocumentsAgGrid = res.map((refDocInfo) => {
                    return {
                        name: refDocInfo.name,
                        link: '/knowledge-base?contentItemId=' + refDocInfo.mongoId,
                    };
                });
                this.referenceDocumentsGridApi.setRowData(this.rowsReferenceDocumentsAgGrid);
                this.cdr.detectChanges();
            });

        setTimeout(() => {
            this.workInstructionsIdsStringGlobal = workInstructionsIdsString;
            this._iomsActionService
                .getSavedWorkInstructionByIdPost(
                    this.workInstructionsIdsStringGlobal,
                    this.actionNumber
                )
                .subscribe((res) => {
                    this.iomsWorkInstFullList = res as IomsWorkInstructionFull[];
                    this.cdr.detectChanges();
                    this.dataDialog['maintenanceReportData'] = this.iomsWorkInstFullList;

                    if (
                        this.iomsWorkInstFullList === undefined ||
                        this.iomsWorkInstFullList.length == 0
                    ) {
                        this._iomsActionService
                            .getWorkInstructionsFullById(this.workInstructionsIdsStringGlobal)
                            .subscribe((res) => {
                                this.iomsWorkInstFullList = res as IomsWorkInstructionFull[];
                                this.cdr.detectChanges();
                                this.dataDialog['maintenanceReportData'] =
                                    this.iomsWorkInstFullList;
                            });
                    }
                });
        }, 1000);
    }

    ngAfterViewInit() {
        // this.referenceDocumentsGridApi.setRowData(this.rowsReferenceDocumentsAgGrid);
    }

    onReferenceDocumentsGridReady($event: GridReadyEvent) {
        this.referenceDocumentsGridApi = $event.api;

        // this.referenceDocumentsGridApi.setRowData(this.rowsReferenceDocumentsAgGrid);
    }

    onReferenceDocumentsGridSelectionChanged($event) {}

    onChecklistGridReady($event) {}

    onChecklistGridSelectionChanged($event) {}

    // IOMSIMAGE

    dialogRefFileUpload: MatDialogRef<DialogReportFileUpload>;
    subDialogRefCsvUpload: Subscription = new Subscription();
    subFileImport: Subscription = new Subscription();
    reportFilesChanged: boolean = false;

    addFile(index) {
        this.dialogRefFileUpload = this._dialog.open(DialogReportFileUpload, {
            height: '200px',
            width: '220px',
            disableClose: false,
        });
        this.subDialogRefCsvUpload = this.dialogRefFileUpload.afterClosed().subscribe((result) => {
            if (result != undefined) {
                if (result.toUpload) {
                    let originalFile: File = result.originalFile;

                    let fileForReport: IomsFileForReport = new IomsFileForReport();
                    let ts: number = new Date().getTime();

                    fileForReport.fileId = 'id' + ts;

                    if (originalFile.type == 'application/pdf') {
                        fileForReport.fileName = originalFile.name;
                    } else {
                        fileForReport.fileName =
                            'Picture ' + this.getNextImageNumber(this.iomsWorkInstFullList);
                    }

                    fileForReport.originalFile = originalFile;
                    fileForReport.thumbnail = result.thumbnail;
                    fileForReport.fileType = result.files.type;
                    fileForReport.actionNumber = this.actionNumber;

                    this.iomsWorkInstFullList[index].files.push(fileForReport);

                    this.reportFilesChanged = true;

                    this.cdr.detectChanges();
                }
            }
        });
    }

    removeFile(index: number, file: IomsFileForReport) {
        this.subConfirmFileDelete = this._dialogService
            .openConfirm({
                message: 'Are you sure you want to delete file?',
                acceptButton: 'Delete',
                title: 'Delete file',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.reportFilesChanged = true;
                    for (let i = 0; i < this.iomsWorkInstFullList[index].files.length; i++) {
                        if (this.iomsWorkInstFullList[index].files[i].fileId == file.fileId) {
                            this.iomsWorkInstFullList[index].files.splice(i, 1);
                            this.cdr.detectChanges();
                        }
                    }
                }
            });
    }

    downloadFile(file: IomsFileForReport) {
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let port = window.location.port;
        if (port == '4200') {
            port = '8080';
        }

        const url =
            protocol +
            '//' +
            hostname +
            ':' +
            port +
            '/EvaPlusRest/iomsActionService/downloadFile?id=' +
            file.fileId +
            '&actionNumber=' +
            this.actionNumber +
            '&fileName=' +
            file.fileName +
            '&fileType=' +
            file.fileType;
        window.open(url);

        // this.userMonitoring.sendDummyRequest("'Signal Analytics','Download Teloc file','"+telocFilePath+"'").subscribe((result: any) => {
        // });
    }

    getNextImageNumber(data: IomsWorkInstructionFull[]): number {
        let imageNumber = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].files.length > 0) {
                for (let j = 0; j < data[i].files.length; j++) {
                    let value: number;
                    value = +data[i].files[j].fileName.split(' ')[1];
                    if (value > imageNumber) {
                        imageNumber = value;
                    }
                }
            }
        }
        return imageNumber + 1;
    }

    createHyperLink(params): HTMLSpanElement {
        if (!params.data) {
            return;
        }
        const spanElement = document.createElement('span');
        spanElement.innerHTML = `<a href="${this.homeUrl}" > ${params.value} </a> `;
        spanElement.addEventListener('click', ($event) => {
            $event.preventDefault();
            // The below code is used to navigate from one page to another page in angular. you can change it          // according to your requirement.
            // this.router.navigate([this.homeUrl]);
            // window.open(this.homeUrl);
            window.open(params.value);
        });
        return spanElement;
    }

    setMockupdata() {
        if (this.rowsReferenceDocumentsAgGrid.length == 0) {
            this.rowsReferenceDocumentsAgGrid.push({
                'name': 'SG847023BEN_A01_Brochure_STELLA-SEPCOS-PRISM_05-2020',
                'link': 'SG847023BEN_A01_Brochure_STELLA-SEPCOS-PRISM_05-2020.pdf',
            });
            this.rowsReferenceDocumentsAgGrid.push({
                'name': 'new_document_A01_Brochure_STELLA-SEPCOS-PRISM_05-2020',
                'link': 'new_document_A01_Brochure_STELLA-SEPCOS-PRISM_05-2020.pdf',
            });
        }
        var preRequirementsObj = [];
        preRequirementsObj.push({
            number: 1,
            item: '\n        UR40 Maintenance e-learning course level 2\n    ',
            ok: false,
            fail: false,
            comment: '',
        });

        var proceduresObj = [];
        proceduresObj.push({
            number: 1,
            item: 'General cleaning of the complete cubical',
            ok: true,
            fail: true,
            comment: '',
        });
        proceduresObj.push({
            number: 2,
            item: 'General visual check of the cubicle',
            ok: true,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 3,
            item: 'Clean the complete switch',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 4,
            item: 'Check external mechanical assembly of the cubicle (bolts, nuts, clips, etc.)',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 5,
            item: 'General check electrical assembly of the cubicle',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 6,
            item: 'Check tightness of the busbar and its cables',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 7,
            item: 'Check tightness of the earth connection',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 8,
            item: 'Check tightness of cable termination',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 9,
            item: 'Check of internal and external cables',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 10,
            item: 'Check insulators',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 11,
            item: 'Check fixation of auxiliary connector ',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 12,
            item: 'Lubricate any needed component',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 13,
            item: 'Check Auxiliary Power Supply 110 Vdc',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 14,
            item: 'Check Mini Circuit Breakers operation',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 15,
            item: 'Check manual operation and indication of the contactor',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 16,
            item: 'Check Equipment Fault indication',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 17,
            item: 'Check Lockout functionality',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 18,
            item: 'Check Trip Order',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 19,
            item: 'Check MIU10',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({ number: 20, item: 'Check VM10', ok: false, fail: false, comment: '' });
        proceduresObj.push({
            number: 21,
            item: 'Check Isolating Amplifier (SINEAX)',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 22,
            item: 'Check Programmable Controller (TWIDO) run status',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 23,
            item: 'Check TWIDO communication status',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 24,
            item: 'Check Voltmeter on the front of the cubicle',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 25,
            item: 'Check Stray Current Measurement output display on the Laptop ',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 26,
            item: 'Check for OVPD correct operation against various voltage and current levels',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 27,
            item: 'Check Surge Arrester',
            ok: false,
            fail: false,
            comment: '',
        });
        proceduresObj.push({
            number: 28,
            item: 'Check light and heater',
            ok: false,
            fail: false,
            comment: '',
        });

        var replacedItemsObj = [];
        // replacedItemsObj.push({number: 1, item: 'Moving contact*', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 2, item: ' Fixed contact*', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 3, item: ' Pole* ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 4, item: ' Spark arresters ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 5, item: ' Horns ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 6, item: ' De-ionization plates ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 7, item: ' Baffles ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 8, item: ' Fork assembly  Catch ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 9, item: ' Compression spring, Closing core, Pusher assembly, Damper, ', ok: false, fail: false, comment: ''});
        // replacedItemsObj.push({number: 10, item: ' Closing device ', ok: false, fail: false, comment: ''});

        var first: IomsWorkInstructionFull = new IomsWorkInstructionFull();
        first.name = 'Bypass Yearly Maintenance';
        first.preRequirementsObj = preRequirementsObj;
        first.proceduresObj = proceduresObj;
        first.replacedItemsObj = replacedItemsObj;
        this.iomsWorkInstFullList.push(first);
    }

    getCheckOK(ch: boolean): String {
        return ch ? '&#10004;' : '';
    }

    getCheckFail(ch: boolean): String {
        return ch ? '&#10060;' : '';
    }

    getTableGenerated(arr): String {
        var out = '';
        arr.forEach((el) => {
            out =
                out +
                '<tr><td>' +
                el.number +
                '</td><td>' +
                el.item +
                '</td><td>' +
                this.getCheckOK(el.ok) +
                '</td><td style="-webkit-filter: brightness(0); filter: brightness(0); font-size: 13px;">' +
                this.getCheckFail(el.fail) +
                '</td><td>' +
                el.comment +
                '</td></tr>';
        });
        return `
    <style>
      table,
      td,
      th {
        padding: 2px;
        border: 1px solid #E0E0E0;
        background-color: #F8F8F8;
        text-align: left;
      }
    </style>
    <table>
    <thead>
      <tr>
        <th>Nr</th>
        <th>Item</th>
        <th>Pass</th>
        <th>Fail</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>${out}</tbody>
    </table>`;
    }

    getTableGeneratedTemplate(arr: any[]): String {
        if (arr.length == 0) {
            return `<h5>None</h5>`;
        }
        var out = '';
        arr.forEach((el) => {
            out =
                out +
                '<tr><td>' +
                el.number +
                '</td><td>' +
                el.item +
                '</td><td>' +
                this.getCheckOK(el.ok) +
                '</td><td style="-webkit-filter: brightness(0); filter: brightness(0); font-size: 13px;">' +
                this.getCheckFail(el.fail) +
                '</td><td>' +
                el.comment +
                '</td></tr>';
        });
        return `
    <table>
    <thead>
      <tr>
        <th>Nr</th>
        <th>Item</th>
        <th>Pass</th>
        <th>Fail</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>${out}</tbody>
    </table>`;
    }

    getTableGeneratedTemplateReplacedItems(arr: any[]): String {
        if (arr.length == 0) {
            return `<h5>None</h5>`;
        }
        var out = '';
        arr.forEach((el) => {
            out =
                out +
                '<tr><td>' +
                el.number +
                '</td><td>' +
                el.component +
                '</td><td>' +
                this.getCheckOK(el.changed) +
                '</td><td>' +
                el.oldSn +
                '</td><td>' +
                el.newSn +
                '</td><td>' +
                el.comments +
                '</td></tr>';
        });
        return `
    <table>
    <thead>
      <tr>
        <th>Nr</th>
        <th>Component</th>
        <th>Changed</th>
        <th>Old serial nbr.</th>
        <th>New serial nbr.</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>${out}</tbody>
    </table>`;
    }

    print() {
        // this.setMockupdata();
        // this.printDefault();
        if (this.currentPath == 'current') {
            this._dialogService
                .openConfirm({
                    message: 'Please be sure that all changes are saved before printing.',
                    acceptButton: 'Print',
                })
                .afterClosed()
                .toPromise()
                .then((confirm: boolean) => {
                    if (confirm) {
                        this.printFromKB();
                    }
                });
        } else {
            this.printFromPreview();
        }
    }

    printDefault() {
        var refDocs = '';
        this.rowsReferenceDocumentsAgGrid.forEach((e) => {
            refDocs = refDocs + '<tr><td>' + e.name + '</td><td>' + e.link + '</td></tr>';
        });

        var printWorkingSteps = '';
        this.iomsWorkInstFullList.forEach((instSet, index) => {
            printWorkingSteps =
                printWorkingSteps +
                '<h3 style="color: blue; margin-top:20px">Working step: ' +
                (index + 1) +
                '</h3>' +
                '<h4 style="color: black; margin-top:20px">Work instruction: ' +
                instSet.name +
                '</h4>';

            printWorkingSteps =
                printWorkingSteps +
                '<h4 style="color: blue; margin-top:20px">Prerequirements</h4>' +
                this.getTableGenerated(instSet.preRequirementsObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4 style="color: blue; margin-top:20px">Procedures</h4>' +
                this.getTableGenerated(instSet.proceduresObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4 style="color: blue; margin-top:20px">Replaced items</h4>' +
                this.getTableGenerated(instSet.replacedItemsObj);
        });

        let printContents, popupWin;
        printContents = document.getElementById('print').innerHTML.toString();
        printContents = (<string>printContents + '').replace('col-sm', 'col-xs');
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>Maintenance Report</title>
          <meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
          <style>
            .salto_pagina_despues{
              page-break-after:always;
            }
            .salto_pagina_anterior{
              page-break-before:always;
            }
            .content {
              height: 100vh;
              width: 100%;
              display: flex;
              flex-direction: column;
            }
            table,
            td,
            th {
              padding: 2px;
              border: 1px solid #E0E0E0;
              background-color: #F8F8F8;
              text-align: left;
            }
          </style>
        </head>
        <body onload="window.print();">
          <h2 style="color: green;">
            Maintenance Report
          </h2>
          ${printContents}
          <h3 style="color: blue; margin-top:20px">Reference Documents</h3>
          <table>
          <thead>
            <tr>
              <th>Document name</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>${refDocs}</tbody>
          </table>
        </body>
        ${printWorkingSteps}
      </html>`);
        /* window.close(); */
        popupWin.document.close();
    }

    async printFromKB() {
        this.showSpinner = true;
        this.cdr.detectChanges();

        let resPrint = await this._iomsActionService
            .getSavedWorkInstructionByIdPostPrint(
                this.workInstructionsIdsStringGlobal,
                this.actionNumber
            )
            .toPromise();
        let iomsWorkInstFullListPrint = resPrint as IomsWorkInstructionFull[];
        this.setDeviceDescriptionPrint(iomsWorkInstFullListPrint);

        let res = await this._iomsActionService.getReportTemplate().toPromise();

        this.showSpinner = false;
        this.cdr.detectChanges();

        if (Object.keys(res).length === 0) {
            this.printDefault();
            return;
        }

        var techniciansRefTable: string = '';
        if (this.technicians.length > 0) {
            var techniciansRef = '';
            this.technicians.forEach((teh) => {
                techniciansRef =
                    techniciansRef +
                    '<tr><td>' +
                    teh.name +
                    '</td><td>' +
                    teh.designation +
                    '</td></tr>';
            });
            techniciansRefTable = `<table>
        <thead>
            <tr>
                <th style='text-align: left;'>Name</th>
                <th style='text-align: left;'>Designation</th>
            </tr>
        </thead>
        <tbody>
          ${techniciansRef}
        </tbody>
      </table>`;
        }

        var refDocs = '';
        this.rowsReferenceDocumentsAgGrid.forEach((e) => {
            refDocs = refDocs + '<tr><td>' + e.name + '</td></tr>';
        });

        var wholeRefDocs = `<table>
          <thead>
              <tr>
                  <th>Document name</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>${refDocs}</td>
              </tr>
          </tbody>
        </table>`;

        var printWorkingSteps = '';
        let styleLoop: string[] = [
            '<div style= border: 1px solid red; display: inline-block;">',
            '<div style=" border: 1px solid green; display: inline-block;">',
        ];

        iomsWorkInstFullListPrint.forEach((instSet, index) => {
            printWorkingSteps =
                printWorkingSteps +
                '<h3>Working step: ' +
                (index + 1) +
                '</h3>' +
                '<div><b>Cubicle Description: </b>' +
                instSet.cubicle +
                '</div>' +
                '<div><b>Equipment description: </b>' +
                instSet.deviceDescription +
                '</div>' +
                '<div><b>Serial number: </b>' +
                instSet.sn +
                '</div>' +
                '<div><b>Work instruction: </b>' +
                instSet.name +
                '</div>';
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Prerequirements</h4>' +
                this.getTableGeneratedTemplate(instSet.preRequirementsObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Procedures</h4>' +
                this.getTableGeneratedTemplate(instSet.proceduresObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Replaced items</h4>' +
                this.getTableGeneratedTemplateReplacedItems(instSet.replacedItemsObj);

            printWorkingSteps = printWorkingSteps + '<div style= border: 1px solid black;">';
            for (let i = 0; i < instSet.files.length; i++) {
                if (instSet.files[i].fileType.indexOf('image') !== -1) {
                    printWorkingSteps =
                        printWorkingSteps + '<H1>' + instSet.files[i].fileName + '</H1> </br>';
                    printWorkingSteps =
                        printWorkingSteps +
                        styleLoop[i] +
                        '<img style="max-width: 100%; max-height: 100%;" src="' +
                        instSet.files[i].printImage +
                        '" /> </div>';
                } else {
                    printWorkingSteps =
                        printWorkingSteps + '<H1>' + instSet.files[i].fileName + '</H1> </br>';
                    printWorkingSteps =
                        printWorkingSteps +
                        styleLoop[i] +
                        '<img style="max-width: 100%; max-height: 100%;" src="' +
                        this.pdfLogoPath +
                        '" /> </div>';
                }
            }
            printWorkingSteps = printWorkingSteps + '</div>';
        });

        let popupWin;
        var originalPath = '/EvaPlusRest/knowledgebaseservice/getReportImage'; // CHECK THIS
        res = res.text.replaceAll('{{SecheronPathToReportImages}}', originalPath);

        res = res.replaceAll('{{actionNumber}}', this.actionNumber);
        res = res.replaceAll('{{startDateTimestamp}}', this.startDateTimestamp);
        res = res.replaceAll('{{endDateTimestamp}}', this.endDateTimestamp);
        res = res.replaceAll('{{lineName}}', this.lineName);
        res = res.replaceAll('{{substation}}', this.substation);
        res = res.replaceAll('{{personInCharge}}', this.personInCharge);
        res = res.replaceAll('{{description}}', this.description);
        res = res.replaceAll('{{referenceDocuments|log}}', wholeRefDocs);
        res = res.replaceAll('{{printWorkingSteps}}', printWorkingSteps);
        res = res.replaceAll('{{actionIdentifier}}', this.reportId);
        res = res.replaceAll(
            '{{list-of-work-instructions-in-the-report}}',
            this.getAllUsedWorkInstructionNames()
        );
        res = res.replaceAll(
            '{{list-of-equipments-in-the-report}}',
            this.getAllEquipmentSerialNumbers()
        );
        res = res.replaceAll('{{toolbox-customer_name}}', this.custRepName);
        res = res.replaceAll('{{toolbox-permit_to_work}}', this.permitToWorkNum);
        res = res.replaceAll('{{toolbox-remarks}}', this.remarksText);
        res = res.replaceAll('{{printPresence}}', techniciansRefTable);

        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(res);
        popupWin.document.close();
    }

    async printFromPreview() {
        this.showSpinner = true;

        let res = await this._iomsActionService.getReportTemplate().toPromise();

        this.showSpinner = false;
        this.cdr.detectChanges();

        if (Object.keys(res).length === 0) {
            this.printDefault();
            return;
        }

        var techniciansRefTable: string = '';
        if (this.technicians.length > 0) {
            var techniciansRef = '';
            this.technicians.forEach((teh) => {
                techniciansRef =
                    techniciansRef +
                    '<tr><td>' +
                    teh.name +
                    '</td><td>' +
                    teh.designation +
                    '</td></tr>';
            });
            techniciansRefTable = `<table>
        <thead>
            <tr>
                <th style='text-align: left;'>Name</th>
                <th style='text-align: left;'>Designation</th>
            </tr>
        </thead>
        <tbody>
          ${techniciansRef}
        </tbody>
      </table>`;
        }

        var refDocs = '';
        this.rowsReferenceDocumentsAgGrid.forEach((e) => {
            refDocs = refDocs + '<tr><td>' + e.name + '</td></tr>';
        });

        var wholeRefDocs = `<table>
          <thead>
              <tr>
                  <th>Document name</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>${refDocs}</td>
              </tr>
          </tbody>
        </table>`;

        var printWorkingSteps = '';
        let styleLoop: string[] = [
            '<div style= border: 1px solid red; display: inline-block;">',
            '<div style=" border: 1px solid green; display: inline-block;">',
        ];

        this.iomsWorkInstFullList.forEach((instSet, index) => {
            printWorkingSteps =
                printWorkingSteps +
                '<h3>Working step: ' +
                (index + 1) +
                '</h3>' +
                '<div><b>Cubicle Description: </b>' +
                instSet.cubicle +
                '</div>' +
                '<div><b>Equipment description: </b>' +
                instSet.deviceDescription +
                '</div>' +
                '<div><b>Serial number: </b>' +
                instSet.sn +
                '</div>' +
                '<div><b>Work instruction: </b>' +
                instSet.name +
                '</div>';
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Prerequirements</h4>' +
                this.getTableGeneratedTemplate(instSet.preRequirementsObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Procedures</h4>' +
                this.getTableGeneratedTemplate(instSet.proceduresObj);
            printWorkingSteps =
                printWorkingSteps +
                '<h4>Replaced items</h4>' +
                this.getTableGeneratedTemplateReplacedItems(instSet.replacedItemsObj);

            printWorkingSteps = printWorkingSteps + '<div style= border: 1px solid black;">';
            for (let i = 0; i < instSet.files.length; i++) {
                if (instSet.files[i].fileType.indexOf('image') !== -1) {
                    printWorkingSteps =
                        printWorkingSteps + '<H1>' + instSet.files[i].fileName + '</H1> </br>';
                    printWorkingSteps =
                        printWorkingSteps +
                        styleLoop[i] +
                        '<img style="max-width: 100%; max-height: 100%;" src="' +
                        instSet.files[i].imageOrig +
                        '" /> </div>';
                } else {
                    printWorkingSteps =
                        printWorkingSteps + '<H1>' + instSet.files[i].fileName + '</H1> </br>';
                    printWorkingSteps =
                        printWorkingSteps +
                        styleLoop[i] +
                        '<img style="max-width: 100%; max-height: 100%;" src="' +
                        this.pdfLogoPath +
                        '" /> </div>';
                }
            }
            printWorkingSteps = printWorkingSteps + '</div>';
        });

        let popupWin;
        var originalPath = '/EvaPlusRest/knowledgebaseservice/getReportImage'; // CHECK THIS
        res = res.text.replaceAll('{{SecheronPathToReportImages}}', originalPath);

        res = res.replaceAll('{{actionNumber}}', this.actionNumber);
        res = res.replaceAll('{{startDateTimestamp}}', this.startDateTimestamp);
        res = res.replaceAll('{{endDateTimestamp}}', this.endDateTimestamp);
        res = res.replaceAll('{{lineName}}', this.lineName);
        res = res.replaceAll('{{substation}}', this.substation);
        res = res.replaceAll('{{personInCharge}}', this.personInCharge);
        res = res.replaceAll('{{description}}', this.description);
        res = res.replaceAll('{{referenceDocuments|log}}', wholeRefDocs);
        res = res.replaceAll('{{printWorkingSteps}}', printWorkingSteps);
        res = res.replaceAll('{{actionIdentifier}}', this.reportId);
        res = res.replaceAll(
            '{{list-of-work-instructions-in-the-report}}',
            this.getAllUsedWorkInstructionNames()
        );
        res = res.replaceAll(
            '{{list-of-equipments-in-the-report}}',
            this.getAllEquipmentSerialNumbers()
        );
        res = res.replaceAll('{{toolbox-customer_name}}', this.custRepName);
        res = res.replaceAll('{{toolbox-permit_to_work}}', this.permitToWorkNum);
        res = res.replaceAll('{{toolbox-remarks}}', this.remarksText);
        res = res.replaceAll('{{printPresence}}', techniciansRefTable);

        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(res);
        popupWin.document.close();
    }

    get homeUrl(): string {
        return 'home';
    }
}

export class IomsWorkInstructionFull {
    _id: any;
    id: string;
    name: string;
    comment: string;
    reportId: string;
    parentId: string;
    parentObjectId: string;
    preRequirements: string[];
    procedures: string[];
    replacedItems: string[];
    text: string;
    type: string;
    preRequirementsObj: any[];
    proceduresObj: any[];
    replacedItemsObj: any[];
    sn: string;
    deviceDescription: string;
    cubicle: string;
    files: IomsFileForReport[];
}

export class IomsFileForReport {
    fileId: string;
    fileName: string;
    originalFile: File;
    thumbnail: string;
    fileType: string;
    actionNumber: string;
    imageOrig: string;
    printImage: string;
}

export class Technician {
    tableOrder: number;
    name: string;
    designation: string;
}

//{"number": 1, "item": "UR40 VISUAL INSPECTION", "ok": true, "fail": false, "comment": "This is editable comment"}
export class TempWorkInstItem {
    number: number;
    item: string;
    ok: boolean;
    fail: boolean;
    comment: string;
}

@Component({
    selector: 'dialogReportFileUpload',
    template: ` <h4>File import</h4>
        <br />

        <td-file-upload
            #singleFileUpload
            [(ngModel)]="files"
            (select)="selectEvent($event)"
            (upload)="uploadEvent($event)"
            (cancel)="cancelEvent()"
            disabled="false"
            required
        >
            <mat-icon>file_upload</mat-icon>
            <span>{{ singleFileUpload.files?.name }} : START UPLOAD</span>
            <ng-template td-file-input-label>
                <mat-icon>attach_file</mat-icon>
                <span>Choose a file...</span>
            </ng-template>
        </td-file-upload>`,
})
export class DialogReportFileUpload {
    public toUpload: boolean = false;
    public uploadCanceled: boolean = false;

    public files;

    // public fileContent: any;
    public thumbnail: any;
    public originalFile: File;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private _iomsDeviceManagementService: IomsDeviceManagerService,
        @Inject(MAT_DIALOG_DATA) public duplicatesInfElData: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    selectEvent(file: File): void {}

    cancelEvent() {
        this.uploadCanceled = true;
        this.dialogRef.close(this);
    }

    uploadEvent(file: File) {
        this.originalFile = file;

        if (
            this.originalFile.type == 'image/jpeg' ||
            this.originalFile.type == 'image/png' ||
            this.originalFile.type == 'image/bmp'
        ) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (e) => {
                try {
                    let fileContent: any = fileReader.result;

                    resizeImage(fileContent, 100, 75).then((resizedImage) => {
                        this.toUpload = true;
                        this.thumbnail = resizedImage;
                        this.dialogRef.close(this);
                    });
                } catch (err) {
                    this.toUpload = false;
                    alert(err.message);
                    this.dialogRef.close(this);
                }
            };
        } else if (this.originalFile.type == 'application/pdf') {
            getBase64(file).then((data) => {
                this.thumbnail = data;
                this.toUpload = true;
                this.dialogRef.close(this);
            });
        } else {
            alert('File type not suported. Only images and pdf are suported.');
        }
    }
}

function resizeImage(src, newX, newY) {
    return new Promise((res, rej) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            let imageRatio = img.width / img.height;
            let elementRatio = newX / newY;

            if (imageRatio < elementRatio) {
                newX = Math.floor(imageRatio * newY);
            }

            if (imageRatio > elementRatio) {
                newY = Math.floor(newX / imageRatio);
            }

            const elem = document.createElement('canvas');
            elem.width = newX;
            elem.height = newY;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, newX, newY);
            const data = ctx.canvas.toDataURL();
            res(data);
        };
        img.onerror = (error) => rej(error);
    });
}

function resizeImageBlocking(src, newX, newY) {
    const img = new Image();
    img.src = src;

    let imageRatio = img.width / img.height;
    let elementRatio = newX / newY;

    if (imageRatio < elementRatio) {
        newX = Math.floor(imageRatio * newY);
    }

    if (imageRatio > elementRatio) {
        newY = Math.floor(newX / imageRatio);
    }

    const elem = document.createElement('canvas');
    elem.width = newX;
    elem.height = newY;
    const ctx = elem.getContext('2d');
    ctx.drawImage(img, 0, 0, newX, newY);
    const data = ctx.canvas.toDataURL();
    return data;
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

@Component({
    selector: 'app-image-formatter-cell2',
    template: ` <div style="width: 20px; display: inline-table;">
            <img
                *ngIf="params.data.ok == false && params.data.fail == false"
                style=" height: 18px; width: 18px; margin-top: -5px;"
                src="{{ imageWarn }}"
            />
        </div>
        {{ params.value }}`,
})
export class WarningIconFormatterComponent {
    params: any;
    imageWarn: string = 'assets/images/warning-sign.jpg';

    agInit(params: any): void {
        this.params = params;
    }
}
