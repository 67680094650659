import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'mat-progress-bar-cell',
    templateUrl: './mat-progress-bar.component.html',
    styleUrls: ['./mat-progress-bar.component.scss'],
})
export class MatProgressBarComponent implements ICellRendererAngularComp {
    mode = 'determinate';
    params: any;
    value: any = null;
    color = 'primary';

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
    }

    refresh(params: any): boolean {
        this.params = params;
        this.value = this.params.value;
        return true;
    }
}
