import { Injectable } from '@angular/core';
import { ConsentAgreementData } from '../app/dialogs/user-consent-dialog/user-consent-dialog';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/usermonitoring/';

@Injectable({ providedIn: 'root' })
export class DialogsServices {
    constructor(private http: EvaPlusHttpService) {}

    getConsentMessage() {
        return this.http.get(baseUrl + 'getConsentMessage');
    }

    sendUserConsentAgreement(agree: ConsentAgreementData, userDetails: any) {
        return this.http.post(baseUrl + 'sendUserConsentAgreement', {
            agree: agree,
            userName: userDetails.name,
            userEmail: userDetails.email,
        });
    }

    checkUserConsentExist(userNameParam: string) {
        return this.http.get(baseUrl + 'checkUserConsetExist', {
            params: { userName: userNameParam },
        });
    }

    downloadLastTehnDaysUserData(userNameParam: string) {
        return this.http.get(baseUrl + 'downloadLastTehnDaysUserData', {
            params: { userName: userNameParam },
        });
    }

    getMailFromParameter() {
        return this.http.get(baseUrl + 'getMailFromParameter');
    }
}
