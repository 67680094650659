import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetAuthorizationToken, SetCurrentUser } from '../action/eva-plus-user.actions';

export interface EvaPlusUser {
    id: string;
    name: string;
    email: string;
    roles: any;
    actions: any;
    lang: string;
    survey_done: boolean;
}

export interface EvaPlusUserStateModel {
    authorizationToken: string;
    currentUser: EvaPlusUser | null;
}

const defaultState = {
    authorizationToken: '',
    currentUser: null,
};

@Injectable()
@State<EvaPlusUserStateModel>({
    name: 'evaPlusUser',
    defaults: defaultState,
})
export class EvaPlusUserState {
    constructor() {}

    @Action(SetAuthorizationToken)
    setAuthorizationToken(ctx: StateContext<EvaPlusUserStateModel>, action: SetAuthorizationToken) {
        ctx.patchState({ authorizationToken: action.authorizationToken });
    }

    @Action(SetCurrentUser)
    setCurrentUser(ctx: StateContext<EvaPlusUserStateModel>, action: SetCurrentUser) {
        ctx.patchState({ currentUser: action.currentUser });
    }

    @Selector()
    static authorizationAPIToken(state: EvaPlusUserStateModel) {
        return state.authorizationToken;
    }

    @Selector()
    static currentUser(state: EvaPlusUserStateModel) {
        return state.currentUser;
    }
}
