import { Pipe, PipeTransform } from '@angular/core';
import { IInfrastructureElement } from '../../../services';

@Pipe({
    name: 'filter2',
})
export class FilterPipeInfrastructure implements PipeTransform {
    transform(items: IInfrastructureElement[], searchText: string): IInfrastructureElement[] {
        if (!items) return [];
        if (!searchText) return [];
        searchText = searchText.toLowerCase();
        return items.filter((it) => {
            return it.name.toLowerCase().includes(searchText);
        });
    }
}
