import { InjectionToken, Optional, Provider, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from '../../../services/http/eva-http.service';

export interface IUser {
    id: string;
    name: string;
    email: string;
    roles: string;
    lang: string;
    suvi: string;
    user_consent: string;
    atte: number;
    deny: number;
    lock: boolean;
    survey_done: boolean;
}

export interface ISaveUser {
    id: string;
    name: string;
    email: string;
    roles: string;
    lang: string;
    suvi: string;
    user_consent: string;
    atte: number;
    deny: number;
    survey_done: boolean;
}

export interface ISaveRole {
    name: string;
    actions: string[];
    vehicles: string[];
    description: string;
    isNewRole: boolean;
}

export interface ISurveyDoneRequest {
    id: string;
    survey_done: boolean;
}

const baseUrl = '/EvaPlusRest/users/';

export class UserService {
    constructor(private http: EvaPlusHttpService, private api: string) {}

    getSupervisors(): Observable<IUser[]> {
        return this.http.get(baseUrl + 'supervisor');
    }

    getUsersList(): Observable<IUser[]> {
        return this.http.get(baseUrl + 'list');
    }

    getUser(id: string): Observable<IUser> {
        return this.http.get(baseUrl + 'single?id=' + id);
    }

    deleteUser(id: string, username: string): Observable<any> {
        return this.http.get(baseUrl + 'delete?id=' + id + '&username=' + username);
    }

    requestUserPasswordReset(id: string): Observable<any> {
        return this.http.get(baseUrl + 'reset?id=' + id);
    }

    setUserLanguage(id: string, lang: string): Observable<any> {
        return this.http.get(baseUrl + 'changeLanguage?id=' + id + '&language=' + lang);
    }

    updateUserSettingsCollection(newUsername: string, oldUsername: string): Observable<any> {
        return this.http.get(
            baseUrl + 'updateUserSettingsCollection?oldUsername=' + oldUsername + '&newUsername=' + newUsername
        );
    }

    createAmendUser(user: ISaveUser): Observable<any> {
        let payload = JSON.stringify(user);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.http.post(baseUrl + 'save', payload, { headers: headers });
    }

    removeLockByAdmin(name) {
        let payload = JSON.stringify({ name: name });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.http.post(baseUrl + 'removeLockByAdmin', payload, { headers: headers });
    }

    getPasswordResetPeriod() {
        return this.http.get(baseUrl + 'getPasswordResetPeriod');
    }

    getRoleActionsSrv(lang: string) {
        return this.http.get(baseUrl + 'roleActions?lang=' + lang);
    }

    saveRoleActions(role: ISaveRole) {
        let payload = JSON.stringify(role);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this.http.post(baseUrl + 'saveRole', payload, { headers: headers });
    }

    getRoleNames() {
        return this.http.get(baseUrl + 'roles');
    }

    getActionsForRoleName(name) {
        return this.http.get(baseUrl + 'roleActionsForRole?name=' + encodeURIComponent(name));
    }

    /**
     * Set the survey_done property in the User model, called when user clicks on the "don't show me this again" in the survey dialog
     * @param request: ISurveyDoneRequest
     */
    setSurveyState(request: ISurveyDoneRequest): Observable<void> {
        return this.http.post(`${baseUrl}surveyDone`, request);
    }

    deleteRole(name: string) {
        return this.http.get(baseUrl + 'deleteRole?role=' + encodeURIComponent(name));
    }
}

export const USERS_API: InjectionToken<string> = new InjectionToken<string>('USERS_API');

export function USER_PROVIDER_FACTORY(
    parent: UserService,
    interceptorHttp: EvaPlusHttpService,
    api: string
): UserService {
    return parent || new UserService(interceptorHttp, api);
}

export const USER_PROVIDER: Provider = {
    // If there is already a service available, use that. Otherwise, provide a new one.
    provide: UserService,
    deps: [[new Optional(), new SkipSelf(), UserService], EvaPlusHttpService, USERS_API],
    useFactory: USER_PROVIDER_FACTORY,
};
