import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import 'pivottable/dist/pivot.min.css';
import 'pivottable/dist/pivot.min.js';
import { Subscription } from 'rxjs';
import {
    DashboardsConfigurationService,
    IDashboardsConfiguration,
    UserMonitoringService,
} from '../../../services';
import { MomentTimezoneDatePipe } from '../../pipes/moment-timezone-date/moment-timezone-date.pipe';
import { PivotTableServices } from '../../../services/pivot-table.service';
import { TdDialogService } from '@covalent/core/dialogs';
import { HrDatetimePicker2Component } from '../../components/hr-datetime-picker2/hr-datetime-picker2.component';

declare var $: any;
declare var jQuery: any;

@Component({
    selector: 'app-user-activities',
    templateUrl: './user-activities.component.html',
    styleUrls: ['./user-activities.component.scss'],
    providers: [MatSnackBar, Title],
})
export class UserActivitiesComponent implements OnInit, AfterViewInit {
    @ViewChild('hrDateTime', { static: false }) _hrDateTime: HrDatetimePicker2Component;
    @Input('eventViewId') eventViewId: string; // used to transfer value to child component from parent component

    timeZone: string;
    users: any[] = [];
    selectedUserValue: string[] = [];
    timefrom: Date;
    timeto: Date;
    selectedRows: any[] = [];
    previewProgresMode: string = 'determinate';
    previewProgresValue: number = 100;
    matTabGroupHeight: number = window.innerHeight - 45 - 10 - 48 - 20 - 5 - 20;
    difTabGroupHeight: number = window.innerHeight - 45 - 10 - 48 - 20;
    pivotViewTableHeight: number;
    pivotViewHeight: number;
    selectedQueryTemplate: string = '';
    queryTemplates: any[] = ['last query'];
    subIsTemplateInDb: Subscription = new Subscription();
    userName: string = '';
    absOrRel: string = '';
    timefromEtcsFormated: string = '';
    timetoEtcsFormated: string = '';
    timeFromRelative: string = ''; // relative time
    timeToRelative: string = ''; // relative time
    formIsValid: boolean = false;

    //ag-grid bound properties
    columnsAgGrid = [
        {
            headerName: 'User name',
            field: 'user',
            width: 140,
            headerTooltip: '',
            cellRenderer: 'imageFormatterComponent',
            cellStyle: this.cellColorGray.bind(this),
        },
        { headerName: 'Roles', field: 'roles', width: 100, headerTooltip: '' },
        { headerName: 'Date Time', field: 'date_time', width: 150, headerTooltip: '' },
        { headerName: 'Module', field: 'module', width: 200, headerTooltip: '' },
        { headerName: 'Action', field: 'page', width: 200, headerTooltip: '' },
        { headerName: 'Parameters', field: 'parameters', width: 400, headerTooltip: '' },
    ];

    rowsAgGrid: any[] = [];
    numberOfRows: number = 0;
    gridApi: GridApi;
    fixedOffset = 162;
    contentHeight: number = window.innerHeight - this.fixedOffset;
    curentTabOpened: number = 0;
    showPivot = true; //
    pivotId: string; // for pivot id to be dinamicaly generated
    pivotTableTemplate: any = {}; // pivot table parameters from template (only for pivot table)
    pivotTemplates: PivotTemplateO[] = []; // array of templates with their content
    selectedTemplate: PivotTemplateO = null; // selected template with its content
    datapt = []; // data that will be shown in pivot table
    previousTabOpened: number = 0;

    // roles
    roleActions: string = '';
    roleAccessEvaDesk: boolean = false;

    roleEnableAll() {
        this.roleAccessEvaDesk = true;
    }

    sub: Subscription = new Subscription();
    subActivatedRoute: Subscription = new Subscription();
    subGetConfigIssuePerVehicle: Subscription = new Subscription();
    subGetEventsForPivot: Subscription = new Subscription();
    subInitTemplateList: Subscription = new Subscription();
    subGetQueryTemplate: Subscription = new Subscription();
    subDeleteTemplateDialog: Subscription = new Subscription();
    subDeleteTemplate: Subscription = new Subscription();
    private el: ElementRef;
    pivotParamsGlobal: any = {};

    my_aggregators = {
        'Count': $.pivotUtilities.aggregators['Count'],
        'Count Unique Values': $.pivotUtilities.aggregators['Count Unique Values'],
        'List Unique Values': $.pivotUtilities.aggregators['List Unique Values'],
        'Sum': $.pivotUtilities.aggregators['Sum'],
        'Integer Sum': $.pivotUtilities.aggregators['Integer Sum'],
        'Average': $.pivotUtilities.aggregators['Average'],
        'Median': $.pivotUtilities.aggregators['Median'],
        'Sample Variance': $.pivotUtilities.aggregators['Sample Variance'],
        'Sample Standard Deviation': $.pivotUtilities.aggregators['Sample Standard Deviation'],
        'Minimum': $.pivotUtilities.aggregators['Minimum'],
        'Maximum': $.pivotUtilities.aggregators['Maximum'],
        'First': $.pivotUtilities.aggregators['First'],
        'Last': $.pivotUtilities.aggregators['Last'],
        'Sum as Fraction of Total': $.pivotUtilities.aggregators['Sum as Fraction of Total'],
        'Sum as Fraction of Rows': $.pivotUtilities.aggregators['Sum as Fraction of Rows'],
        'Sum as Fraction of Columns': $.pivotUtilities.aggregators['Sum as Fraction of Columns'],
        'Count as Fraction of Total': $.pivotUtilities.aggregators['Count as Fraction of Total'],
        'Count as Fraction of Rows': $.pivotUtilities.aggregators['Count as Fraction of Rows'],
        'Count as Fraction of Columns':
            $.pivotUtilities.aggregators['Count as Fraction of Columns'],
    };

    constructor(
        @Inject(ElementRef) el: ElementRef,
        private _titleService: Title,
        private _userMonitoringService: UserMonitoringService,
        private momentTimezoneDatePipe: MomentTimezoneDatePipe,
        private _route: ActivatedRoute,
        private _pivotservice: PivotTableServices,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private _dialogService: TdDialogService,
        private _dashboardsConfigurationService: DashboardsConfigurationService,
        private _snackBarService: MatSnackBar
    ) {
        this._userMonitoringService.getUserList().subscribe((userList: any[]) => {
            this.users = userList;
            this.users.unshift({ viewValue: 'ALL', value: 'ALL' });
        });

        const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        this.roleActions = currentUser && currentUser.actions;

        if (this.roleActions.indexOf('ALL') != -1) {
            this.roleEnableAll();
        }

        this.el = el;
    }

    ngOnInit() {
        this._userMonitoringService
            .sendDummyRequest("'User Monitoring','First Page'")
            .subscribe((result: any) => {});
        this.selectedUserValue.push('ALL');
        this.fillDateFields(Date.now());
        this.initializeInOnInit();
        this.selectedQueryTemplate = 'last template';
        this.userName = this.getUserName();
        this.initQueryTemplateList();

        this._dashboardsConfigurationService
            .getDashboards()
            .subscribe((dashboardsJSON: IDashboardsConfiguration) => {
                this.timeZone = dashboardsJSON.timeZone;
            });
    }

    private oldVal: any;

    onCellEditingStarted(event: CellEditingStartedEvent) {
        this.oldVal = event.value;
    }

    oncellEditingStopped(event: CellEditingStoppedEvent) {
        if (event && event.api) {
            let rowNode = event.api.getRowNode(event.rowIndex.toString());
            if (rowNode) {
                rowNode.setDataValue(event.column, this.oldVal);
            }
        }
    }

    gridOptions: GridOptions = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    initializeInOnInit() {
        // showing and hiding toolbar, pivot and tabs
        if (this.eventViewId != undefined) {
            this.pivotId = this.eventViewId + '1';
            this.elementRef.nativeElement.style.setProperty('--display-pivot', 'none');
            this.elementRef.nativeElement.style.setProperty('--display-tabs', 'none');
            this.elementRef.nativeElement.style.setProperty('--padding-top', '0px');
            this.elementRef.nativeElement.style.setProperty('--margin-top', '-10px');
            this.elementRef.nativeElement.style.setProperty('--margin-view-left', '5px');
            this.elementRef.nativeElement.style.setProperty('--margin-view-right', '5px');
        } else {
            this.pivotId = 'output';
            this.elementRef.nativeElement.style.setProperty('--display-pivot', 'table-cell');
            this.elementRef.nativeElement.style.setProperty('--display-tabs', 'fixed');
            this.elementRef.nativeElement.style.setProperty('--margin-view-left', '8px');
            this.elementRef.nativeElement.style.setProperty('--margin-view-right', '8px');
        }
        this.onResize({});
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.subActivatedRoute.unsubscribe();
        this.subGetConfigIssuePerVehicle.unsubscribe();
        this.subGetEventsForPivot.unsubscribe();
        this.subDeleteTemplateDialog.unsubscribe();
        this.subDeleteTemplate.unsubscribe();
    }

    pivotTemplatesPublic: PivotTemplateO[] = [];
    pivotTemplatesPrivate: PivotTemplateO[] = [];

    ngAfterViewInit() {
        setTimeout(() => {
            this.onResize({});
        }, 750);

        this._titleService.setTitle('User activity');
        if (!this.el || !this.el.nativeElement || !this.el.nativeElement.children) {
            return;
        }
        var container = this.el.nativeElement;
        var inst = jQuery(container);
        var targetElement = inst.find(document.getElementById(this.pivotId));
        if (!targetElement) {
            return;
        }
        //this helps if you build more than once as it will wipe the dom for that element
        while (targetElement.firstChild) {
            targetElement.removeChild(targetElement.firstChild);
        }
        var renderers = $.extend(
            $.pivotUtilities.renderers,
            $.pivotUtilities.c3_renderers,
            $.pivotUtilities.export_renderers
        );
        targetElement.pivotUI(this.datapt, {
            renderers: renderers,
            rowOrder: 'value_z_to_a',
            colOrder: 'value_z_to_a',
            aggregators: this.my_aggregators,
            rendererOptions: {
                table: {},
            },
        });
        this._hrDateTime.setDateTimeRemote(this.timefrom, this.timeto, 'abs');
        this.cd.detectChanges();
    }

    onGridReady($event: GridReadyEvent) {
        this.gridApi = $event.api;
    }

    setDateTimeFromTo(pfrom: string, pto: string, absOrRel: string) {
        this.absOrRel = absOrRel;

        this.timefrom = new Date(+pfrom);
        this.timeto = new Date(+pto);
        this.timeFromRelative = this.getRelativeString(this.timefrom);
        this.timeToRelative = this.getRelativeString(this.timeto);
        this.checkTimeValid();
    }

    getUserActivitesLog() {
        this.previewProgresMode = 'indeterminate';
        this.sub = this._userMonitoringService
            .getUserActivitiesLogList(
                this.selectedUserValue,
                this.timefrom.getTime().toString(),
                this.timeto.getTime().toString()
            )
            .subscribe((res: any[]) => {
                if (res.length) {
                }
                this.rowsAgGrid = res;
                this.previewProgresMode = 'determinate';
                this.datapt = res;
                var renderers = $.extend(
                    $.pivotUtilities.renderers,
                    $.pivotUtilities.c3_renderers,
                    $.pivotUtilities.export_renderers
                );
                var container = this.el.nativeElement;
                var inst = jQuery(container);
                var targetElement = inst.find(document.getElementById(this.pivotId));
                // this.pivotTableTemplate.aggregators = this.my_aggregators;
                // if ((this/.pivotTableTemplate != undefined) && (this.pivotTableTemplate != {}) && (this.pivotTableTemplate != "") ) {
                // targetElement.pivotUI(this.datapt, this.pivotParamsGlobal, true);
                // }else {
                targetElement.pivotUI(this.datapt, {
                    renderers: renderers,
                    rowOrder: 'value_z_to_a',
                    colOrder: 'value_z_to_a',
                    aggregators: this.my_aggregators,
                    table: {},
                });
                // }
            });
    }

    fillDateFields(timeInMiliseconds: number) {
        // var timeNow: number = Date.now();
        var timeFrom1: Date = new Date(timeInMiliseconds);
        timeFrom1.setHours(0);
        timeFrom1.setMinutes(0);
        timeFrom1.setSeconds(0);
        var timeTo1: Date = new Date(timeInMiliseconds + 24 * 3600 * 1000);
        timeTo1.setHours(0);
        timeTo1.setMinutes(0);
        timeTo1.setSeconds(0);
        this.timefrom = timeFrom1;
        this.timeto = timeTo1;
    }

    // ag-grid events
    onSelectionChanged($event: SelectionChangedEvent) {}

    runCsvExport() {
        const ext = new Date().toLocaleString();
        const params = {
            skipHeader: false,
            columnGroups: true,
            skipFooters: true,
            skipGroups: true,
            skipPinnedTop: true,
            skipPinnedBottom: true,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: 'dataview_' + ext + '_.csv',
            columnSeparator: ',',
        };
        this._userMonitoringService
            .sendDummyRequest("'User Monitoring','Export CSV'")
            .subscribe((result: any) => {});
        this.gridApi.exportDataAsCsv(params);
    }

    cellColorGray(params) {
        return { 'background-color': '#f1f1f1', 'font-weight': 'bold' }; // ligth gray for vehicle column
        // return { "background-color": "#f1f1f1" };
    }

    cellColorChangeDuration(params) {
        var color = this.vehicleDurationGetColor(params.value);
        return { 'background-color': color };
    }

    vehicleDurationGetColor(status): string {
        var lenght: number = status.length;
        if (lenght > 3 && lenght <= 4) {
            return '#E4C0B7';
        } else if (lenght > 4) {
            return '#E0A090';
        }
    }

    cellColorChangeStatus(params) {
        var color = this.vehicleStatusGetColor(params.value);
        return { 'background-color': color };
    }

    vehicleStatusGetColor(status): string {
        var statusTmp: string = status;
        if (statusTmp.toLowerCase() == 'uploaded') {
            return '#A1FFA6'; // light green
        }
        if (statusTmp.toLocaleLowerCase() == 'created') {
            return '#FFF48A'; // light yellow
        }
        if (statusTmp.toLocaleLowerCase() == 'converted') {
            return '#FFF48A'; // light yellow
        }
        if (statusTmp.toLocaleLowerCase() == 'upload failed') {
            return '#FFF48A'; // light yellow
        }
        if (statusTmp.toLocaleLowerCase() == 'error') {
            return '#FF8989'; // light red
        }
        if (statusTmp.toLocaleLowerCase() == 'failed') {
            return '#FF8989'; // light red
        }
        return '#FFFFFF'; // white
    }

    // for showing pivot table
    loadPivotParametersFromTemplate() {
        var container = this.el.nativeElement;
        var inst = jQuery(container);
        var targetElement = inst.find(document.getElementById(this.pivotId));
        this.pivotTableTemplate.aggregators = this.my_aggregators;
        $(document.getElementById(this.pivotId)).pivotUI(
            this.rowsAgGrid,
            this.pivotTableTemplate,
            true
        );
    }

    curentTabOpenedName: string = 'list';

    onChangeTab($event) {
        this.curentTabOpened = $event.index;
        if (this.previousTabOpened == 1) {
            this.pivotParamsGlobal = $(document.getElementById(this.pivotId)).data(
                'pivotUIOptions'
            );
        }
        this.pivotParamsGlobal.rendererOptions = {};
        this.pivotParamsGlobal.rendererOptions.table = {};
        if ($event.index == 1) {
            $(document.getElementById(this.pivotId)).pivotUI(
                this.datapt,
                this.pivotParamsGlobal,
                true
            );
            this._userMonitoringService
                .sendDummyRequest("'User Monitoring','User Activities Pivot'")
                .subscribe((result: any) => {});
        } else {
            this._userMonitoringService
                .sendDummyRequest("'User Monitoring','User Activities Log'")
                .subscribe((result: any) => {});
        }
        this.previousTabOpened = $event.index;
        this.cd.detectChanges();
    }

    frameworkComponents = {
        imageFormatterComponent: ImageFormatterComponentPerson,
    };

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        setTimeout(() => {
            // if (this.eventViewId == undefined) {
            this.pivotViewHeight = this.matTabGroupHeight - 37 - 1;
            this.pivotViewTableHeight = this.pivotViewHeight;
            this.cd.detectChanges();
            this.contentHeight = this.matTabGroupHeight - 70;
            this.difTabGroupHeight = this.matTabGroupHeight - 25;
            // this.matSidenavContentHeightList = this.eventViewHeight - 58;
            // }
        }, 750);
    }

    public onChangeQueryTemplate(event) {
        this.selectedQueryTemplate = event.value;
        this.loadTemplate();
    }

    loadTemplate() {
        this.subGetQueryTemplate = this._userMonitoringService
            .etcsLoadQueryTemplateSrvNew(this.selectedQueryTemplate, this.userName)
            .subscribe((res: any) => {
                this.selectedQueryTemplate = res.name;

                if (res.selectedUsers.indexOf('ALL') != -1) {
                    this.selectedUserValue = ['ALL'];
                } else {
                    this.selectedUserValue = res.selectedUsers;
                }

                var timeFrom_moment_transformed: string = '';
                var timeTo_Moment_transformed: string = '';
                timeFrom_moment_transformed = this.momentTimezoneDatePipe.transform(
                    res.timefrom,
                    'yyyy-MM-dd HH:mm:ss',
                    this.timeZone
                );
                timeTo_Moment_transformed = this.momentTimezoneDatePipe.transform(
                    res.timeto,
                    'yyyy-MM-dd HH:mm:ss',
                    this.timeZone
                );

                var moment_transformed_date_fromDate: Date = new Date(timeFrom_moment_transformed);
                var moment_transformed_date_toDate: Date = new Date(timeTo_Moment_transformed);

                this.timefrom = moment_transformed_date_fromDate;
                this.timeto = moment_transformed_date_toDate;

                this._hrDateTime.setDateTimeRemote(this.timefrom, this.timeto, 'abs');
                this.setDateTimeFromTo(
                    String(this.timefrom.getTime()),
                    String(this.timeto.getTime()),
                    'abs'
                );

                this.getUserActivitesLog();
            });
    }

    saveQueryTemplate() {
        this._userMonitoringService
            .saveQueryToTemplateSrvNew(
                this.userName,
                this.timefrom,
                this.timeto,
                this.selectedQueryTemplate,
                true,
                this.selectedUserValue
            )
            .subscribe((res: TransferOK) => {
                var templateLoad: any = {};
                this.queryTemplates.push(templateLoad);
                this._snackBarService.open('Template overwritten!', 'Ok', { duration: 3000 });
            });
    }

    saveAsQueryTemplate() {
        this._dialogService
            .openPrompt({
                message: 'Enter template name',
                acceptButton: 'Save',
                title: 'Save Template',
            })
            .afterClosed()
            .subscribe((templateName: string) => {
                if (typeof templateName != 'undefined') {
                    var format = /[-!$%^&*()_+@|~=`\\#{}\[\]:";'<>?,.\/]/;
                    templateName = templateName.trim();
                    if (templateName.length > 0) {
                        if (!format.test(templateName)) {
                            this.subIsTemplateInDb = this._userMonitoringService
                                .isQueryTemplateInDb(templateName)
                                .subscribe(
                                    (response) => {
                                        if (response.templateExists) {
                                            this._dialogService.openAlert({
                                                message:
                                                    'Template with entered name already exists! Please choose another name if you want to save template.',
                                                closeButton: 'Close',
                                            });
                                        } else {
                                            this._userMonitoringService
                                                .saveQueryToTemplateSrvNew(
                                                    this.userName,
                                                    this.timefrom,
                                                    this.timeto,
                                                    templateName,
                                                    false,
                                                    this.selectedUserValue
                                                )
                                                .subscribe((res: TransferOK) => {
                                                    var templateLoad: any = {};
                                                    this.selectedQueryTemplate = templateName;
                                                    templateLoad.value = templateName;
                                                    templateLoad.viewValue = templateName;
                                                    this.queryTemplates.push(templateLoad);
                                                    this._snackBarService.open(
                                                        'Template saved',
                                                        'Ok',
                                                        { duration: 3000 }
                                                    );
                                                });
                                        }
                                    },
                                    (error) => {
                                        this._dialogService.openAlert({
                                            message: 'Issue on the server side.',
                                            closeButton: 'Close',
                                        });
                                    }
                                );
                        }
                    }
                }
            });
    }

    getUserName() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var name = currentUser && currentUser.name;
        return name;
    }

    initQueryTemplateList() {
        this.queryTemplates = [];
        this.subInitTemplateList = this._userMonitoringService
            .etcsLoadQueryTemplateListSrvNew(this.userName)
            .subscribe((res: any[]) => {
                for (var i = 0; i < res.length; i++) {
                    this.queryTemplates.push(res[i]);
                }
            });
    }

    deleteQueryTemplate() {
        this.subDeleteTemplate = this._dialogService
            .openConfirm({
                message: 'Are you sure you want to delete template?',
                acceptButton: 'Delete',
                title: 'Delete Template',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.subDeleteTemplate = this._userMonitoringService
                        .userDeleteTemplateSrvNew(this.selectedQueryTemplate, this.userName)
                        .subscribe(
                            () => {
                                this._snackBarService.open('Template deleted', 'Ok', {
                                    duration: 3000,
                                });
                                this.initQueryTemplateList();
                                ///this.resetAll();
                            },
                            (error) => {
                                this._dialogService.openAlert({
                                    message: 'There was an error trying to delete the template.',
                                });
                            }
                        );
                }
            });
    }

    // check if both relative times are entetered
    checkTimeValid() {
        var res: boolean = false;
        if (this.absOrRel == 'td') {
            res = true;
            this.formIsValid = res;
        } else {
            if (this.timeFromRelative != '' && this.timeToRelative != '') {
                res = true;
            }
            this.formIsValid = res;
        }
    }

    getRelativeString(date: Date) {
        // calculates how manu days from date
        var now = new Date().getTime();
        var the = date.getTime();
        var difference_ms = (now - the) / 1000;
        var seconds = Math.floor(difference_ms % 60);
        difference_ms = difference_ms / 60;
        var minutes = Math.floor(difference_ms % 60);
        difference_ms = difference_ms / 60;
        var hours = Math.floor(difference_ms % 24);
        var days = Math.floor(difference_ms / 24);
        return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
    }

    resetAll() {
        this.userName = '';
        this.timefrom = undefined;
        this.timeto = undefined;
        this._hrDateTime.setDateTimeRemote(this.timefrom, this.timeto, 'abs');
        this.selectedUserValue = [];
        this.selectedQueryTemplate = '';
    }

    checkAllSelection() {
        if (this.selectedUserValue.indexOf('ALL') != -1) {
            this.selectedUserValue = ['ALL'];
        }
    }
}

@Component({
    selector: 'app-image-formatter-cell-teloc-overview5',
    template: ` <div layout="row">
        <mat-icon color="primary" style="font-size: 17px; margin-top: 3px;">person</mat-icon>
        <div style="margin-top: -2px; margin-left: 5px;">{{ params.value }}</div>
    </div>`,
})
export class ImageFormatterComponentPerson {
    params: any;

    agInit(params: any): void {
        this.params = params;
    }
}

class PivotTemplateO {
    user: string = '';
    name: string = '';
    content: any = {};
    isDefault: boolean = false;
    vehicles: string[] = [];
    events: string[] = [];
    timefromrelative: string = '';
    timetorelative: string = '';
    paramforlabel: string = '';
    usedimensionlabel: boolean = false;
    markertransparencymapview: string = '';
    markersize: number;
    usedimensionsize: boolean = false;
    selecteddimensionmarkersize: string = '';
    labelson: boolean = true;
    defaulttab: number = 2;
    mapLat: number;
    mapLon: number;
    mapZoom: number;
    usedimensiontransparency: boolean = false;
    selecteddimensiontransparency: string = '';
    transparencyinvert: boolean = false;
    sizeinvert: boolean = false;

    // *** private or public template ***
    publictemplate: boolean = false;
}

class TransferOK {
    transferOK: boolean = false;
}
