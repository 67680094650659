import { IBookmarkMarkerData } from '../../../../services/signal.service';

export class SetShowPreview {
    static readonly type = '[Canvas] Set Show Preview';
    constructor(public show: boolean) {}
}

export class SetPreviewClickDetected {
    static readonly type = '[Canvas] Set Preview Click Detected ';
    constructor(public previewClickDetected: boolean) {}
}

export class SetPreviewChartDisplayed {
    static readonly type = '[Canvas] Set Preview Chart Displayed';
    constructor(public previewChartDisplayed: boolean) {}
}

export class SetPageOpenedFromOtherPage {
    static readonly type = '[Canvas] Set Page Opened From Other Page';
    constructor(public pageOpenedFromOtherPage: boolean) {}
}

export class SetTimeFromParam {
    static readonly type = '[Canvas] Set Time From Param';
    constructor(public timeFromParam: number) {}
}

export class SetPreviewTimeLimit {
    static readonly type = '[Canvas] Set Preview Time Limit';
    constructor(public min: number, public max: number) {}
}

export class SetTimeSignalsMillis {
    static readonly type = '[Canvas] Set TimeSignalsMillis';
    constructor(public timeFromSignalsMillis: number, public timeToSignalsMillis: number) {}
}

export class SetMoveLeftOrRight {
    static readonly type = '[Canvas] Set Move Left Or Right';
    constructor(public moveLeftOrRight: string) {}
}

export class SetIntervalInMinutes {
    static readonly type = '[Canvas] Set Interval In Minutes ';
    constructor(public intervalInMinutes: number) {}
}

export class SetRelativePostionOn {
    static readonly type = '[Canvas] Set Relative Position On';
    constructor(public relativePositionOn: boolean) {}
}

export class SetPreviewChartTimeSelected {
    static readonly type = '[Canvas] Set Preview Chart Time Selected';
    constructor(public previewChartTimeSelected: number) {}
}

export class SetAnalogTimeMilisOne {
    static readonly type = '[Canvas] Set Analog Time Milis One ';
    constructor(public analogTimeMilisOne: number) {}
}

export class SetPreviewProgresMode {
    static readonly type = '[Canvas] Set Preview Progres Mode';
    constructor(public previewProgresMode: string) {}
}

export class SetCanvasId {
    static readonly type = '[Canvas] Set Canvas Id';
    constructor(public id: string) {}
}

export class RemoveMarkers {
    static readonly type = '[Canvas] Remove Markers';
}

export class CalculatePreviewChart {
    static readonly type = '[Canvas] Calculate Preview Chart';
}

export class CalculateMovementOfMarkers {
    static readonly type = '[Canvas] Calculate Movement Of Markers';
}

export class ResetStoreCanvas {
    static readonly type = '[Canvas] Reset Store Canvas';
}
