export enum AnnouncementType {
    Info = 'info',
    Announcement = 'announcement',
    Warning = 'warning',
}

export function AnnouncementTypeToString(value: AnnouncementType): string {
    switch (value) {
        case AnnouncementType.Info:
            return 'Information';
        case AnnouncementType.Announcement:
            return 'Announcement';
        case AnnouncementType.Warning:
            return 'Warning';
    }
}

export interface IAnnouncement {
    id: string;
    type: AnnouncementType;
    title: string;
    text: string;
}

export class Announcement implements IAnnouncement {
    id: string;
    type: AnnouncementType;
    title: string;
    text: string;

    constructor(
        id: string = '',
        type: AnnouncementType = AnnouncementType.Info,
        title: string = '',
        text: string = ''
    ) {
        this.id = id;
        this.type = type;
        this.title = title;
        this.text = text;
    }

    static from(value: IAnnouncement): Announcement {
        return new Announcement(value.id, value.type, value.title, value.text);
    }
}
