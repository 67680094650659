export const iomsApiStartPoint = '/IomsRestApi/';

export const iomsEndpoints = {
    health: `${iomsApiStartPoint}actuator/health`,
    groupByData: `${iomsApiStartPoint}equipmentWear/getGroupByData`,
    keyValueGetData: `${iomsApiStartPoint}api/v1/keyvalue/getData`,
    equipmentAndCubicleData: `${iomsApiStartPoint}equipmentWear/getEquipmentAndCubicleData`,
    createTicketInfo: `${iomsApiStartPoint}api/v1/createTicket/createTicketInfo`,
    createNewTicket: `${iomsApiStartPoint}api/v1/createTicket/createTicket`,
    getMaintainceTicket: `${iomsApiStartPoint}api/v1/createTicket/getTickets`,
    getMaintainceTicketInfo: `${iomsApiStartPoint}api/v1/createTicket/getTicketInfo`,
    closeMaintainceTicket: `${iomsApiStartPoint}api/v1/createTicket/closeTicket`,
    getPivotTableDataByUserId: `${iomsApiStartPoint}api/v1/keyvalue/getPivotTableDataByUserId`,
    protectionTrippingMetaData: `${iomsApiStartPoint}protectionTripping/getMetaData`,
    protectionTrippingAnalysisData: `${iomsApiStartPoint}protectionTripping/analysisData`,
    protectionTrippingFaults: `${iomsApiStartPoint}protectionTripping/getFaultRecorder`,
    protectionTrippingComtrade: `${iomsApiStartPoint}protectionTripping/downloadComtrade`,
    protectionTrippingCellData: `${iomsApiStartPoint}protectionTripping/getCellData`,
    timeZones: `${iomsApiStartPoint}api/v1/keyvalue/getTimeZones`,
    deletepivottable: `${iomsApiStartPoint}api/v1/keyvalue/deletepivottable`,
    updatepivottable: `${iomsApiStartPoint}api/v1/keyvalue/updatepivottable`,
    savepivottable: `${iomsApiStartPoint}api/v1/keyvalue/savepivottable`,
    dashboardApiBaseUrl: `${iomsApiStartPoint}api/v1/dashboard/`,
    getSepcosHistory: `${iomsApiStartPoint}sepcosHistory/getMetaData`,
    getRefreshCacheBackend: `${iomsApiStartPoint}api/v1/dashboard/refreshCache`,
    keyValueSaveData: `${iomsApiStartPoint}api/v1/keyvalue/saveData`,
    fileAdminBaseUrl: `${iomsApiStartPoint}api/v1/zipArchives/`,
    traceability: `${iomsApiStartPoint}api/v1/traceability/getTraceData`,
    equipmentType: `${iomsApiStartPoint}api/v1/equipmentType/`,
    lines: `${iomsApiStartPoint}api/v1/line/getLines`,
    getInstalledVersionData: `${iomsApiStartPoint}api/v1/installVersion/getAll`,
    getSystemInfo: `${iomsApiStartPoint}api/v1/systemInfo/getAllSysInfoByTime?time=`,
    sepcosHistoryAnalysisData: `${iomsApiStartPoint}sepcosHistory/analysisData`,
    sepcosHistoryCellData: `${iomsApiStartPoint}sepcosHistory/getCellData`,
    sepcosHistoryMetaData: `${iomsApiStartPoint}sepcosHistory/getMetaData`,
    adminPartsGetEquipments: `${iomsApiStartPoint}api/v1/equipmentType/getEquipments`,
    adminPartsGetPartsByEquipmentId: `${iomsApiStartPoint}api/v1/equipmentType/getPartsByEquipmentId?id=`,
    adminPartsSaveEquipments: `${iomsApiStartPoint}api/v1/equipmentType/saveEquipments`,
    adminPartsGetResetCounter: `${iomsApiStartPoint}api/v1/equipmentType/getResetCounter?partId=`,
    adminPartsGetMatchingDateOrNot: `${iomsApiStartPoint}api/v1/equipmentType/getMatchingDateOrNot?partId=`,
    adminPartsSaveResetCounter: `${iomsApiStartPoint}api/v1/equipmentType/saveResetCounter`,
    saveLine: `${iomsApiStartPoint}api/v1/line/saveLine`,
    duplicateLine: `${iomsApiStartPoint}api/v1/line/duplicateLine`,
    duplicateStation: `${iomsApiStartPoint}api/v1/line/duplicateStation`,
    deleteLine: `${iomsApiStartPoint}api/v1/line/deleteLine`,
    getSuggestionList: `${iomsApiStartPoint}intelligent-suggestions`,
    updateIntelligentSuggestionList: `${iomsApiStartPoint}intelligent-suggestions`,
    getIntelligentSolutionHistory: `${iomsApiStartPoint}intelligent-suggestions-history/`,
    updateIntelligentSolutionHistory: `${iomsApiStartPoint}intelligent-suggestions-history`,
    deleteIntelligentSolutionHistory: `${iomsApiStartPoint}intelligent-suggestions-history`,
    parameter: `${iomsApiStartPoint}api/v1/parameter/`,
    backup: `${iomsApiStartPoint}api/v1/backUp/`,
    getIntelligentSolutionConfig: `${iomsApiStartPoint}intelligent-suggestions-config`,
    getIntelligentSuggestionByType: `${iomsApiStartPoint}intelligent-suggestions/`,
    updateStatusByType: `${iomsApiStartPoint}intelligent-suggestions`,
    updateStatusByParent: `${iomsApiStartPoint}intelligent-suggestions-config`,
    evaPlusRestVersion: `/EvaPlusRest/configuration/getVersion`,
    protectionTripping: `${iomsApiStartPoint}protectionTripping`,
};

export const keyValueEndpoints = {
    userID: 'userID',
    page: 'page',
    protectionTripping: 'protectiontripping',
    keyName: 'keyName',
    dateFormat: 'Date_Format',
    timeWithMili: 'time_with_milli',
    nextMaintenceDuration: 'nextMaintenceDuration',
    ticketConfiguration: 'ticketConfiguration',
    getTimeZones: 'getTimeZones',
    deviceMonitoringTableConfig: 'deviceMonitoringTableConfig',
    Logo: 'Logo',
    Title: 'Title',
    dashBoardLinesConnection: 'dashBoardLineConfigImage',
    Logo_Background_Color: 'Logo_Background_Color',
    longDateFormat: 'long_date_format',
    timeWithSeconds: 'time_with_seconds',
    shortTime: 'short_time',
    protectionTrippingTableConfig: 'protectionTrippingTableConfig',
    GATEWAY_ID: 'GATEWAY_ID',
};

export const equipmentType = {
    equipmentsInfo: 'getEquipmentsInfo',
    saveEquipmentsInfo: 'saveEquipmentsInfo',
};

export const protectionTripping = {
    getCurveData: 'getCurveData',
};
