import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TENANT } from '../config/api.config';
import { EvaPlusHttpService } from './http/eva-http.service';
import { ICriteriaDataItem, ILastTs } from './signal.service';

export interface INotificationSignalData {
    vehicleId: String;
    timeStr: String;
    driverId: String;
    identifier: String;
    value: any;
}

export interface INotificationEventData {
    telocFile: string;
    vehicleId: String;
    timeStr: String;
    eventType: string;
    eventDesc: String;
    driverId: String;
    routeCode: String;
    gpsLocation: String;
    signals: any[];
}

const baseUrl = '/EvaPlusRest/notifications/';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private http: EvaPlusHttpService) {}

    getVehicleIds(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getVehicleIds', { params: { tenant: TENANT } });
    }

    getRecordersForVehicle(vehicleId: string): Observable<string[]> {
        return this.http.get(baseUrl + 'getRecordersForVehicle', {
            params: { tenant: TENANT, vehicleId },
        });
    }

    // Signals
    lastDateForVehicleAndRecorderSignals(
        vehicleId: string,
        recorderId: string
    ): Observable<ILastTs> {
        return this.http.get(baseUrl + 'getLastDateForVehicleAndRecorderSignals', {
            params: { tenant: TENANT, vehicleId, recorderId },
        });
    }

    getSignalList(
        timeFrom: string,
        timeTo: string,
        vehicleId: string,
        recorderId: string
    ): Observable<INotificationSignalData[]> {
        return this.http.get(baseUrl + 'getSignalList', {
            params: {
                tenant: TENANT,
                from: timeFrom,
                to: timeTo,
                vehicleId,
                recorderId,
            },
        });
    }

    // Events
    getEventNames(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getEventNames', { params: { tenant: TENANT } });
    }

    lastDateForVehicleAndRecorderEvents(
        vehicleId: string,
        recorderId: string
    ): Observable<ILastTs> {
        return this.http.get(baseUrl + 'getLastDateForVehicleAndRecorderEvents', {
            params: {
                tenant: TENANT,
                vehicleId,
                recorderId,
            },
        });
    }

    getEventList(
        eventType: string,
        timeFrom: string,
        timeTo: string,
        vehicleId: string,
        recorderId: string
    ): Observable<INotificationEventData[]> {
        return this.http.get(baseUrl + 'getEventList', {
            params: {
                tenant: TENANT,
                eventType,
                from: timeFrom,
                to: timeTo,
                vehicleId,
                recorderId,
            },
        });
    }
}
