import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { AddNotificationMessage } from '../action/notification-message.actions';

export interface SingleNotificationMessage {
    id: string;
    title: string;
    message: string;
    closeCallback: (notification: SingleNotificationMessage, dismissedByAction: boolean) => void;
    autoCloseDuration: number;
    actionButtonLabel: string;
}

export interface NotificationMessageStateModel {}

const defaultState = {};

@Injectable()
@State<NotificationMessageStateModel>({
    name: 'notification',
    defaults: defaultState,
})
export class NotificationMessageState {
    constructor(private snackBar: MatSnackBar, private store: Store) {}

    @Action(AddNotificationMessage)
    addNotification(
        ctx: StateContext<NotificationMessageStateModel>,
        action: AddNotificationMessage
    ) {
        let notification: SingleNotificationMessage = action.notificationMessage;

        if (!notification.id) notification.id = new Date().getTime().toString();

        let snackBar = this.snackBar.open(
            notification.message,
            notification.actionButtonLabel ? notification.actionButtonLabel : 'Ok',
            { duration: notification.autoCloseDuration > 0 ? notification.autoCloseDuration : 0 }
        );

        if (notification.closeCallback) {
            snackBar.afterDismissed().subscribe((info) => {
                notification.closeCallback(notification, info.dismissedByAction);
            });
        }
    }
}
