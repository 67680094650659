import { SignalTemplateStateModel } from '../reducer/signal-template.state';
import { SingleSignalSettingStateModel } from '../reducer/single-signal-setting.state';

export class SetSelectedTemplate {
    static readonly type = '[SignalAnalyticsModule] Set Selected Template';
    constructor(public template: SignalTemplateStateModel | null) {}
}

export class FailedToLoadTemplate {
    static readonly type = '[SignalAnalyticsModule] Failed To Load Template';
    constructor(public templateName?: string) {}
}

export class SetUserTemplates {
    static readonly type = '[SignalAnalyticsModule] Set User Templates';
    constructor(public userTemplates: SignalTemplateStateModel[]) {}
}

export class SetSignalsTime {
    static readonly type = '[SignalAnalyticsModule] Set Signals Time';
    constructor(public timeFrom: string, public timeTo: string) {}
}

export class SetSignalSelectedTime {
    static readonly type = '[SignalAnalyticsModule] Set Signal Selected Time';
    constructor(public signalSelectedTime: number) {}
}

export class SetLastTimestampForVehicle {
    static readonly type = '[SignalAnalyticsModule] Set Last Timestamp For Vehicle';
    constructor(public lastTimestampForVehicle: number) {}
}

export class SetTimeStampFromSearchResultAdjusted {
    static readonly type = '[SignalAnalyticsModule] Set Time Stamp From Search Result Adjusted';
    constructor(public timeStampFromSearchResultAdjusted: number) {}
}

export class SetCdConfigIssue {
    static readonly type = '[SignalAnalyticsModule] Set Cd Config Issue';
    constructor(public cdConfigIssue: string) {}
}

export class GetPreviewData {
    static readonly type = '[SignalAnalyticsModule] Get Preview Data';
}

export class PreviewDataLoaded {
    static readonly type = '[SignalAnalyticsModule] Preview Data Loaded';
}

export class GetData {
    static readonly type = '[SignalAnalyticsModule] Get Data';
}

export class ClearSignalData {
    static readonly type = '[SignalAnalyticsModule] Clear Signal Data';
}

export class ClearPreviewData {
    static readonly type = '[PreviewAnalyticsModule] Clear Signal Data';
}
export class RefreshData {
    static readonly type = '[SignalAnalyticsModule] Refresh Data';
}

export class GetLastDateForVehicle {
    static readonly type = '[SignalAnalyticsModule] Get Last Date For Vehicle';
}

export class OpenSaForParams {
    static readonly type = '[SignalAnalyticsModule] Open Signal Analytics For Params';
    constructor(
        public vehicleId: string,
        public recorderId: string,
        public time: string,
        public templateName: string
    ) {}
}

export class GetTimeZone {
    static readonly type = '[SignalAnalyticsModule] Get TimeZone';
}

export class ResetSignalAnalyticsModule {
    static readonly type = '[SignalAnalyticsModule] Reset Store SignalAnalyticsModule';
}
