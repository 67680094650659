import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IomsActionType, IomsDevice } from '../../../../services/iomsdevicemanger.service';
import { deviceTreeI } from './load-devices.service';
import { ScheduleActionService } from './schedule-action.service';
import { ScheduleSeriesService } from './schedule-series.service';
import { SaveNewActionService } from './save-new-action2.service';
import { DialogScheduleEvent } from '../calendar-scheduler.component';

@Injectable({
    providedIn: 'root',
})
export class CreateNewService {
    constructor(
        public _dialog: MatDialog,
        private _scheduleActionService: ScheduleActionService,
        private _scheduleSeriesService: ScheduleSeriesService,
        private _saveNewActionService2: SaveNewActionService
    ) {}

    public async createNew(
        currentUser: any,
        actionTypes: IomsActionType[],
        linesTree: deviceTreeI[],
        iomsDevices: IomsDevice[],
        newAction: {
            start: number;
            end: number;
            lineId: string; //"Line 1";
            substationId: string;
        } = <any>{}
    ): Promise<boolean> {
        let dialog = this._dialog.open(DialogChooseNew, {
            height: '300px',
            width: '250px',
            autoFocus: false,
        });
        let res = await dialog.afterClosed().toPromise();
        if (res) {
            if (res === 'action') {
                return this.newAction(currentUser, actionTypes, linesTree, iomsDevices, newAction);
            } else {
                return this.newSeries(currentUser, actionTypes, linesTree, iomsDevices, newAction);
            }
        }
        return false;
    }

    public async createNew2(
        viewType: string,
        workInstructionsInfo: any[],
        currentUser: any,
        actionTypes: IomsActionType[],
        linesTree: deviceTreeI[],
        iomsDevices: IomsDevice[],
        newAction: {
            start: number;
            end: number;
            lineId: string; //"Line 1";
            substationId: string;
        } = <any>{}
    ): Promise<boolean> {
        let dialog = this._dialog.open(DialogScheduleEvent, {
            height: '700px',
            width: '900px',
            autoFocus: false,
            data: {
                user: currentUser,
                clickedDate: new Date(newAction.start),
                view: viewType,
                actionTypes: actionTypes,
                allDevices: iomsDevices,
                workInstructionsInfo: workInstructionsInfo,
                newAction: newAction,
            },
        });

        let dialogData = await dialog.afterClosed().toPromise();

        return this.saveAction(dialogData);
    }

    private async saveAction(data: any): Promise<boolean> {
        return await this._saveNewActionService2.saveAction(data);
    }

    private async newAction(
        currentUser: any,
        actionTypes: IomsActionType[],
        linesTree: deviceTreeI[],
        iomsDevices: IomsDevice[],
        newAction: {
            start: number;
            end: number;
            lineId: string; //"Line 1";
            substationId: string;
        } = <any>{}
    ): Promise<boolean> {
        const now = new Date().getTime();
        return await this._scheduleActionService.scheduleAction(
            {
                mongoId: undefined,
                userId: currentUser.id,
                actionReportId: '',
                seriesActionNumber: Number(now.toString()),
                actionNumber: now.toString(),
                creationDateMills: now,
                start: newAction.start,
                end: newAction.end,
                duration: '0',
                actionType: undefined,
                deviceLevelId: newAction.substationId, // substationId contains line_substation
                description: '',
                reportId: '',
                location: '',
                equipmentIds: [],
                workInstructionsIds: [],
                assigned: false,
                status: '',
                selectedEquipmentIds: [],
                selectedWorkInstructionsIds: [],
            },
            currentUser,
            actionTypes,
            linesTree,
            iomsDevices
        );
    }
    private async newSeries(
        currentUser: any,
        actionTypes: IomsActionType[],
        linesTree: deviceTreeI[],
        iomsDevices: IomsDevice[],
        newAction: {
            start: number;
            end: number;
            lineId: string; //"Line 1";
            substationId: string;
        } = <any>{}
    ): Promise<boolean> {
        const now = new Date().getTime();
        return await this._scheduleSeriesService.scheduleSeries(
            {
                mongoId: undefined,
                userId: currentUser.id,
                actionReportId: '',
                seriesActionNumber: Number(now.toString()),
                actionNumber: now.toString(),
                creationDateMills: now,
                start: newAction.start,
                end: newAction.end,
                duration: '0',
                actionType: undefined,
                deviceLevelId: newAction.substationId, // substationId contains line_substation
                description: '',
                reportId: '',
                location: '',
                equipmentIds: [],
                workInstructionsIds: [],
                assigned: false,
                status: '',
                selectedEquipmentIds: [],
                selectedWorkInstructionsIds: [],
            },
            currentUser,
            actionTypes,
            linesTree,
            iomsDevices
        );
    }
}

@Component({
    selector: 'choose-new-action-dialog',
    template: `
        <h3 mat-dialog-title>Create new...</h3>
        <mat-divider></mat-divider>
        <mat-dialog-content>
            <div layout="column" layout-margin>
                <button mat-button (click)="newAction()" layout-margin>New Action</button>
                <button mat-button (click)="newSeries()" layout-margin>New Series</button>
            </div>
            <mat-divider></mat-divider>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    `,
})
export class DialogChooseNew implements OnInit {
    constructor(public dialogRef: MatDialogRef<any>) {}

    ngOnInit(): void {}
    newAction() {
        this.dialogRef.close('action');
    }
    newSeries() {
        this.dialogRef.close('series');
    }
}
