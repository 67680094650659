import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TENANT } from '../config/api.config';
import { EvaPlusHttpService } from './http/eva-http.service';
import { ICriteriaDataItem } from './signal.service';

export interface ISignalListData {
    signalType: String;
    signalName: String;
    originName: String;
    memoryType: String;
    signalTranslation: String;
}

const baseUrl = '/EvaPlusRest/refSignals/';

@Injectable()
export class SignalListService {
    constructor(private http: EvaPlusHttpService) {}

    getVehicleIds(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'getVehicleIds?tenant=' + TENANT);
    }

    getSignalListPerVehicle(vehicleId: string): Observable<ISignalListData[]> {
        return this.http.get(
            baseUrl + 'getSignalListPerVehicle?tenant=' + TENANT + '&vehicleId=' + vehicleId
        );
    }
}
