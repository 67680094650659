import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaPlusHttpService } from './http/eva-http.service';
import { ICriteriaDataItem } from './signal.service';

export interface IFleetLocation {
    vehicle_id: string;
    latitude: string;
    longitude: string;
    speed: number;
    locationtime: number;
    delay: number;
    rotation: number;
    gpsAccuracy: number;
    composition: string;
    serialNumber: string;
    vehicleState: string;
    eventTimestamp: number;
}

export interface IInfrastructureElement {
    id: string;
    name: string;
    type: string;
    lat: number;
    lon: number;
    radius: number;
    validTill: Date;
    eventGen: boolean;
    typeicon?: string;
    zoomLevel?: number;
}

export interface IInfrastructureType {
    id: string;
    type: string;
    icon: string;
    changeable: boolean;
    zoomLimit: string;
    maxSpeed: string;
}

export class PoiLayerSettings {
    poiId: string = '';
    poiType: string = '';
    shown: boolean = true;
}

export class MapLayerSettings {
    poiLayerSettings: PoiLayerSettings[] = [];
    vehicleLayerShown: boolean = true;
}

export interface IInfrastructureElementInDb {
    existsInDb: boolean;
}

export class EventState {
    eventState: string = '';
    events: string[] = [];
}

export class Timeout {
    status: string;
    value: string;
    unit: string;
}

export class VehicleTypes {
    vehicleId: string;
    type: string;
}

export class LastSeenSignalForFleetLocation {
    signal: string;
}

export class LastSeenEventForFleetLocation {
    event: string;
}

export class LastSeenSignalData {
    vehicleId: string;
    signalId: string;
    signalValue: string;
    signalType: string;
    timestamp: number;
}

export class LastSeenEventData {
    vehicleId: string;
    eventType: string;
    eventState: string;
    time: number;
    updatedAgo: string;
}

export class VehicleState {
    vehicleId: string;
    state: string;
    timestamp: number;
}

const baseUrl = '/EvaPlusRest/fleetlocationservice/';

@Injectable({ providedIn: 'root' })
export class FleetManagementService {
    selectedVehicle: BehaviorSubject<string>;

    sideNavButtonClicked: BehaviorSubject<number>;

    constructor(private http: EvaPlusHttpService) {
        this.selectedVehicle = new BehaviorSubject('');
        this.sideNavButtonClicked = new BehaviorSubject(0);
    }

    getFleetLocation(): Observable<any> {
        return this.http.get(baseUrl + 'fleetlocation');
    }

    getFleetLocationMicro(): Observable<any> {
        return this.http.get(baseUrl + 'fleetlocationmicro');
    }

    saveEventStates(eventState: string, events: string[]): Observable<any> {
        let content = JSON.stringify({ eventState: eventState, events: events });
        return this.http.post(baseUrl + 'saveEventStates', content);
    }

    getEventStates(): Observable<EventState[]> {
        return this.http.get(baseUrl + 'getEventStates');
    }

    getTimeouts(): Observable<Timeout[]> {
        return this.http.get(baseUrl + 'getTimeouts');
    }

    saveTimeouts(status: string, value: string, unit: string): Observable<any> {
        let content = JSON.stringify({ status: status, value: value, unit: unit });
        return this.http.post(baseUrl + 'saveTimeouts', content);
    }

    // saveSettingsSrv(payload1: string): Observable<any> {
    //     let uriString = baseUrl + '/EvaPlusRest/fleetlocationservice/savesettings';
    //     // let payload = JSON.stringify(payload1);
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     return this.http.post(uriString, payload1)
    //         .map((response: Response) => {
    //             return response.json();
    //         });
    // }

    saveMapViewSrv(payload1: string): Observable<any> {
        return this.http.post(baseUrl + 'savemapview', payload1);
    }

    loadSettingsSrv(): Observable<any> {
        return this.http.get(baseUrl + 'loadsettings');
    }

    getAdditionalInfo(vehicleId: string): Observable<any> {
        return this.http.get(baseUrl + 'getAdditionalInfo', { params: { vehicleId } });
    }

    getDefaultMapViewSrv(user: string): Observable<any> {
        return this.http.get(baseUrl + 'getDefaultMapView/' + user);
    }

    getNotificationsSrv(): Observable<any> {
        let uriString = baseUrl + '/EvaPlusRest/fleetlocationservice/notifications';
        return this.http.get(uriString);
    }

    setNotificationAsSeenSrv(payload: string): Observable<any> {
        return this.http.post(baseUrl + 'setasseen', payload);
    }

    getNotificationForVehicleSrv(vehicleId: string, timeRange: string, currentTime: string): Observable<any> {
        let payload = JSON.stringify({
            vehicleid: vehicleId,
            timerange: timeRange,
            currenttime: currentTime,
        });
        return this.http.post(baseUrl + 'getNotificationForVehicle', payload);
    }

    notificationAddCommentSrv(id: string, comment: string): Observable<any> {
        let payload = JSON.stringify({ id: id, comment: comment });
        return this.http.post(baseUrl + 'addNotificationComment', payload);
    }

    showVehicleService(vehicleId: string) {
        this.selectedVehicle.next(vehicleId);
    }

    sideNavButtonClickedSrv(change: number) {
        this.sideNavButtonClicked.next(change);
    }

    // infrastructure elements services
    // list of infrastructure elements
    loadInfrastructureElementsSrv(): Observable<IInfrastructureElement[]> {
        return this.http.get(baseUrl + 'infrastructureElementList');
    }

    // list of infrastructure element types
    loadInfrastructureElementTypeList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseUrl + 'infrastructureElementTypesList');
    }

    // is infrastructure element in db
    isInfrastructureElementInDb(id: string): Observable<IInfrastructureElementInDb> {
        return this.http.get(baseUrl + 'infrastructureElement/existsInDb/' + id);
    }

    // adding infrastructure element
    addInfrastructureElement(
        name: string,
        type: string,
        lat: number,
        lon: number,
        radius: number,
        validTill: number,
        eventGen: boolean
    ): Observable<any> {
        let payload = JSON.stringify({
            name: name,
            type: type,
            lat: lat,
            lon: lon,
            radius: radius,
            validTill: validTill,
            eventGen: eventGen,
        });
        return this.http.post(baseUrl + 'infrastructureElement/addinfra', payload);
    }

    // is infrastructure element in db
    isInfrastructureTypeInDb(type: string): Observable<any> {
        return this.http.get(baseUrl + 'infrastructureType/existsInDb/' + type);
    }

    // load all POI types
    loadInfrastructureTypesSrv(): Observable<IInfrastructureType[]> {
        return this.http.get(baseUrl + 'infrastructureTypeList');
    }

    // add POI type
    addInfrastructureType(type: string, icon: string, zoomLimit: string, maxSpeed: string): Observable<any> {
        let payload = JSON.stringify({
            type: type,
            icon: icon,
            zoomLimit: zoomLimit,
            maxSpeed: maxSpeed,
        });
        return this.http.post(baseUrl + 'infrastructureElement/addInfraType', payload);
    }

    // edit POI type
    editInfrastructureType(
        type: string,
        icon: string,
        id: string,
        zoomLimit: string,
        maxSpeed: string
    ): Observable<any> {
        let payload = JSON.stringify({
            type: type,
            icon: icon,
            id: id,
            zoomLimit: zoomLimit,
            maxSpeed: maxSpeed,
        });
        return this.http.post(baseUrl + 'infrastructureElement/editInfraType', payload);
    }

    //delete POI type
    deleteInfrastructureType(poiType: IInfrastructureType): Observable<any> {
        let payload = JSON.stringify({ id: poiType.id, type: poiType.type, icon: poiType.icon });
        return this.http.post(baseUrl + 'infrastructureType/delete', payload);
    }

    //editing infrastrucure element
    editInfrastructureElement(
        id: string,
        name: string,
        type: string,
        lat: number,
        lon: number,
        radius: number,
        validTill: number,
        eventGen: boolean
    ): Observable<any> {
        let payload = JSON.stringify({
            id: id,
            name: name,
            type: type,
            lat: lat,
            lon: lon,
            radius: radius,
            validTill: validTill,
            eventGen: eventGen,
        });
        return this.http.post(baseUrl + 'infrastructureElement/editinfra', payload);
    }

    //delete poi elements
    deleteInfrastructureElement(ids: string[]): Observable<any> {
        let payload = JSON.stringify({ ids: ids });
        return this.http.post(baseUrl + 'infrastructureElement/delete', payload);
    }

    // download file with infrastructure elements
    downloadFile(ids: string[]): Observable<any> {
        let payload = JSON.stringify({ ids: ids });
        return this.http.post(baseUrl + 'downloadInfrastructureElements', payload);
    }

    // // infrastructure file upload
    // infrastructureFileUploadSrv(content: String): Observable<any> {
    //     let uriString = baseUrl + '/EvaPlusRest/fleetlocationservice/infraupload';
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'text/plain');
    //     return this.http.post(uriString, content)
    //         .map((res: Response) => {
    //             return res.json();
    //         });
    // }

    // infrastructure elements file upload
    infrastructureFileUploadSrv(content: String | ArrayBuffer | null): Observable<any> {
        return this.http.post(baseUrl + 'infELUploadCheckDuplicates', content);
    }

    // infrastructure elements update using file upload
    updateInfrastructureElements(content: String | ArrayBuffer | null): Observable<any> {
        return this.http.post(baseUrl + 'updateInrastructureElements', content);
    }

    // save speed threshold
    saveSpeedThresholdSrv(speedThreshold: number): Observable<any> {
        let payload = JSON.stringify({ speed_threshold: speedThreshold });
        return this.http.post(baseUrl + 'saveSpeedThreshold', payload);
    }

    // get speed threshold
    getSpeedThresholdSrv(): Observable<any> {
        return this.http.get(baseUrl + 'getSpeedThreshold');
    }

    // save POI layer settings
    saveMapLayersSettingsSrv(
        user: string,
        poiLayerSettings: PoiLayerSettings[],
        vehicleLayerShown: boolean
    ): Observable<any> {
        let payload = JSON.stringify({
            user: user,
            poiLayerSettings: poiLayerSettings,
            vehicleLayerShown: vehicleLayerShown,
        });
        return this.http.post(baseUrl + 'saveLayersSettings', payload);
    }

    // load POI layer settings
    // loadLayerPoiSrv(user: string): Observable<PoiLayerSettings[]> {
    loadMapLayersSettingsSrv(user: string): Observable<MapLayerSettings> {
        return this.http.get(baseUrl + 'loadLayersSettings/' + user);
    }

    getVehicleDashboardSignals(vehicle: any): Observable<any> {
        return this.http.get(baseUrl + 'getVehicleDashboardSignals', { params: { vehicle } });
    }

    getVehicleDashboardEvents(vehicle: any): Observable<any> {
        return this.http.get(baseUrl + 'getVehicleDashboardEvents', { params: { vehicle } });
    }

    getAdditionalSignalsForEvents(id: any): Observable<any> {
        return this.http.get(baseUrl + 'getAdditionalSignalsForEvents', { params: { id } });
    }

    getVehicleDashboardGroupEvents(vehicle: any, from: string, to: string): Observable<any> {
        return this.http.get(baseUrl + 'getVehicleDashboardGroupEvents', { params: { from, to } });
    }

    getFleetMagementOverview(): Observable<any> {
        return this.http.get(baseUrl + 'fleetMagementOverview');
    }

    getFleetMagementSettings(): Observable<any> {
        return this.http.get(baseUrl + 'fleetMagementSettings');
    }

    saveFleetMagementSettings(data: any): Observable<any> {
        let payload = JSON.stringify({ nodes: data });
        return this.http.post(baseUrl + 'fleetMagementSaveSettings', payload);
    }

    findFleetForVehicleSrv(vehicle: string): Observable<any> {
        return this.http.get(baseUrl + 'findFleetForVehicle', { params: { vehicle } });
    }

    callPredictionService(vehicle: any, from: string, to: string, daysToPredict: number): Observable<any> {
        let uriString =
            baseUrl + 'getPredictions?vehicle=' + vehicle + '&from=' + from + '&to=' + to + '&pdays=' + daysToPredict;
        let payload = JSON.stringify({ nodes: '' });
        return this.http.post(uriString, payload);
    }

    getDistanceUnitService(vehicle: any): Observable<any> {
        let uriString = baseUrl + '/EvaPlusRest/fleetlocationservice/getDistanceUnit?vehicle=' + vehicle;
        return this.http.get(uriString);
    }

    getVehiclesTypesSrv(): Observable<VehicleTypes[]> {
        return this.http.get(baseUrl + 'getVehiclesTypes');
    }

    // last seen signals and events...
    getListLastSeenSignalsSrv(): Observable<LastSeenSignalForFleetLocation[]> {
        return this.http.get(baseUrl + 'listLastSeenSignals');
    }

    getListLastSeenEventsSrv(): Observable<LastSeenEventForFleetLocation[]> {
        return this.http.get(baseUrl + 'listLastSeenEvents');
    }

    saveRequiredLiveSignalsSrv(user: string, requiredSignals: string[]): Observable<any> {
        let payload = JSON.stringify({ user: user, requiredSignals: requiredSignals });
        return this.http.post(baseUrl + 'saveRequiredSignals', payload);
    }

    getRequiredLiveSignalsSrv(user: string): Observable<string[]> {
        return this.http.get(`${baseUrl}getRequiredSignals/${user}`);
    }

    saveRequiredLiveEventsSrv(user: string, requiredEvents: string[]): Observable<any> {
        let payload = JSON.stringify({ user: user, requiredEvents: requiredEvents });
        return this.http.post(baseUrl + 'saveRequiredEvents', payload);
    }

    getRequiredLiveEventsSrv(user: string): Observable<string[]> {
        return this.http.get(baseUrl + 'getRequiredEvents/' + user);
    }

    getLatestMqttSignals(vehicleId: string): Observable<LastSeenSignalData[]> {
        return this.http.get(baseUrl + 'getLatestMqttSignals', { params: { vehicle: vehicleId } });
    }

    getLatestEvents(vehicleId: string): Observable<LastSeenEventData[]> {
        return this.http.get(baseUrl + 'getLatestEvents', { params: { vehicle: vehicleId } });
    }

    // ...last seen signals and events

    setVehicleStateToOKSrv(vehicleId: string): Observable<any> {
        return this.http.get(baseUrl + 'setStateOK', { params: { vehicle: vehicleId } });
    }

    vehicleLookupTableUpload(file: File): Observable<any> {
        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http
            .post('/EvaPlusRest/fleetManagementServiceSettings/fleetLookupTable', formData, {
                disableAutoContentTypeHader: true,
            })
            .pipe(
                map((res: Response) => {
                    return res;
                })
            );
    }

    vehicleLookupTableDownload(): any {
        let uriString = '/EvaPlusRest/fleetManagementServiceSettings/fleetLookupTableDownload';
        return this.http.get(uriString, { responseType: 'blob' });
    }

    getAllFleetsVehiclesAndMemories(): Observable<any> {
        return this.http.get(baseUrl + 'getAllFleetsVehiclesAndMemories');
    }

    getAllVehicles(): Observable<any> {
        return this.http.get(baseUrl + 'getAllVehicles');
    }
}

export interface ISettingsSavedOK {
    savingSettingsOK: boolean;
}

export interface ISettingsLoadedOK {
    loadingSettingsOK: boolean;
}
