import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    SetAuthorizationToken,
    SetCurrentUser,
} from '../app/store/signal-analytics/action/eva-plus-user.actions';
import { EvaPlusUser } from '../app/store/signal-analytics/reducer/eva-plus-user.state';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/auth/';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: EvaPlusHttpService, private store: Store) {
        let token: string = this.getToken();

        if (token) {
            this.store.dispatch(new SetAuthorizationToken(token));
        }

        let currentUserString: string = this.getUser();

        if (currentUserString) {
            let currentUser: EvaPlusUser = JSON.parse(currentUserString);
            this.store.dispatch(new SetCurrentUser(currentUser));
        }
    }

    getToken() {
        return localStorage.getItem('authToken') || '';
    }

    getUser() {
        return localStorage.getItem('currentUser') || '';
    }

    checkToken(token: string): Observable<boolean> {
        let payload = JSON.stringify({ token: token });

        return this.http.post(baseUrl + 'checkToken', payload).pipe(
            map((response: any) => {
                return response.tokenValid;
            })
        );
    }

    login(username: string, password: string): Observable<any> {
        let payload = JSON.stringify({ username: username, password: password });

        return this.http.post(baseUrl + 'login', payload).pipe(
            map((response: any) => {
                // login successful if there's a jwt token in the response
                if (response == null) {
                    return { result: false, issue: true };
                }

                let id = response && response.id;
                let name = response && response.name;
                let email = response && response.email;

                if (email == '403') {
                    return { result: false, mustUpdatePwFlag: true };
                }

                let daysLeft = -99999;

                if (email.includes('#')) {
                    daysLeft = email.split('#')[2];
                    email = email.split('#')[0];
                }

                let roles = response && response.roles;
                let authToken = response && response.token;
                let lang = response && response.lang;
                let roleActions = response && response.roleActions;

                if (authToken) {
                    this.store.dispatch(new SetAuthorizationToken(authToken));

                    let currentUser: EvaPlusUser = {
                        id: id,
                        name: name,
                        email: email,
                        roles: roles,
                        actions: roleActions,
                        lang: lang,
                        survey_done: response.survey_done ? response.survey_done : false,
                    };

                    localStorage.setItem('authToken', authToken);
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    this.store.dispatch(new SetCurrentUser(currentUser));

                    // for dislplaying browser info
                    localStorage.setItem('displayMessage', 'true');

                    this.setCookie('language', lang, 100);

                    if (daysLeft != -99999) {
                        return { result: true, shouldUpdatePwFlag: true, daysLeft: daysLeft };
                    } else {
                        return { result: true };
                    }
                } else {
                    if (email == '423') {
                        return { result: false, locked: true };
                    } else if (email == '424') {
                        return { result: false, adminLock: true };
                    } else {
                        return { result: false, wrong: true };
                    }
                }
                // return true;
            })
        );
    }

    setPassword(newpassword: string, token: string): Observable<boolean> {
        let payload = JSON.stringify({ newpassword: newpassword, token: token });

        return this.http.post(baseUrl + 'setPassword', payload).pipe(
            map((response: responseChangePassword) => {
                let msg = response.msg;
                if (msg == 'Password successfully changed.') {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    resetPassword(email: string, isNewUser: boolean): Observable<any> {
        let payload = JSON.stringify({ email: email, isNewUser: isNewUser });

        return this.http.post(baseUrl + 'passwordReset', payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    changepassword(oldpassword: string, newpassword: string): Observable<boolean> {
        let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        let email = currentUser && currentUser.email;
        let username = currentUser && currentUser.name;
        let payload = JSON.stringify({
            email: email,
            username: username,
            oldpassword: oldpassword,
            newpassword: newpassword,
        });
        const authHeader = this.getToken();
        /* let httpHeaders = new HttpHeaders({
             'Content-Type': 'application/json',
             'Authorization': authHeader
         });*/
        let options = {
            headers: {
                'Authorization': authHeader,
            },
        };
        return this.http.post(baseUrl + 'changepass', payload, options).pipe(
            map((response: responseChangePassword) => {
                let msg = response.msg;
                if (msg == 'Password successfully changed.') {
                    localStorage.removeItem('updatePwMsg');
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    getVersion(): Observable<any> {
        return this.http.get('/EvaPlusRest/configuration/getVersion');
    }

    checkActionPermission(action: string): boolean {
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let roleActions = currentUser && currentUser.actions;

        if (roleActions.indexOf(action) != -1 || roleActions.indexOf('ALL') != -1) {
            return true;
        } else {
            return false;
        }
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        localStorage.removeItem('updatePwMsg');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('fileUpload');
        localStorage.removeItem('authToken');
        localStorage.removeItem('currentUser');
        this.store.dispatch(new SetCurrentUser(null));
    }

    setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires: string = 'expires=' + d.toUTCString();
        document.cookie =
            name + '=' + value + '; ' + expires + (path.length > 0 ? '; path=' + path : '');
    }
}

export interface responseChangePassword {
    msg: string;
    email: string;
}
