import { NgModule } from '@angular/core';
import { HrVehicleFleetComponent } from './hr-vehicle-fleet.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [HrVehicleFleetComponent],
    exports: [HrVehicleFleetComponent],
    imports: [
        MatIconModule,
        CommonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        TreeModule,
        MatButtonModule,
        TranslateModule,
    ],
})
export class HrVehicleFleetModule {}
