import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TENANT } from '../config/api.config';
import { EvaPlusHttpService } from './http/eva-http.service';
import { IChartDataComplete, ICriteriaData, ILastTs, ISignalsChanged } from './signal.service';

const baseUrl = '/EvaPlusRest/etcsanalytics/';
const baseTemplateUrl = '/EvaPlusRest/templates/';
const baseSearchUrl = '/EvaPlusRest/search/';

export class EtcsVehiclesResult {
    vehicles: string[] = [];
}

export class EtcsAllMessagesForVehicle {
    messages: EtcsMessage[];
}

export class SignalTypes {
    types: string[] = [];
}

export class EtcsTimeRange {
    vehicle: string;
    mini: string;
    maxi: string;
}

export class EtcsMessage {
    message: string;
    count: string;
}

@Injectable({ providedIn: 'root' })
export class SignalEtcsService {
    constructor(private http: EvaPlusHttpService) {}

    getPrefetchDataParam(): Observable<any> {
        return this.http.get(baseUrl + 'getPrefetchDataParam', {
            params: {
                tenant: TENANT,
            },
        });
    }

    getSignalsCount: number = 0;

    signalsQueryFull(
        fleetId: string,
        vehicleId: string,
        memoryType: string,
        timefrom: string,
        timeto: string,
        signalsAnalog: string[],
        signalsDigital: string[],
        signalsOther: string[],
        signalsStanding: string[],
        signalsEtcs: string[],
        signalsEvent: string[],
        etcsType: string
    ): Observable<IChartDataComplete> {
        this.getSignalsCount++;

        var uriString =
            'getSignalsQueryFull?tenant=' +
            TENANT +
            '&fleetId=' +
            encodeURIComponent(fleetId) +
            '&vehicleId=' +
            encodeURIComponent(vehicleId) +
            '&memoryType=' +
            memoryType +
            '&from=' +
            timefrom +
            '&to=' +
            timeto;
        signalsAnalog.forEach((element) => {
            uriString += '&signalsAnalog=' + element;
        });
        signalsDigital.forEach((element) => {
            uriString += '&signalsDigital=' + element;
        });
        signalsOther.forEach((element) => {
            uriString += '&signalsOther=' + element;
        });
        signalsStanding.forEach((element) => {
            uriString += '&signalsStanding=' + element;
        });
        if (signalsEtcs) {
            signalsEtcs.forEach((element) => {
                uriString += '&signalsEtcs=' + element;
            });
        }
        signalsEvent.forEach((element) => {
            uriString += '&signalsEvent=' + element;
        });
        uriString += '&etcsType=' + etcsType;

        return this.http.get(baseUrl + uriString);
    }

    signalsChanged(vehicleId: string, timeFrom: string, timeTo: string): Observable<ISignalsChanged> {
        return this.http.get(baseUrl + 'getSignalsChanged', {
            params: {
                tenant: TENANT,
                vehicleId,
                timeFrom,
                timeTo,
            },
        });
    }

    signalsPreview(
        fleetId: string,
        vehicleId: string,
        timeFrom: string,
        timeTo: string
    ): Observable<IChartDataComplete> {
        return this.http.get(baseUrl + 'getSignalsPreview', {
            params: {
                tenant: TENANT,
                fleetId,
                vehicleId,
                from: timeFrom,
                to: timeTo,
            },
        });
    }

    lastDateForVehicle(vehicleId: string): Observable<ILastTs> {
        return this.http.get(baseUrl + 'getLastDateForVehicle', {
            params: { tenant: TENANT, vehicleId },
        });
    }

    criteriaQuery(vehicleId: string): Observable<ICriteriaData> {
        return this.http.get(baseUrl + 'getSignalsCriteria', {
            params: { tenant: TENANT, vehicleId },
        });
    }

    getDistinctSignalsForFleet(fleet: string, useSystemSignalSettings: boolean): Observable<ICriteriaData> {
        return this.http.get(baseUrl + 'getDistinctSignalsForFleet', {
            params: { tenant: TENANT, fleet: encodeURIComponent(fleet), useSystemSignalSettings },
        });
    }

    getVehicleList(): Observable<string[]> {
        return this.http.get(baseUrl + 'getVehicleList', {
            params: { tenant: TENANT },
        });
    }

    getEtcsMEssage(fleetId: string, vehicleId: string, recordId: number, time: string): Observable<ICriteriaData> {
        return this.http.get(baseUrl + 'getEtcsMessage', {
            params: {
                tenant: TENANT,
                fleetId,
                vehicleId,
                recordId,
                time,
            },
        });
    }

    getRecordersForVehicle(vehicleId: string): Observable<string[]> {
        return this.http.get(baseUrl + 'getRecordersForVehicle', {
            params: {
                tenant: TENANT,
                vehicleId,
            },
        });
    }

    getRecordersForVehicleSA(vehicleId: string): Observable<string[]> {
        return this.http.get(baseUrl + 'getRecordersForVehicleSA', {
            params: { tenant: TENANT, vehicleId },
        });
    }

    getDefaultTemplate(fleet: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'default', { params: { fleet } });
    }

    getTemplate(name: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'single/' + name);
    }

    // For ETCS search...
    sendEtcsQuerySrv(
        vehicleid: string,
        signaltype: string,
        etcsmessages: string[],
        timefrom: Date,
        timeto: Date,
        query: Object
    ): Observable<any> {
        let content = {
            vehicleid: vehicleid,
            signaltype: signaltype,
            etcsmessages: etcsmessages,
            timefrom: timefrom,
            timeto: timeto,
            query: query,
        };
        return this.http.post(baseSearchUrl + 'searchETCS', content);
    }

    getVehiclesWithEtcsSrv(): Observable<EtcsVehiclesResult> {
        return this.http.get(baseSearchUrl + 'getETCSvehicles');
    }

    getVehiclesSrv(): Observable<EtcsVehiclesResult> {
        return this.http.get(baseSearchUrl + 'getVehicles');
    }

    getSignalTypesSrv(): Observable<SignalTypes> {
        return this.http.get(baseSearchUrl + 'getSignalTypes');
    }

    getEtcsMessagesSrv(vehicleid: string, signaltype: string): Observable<EtcsAllMessagesForVehicle> {
        return this.http.get(baseSearchUrl + 'getETCSmessages', {
            params: {
                vehicle: vehicleid,
                signal_type: signaltype,
            },
        });
    }

    getEtcsVehicleTimeRangeSrv(vehicleId: string, signaltype: string): Observable<EtcsTimeRange> {
        return this.http.get(baseSearchUrl + 'getSignalsTimeRange', {
            params: {
                vehicle: vehicleId,
                signal_type: signaltype,
            },
        });
    }

    // http://localhost:8080/EvaPlusRest/search/getSignalsTree?vehicle=2412&signal_type=ETCS&messageIdList=12_13_23_34_45
    getEtcsSearchSignalsTreeSrv(vehicleId: string, signalType: string, messagesList: string) {
        return this.http.get(baseSearchUrl + 'getSignalsTree', {
            params: {
                vehicle: vehicleId,
                messageIdList: messagesList,
                signal_type: signalType,
            },
        });
    }

    // http://localhost:8080/EvaPlusRest/search/getSignalsTree?vehicle=2412&signal_type=ETCS&messageIdList=12_13_23_34_45
    getEtcsSearchSignalsTreeSrvNew(vehicleId: string, signaltype: string, messagesList: string) {
        return this.http.get('/SearchEngineServices/webapi/grid/getSignalsTree', {
            params: {
                vehicle: vehicleId,
                messageIdList: messagesList,
                signal_type: signaltype,
            },
        });
    }

    getGumSignals() {
        return this.http.get(baseSearchUrl + 'gumSignals');
    }

    getSearchButtonProperties() {
        return this.http.get(baseSearchUrl + 'getButtonsProperties');
    }

    etcsGetSearchResultDataSrv(searchid: string, iteration: number) {
        return this.http.get(baseSearchUrl + 'getEtcsResultData', {
            params: { searchid, iteration },
        });
    }

    getSignalsProperty(signals: string) {
        return this.http.get(baseSearchUrl + 'getSignalsProperty', { params: { signals } });
    }

    saveQueryToTemplateSrv(
        user: string,
        update: boolean,
        tempName: string,
        vehicleId: string,
        signalType: string,
        selectedSignals: string[],
        dateFrom: Date,
        dateTo: Date,
        query: string[]
    ) {
        let payload = JSON.stringify({
            user: user,
            update: update,
            name: tempName,
            vehicle_id: vehicleId,
            signal_type: signalType,
            date_from: dateFrom.getTime(),
            date_to: dateTo.getTime(),
            selected_signals: selectedSignals,
            query: query,
        });
        return this.http.post(baseSearchUrl + 'saveQueryTemplate', payload);
    }

    // http://localhost:8080/EvaPlusRest/search/getTemplate?name=temp
    etcsLoadQueryTemplateSrv(name: string, user: string) {
        return this.http.get(baseSearchUrl + 'getTemplate', { params: { name, user } });
    }

    // http://localhost:8080/EvaPlusRest/search/deleteQueryTemplate
    etcsDeleteQueryTemplateSrv(name: string, user: string) {
        return this.http.get(baseSearchUrl + 'deleteQueryTemplate', { params: { name, user } });
    }

    // http://localhost:8080/EvaPlusRest/search/getTemplateList
    etcsLoadQueryTemplateListSrv(user: string) {
        return this.http.get(baseSearchUrl + 'getTemplateList', { params: { user } });
    }

    isQueryTemplateInDb(name: string): Observable<any> {
        return this.http.get(baseSearchUrl + 'queryTemplateExistsInDb', { params: { name } });
    }

    // NEW SEARCH MODULE

    saveQueryToTemplateSrvNew(
        user: string,
        update: boolean,
        tempName: string,
        vehicleId: string,
        signalType: string,
        selectedSignals: string[],
        dateFrom: Date,
        dateTo: Date,
        query: string[],
        additionalsignals: string[],
        additionalvehicles: string[],
        openTab: number,
        pivotConfig: any,
        absOrRel: string,
        timeFromRelative: string,
        timeToRelative: string,
        mapParamInstance: mapParams
    ) {
        let payload = JSON.stringify({
            user: user,
            update: update,
            name: tempName,
            vehicle_id: vehicleId,
            signal_type: signalType,
            date_from: dateFrom.getTime(),
            date_to: dateTo.getTime(),
            selected_signals: selectedSignals,
            query: query,
            additionalsignals: additionalsignals,
            additionalvehicles: additionalvehicles,
            openTab: openTab,
            pivotConfig: pivotConfig,
            absOrRel: absOrRel,
            timeFromRelative: timeFromRelative,
            timeToRelative: timeToRelative,
            mapParams: mapParamInstance,
        });
        return this.http.post(baseSearchUrl + 'saveQueryTemplateNew', payload);
    }

    // http://localhost:8080/EvaPlusRest/search/getTemplateNew?name=temp
    etcsLoadQueryTemplateSrvNew(name: string, user: string) {
        return this.http.get(baseSearchUrl + 'getTemplateNew', { params: { name, user } });
    }

    // http://localhost:8080/EvaPlusRest/search/deleteQueryTemplateNew
    etcsDeleteQueryTemplateSrvNew(name: string, user: string) {
        return this.http.get(baseSearchUrl + 'deleteQueryTemplateNew', { params: { name, user } });
    }

    // http://localhost:8080/EvaPlusRest/search/getTemplateListNew
    etcsLoadQueryTemplateListSrvNew(user: string) {
        return this.http.get(baseSearchUrl + 'getTemplateListNew', { params: { user } });
    }

    isQueryTemplateInDbNew(name: string): Observable<any> {
        return this.http.get(baseSearchUrl + 'queryTemplateExistsInDbNew', { params: { name } });
    }

    getVehicleParams(): Observable<any> {
        return this.http.get(baseSearchUrl + 'getVehicleParams');
    }

    getSignal(vehicleId: string, signalName: string): Observable<any> {
        return this.http.get(baseUrl + 'getSignal', {
            params: { vehicleId, signalName },
        });
    }
}

export class mapParams {
    selectedDimensionForLabel: string = '';
    useDimensionForLabel: boolean = false;
    markerTransparencyMapView: string = '0.8';
    markerSizeMapView: number = 50;
    useDimensionForSize: boolean = false;
    selectedDimensionForMarkerSize: string = '';
    labelsOn: boolean = true;
    mapCenterLat: number;
    mapCenterLon: number;
    zoom: number = 10;
    useDimensionForTransparency: boolean = false;
    selectedDimensionForMarkerTransparency: string = '';
    transparencyInvert: boolean = false;
    sizeInvert: boolean = false;
}
