import { SignalTemplateStateModel } from '../reducer/signal-template.state';

export class SetSelectedFleet {
    static readonly type = '[Toolbar] Set Selected Fleet';
    constructor(public fleet: string) {}
}

export class SetTemplateFleet {
    static readonly type = '[Toolbar] Set Template Fleet';
    constructor(public fleet: string) {}
}
export class SetVehicle {
    static readonly type = '[Toolbar] Set Vehicle';
    constructor(public vehicle: string) {}
}

export class SetRecorder {
    static readonly type = '[Toolbar] Set Recorder';
    constructor(public recorder: string) {}
}

export class SetEtcsType {
    static readonly type = '[Toolbar] Set Etcs Type';
    constructor(public etcsType: string) {}
}

export class SetTime {
    static readonly type = '[Toolbar] Set Time';
    constructor(public timeFrom: Date, public timeTo: Date) {}
}

export class SetTimeForTimestamp {
    static readonly type = '[Toolbar] Set Time For Timestamp';
}

export class SetRecordersAndVehicleDistanceUnits {
    static readonly type = '[Toolbar] Set Recorders and Vehicle Distance Units';
    constructor(public recs: string[]) {}
}

export class SetDefaultTemplateName {
    static readonly type = '[Toolbar] Set Default Template Name';
    constructor(public templateName: string) {}
}

export class SetAnotherVehicleSelected {
    static readonly type = '[Toolbar] Set Another Vehicle Selected';
    constructor(public anotherVehicleSelected: boolean) {}
}

export class LoadTemplateData {
    static readonly type = '[Toolbar] Load Template Data';
    constructor(public templateData: any, public isDefault?: boolean) {}
}

export class TemplateDataLoaded {
    static readonly type = '[Toolbar] Template Data Loaded';
}

export class ResetStoreToolbar {
    static readonly type = '[Toolbar] Reset Store Toolbar';
}
