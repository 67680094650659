export class MapIconsPoiTypes {
    public icons: any[] = [
        { icon: 'noIcon' },
        { icon: 'assets/icons/icon_infra01.png' },
        { icon: 'assets/icons/icon_infra02.png' },
        { icon: 'assets/icons/icon_infra03.png' },
        { icon: 'assets/icons/icon_infra04.png' },
        { icon: 'assets/icons/icon_infra05.png' },
        { icon: 'assets/icons/icon_infra06.png' },
        { icon: 'assets/icons/icon_infra07.png' },
    ];
}
