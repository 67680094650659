/**
 * Created by dordevic on 21-Mar-18.
 */
let mapStyleEnanceRailroads: any = [
    {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'poi',
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#202020',
            },
            {
                'visibility': 'on',
            },
        ],
    },
];

export { mapStyleEnanceRailroads };
