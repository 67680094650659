import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { take } from 'rxjs/operators';
import { OauthConfigService } from '../services/oauth-config.service';
import { environment } from './../environments/environment';

@Component({
    selector: 'qs-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        private _iconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer,
        private oauthService: OAuthService,
        private oauthConfigService: OauthConfigService
    ) {
        if (environment.oauthSupportEnabled) {
            this.configureSingleSignOn();
        }
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'teradata',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'github',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/github.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'covalent',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/covalent.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'covalent-mark',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/covalent-mark.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'teradata-ux',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata-ux.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'appcenter',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/appcenter.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'listener',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/listener.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'querygrid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/querygrid.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'haslerrail',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/haslerrail.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'haslerrail-black',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/haslerrail-black.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'refresh',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/refresh.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'signal-analytics-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/signal-analytic-icon.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'haslerrail-pixy',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/haslerrail-logo1.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'info-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info-icon.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'warning-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning-icon.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'announcement-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/announcement-icon.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-train-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/train.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-simulator-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/das-simulator.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-reporting-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/das-reporting.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-graph-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graph.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-stations-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/das-stations-icon.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-train-track-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/train-track.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-timetable-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icons-timetable-clock.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'speed-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/speed-meter.svg')
        );
        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'das-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/das-icon.svg')
        );

        this._iconRegistry.addSvgIconInNamespace(
            'assets',
            'add-icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-icon.svg')
        );

        if (environment.buildFor === 'secheron') {
            this._iconRegistry.addSvgIconInNamespace(
                'assets',
                'app-icon',
                this._domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/haslerrail-secheron-group.svg')
            );
        } else {
            this._iconRegistry.addSvgIconInNamespace(
                'assets',
                'app-icon',
                this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/haslerrail-logo1.svg')
            );
        }
    }

    configureSingleSignOn() {
        this.oauthConfigService
            .getConfig()
            .pipe(take(1))
            .subscribe((authConfig: AuthConfig) => {
                this.oauthService.configure(authConfig);
                this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
                    if (!this.oauthService.hasValidAccessToken()) {
                        this.oauthService.initCodeFlow();
                    } else {
                        this.oauthService.setupAutomaticSilentRefresh();
                    }
                });
            });
    }
}
