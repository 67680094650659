import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

@Injectable({ providedIn: 'root' })
export class ManalUploadService {
    constructor(private httpClient: EvaPlusHttpService) {}

    private cancelPendingRequest$ = new Subject<void>();

    // manual import with track upload progress (multiple files)
    batchFileUploadTrackProgress(files: FileList | File): Observable<any> {
        const formData = new FormData();
        if (files instanceof FileList) {
            for (let i: number = 0; i < files.length; i++) {
                formData.append('file', files[i], files[i].name);
            }
        } else {
            formData.append('file', files, files.name);
        }
        //   return this.http.post(uriString, formData).pipe(
        //     map((res: Response) => {
        //         return res.json();
        //     })
        // );
        // return this.httpNative.post('https://file.io', formData, {reportProgress: true, observe: 'events'});

        return this.httpClient.post('/EvaPlusRest/manualimport/batch', formData, {
            reportProgress: true,
            observe: 'events',
            disableAutoContentTypeHader: true,
            cancelSubject: this.cancelPendingRequest$,
        });
    }

    cancelBatchFileUpload() {
        this.cancelPendingRequest$.next();
        this.cancelPendingRequest$.complete();
    }
}
