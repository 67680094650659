import { Injectable } from '@angular/core';
import * as momentTimezone from 'moment-timezone';
import { IPreviewChartParams } from './signal.service';

@Injectable({
    providedIn: 'root',
})
export class SaUtilsService {
    constructor() {}

    getRecorderSNvalue(value: string): string {
        if (value.indexOf('|') != -1) {
            return value.split(' | ')[0];
        }
        return value;
    }

    adjustDataPrint(columnName: string) {
        if (columnName.includes('[')) {
            return columnName.slice(0, columnName.indexOf('[') - 1);
        } else {
            return columnName;
        }
    }

    getUserName() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var name = currentUser && currentUser.name;
        return name;
    }

    arrDiffContent(arr: string[], prevArr: string[]): boolean {
        if (arr == null || prevArr == null) {
            return true;
        }
        if (arr.length != prevArr.length) {
            return true;
        }
        let res: string[] = arr.filter((el) => !prevArr.includes(el));
        return res != null && res.length != 0;
    }

    arrSingleValueDiff(arr: string[], prevArr: string[]): boolean {
        if (arr == null || prevArr == null) {
            return false;
        }
        if (Math.abs(arr.length - prevArr.length) != 1) {
            return false;
        }
        let longer: string[] = null;
        let shorter: string[] = null;

        if (arr.length > prevArr.length) {
            longer = arr;
            shorter = prevArr;
        } else {
            longer = prevArr;
            shorter = arr;
        }
        let res: string[] = longer.filter((el) => !shorter.includes(el));
        return res != null && res.length == 1;
    }

    formatTimeForShowing(timemilis: number): string {
        var ms: number = Math.abs(Math.floor(timemilis % 1000));
        var minute: number = Math.floor(timemilis / (1000 * 60));
        var secundes: number = Math.floor((timemilis - minute * 1000 * 60) / 1000);
        var minStr: string = '';
        minStr = '' + minute;
        var secStr: string = '';
        if (secundes < 10) {
            secStr = '0' + secundes;
        } else {
            secStr = '' + secundes;
        }
        var msStr: string = '';
        if (ms < 10) {
            msStr = '00' + ms;
        }
        if (ms < 100) {
            msStr = '0' + ms;
        } else {
            msStr = '' + ms;
        }
        return minStr + ':' + secStr + '.' + msStr;
    }

    adjustPreviewTime(timestamp: number, timeZone: string): number {
        var timeNodeConfigTimezone = momentTimezone.tz(timestamp, timeZone);
        var timeNodeConfigTimezoneFormated =
            timeNodeConfigTimezone.format('YYYY-MM-DD hh:mm:ss ZZ');
        var tsNode: number = timeNodeConfigTimezone.valueOf();
        var timeNodeLocalTimezone = momentTimezone(timestamp);
        var timeNodeLocalTimezoneFormated = timeNodeLocalTimezone.format('YYYY-MM-DD hh:mm:ss ZZ');
        var tsNode: number = timeNodeLocalTimezone.valueOf();
        var configOffset = timeNodeConfigTimezone.utcOffset();
        var userOffset = timeNodeLocalTimezone.utcOffset();
        var offsetDiff: number = 0;

        var timestampAdjusted: number = 0;

        // C = config time zone offset from UTC
        // U = user time zone offset from UTC
        if (configOffset < 0 && userOffset < 0) {
            // |--C----U----||------------|
            if (configOffset < userOffset) {
                offsetDiff = Math.abs(configOffset) - Math.abs(userOffset);
                timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
            }
            // |---U---C----||------------|
            if (configOffset > userOffset) {
                offsetDiff = Math.abs(userOffset) - Math.abs(configOffset);
                timestampAdjusted = +timestamp - offsetDiff * 60 * 1000;
            }
        }

        // |-----C------||----U-------|
        if (configOffset < 0 && userOffset > 0) {
            offsetDiff = Math.abs(configOffset) + userOffset;
            timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
        }

        // |------U-----||----C-------|
        if (configOffset > 0 && userOffset < 0) {
            offsetDiff = configOffset + Math.abs(userOffset);
            timestampAdjusted = +timestamp - offsetDiff * 60 * 1000;
        }

        if (configOffset > 0 && userOffset > 0) {
            // |------------||---C----U---|
            if (configOffset < userOffset) {
                offsetDiff = userOffset - configOffset;
                timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
            }
            // |------------||---U----C---|
            if (configOffset > userOffset) {
                offsetDiff = configOffset - userOffset;
                timestampAdjusted = +timestamp - offsetDiff * 60 * 1000;
            }
        }

        // |-----|CU|------|CU|-----|CU|------|
        if (configOffset == userOffset) {
            offsetDiff = 0;
            timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
        }

        // |------------|C|-------U----|
        if (configOffset == 0 && userOffset > 0) {
            offsetDiff = userOffset - configOffset;
            timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
        }

        // |------U-----|C|------------|
        if (configOffset == 0 && userOffset < 0) {
            offsetDiff = configOffset - userOffset;
            timestampAdjusted = +timestamp - offsetDiff * 60 * 1000;
        }

        // |------------|U|------C-----|
        if (configOffset > 0 && userOffset == 0) {
            offsetDiff = configOffset - userOffset;
            timestampAdjusted = +timestamp - offsetDiff * 60 * 1000;
        }

        // |-----C------|U|------------|
        if (configOffset < 0 && userOffset == 0) {
            offsetDiff = Math.abs(configOffset) - Math.abs(userOffset);
            timestampAdjusted = +timestamp + offsetDiff * 60 * 1000;
        }

        return timestampAdjusted;
    }

    getTimestamp(index: number, rows: any[], timeMaper: string): string {
        const timestamp: string = rows[index][timeMaper].substring(
            0,
            rows[index][timeMaper].indexOf('.')
        );
        const splitOne = timestamp.split('-');
        const year = splitOne[0];
        const month = splitOne[1];
        const restOne = splitOne[2];
        const splitTwo = restOne.split(' ');
        const day = splitTwo[0];
        const restTwo = splitTwo[1];
        const splitThree = restTwo.split(':');
        const hour = splitThree[0];
        const minut = splitThree[1];
        return `${year}${month}${day}_${hour}h${minut}m`;
    }

    getDistanceUnitView(sn: string, vehicleDistanceUnits): string {
        var unit =
            vehicleDistanceUnits[sn] != null ? vehicleDistanceUnits[sn].split(' | ')[1] : 'null';
        if (unit != 'null') {
            return unit;
        }
        return 'km';
    }

    calculateCorrectTimeForSharableLink(
        localTimeOffset: number,
        timeZoneParamOffset: number,
        cleanDate: string
    ) {
        if (localTimeOffset < 0) {
            timeZoneParamOffset = timeZoneParamOffset - localTimeOffset;
        } else {
            timeZoneParamOffset = timeZoneParamOffset + localTimeOffset;
        }

        var correctTimeLink;
        if (timeZoneParamOffset < 0) {
            correctTimeLink = momentTimezone(cleanDate)
                .subtract(Math.abs(timeZoneParamOffset), 'minutes')
                .valueOf();
        } else {
            correctTimeLink = momentTimezone(cleanDate)
                .add(Math.abs(timeZoneParamOffset), 'minutes')
                .valueOf();
        }

        return correctTimeLink;
    }

    getPreviewTimeForTimestamp(ts: number, timeZone: string): IPreviewChartParams {
        const userTime = momentTimezone(ts);
        const userTimeWithTimeZone = momentTimezone(ts).tz(timeZone);

        // Get is there a different between userTime and time with timezone
        const isDateDifferent = userTime.diff(userTimeWithTimeZone);

        // If time is equal, only add one day to the timeTo value
        if (isDateDifferent === 0) {
            let timeFromTimestamp: number = ts;
            let timeFrom: Date = new Date(timeFromTimestamp);
            this.resetDateTime(timeFrom);

            let timeToTimestamp: number = timeFromTimestamp + 1000 * 60 * 60 * 24;
            let timeTo: Date = new Date(timeToTimestamp);
            this.resetDateTime(timeTo);

            return { timeFrom: timeFrom.toJSON(), timeTo: timeTo.toJSON() };
        } else {
            let timeFromTimestamp: number = ts;
            const isUserTimeGreater = Math.sign(isDateDifferent);

            if (isUserTimeGreater === 1) {
                timeFromTimestamp = timeFromTimestamp - 1000 * 60 * 60 * 24;
            } else if (isUserTimeGreater === -1) {
                timeFromTimestamp = timeFromTimestamp + 1000 * 60 * 60 * 24;
            }

            const timeFrom: Date = new Date(timeFromTimestamp);
            this.resetDateTime(timeFrom);

            const timeToTimestamp: number = timeFromTimestamp + 1000 * 60 * 60 * 24;
            const timeTo: Date = new Date(timeToTimestamp);
            this.resetDateTime(timeTo);

            return { timeFrom: timeFrom.toJSON(), timeTo: timeTo.toJSON() };
        }
    }

    resetDateTime(dateTime: Date) {
        dateTime.setHours(0);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
    }
}
