import { Injectable } from '@angular/core';
import { RRule } from 'rrule';
import { IomsDeviceManagerService } from '../../../../services/iomsdevicemanger.service';

@Injectable({
    providedIn: 'root',
})
export class CreateIndividualFromSeries {
    //NS*

    constructor(private _iomsDeviceManagementService: IomsDeviceManagerService) {}

    async saveRecurringActionSeriesAsSingleActions(
        userId: string,
        actionNumber: string,
        creationDateMills: string,
        eventStart: number,
        eventEnd: number,
        duration: string,
        rrule: any,
        actionType: string,
        deviceLevelId: string,
        description: string,
        reportId: string,
        location: string,
        selectedEquipmentIds: string[],
        selectedWorkInstructionsIds: string[],
        dateLimit?: number
    ): Promise<boolean> {
        if (dateLimit == undefined) {
            dateLimit = 0;
        }

        var eventsTemp: any[] = [];

        let rruleTemp = rrule;
        let st: Date = new Date(rruleTemp.dtstart);
        let et: Date = new Date(rruleTemp.until);
        rruleTemp.dtstart = st;
        rruleTemp.until = et;

        const rule: RRule = new RRule({ ...rruleTemp });

        var timeStart: Date;
        var timeEnd: Date;

        let ind = 0;

        rule.all().forEach((date) => {
            timeStart = new Date(date.valueOf());
            timeStart.setHours(new Date(eventStart).getHours()); // proveriti ovo *************************************************
            timeStart.setMinutes(new Date(eventStart).getMinutes());

            if (timeStart.getTime() > dateLimit) {
                timeEnd = new Date(date.valueOf());

                switch (duration) {
                    case '2 days':
                        timeEnd.setDate(timeStart.getDate() + 2);
                        break;
                    case '3 days':
                        timeEnd.setDate(timeStart.getDate() + 3);
                        break;
                    case '4 days':
                        timeEnd.setDate(timeStart.getDate() + 4);
                        break;
                    case '5 days':
                        timeEnd.setDate(timeStart.getDate() + 5);
                        break;
                    case '6 days':
                        timeEnd.setDate(timeStart.getDate() + 6);
                        break;
                    case '7 days':
                        timeEnd.setDate(timeStart.getDate() + 7);
                        break;
                    case '10 days':
                        timeEnd.setDate(timeStart.getDate() + 10);
                        break;
                }

                timeEnd.setHours(new Date(eventEnd).getHours());
                timeEnd.setMinutes(new Date(eventEnd).getMinutes());

                // for covering the case, for ex. if start time is set at 23h and duration is 2 hours, then end date
                // should be on 1 in the morning of the next day
                if (timeStart.getTime() > timeEnd.getTime()) {
                    timeEnd.setDate(timeStart.getDate() + 1);
                }

                eventsTemp.push({
                    userId: userId,
                    seriesActionNumber: +actionNumber,
                    actionNumber: actionNumber + '0' + ind,
                    creationDateMills: creationDateMills,
                    start: timeStart.getTime(),
                    end: timeEnd.getTime(),
                    duration: duration,
                    actionType: actionType,
                    deviceLevelId: deviceLevelId,
                    description: description,
                    reportId: reportId,
                    location: location,
                    selectedEquipmentIds: selectedEquipmentIds,
                    selectedWorkInstructionsIds: selectedWorkInstructionsIds,
                });

                ind++;
            }
        });

        try {
            let res3 = await this._iomsDeviceManagementService
                .saveIndividualScheduleEvents(eventsTemp)
                .toPromise();
            return true;
        } catch (e) {
            return false;
        }
    }
}
