// *** reccuring events...
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { DatePipe, PlatformLocation } from '@angular/common'; //SCHEDULE ACTION
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { Subject, Subscription } from 'rxjs';

import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarEventTitleFormatter,
    CalendarView,
    DAYS_OF_WEEK,
} from 'angular-calendar';
import moment from 'moment-timezone';
import { RRule } from 'rrule';

import {
    IomsDevice,
    IomsDeviceManagerService,
    WorkInstructionNameAndId,
} from '../../../services/iomsdevicemanger.service'; //SCHEDULE ACTION
import { KnowledgeBaseService } from '../../../services/knowledgebase.service'; //SCHEDULE ACTION
import { IomsTeamLevel, IomsTeamManagerService } from '../../../services/iomsTeamManager.service'; //SCHEDULE ACTION
import { MatSnackBar } from '@angular/material/snack-bar'; //SCHEDULE ACTION
import { TdDialogService } from '@covalent/core/dialogs'; //SCHEDULE ACTION
import { DialogReportPreviewComponent } from '../ongoing/dialog-report-preview/dialog-report-preview.component';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';

interface RecurringEvent {
    id: number;
    title: string;
    color: any;
    rrule?: {
        freq: any;
        dtstart: Date;
        until?: Date;
        bymonth?: number[];
        bymonthday?: number;
        byweekday?: any;
        interval?: number;
        bysetpos?: any;
        wkst?: any;
    };
    start: Date;
    end: Date;
    actions?: CalendarEventAction[];
}

interface RecurringEventNew {
    //SCHEDULE ACTION
    id: string;
    userId: string;
    actionNumber: number;
    creationDate: number;
    duration: string;
    actionType: string;
    deviceLevelId: string;
    description: string;
    reportId: string;
    location: string;
    assignedBy: string;
    approvalDate: number;
    iomsTeam: string;
    assigned: boolean;
    iomsDevices: string[];
    workInstructions: string[];
    repeat?: {
        freq: any;
        dtstart: Date;
        until?: Date;
        bymonth?: number[];
        bymonthday?: number;
        byweekday?: any;
        interval?: number;
        bysetpos?: any;
        wkst?: any;
    };
    start: Date;
    end: Date;
    actions?: CalendarEventAction[];
}

moment.tz.setDefault('Utc');

// ...reccuring
@Component({
    selector: 'app-calendar-scheduler',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './calendar-scheduler.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter,
        },
    ],
    styles: [
        `
            .square-shape span.cal-event {
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                border-radius: 00%;
            }
        `,
        `
            .lightning-shape span.cal-event {
                clip-path: polygon(25% 0%, 70% 0%, 40% 35%, 95% 35%, 20% 100%, 40% 55%, 0% 55%);
                border-radius: 00%;
            }
        `,
        `
            .star-shape span.cal-event {
                clip-path: polygon(
                    50% 0%,
                    61% 35%,
                    98% 35%,
                    68% 57%,
                    79% 91%,
                    50% 70%,
                    21% 91%,
                    32% 57%,
                    2% 35%,
                    39% 35%
                );
                border-radius: 00%;
            }
        `,
        `
            .clip-shape span.cal-event {
                clip-path: polygon(10% 0%, 90% 0%, 76% 76%, 50% 100%, 24% 76%);
                border-radius: 00%;
            }
        `,
        `
            mwl-calendar-open-day-events > div {
                background-color: #939393 !important;
            }
        `,
    ],
})
export class CalendarSchedulerComponent implements OnInit, OnDestroy {
    toggleGroupValue1 = '2';
    toggleGroupValue2 = '1';

    readOnlyUser: boolean = false;

    ngOnInit() {
        this.getActionTypes(); //SCHEDULE ACTION
        this.loadDevices(); //SCHEDULE ACTION
        this.loadTeams(); //SCHEDULE ACTION
        this.loadWorkInstructionsInfo(); //SCHEDULE ACTION
        this.getActionSeries(); //SCHEDULE ACTION
        this.getSingleActions(); //SCHEDULE ACTION
        this.getUser(true); //SCHEDULE ACTION
        this.getAllUsers(); //SCHEDULE ACTION

        var date1: Date = moment('08:15', 'HHmm').toDate();
        var date2: Date = moment('08:15', 'HH:mm').toDate();
        this.readOnlyUser = this.isReadOnlyRole();
    }

    isReadOnlyRole(): boolean {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentRoleActions = currentUser && currentUser.actions;
        if (currentRoleActions != undefined) {
            if (
                currentRoleActions.indexOf('ALL') != -1 ||
                currentRoleActions.indexOf('IOMS_MODIFICATION_ACCESS') != -1
            ) {
                return false;
            }
        }
        return true;
    }

    ngOnDestroy() {
        this.subDialogAddCalendarEvent.unsubscribe();
        this.subGetActionTypes.unsubscribe(); //SCHEDULE ACTION
        this.subLoadDevices.unsubscribe(); //SCHEDULE ACTION
        this.subLoadTeams.unsubscribe(); //SCHEDULE ACTION
        this.subGetScheduledActions.unsubscribe(); //SCHEDULE ACTION
        this.subDeleteEventSeries.unsubscribe(); //SCHEDULE ACTION
    }

    weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    hourSegments = '4';

    viewDate: Date = new Date();
    loc = { firstDayOfWeek: 1 };

    actions: CalendarEventAction[] = [
        {
            label: '<i> Edit series </i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: any }): void => {
                this.handleEvent('Edited', event);
                this.editActionSeries(event.seriesActionNumber); //SCHEDULE ACTION
            },
        },
        {
            label: '<i> Delete series </i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: any }): void => {
                this.deleteEventSeries(event.seriesActionNumber); //SCHEDULE ACTION
                this.handleEvent('Deleted', event);
            },
        },
        {
            label: '<i> Assign </i>',
            a11yLabel: 'Assign',
            onClick: ({ event }: { event: any }): void => {
                this.assignAction(event); //SCHEDULE ACTION
            },
        },
        {
            label: '<i> Edit </i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: any }): void => {
                this.editSingleAction(event); //SCHEDULE ACTION
            },
        },
        {
            label: '<i> Delete </i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: any }): void => {
                this.deleteSingleAction(event.mongoId); //SCHEDULE ACTION
            },
        },
        {
            label: '<i> See action </i>',
            a11yLabel: 'Action',
            onClick: ({ event }: { event: any }): void => {
                this.assignAction(event);
            },
        },
        {
            label: '<i> See report </i>',
            a11yLabel: 'Report',
            onClick: ({ event }: { event: any }): void => {
                this.showReportPreview(event);
            },
        },
    ];

    dialogRefReportPreview: MatDialogRef<DialogReportPreviewComponent>;

    showReportPreview(event) {
        this.dialogRefReportPreview = this._dialog.open(DialogReportPreviewComponent, {
            height: '800px',
            width: '1200px',
            data: {
                mongoId: event.mongoId,
                currentActionReportId: event.actionReportId,
                selectedWorkInstructionsIdsArray: event.workInstructionsIds,
                status: 'COMPLETED',
                currentPath: 'past-actions',
                allUsers: this.allUsers,
            },
            disableClose: true,
        });

        this.dialogRefReportPreview.afterClosed().subscribe((dialogData) => {});
    }

    refresh: Subject<any> = new Subject();

    events: any[] = [];

    activeDayIsOpen: boolean = true;

    currentUserName: any;
    currentUser: any;
    allUsers: any[];

    constructor(
        // added for recurring events
        private cdr: ChangeDetectorRef,
        public _dialog: MatDialog,
        private _iomsDeviceManagementService: IomsDeviceManagerService, //SCHEDULE ACTION
        private _iomsTeamManagerService: IomsTeamManagerService, //SCHEDULE ACTION
        private _knowledgeBaseService: KnowledgeBaseService, //SCHEDULE ACTION
        private _snackBar: MatSnackBar, //SCHEDULE ACTION
        private _dialogService: TdDialogService //SCHEDULE ACTION
    ) {}

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        // update all events array
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });

        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        this.refreshView();

        for (var i = 0; i < this.events.length; i++) {
            if (event.id == this.events[i].id) {
                break;
            }
        }
    }

    subDialogAddCalendarEvent: Subscription = new Subscription();

    // SCHEDULE ACTION...
    subLoadDevices: Subscription = new Subscription();
    subLoadTeams: Subscription = new Subscription();
    subDeleteEventSeries: Subscription = new Subscription();
    dataset: IomsDevice[];
    teams: IomsTeamLevel[];
    workInstructionsInfo: any[];
    dialogRefScheduleEvent: MatDialogRef<DialogScheduleEvent>;
    dialogRefEditScheduleEvent: MatDialogRef<DialogEditScheduleEvent>;
    dialogRefAssignAction: MatDialogRef<DialogAssignAction>;
    dialogRefEditSingleScheduleEvent: MatDialogRef<DialogEditSingleAction>;
    // dialogRefAssignScheduleEvent: MatDialogRef<DialogAssignScheduleEvent>;
    // dialogRefOngoingAction: MatDialogRef<DialogOngoingAction>;
    subDialogScheduleEvent: Subscription = new Subscription();
    // filteredWorkInstructionsInfo: any[];
    subGetActionTypes: Subscription = new Subscription();
    subGetScheduledActions: Subscription = new Subscription();
    actionTypes: any[];
    scheduleActions: any[];
    // ...SCHEDULE ACTION

    actionSeries: any[] = [];

    getActionSeries() {
        //SCHEDULE ACTION
        this.subGetScheduledActions = this._iomsDeviceManagementService
            .getScheduledActionsSrv()
            .subscribe((res: any[]) => {
                this.actionSeries = res;
            });
    }

    firstGetActionTypeCalled: boolean = true;

    getActionTypes() {
        //SCHEDULE ACTION
        this.subGetActionTypes = this._iomsDeviceManagementService
            .getActionTypesSrv()
            .subscribe((res: any) => {
                this.actionTypes = res;

                if (this.firstGetActionTypeCalled) {
                    this.firstGetActionTypeCalled = false;
                    this.getSingleActions();
                }
            });
    }

    loadDevices() {
        //SCHEDULE ACTION
        this.subLoadDevices = this._iomsDeviceManagementService
            .loadDevicesSrv()
            .subscribe((res: IomsDevice[]) => {
                this.dataset = res;

                // setting of the name and id object and work instructions names string
                for (let i = 0; i < this.dataset.length; i++) {
                    this.dataset[i].workInstructionNameAndId = [];
                    this.dataset[i].workInstructionNames = '';

                    let wi: string[] = this.dataset[i].workInstructions.split('&');
                    for (let j = 0; j < wi.length; j++) {
                        let wiTemp: WorkInstructionNameAndId = new WorkInstructionNameAndId();

                        wiTemp.name = wi[j].split(';')[0];
                        wiTemp.objectId = wi[j].split(';')[1];

                        this.dataset[i].workInstructionNameAndId.push(wiTemp);
                        if (j < wi.length - 1) {
                            this.dataset[i].workInstructionNames =
                                this.dataset[i].workInstructionNames + wiTemp.name + ', ';
                        } else {
                            this.dataset[i].workInstructionNames =
                                this.dataset[i].workInstructionNames + wiTemp.name;
                        }
                    }
                }

                this.cdr.detectChanges();
            });
    }

    loadTeams() {
        //SCHEDULE ACTION
        // load all documents from db
        this.subLoadTeams = this._iomsTeamManagerService
            .getTeamLevelList()
            .subscribe((res: IomsTeamLevel[]) => {
                this.teams = res;
                this.cdr.detectChanges();
            });
    }

    loadWorkInstructionsInfo() {
        //SCHEDULE ACTION
        this._knowledgeBaseService.loadWorkInstructionsSrv().subscribe((res: any) => {
            this.workInstructionsInfo = res;
        });
    }

    getUser(loadInfoFromDb: boolean) {
        //SCHEDULE ACTION
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUserName = currentUser.name;

        if (loadInfoFromDb) {
            this.getUserFromDb();
        }

        return currentUser;
    }

    getUserFromDb() {
        this._iomsDeviceManagementService
            .getUserFromDbSrv(this.currentUserName)
            .subscribe((res: any) => {
                this.currentUser = res;
            });
    }

    getAllUsers() {
        this._iomsDeviceManagementService.getAllUsersSrv().subscribe((res: any) => {
            this.allUsers = res;
        });
    }

    findUserById(id: string): any {
        for (let i = 0; i < this.allUsers.length; i++) {
            if (id == this.allUsers[i].id) {
                return this.allUsers[i];
            }
        }
    }

    saveRecurringActionSeriesAsSingleActions(
        userId: string,
        actionNumber: string,
        creationDateMills: string,
        eventStart: number,
        eventEnd: number,
        duration: string,
        rrule: any,
        actionType: string,
        deviceLevelId: string,
        description: string,
        reportId: string,
        location: string,
        selectedEquipmentIds: string[],
        selectedWorkInstructionsIds: string[],
        dateLimit?: number
    ) {
        if (dateLimit == undefined) {
            dateLimit = 0;
        }

        var eventsTemp: any[] = [];

        let rruleTemp = rrule;
        let st: Date = new Date(rruleTemp.dtstart);
        let et: Date = new Date(rruleTemp.until); ///
        rruleTemp.dtstart = st;
        rruleTemp.until = et;

        const rule: RRule = new RRule({ ...rruleTemp });

        var timeStart: Date;
        var timeEnd: Date;

        let ind = 0;

        rule.all().forEach((date) => {
            timeStart = new Date(date.valueOf());
            timeStart.setHours(new Date(eventStart).getHours()); // proveriti ovo *************************************************
            timeStart.setMinutes(new Date(eventStart).getMinutes());

            if (timeStart.getTime() > dateLimit) {
                timeEnd = new Date(date.valueOf());

                switch (duration) {
                    case '2 days':
                        timeEnd.setDate(timeStart.getDate() + 2);
                        break;
                    case '3 days':
                        timeEnd.setDate(timeStart.getDate() + 3);
                        break;
                    case '4 days':
                        timeEnd.setDate(timeStart.getDate() + 4);
                        break;
                    case '5 days':
                        timeEnd.setDate(timeStart.getDate() + 5);
                        break;
                    case '6 days':
                        timeEnd.setDate(timeStart.getDate() + 6);
                        break;
                    case '7 days':
                        timeEnd.setDate(timeStart.getDate() + 7);
                        break;
                    case '10 days':
                        timeEnd.setDate(timeStart.getDate() + 10);
                        break;
                }

                timeEnd.setHours(new Date(eventEnd).getHours());
                timeEnd.setMinutes(new Date(eventEnd).getMinutes());

                // for covering the case, for ex. if start time is set at 23h and duration is 2 hours, then end date
                // should be on 1 in the morning of the next day
                if (timeStart.getTime() > timeEnd.getTime()) {
                    timeEnd.setDate(timeStart.getDate() + 1);
                }

                eventsTemp.push({
                    userId: userId,
                    seriesActionNumber: actionNumber,
                    actionNumber: actionNumber + '0' + ind,
                    creationDateMills: creationDateMills,
                    start: timeStart.getTime(),
                    end: timeEnd.getTime(),
                    duration: duration,
                    actionType: actionType,
                    deviceLevelId: deviceLevelId,
                    description: description,
                    reportId: reportId,
                    location: location,
                    selectedEquipmentIds: selectedEquipmentIds,
                    selectedWorkInstructionsIds: selectedWorkInstructionsIds,
                });

                ind++;
            }
        });

        this._iomsDeviceManagementService
            .saveIndividualScheduleEvents(eventsTemp)
            .subscribe((res: any) => {
                this.getSingleActions();
            });
    }

    getSingleActions() {
        this._iomsDeviceManagementService.getSingleActionsSrv().subscribe((res: any[]) => {
            for (let i = 0; i < res.length; i++) {
                //transform date string to date
                let st: Date = new Date(res[i].start);
                let et: Date = new Date(res[i].end);
                res[i].start = st;
                res[i].end = et;

                let levIdSplit: string[] = res[i].deviceLevelId.split('_');

                // set description to title
                res[i].title =
                    levIdSplit[1] +
                    ' : ' +
                    levIdSplit[2].replace(';', ',') +
                    ', ' +
                    res[i].description;

                // set action color
                let color1: string;
                let color2: string;

                let cssClass: string;

                if (res[i].assigned && res[i].status == '') {
                    color1 = '#29f75d'; // green
                    color2 = '#cefdda';
                } else {
                    color1 = '#0070c0'; // blue
                    color2 = '#cceaff';

                    if (res[i].status == 'STARTED') {
                        color1 = '#ffa843'; // orange
                        color2 = '#ffe7cc';
                    }

                    if (res[i].status == 'COMPLETED') {
                        color1 = '#8464a2'; // purple
                        color2 = '#e6dfec';
                    }

                    if (res[i].status == 'REFUSED') {
                        color1 = '#984807'; // brown
                        color2 = '#fbd5b6';
                    }
                }
                res[i].color = {
                    primary: color1,
                    secondary: color2,
                };

                let stylesObj: Object;
                stylesObj = {
                    'clip-path': 'polygon(50% 0%, 0% 100%, 100% 100%)',
                    'border-radius': '00%',
                };

                for (let j = 0; j < this.actionTypes.length; j++) {
                    if (res[i].actionType == this.actionTypes[j].mongoId) {
                        if (this.actionTypes[j].actionType == 'Planned') {
                            cssClass = '';
                            stylesObj = {};
                        }

                        if (this.actionTypes[j].actionType == 'Routine') {
                            cssClass = 'square-shape';
                            stylesObj = {
                                'clip-path': 'polygon(50% 0%, 0% 100%, 100% 100%)',
                                'border-radius': '00%',
                            };
                        }

                        if (this.actionTypes[j].actionType == 'Break down') {
                            cssClass = 'lightning-shape';
                            stylesObj = {
                                'clip-path':
                                    'polygon(25% 0%,70% 0%,40% 35%,95% 35%,20% 100%,40% 55%,0% 55%)',
                                'border-radius': '00%',
                            };
                        }

                        if (this.actionTypes[j].actionType == 'Preventive') {
                            cssClass = 'star-shape';
                            stylesObj = {
                                'clip-path':
                                    'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                                'border-radius': '00%',
                            };
                        }

                        break;
                    }
                }

                res[i].cssClass = cssClass;
                res[i].stylesObj = stylesObj;

                res[i].draggable = true;
                res[i].resizable = {
                    beforeStart: true,
                    afterEnd: true,
                };

                if (this.readOnlyUser) {
                    res[i].actions = [this.actions[5], this.actions[6]];
                } else {
                    // if action is recurring then show edit and delete series butons, if not, the show only assign button
                    if (res[i].actionNumber != res[i].seriesActionNumber) {
                        // action belongs to the series of actions

                        if (res[i].status == 'COMPLETED') {
                            res[i].actions = [this.actions[5], this.actions[6]];
                        } else {
                            if (res[i].assigned) {
                                res[i].actions = [
                                    this.actions[0],
                                    this.actions[1],
                                    this.actions[2],
                                ];
                            } else {
                                res[i].actions = [
                                    this.actions[0],
                                    this.actions[1],
                                    this.actions[2],
                                    this.actions[3],
                                    this.actions[4],
                                ];
                            }
                        }
                    } else {
                        // action is created as one time action
                        if (res[i].status == 'COMPLETED') {
                            res[i].actions = [this.actions[5], this.actions[6]];
                        } else {
                            if (res[i].assigned) {
                                res[i].actions = [this.actions[2]];
                            } else {
                                res[i].actions = [
                                    this.actions[2],
                                    this.actions[3],
                                    this.actions[4],
                                ];
                            }
                        }
                    }
                }
            }

            this.events = res;
            this.cdr.detectChanges();
            this.refreshView();
        });
    }

    addAction(date: Date): void {
        //SCHEDULE ACTION

        if (this.readOnlyUser) {
            return;
        }

        this.dialogRefScheduleEvent = this._dialog.open(DialogScheduleEvent, {
            height: '700px',
            width: '900px',
            data: {
                user: this.currentUser,
                clickedDate: date,
                view: this.view,
                actionTypes: this.actionTypes,
                allDevices: this.dataset,
                // teams: this.teams, // ne treba
                workInstructionsInfo: this.workInstructionsInfo,
            },
            // disableClose: true,
        });
        this.subDialogScheduleEvent = this.dialogRefScheduleEvent
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        let eventStart: number;
                        let eventEnd: number;
                        // if (dialogData.toSave && dialogData.repeatChoice.value == 0) {
                        //   eventStart =  "" + dialogData.eventStart.getTime();
                        //   eventEnd = "" + dialogData.eventEnd.getTime();
                        // } else {
                        //   eventStart = dialogData.eventStart;
                        //   eventEnd = dialogData.eventEnd;
                        // }

                        eventStart = dialogData.eventStart.getTime();
                        eventEnd = dialogData.eventEnd.getTime();

                        var rrule = {};
                        let selectedRepeatOption: number = 0;

                        // DAILY
                        if (dialogData.toSave && dialogData.repeatChoice.value == 1) {
                            selectedRepeatOption = 1;
                            var bywd: any = [];
                            if (dialogData.dailyExcludeWeekend) {
                                bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
                            }
                            rrule = {
                                freq: RRule.DAILY,
                                interval: dialogData.intervalDays,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: bywd,
                            };
                        }

                        // WEEKLY
                        if (dialogData.toSave && dialogData.repeatChoice.value == 2) {
                            selectedRepeatOption = 2;
                            rrule = {
                                freq: RRule.WEEKLY,
                                interval: dialogData.intervalWeeks,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.weeklyByDay,
                            };
                        }

                        // SAME DAY EACH MONTH
                        if (dialogData.toSave && dialogData.repeatChoice.value == 3) {
                            selectedRepeatOption = 3;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: dialogData.intervalMonths,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [
                                    RRule.MO,
                                    RRule.TU,
                                    RRule.WE,
                                    RRule.TH,
                                    RRule.FR,
                                    RRule.SA,
                                    RRule.SU,
                                ],
                                bysetpos: [dialogData.dayMonthly],
                            };
                        }

                        // SAME WEEK EACH MONTH
                        if (dialogData.toSave && dialogData.repeatChoice.value == 4) {
                            selectedRepeatOption = 4;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: dialogData.intervalMonths,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.monthlyByDayRule,
                                bysetpos: [dialogData.sameWeekMonthlySetPos],
                            };
                        }

                        // SAME DAY EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 5) {
                            selectedRepeatOption = 5;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [
                                    RRule.MO,
                                    RRule.TU,
                                    RRule.WE,
                                    RRule.TH,
                                    RRule.FR,
                                    RRule.SA,
                                    RRule.SU,
                                ],
                                bymonth: [dialogData.selectedMonthValue],
                                bysetpos: [dialogData.dayOfTheMonthYearly],
                            };
                        }

                        // SAME WEEK EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 6) {
                            selectedRepeatOption = 6;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [dialogData.monthlyByDayRule],
                                bymonth: [dialogData.selectedMonthValue],
                                bysetpos: [dialogData.sameWeekMonthlySetPos],
                            };
                        }

                        // EVERY 6 MONTHS
                        if (dialogData.toSave && dialogData.repeatChoice.value == 7) {
                            selectedRepeatOption = 7;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: 6,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                            };
                        }

                        // SAME WEEK NUMBER EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 8) {
                            selectedRepeatOption = 8;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.monthlyByDayRule,
                                bysetpos: [dialogData.selectedWeekNumberYearly],
                            };
                        }

                        let deviceLevelId: string = '';

                        deviceLevelId =
                            dialogData.selectedLine + '_' + dialogData.selectedSubstation;

                        let cubArrayTemp: string[] = dialogData.selectedCubicles;

                        for (let i = 0; i < cubArrayTemp.length; i++) {
                            if (i == 0) {
                                deviceLevelId = deviceLevelId + '_' + cubArrayTemp[i];
                            } else {
                                deviceLevelId = deviceLevelId + ';' + cubArrayTemp[i];
                            }
                        }

                        let selectedEquipment1: IomsDevice[] = dialogData.selectedEquipment;
                        let selectedEquipmentIds: string[] = [];
                        for (var i = 0; i < selectedEquipment1.length; i++) {
                            selectedEquipmentIds.push(
                                selectedEquipment1[i].levelId.split('_')[2] +
                                    ';' +
                                    selectedEquipment1[i].mongoId
                            );
                        }

                        if (dialogData.toSave && dialogData.repeatChoice.value == 0) {
                            let eventsTemp: any[] = [];

                            eventsTemp.push({
                                userId: this.currentUser.id,
                                seriesActionNumber: dialogData.actionNumber,
                                actionNumber: '' + dialogData.actionNumber,
                                creationDateMills: dialogData.creationDateMills,
                                start: eventStart,
                                end: eventEnd,
                                duration: dialogData.selectedDuration.viewValue,
                                actionType: dialogData.selectedActionType.mongoId,
                                deviceLevelId: deviceLevelId,
                                description: dialogData.briefDescription,
                                reportId: dialogData.reportId,
                                location: dialogData.location,
                                selectedEquipmentIds: selectedEquipmentIds,
                                selectedWorkInstructionsIds: dialogData.selectedWorkInstructionsIds,
                            });

                            // save individual
                            this._iomsDeviceManagementService
                                .saveIndividualScheduleEvents(eventsTemp)
                                .subscribe((res: any) => {
                                    this.getSingleActions();
                                });
                        } else {
                            // save series
                            this._iomsDeviceManagementService
                                .saveActionSeries(
                                    this.currentUser.id,
                                    dialogData.actionNumber,
                                    dialogData.creationDateMills,
                                    eventStart,
                                    eventEnd,
                                    dialogData.selectedDuration.viewValue,
                                    JSON.stringify(rrule),
                                    dialogData.selectedActionType.mongoId,
                                    deviceLevelId,
                                    dialogData.briefDescription,
                                    dialogData.reportId,
                                    dialogData.location,
                                    selectedEquipmentIds,
                                    dialogData.selectedWorkInstructionsIds,
                                    selectedRepeatOption
                                )
                                .subscribe(
                                    (res: any) => {
                                        this.getActionSeries();
                                        this.saveRecurringActionSeriesAsSingleActions(
                                            this.currentUser.id,
                                            dialogData.actionNumber,
                                            dialogData.creationDateMills,
                                            eventStart,
                                            eventEnd,
                                            dialogData.selectedDuration.viewValue,
                                            rrule,
                                            dialogData.selectedActionType.mongoId,
                                            deviceLevelId,
                                            dialogData.briefDescription,
                                            dialogData.reportId,
                                            dialogData.location,
                                            selectedEquipmentIds,
                                            dialogData.selectedWorkInstructionsIds
                                        );
                                        this._snackBar.open('Action saved', 'Ok', {
                                            duration: 5000,
                                        });
                                    },
                                    (error) => {
                                        this._snackBar.open('Error while saving!', 'Ok', {
                                            duration: 5000,
                                        });
                                    }
                                );
                        }
                    }
                }
            });
    }

    editActionSeries(seriesActionNumber: string): void {
        //SCHEDULE ACTION
        // only actions after this time will considered for editing
        let dateLimit: number = new Date().getTime();

        let selectedScheduleAction: any;
        for (let i = 0; i < this.actionSeries.length; i++) {
            if (this.actionSeries[i].actionNumber == seriesActionNumber) {
                selectedScheduleAction = this.actionSeries[i];
                break;
            }
        }

        let user: any = this.findUserById(selectedScheduleAction.userId);

        this.dialogRefEditScheduleEvent = this._dialog.open(DialogEditScheduleEvent, {
            height: '700px',
            width: '900px',
            data: {
                creationDate: selectedScheduleAction.creationDate,
                actionNumber: seriesActionNumber,
                eventStart: new Date(selectedScheduleAction.start),
                eventEnd: new Date(selectedScheduleAction.end),
                actionTypeId: selectedScheduleAction.actionType,
                user: user,
                view: this.view,
                actionTypes: this.actionTypes,
                allDevices: this.dataset,
                workInstructionsInfo: this.workInstructionsInfo,
                reportId: selectedScheduleAction.reportId,
                location: selectedScheduleAction.location,
                description: selectedScheduleAction.description,
                deviceLevelId: selectedScheduleAction.deviceLevelId,
                selectedEquipmentIds: selectedScheduleAction.iomsDevices,
                selectedWorkInstructionsIds: selectedScheduleAction.workInstructions,
                selectedRepeatOption: selectedScheduleAction.selectedRepeatOption,
                rrule: selectedScheduleAction.repeat,
                selectedDuration: selectedScheduleAction.duration,
            },
            // disableClose: true,
        });
        this.subDialogScheduleEvent = this.dialogRefEditScheduleEvent
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        let eventStart: number;
                        let eventEnd: number;
                        eventStart = dialogData.eventStart.getTime();
                        eventEnd = dialogData.eventEnd.getTime();

                        var rrule = {};
                        let selectedRepeatOption: number = 0;

                        // DAILY
                        if (dialogData.toSave && dialogData.repeatChoice.value == 1) {
                            selectedRepeatOption = 1;
                            var bywd: any = [];
                            if (dialogData.dailyExcludeWeekend) {
                                bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
                            }
                            rrule = {
                                freq: RRule.DAILY,
                                interval: dialogData.intervalDays,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: bywd,
                            };
                        }

                        // WEEKLY
                        if (dialogData.toSave && dialogData.repeatChoice.value == 2) {
                            selectedRepeatOption = 2;
                            rrule = {
                                freq: RRule.WEEKLY,
                                interval: dialogData.intervalWeeks,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.weeklyByDay,
                            };
                        }

                        // SAME DAY EACH MONTH
                        if (dialogData.toSave && dialogData.repeatChoice.value == 3) {
                            selectedRepeatOption = 3;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: dialogData.intervalMonths,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [
                                    RRule.MO,
                                    RRule.TU,
                                    RRule.WE,
                                    RRule.TH,
                                    RRule.FR,
                                    RRule.SA,
                                    RRule.SU,
                                ],
                                bysetpos: [dialogData.dayMonthly],
                            };
                        }

                        // SAME WEEK EACH MONTH
                        if (dialogData.toSave && dialogData.repeatChoice.value == 4) {
                            selectedRepeatOption = 4;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: dialogData.intervalMonths,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.monthlyByDayRule,
                                bysetpos: [dialogData.sameWeekMonthlySetPos],
                            };
                        }

                        // SAME DAY EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 5) {
                            selectedRepeatOption = 5;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [
                                    RRule.MO,
                                    RRule.TU,
                                    RRule.WE,
                                    RRule.TH,
                                    RRule.FR,
                                    RRule.SA,
                                    RRule.SU,
                                ],
                                bymonth: [dialogData.selectedMonthValue],
                                bysetpos: [dialogData.dayOfTheMonthYearly],
                            };
                        }

                        // SAME WEEK EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 6) {
                            selectedRepeatOption = 6;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: [dialogData.monthlyByDayRule],
                                bymonth: [dialogData.selectedMonthValue],
                                bysetpos: [dialogData.sameWeekMonthlySetPos],
                            };
                        }

                        // EVERY 6 MONTHS
                        if (dialogData.toSave && dialogData.repeatChoice.value == 7) {
                            selectedRepeatOption = 7;
                            rrule = {
                                freq: RRule.MONTHLY,
                                interval: 6,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                            };
                        }

                        // SAME WEEK NUMBER EACH YEAR
                        if (dialogData.toSave && dialogData.repeatChoice.value == 8) {
                            selectedRepeatOption = 8;
                            rrule = {
                                freq: RRule.YEARLY,
                                interval: dialogData.intervalYears,
                                dtstart: dialogData.eventdtStartMills,
                                until: dialogData.eventUntilMills,
                                byweekday: dialogData.monthlyByDayRule,
                                bysetpos: [dialogData.selectedWeekNumberYearly],
                            };
                        }

                        let deviceLevelId: string = '';

                        deviceLevelId =
                            dialogData.selectedLine + '_' + dialogData.selectedSubstation;

                        let cubArrayTemp: string[] = dialogData.selectedCubicles;

                        for (let i = 0; i < cubArrayTemp.length; i++) {
                            if (i == 0) {
                                deviceLevelId = deviceLevelId + '_' + cubArrayTemp[i];
                            } else {
                                deviceLevelId = deviceLevelId + ';' + cubArrayTemp[i];
                            }
                        }

                        let selectedEquipment1: IomsDevice[] = dialogData.selectedEquipment;
                        let selectedEquipmentIds: string[] = [];
                        for (var i = 0; i < selectedEquipment1.length; i++) {
                            selectedEquipmentIds.push(
                                selectedEquipment1[i].levelId.split('_')[2] +
                                    ';' +
                                    selectedEquipment1[i].mongoId
                            );
                        }

                        if (dialogData.toSave && dialogData.repeatChoice.value == 0) {
                            let eventsTemp: any[] = [];

                            eventsTemp.push({
                                userId: user.id,
                                seriesActionNumber: dialogData.actionNumber,
                                actionNumber: '' + dialogData.actionNumber,
                                creationDateMills: dialogData.creationDateMills,
                                start: eventStart,
                                end: eventEnd,
                                duration: dialogData.selectedDuration.viewValue,
                                actionType: dialogData.selectedActionType.mongoId,
                                deviceLevelId: deviceLevelId,
                                description: dialogData.briefDescription,
                                reportId: dialogData.reportId,
                                location: dialogData.location,
                                selectedEquipmentIds: selectedEquipmentIds,
                                selectedWorkInstructionsIds: dialogData.selectedWorkInstructionsIds,
                            });

                            this.subDeleteEventSeries = this._iomsDeviceManagementService
                                .deleteEventSeries(dialogData.actionNumber)
                                .subscribe((response) => {
                                    this._iomsDeviceManagementService
                                        .saveIndividualScheduleEvents(eventsTemp)
                                        .subscribe((res: any) => {
                                            this.getSingleActions();
                                        });
                                });
                        } else {
                            // this backend call will update recurring action, then it will delete individual actions and then it will delete correspondong actions report
                            this._iomsDeviceManagementService
                                .editScheduleAction(
                                    selectedScheduleAction.mongoId,
                                    user.id,
                                    dialogData.actionNumber,
                                    dialogData.creationDateMills,
                                    eventStart,
                                    eventEnd,
                                    dialogData.selectedDuration.viewValue,
                                    JSON.stringify(rrule),
                                    dialogData.selectedActionType.mongoId,
                                    deviceLevelId,
                                    dialogData.briefDescription,
                                    dialogData.reportId,
                                    dialogData.location,
                                    selectedEquipmentIds,
                                    dialogData.selectedWorkInstructionsIds,
                                    selectedRepeatOption,
                                    dateLimit
                                )
                                .subscribe(
                                    (res: any) => {
                                        this.getActionSeries();

                                        // this method will create individual events and save them in database
                                        this.saveRecurringActionSeriesAsSingleActions(
                                            user.id,
                                            dialogData.actionNumber,
                                            dialogData.creationDateMills,
                                            eventStart,
                                            eventEnd,
                                            dialogData.selectedDuration.viewValue,
                                            rrule,
                                            dialogData.selectedActionType.mongoId,
                                            deviceLevelId,
                                            dialogData.briefDescription,
                                            dialogData.reportId,
                                            dialogData.location,
                                            selectedEquipmentIds,
                                            dialogData.selectedWorkInstructionsIds,
                                            dateLimit
                                        );

                                        this._snackBar.open('Action saved', 'Ok', {
                                            duration: 5000,
                                        });
                                    },
                                    (error) => {
                                        this._snackBar.open('Error while saving!', 'Ok', {
                                            duration: 5000,
                                        });
                                    }
                                );
                        }
                    }
                }
            });
    }

    deleteEventSeries(seriesActionNumber: number) {
        //SCHEDULE ACTION

        // only actions after this time will considered for delete
        let dateLimit: number = new Date().getTime();

        this._dialogService
            .openConfirm({
                message: 'Are you sure you want to event series?',
                acceptButton: 'Delete',
                title: 'Delete event series',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.subDeleteEventSeries = this._iomsDeviceManagementService
                        .deleteEventSeriesNew(seriesActionNumber, dateLimit)
                        .subscribe(
                            (response) => {
                                this.getActionSeries();
                                this.getSingleActions();
                                this._snackBar.open('Event series is deleted', 'Ok', {
                                    duration: 3000,
                                });
                            },
                            (error) => {
                                this._snackBar.open('Error while deleting', 'Ok', {
                                    duration: 3000,
                                });
                            }
                        );
                }
            });
    }

    assignAction(action: any) {
        let user: any = this.findUserById(action.userId);

        this.dialogRefAssignAction = this._dialog.open(DialogAssignAction, {
            height: '700px',
            width: '900px',
            data: {
                readOnlyUser: this.readOnlyUser,
                assigned: action.assigned,
                approvalDate: action.approvalDate,
                teamId: action.teamId,
                mongoId: action.mongoId,
                currentUser: this.currentUser,
                creationDate: action.creationDateMills,
                actionNumber: action.seriesActionNumber,
                eventStart: new Date(action.start),
                eventEnd: new Date(action.end),
                actionTypeId: action.actionType,
                user: user,
                view: this.view,
                actionTypes: this.actionTypes,
                allDevices: this.dataset,
                teams: this.teams,
                workInstructionsInfo: this.workInstructionsInfo,
                reportId: action.reportId,
                location: action.location,
                description: action.description,
                deviceLevelId: action.deviceLevelId,
                selectedEquipmentIds: action.equipmentIds,
                selectedWorkInstructionsIds: action.workInstructionsIds,
                selectedDuration: action.duration,
                status: action.status,
            },
            disableClose: true,
        });

        this.dialogRefAssignAction.afterClosed().subscribe((dialogData) => {
            if (dialogData != undefined) {
                if (dialogData.assigned) {
                    this.getSingleActions();
                }
            }
        });
    }

    editSingleAction(event: any): void {
        //SCHEDULE ACTION
        let user: any = this.findUserById(event.userId);

        this.dialogRefEditSingleScheduleEvent = this._dialog.open(DialogEditSingleAction, {
            height: '700px',
            width: '900px',
            data: {
                creationDate: event.creationDateMills,
                actionNumber: event.actionNumber,
                eventStart: new Date(event.start),
                eventEnd: new Date(event.end),
                actionTypeId: event.actionType,
                user: user,
                view: this.view,
                actionTypes: this.actionTypes,
                allDevices: this.dataset,
                workInstructionsInfo: this.workInstructionsInfo,
                reportId: event.reportId,
                location: event.location,
                description: event.description,
                deviceLevelId: event.deviceLevelId,
                selectedEquipmentIds: event.equipmentIds,
                selectedWorkInstructionsIds: event.workInstructionsIds,
                selectedDuration: event.duration,
            },
            // disableClose: true,
        });
        this.subDialogScheduleEvent = this.dialogRefEditSingleScheduleEvent
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        let eventStart: number;
                        let eventEnd: number;
                        eventStart = dialogData.eventStart.getTime();
                        eventEnd = dialogData.eventEnd.getTime();

                        let deviceLevelId: string = '';

                        deviceLevelId =
                            dialogData.selectedLine + '_' + dialogData.selectedSubstation;

                        let cubArrayTemp: string[] = dialogData.selectedCubicles;

                        for (let i = 0; i < cubArrayTemp.length; i++) {
                            if (i == 0) {
                                deviceLevelId = deviceLevelId + '_' + cubArrayTemp[i];
                            } else {
                                deviceLevelId = deviceLevelId + ';' + cubArrayTemp[i];
                            }
                        }

                        let selectedEquipment1: IomsDevice[] = dialogData.selectedEquipment;
                        let selectedEquipmentIds: string[] = [];
                        for (var i = 0; i < selectedEquipment1.length; i++) {
                            selectedEquipmentIds.push(
                                selectedEquipment1[i].levelId.split('_')[2] +
                                    ';' +
                                    selectedEquipment1[i].mongoId
                            );
                        }

                        let eventTemp: any = {};

                        eventTemp = {
                            mongoId: event.mongoId,
                            start: dialogData.eventStart.getTime(),
                            end: dialogData.eventEnd.getTime(),
                            duration: dialogData.selectedDuration.viewValue,
                            actionType: dialogData.selectedActionType.mongoId,
                            deviceLevelId: deviceLevelId,
                            description: dialogData.briefDescription,
                            reportId: dialogData.reportId,
                            location: dialogData.location,
                            selectedEquipmentIds: selectedEquipmentIds,
                            selectedWorkInstructionsIds: dialogData.selectedWorkInstructionsIds,
                        };

                        this._iomsDeviceManagementService
                            .editIndividualScheduleEvents(eventTemp)
                            .subscribe(
                                (res: any) => {
                                    this.getSingleActions();
                                    this._snackBar.open('Event is saved', 'Ok', { duration: 3000 });
                                },
                                (error) => {
                                    this._snackBar.open('Error while saving', 'Ok', {
                                        duration: 3000,
                                    });
                                }
                            );
                    }
                }
            });
    }

    deleteSingleAction(mongoId: string) {
        //SCHEDULE ACTION
        this._dialogService
            .openConfirm({
                message: 'Are you sure you want to delete action?',
                acceptButton: 'Delete',
                title: 'Delete action',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.subDeleteEventSeries = this._iomsDeviceManagementService
                        .deleteSingleAction(mongoId)
                        .subscribe(
                            (response) => {
                                this.getActionSeries();
                                this.getSingleActions();
                                this._snackBar.open('Action is deleted', 'Ok', { duration: 3000 });
                            },
                            (error) => {
                                this._snackBar.open('Error while deleting', 'Ok', {
                                    duration: 3000,
                                });
                            }
                        );
                }
            });
    }

    currentView: string = 'month';

    setView(view: CalendarView) {
        this.view = view;
        this.currentView = view.toString();
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    refreshView(): void {
        this.refresh.next();
    }

    dialogRefAddCalendarEvent: MatDialogRef<DialogAddCalendarEvent>;
}

// NOT IN USE
@Component({
    selector: 'dialogAddCalendarEventt',
    templateUrl: 'dialog-add-calendar-event.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
})
export class DialogAddCalendarEvent {
    public toSave: boolean = false;

    public description: string = '';

    public clickedDate: Date;

    public eventStart: Date;
    public eventEnd: Date;

    public eventdtStart: Date;
    public eventUntil: Date;

    public eventStartDisabled: boolean = true;
    public eventEndDisabled: boolean = true;
    public tpTimeSelectionEnabled: boolean = false;
    public tpDateSelectionEnabled: boolean = true;

    public week: string[] = ['first', 'second', 'third', 'fourth', 'last'];
    public day: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // public months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Okt", "Nov", "Dec"];

    public rule: RRule;

    public intervalDays: number = 1;
    public intervalWeeks: number = 1;
    public intervalMonths: number = 1;

    public recurrenceOptions: any[] = [
        { value: 0, viewValue: 'Never' },
        { value: 1, viewValue: 'Daily' },
        { value: 2, viewValue: 'Weekly' },
        { value: 3, viewValue: 'The same day each month' },
        { value: 4, viewValue: 'The same week each month' },
        { value: 5, viewValue: 'The same day each year' },
        { value: 6, viewValue: 'The same week each year' },
    ];

    public durationOptions: any[] = [
        { value: 0, viewValue: 'All day' },
        { value: 1, viewValue: '30 minutes' },
        { value: 2, viewValue: '1 hour' },
        { value: 3, viewValue: '90 minutes' },
        { value: 4, viewValue: '2 hours' },
        { value: 5, viewValue: 'Custom' },
    ];

    // SAME DAY EACH MONTH...
    public dayMonthly: number = 1;
    // ...SAME WEEK EACH MONTH

    // SAME WEEK EACH MONTH...
    public weeks: any[] = [
        { value: 1, viewValue: 'first' },
        { value: 2, viewValue: 'second' },
        { value: 3, viewValue: 'third' },
        { value: 4, viewValue: 'fourth' },
        { value: 5, viewValue: 'last' },
    ];

    public selectedWeek: any = this.weeks[0];
    public sameWeekMonthlySetPos: number = this.weeks[0].value;

    public days: any[] = [
        { value: 0, viewValue: 'Monday' },
        { value: 1, viewValue: 'Tuesday' },
        { value: 2, viewValue: 'Wednesday' },
        { value: 3, viewValue: 'Thursday' },
        { value: 4, viewValue: 'Friday' },
        { value: 5, viewValue: 'Saturday' },
        { value: 6, viewValue: 'Sunday' },
    ];

    monthlySelectedDay: any = this.days[0];
    monthlyByDayRule: any = RRule.MO;
    // ...SAME WEEK EACH MONTH

    public selectedDuration: any = this.durationOptions[0];

    public repeatChoice: any = this.recurrenceOptions[0];

    public weeklyOnMon: boolean = false;
    public weeklyOnTue: boolean = false;
    public weeklyOnWed: boolean = false;
    public weeklyOnThu: boolean = false;
    public weeklyOnFri: boolean = false;
    public weeklyOnSat: boolean = false;
    public weeklyOnSun: boolean = false;

    // SAME DAY YEARLY...
    dayOfTheMonthYearly: number = 1;
    intervalYears: number = 1;

    months: any[] = [
        { value: 1, viewValue: 'January' },
        { value: 2, viewValue: 'February' },
        { value: 3, viewValue: 'March' },
        { value: 4, viewValue: 'April' },
        { value: 5, viewValue: 'May' },
        { value: 6, viewValue: 'June' },
        { value: 7, viewValue: 'July' },
        { value: 8, viewValue: 'August' },
        { value: 9, viewValue: 'September' },
        { value: 10, viewValue: 'October' },
        { value: 11, viewValue: 'November' },
        { value: 12, viewValue: 'December' },
    ];

    selectedMonth: any = this.months[0];
    selectedMonthValue: number = this.selectedMonth.value;

    // ...SAME DAY YEARLY

    // SAME WEEK EACH YEAR...
    yearlySelectedDay: any = this.days[0];

    // ...SAME WEEK EACH YEAR

    durationOptionChange(event) {
        this.cdr.detectChanges();
        switch (event.value) {
            case 0:
                var tempDate1 = new Date(this.eventStart);
                tempDate1.setHours(0, 0, 0, 0);
                this.eventStart = tempDate1;
                var tempDate2 = new Date(this.eventStart);
                tempDate2.setHours(23, 59, 59, 999);
                this.eventEnd = tempDate2;
                this.eventStartDisabled = true;
                this.eventEndDisabled = true;
                this.tpTimeSelectionEnabled = false;
                break;

            case 1:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 30);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 2:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 60);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 3:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 90);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 4:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 120);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 5:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = false;
                break;
        }
        this.cdr.detectChanges();
    }

    dateStartChange($event) {
        if (this.selectedDuration.value == 0) {
            this.eventStart.setHours(0, 0, 0, 0);
            var tempDate = new Date(this.eventStart);
            tempDate.setHours(23, 59, 59, 999);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 1) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 2) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 60);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 3) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 90);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 4) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 120);
            this.eventEnd = tempDate;
        }

        this.cdr.detectChanges();
    }

    constructor(
        public dialogRef: MatDialogRef<DialogAddCalendarEvent>,
        private platformLocation: PlatformLocation,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.clickedDate = new Date(data.clickedDate);
        if (data.view == 'month') {
            this.eventStart = new Date(this.clickedDate.setHours(0, 0, 0, 0));
            this.eventEnd = new Date(this.clickedDate.setHours(23, 59, 59, 999));
        }

        if (data.view == 'week' || data.view == 'day') {
            this.eventStart = this.clickedDate;
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
            this.selectedDuration = this.durationOptions[1];
            this.tpTimeSelectionEnabled = true;
            this.eventStartDisabled = false;
            this.eventEndDisabled = true;
        }

        this.eventdtStart = new Date(this.clickedDate.setHours(8, 0, 0, 0));
        var tempDate1 = new Date(this.eventdtStart);
        tempDate1.setFullYear(this.eventdtStart.getFullYear() + 3);
        this.eventUntil = new Date(tempDate1);
    }

    save() {
        this.toSave = true;

        // NEVER
        if (this.repeatChoice.value == 0) {
        }

        // DAILY
        if (this.repeatChoice.value == 1) {
            this.rule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.rule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            this.rule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR
        if (this.repeatChoice.value == 5) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    // recurring event start
    currentRule: RRule;
    currentRuleString: string;

    currentRuleText: string = '';

    weeklyByDay: any[] = [];
    dailyExcludeWeekend: boolean = false;

    updateRuleText() {
        // DAILY
        if (this.repeatChoice.value == 1) {
            var bywd: any[] = [];
            if (this.dailyExcludeWeekend) {
                bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            }

            this.currentRule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: bywd,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.weeklyByDay = [];
            if (this.weeklyOnMon) {
                this.weeklyByDay.push(RRule.MO);
            }
            if (this.weeklyOnTue) {
                this.weeklyByDay.push(RRule.TU);
            }
            if (this.weeklyOnWed) {
                this.weeklyByDay.push(RRule.WE);
            }
            if (this.weeklyOnThu) {
                this.weeklyByDay.push(RRule.TH);
            }
            if (this.weeklyOnFri) {
                this.weeklyByDay.push(RRule.FR);
            }
            if (this.weeklyOnSat) {
                this.weeklyByDay.push(RRule.SA);
            }
            if (this.weeklyOnSun) {
                this.weeklyByDay.push(RRule.SU);
            }

            this.currentRule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR

        if (this.repeatChoice.value == 5) {
            this.selectedMonthValue = this.selectedMonth.value;

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.selectedMonthValue = this.selectedMonth.value;

            if (this.yearlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.yearlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.yearlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.yearlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.yearlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.yearlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.yearlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        this.currentRuleText = this.currentRule.toText();
        this.currentRuleString = this.currentRule.toString();

        this.cdr.detectChanges();
    }

    recurrenceOptionChange() {
        if (this.repeatChoice.value > 0) {
            this.updateRuleText();
        }
    }
}

//SCHEDULE ACTION
@Component({
    selector: 'dialogScheduleEvent',
    templateUrl: 'dialog-schedule-event.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
})
export class DialogScheduleEvent {
    public user: any;

    public actionNumber: number;

    public creationDate: Date;
    public creationDateMills: number;
    public creationDateString: string;

    public actionTypes: any;
    public selectedActionType: any;

    public allDevices: IomsDevice[];

    public briefDescription: string = '';
    public reportId: string = '';
    public location: string = '';

    // public teams: IomsTeamLevel[]; // ne treba
    // public selectedTeam: IomsTeamLevel;

    public workInstructionsInfo: any[];
    public filteredWorkInstructionsInfo: any[];
    public selectedWorkInstructions: any[];
    public selectedWorkInstructionsIds: string[] = [];

    public assignedBy: any;
    // public assigned: boolean = false; // ne treba

    public approvalDate: Date; // ne treba
    public approvalDateMills: number; // ne treba
    public approvalDateString: string; // ne treba

    constructor(
        public dialogRef: MatDialogRef<DialogScheduleEvent>,
        public _dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.user = data.user;
        this.assignedBy = data.user;
        this.actionTypes = data.actionTypes;

        var crDate: Date = new Date();

        // this.approvalDate = new Date(); // ne treba
        // this.approvalDateMills = this.approvalDate.getTime(); // ne treba
        // this.approvalDateString = this.datePipe.transform(this.approvalDate, 'dd/MM/yyyy');  // ne treba

        this.actionNumber = crDate.getTime();
        this.creationDateMills = crDate.getTime();
        this.creationDateString = this.datePipe.transform(crDate, 'dd/MM/yyyy @ HH:mm');

        this.allDevices = data.allDevices;

        for (var i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            if (!this.allLines.includes(line)) {
                this.allLines.push(line);
            }
        }
        this.allLines.sort(function (a, b) {
            var nameA = a.toLowerCase(),
                nameB = b.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        // this.teams = data.teams;
        // this.teams.sort(function(a, b){
        //   var nameA=a.id.toLowerCase(), nameB=b.id.toLowerCase()
        //   if (nameA < nameB) //sort string ascending
        //       return -1
        //   if (nameA > nameB)
        //       return 1
        //   return 0
        // });

        this.workInstructionsInfo = data.workInstructionsInfo;

        this.clickedDate = new Date(data.clickedDate);
        if (data.view == 'month' && data.newAction == undefined) {
            // check if dialog is opened from calendar and not from gantt
            this.eventStart = new Date(this.clickedDate.setHours(0, 0, 0, 0));
            this.eventEnd = new Date(this.clickedDate.setHours(23, 59, 59, 999));
        }

        if ((data.view == 'week' || data.view == 'day') && data.newAction == undefined) {
            // check if dialog is opened from calendar and not from gantt
            this.eventStart = this.clickedDate;
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
            this.selectedDuration = this.durationOptions[1];
            this.tpTimeSelectionEnabled = true;
            this.eventStartDisabled = false;
            this.eventEndDisabled = true;
        }

        this.eventdtStart = new Date(this.clickedDate.setHours(8, 0, 0, 0));
        var tempDate1 = new Date(this.eventdtStart);
        tempDate1.setFullYear(this.eventdtStart.getFullYear() + 3);
        this.eventUntil = new Date(tempDate1);

        // if data.newAction is not undefined then this dialog is called from the gantt chart
        // and line, substation and start, end time should be filled when dialog is opened
        if (data.newAction != undefined) {
            // set line and substation
            let levelArr: string[] = data.newAction.substationId.split('_');
            this.selectedLine = levelArr[0];
            this.lineChanged();
            this.selectedSubstation = levelArr[1];
            this.substationChanged();

            let diff: number = data.newAction.end - data.newAction.start - 24 * 3600000;
            if (diff == 0) {
                // gantt chart shifts are disabled and duration is all day (from midnight to midnight),
                // so set end time to 23:59 so that action would not show in the calendar as two days action
                let tDate: Date = new Date(data.newAction.end);
                tDate.setDate(tDate.getDate() - 1);
                tDate.setHours(23, 59, 59, 999);

                this.eventStart = new Date(data.newAction.start);
                this.eventEnd = tDate;
                this.selectedDuration = this.durationOptions[0];
            } else {
                // set start and end time and set duration to custom
                this.eventStart = new Date(data.newAction.start);
                this.eventEnd = new Date(data.newAction.end);
                this.selectedDuration = this.durationOptions[12];
            }
        }
    }

    loadFromTemplate() {}

    public toSave: boolean = false;

    public description: string = '';

    public clickedDate: Date;

    public eventStart: Date;
    public eventEnd: Date;

    public eventdtStart: Date;
    public eventUntil: Date;

    public eventdtStartMills: number; // NOVO
    public eventUntilMills: number; // NOVO

    public eventStartDisabled: boolean = true;
    public eventEndDisabled: boolean = true;
    public tpTimeSelectionEnabled: boolean = false;
    public tpDateSelectionEnabled: boolean = true;

    public week: string[] = ['first', 'second', 'third', 'fourth', 'last'];
    public day: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // public months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Okt", "Nov", "Dec"];

    public rule: RRule;

    public intervalDays: number = 1;
    public intervalWeeks: number = 1;
    public intervalMonths: number = 1;

    public selectedWeekNumberYearly: number = 1;

    public recurrenceOptions: any[] = [
        { value: 0, viewValue: 'Never' },
        { value: 1, viewValue: 'Daily' },
        { value: 2, viewValue: 'Weekly' },
        { value: 3, viewValue: 'The same day each month' },
        // {value: 4, viewValue: "The same week each month"},
        { value: 5, viewValue: 'The same day each year' },
        { value: 8, viewValue: 'The same week number each year' },
        { value: 6, viewValue: 'The same week each year' },
        { value: 7, viewValue: 'Every 6 months' },
    ];

    public durationOptions: any[] = [
        { value: 0, viewValue: 'All day' },
        { value: 1, viewValue: '30 minutes' },
        { value: 2, viewValue: '1 hour' },
        { value: 3, viewValue: '90 minutes' },
        { value: 4, viewValue: '2 hours' },
        { value: 5, viewValue: '2 days' },
        { value: 6, viewValue: '3 days' },
        { value: 7, viewValue: '4 days' },
        { value: 8, viewValue: '5 days' },
        { value: 9, viewValue: '6 days' },
        { value: 10, viewValue: '7 days' },
        { value: 11, viewValue: '10 days' },
        { value: 12, viewValue: 'Custom' },
    ];

    // SAME DAY EACH MONTH...
    public dayMonthly: number = 1;
    // ...SAME WEEK EACH MONTH

    // SAME WEEK EACH MONTH...
    public weeks: any[] = [
        { value: 1, viewValue: 'first' },
        { value: 2, viewValue: 'second' },
        { value: 3, viewValue: 'third' },
        { value: 4, viewValue: 'fourth' },
        { value: 5, viewValue: 'last' },
    ];

    public selectedWeek: any = this.weeks[0];
    public sameWeekMonthlySetPos: number = this.weeks[0].value;

    public days: any[] = [
        { value: 0, viewValue: 'Monday' },
        { value: 1, viewValue: 'Tuesday' },
        { value: 2, viewValue: 'Wednesday' },
        { value: 3, viewValue: 'Thursday' },
        { value: 4, viewValue: 'Friday' },
        { value: 5, viewValue: 'Saturday' },
        { value: 6, viewValue: 'Sunday' },
    ];

    monthlySelectedDay: any = this.days[0];
    monthlyByDayRule: any = RRule.MO;
    // ...SAME WEEK EACH MONTH

    public selectedDuration: any = this.durationOptions[0];

    public repeatChoice: any = this.recurrenceOptions[0];

    public weeklyOnMon: boolean = false;
    public weeklyOnTue: boolean = false;
    public weeklyOnWed: boolean = false;
    public weeklyOnThu: boolean = false;
    public weeklyOnFri: boolean = false;
    public weeklyOnSat: boolean = false;
    public weeklyOnSun: boolean = false;

    // SAME DAY YEARLY...
    dayOfTheMonthYearly: number = 1;
    intervalYears: number = 1;

    months: any[] = [
        { value: 1, viewValue: 'January' },
        { value: 2, viewValue: 'February' },
        { value: 3, viewValue: 'March' },
        { value: 4, viewValue: 'April' },
        { value: 5, viewValue: 'May' },
        { value: 6, viewValue: 'June' },
        { value: 7, viewValue: 'July' },
        { value: 8, viewValue: 'August' },
        { value: 9, viewValue: 'September' },
        { value: 10, viewValue: 'October' },
        { value: 11, viewValue: 'November' },
        { value: 12, viewValue: 'December' },
    ];

    selectedMonth: any = this.months[0];
    selectedMonthValue: number = this.selectedMonth.value;
    // ...SAME DAY YEARLY

    // SAME WEEK EACH YEAR...
    yearlySelectedDay: any = this.days[0];

    // ...SAME WEEK EACH YEAR

    durationOptionChange(event) {
        this.cdr.detectChanges();
        switch (event.value) {
            case 0:
                var tempDate1 = new Date(this.eventStart);
                tempDate1.setHours(0, 0, 0, 0);
                this.eventStart = tempDate1;
                var tempDate2 = new Date(this.eventStart);
                tempDate2.setHours(23, 59, 59, 999);
                this.eventEnd = tempDate2;
                this.eventStartDisabled = true;
                this.eventEndDisabled = true;
                this.tpTimeSelectionEnabled = false;
                break;

            case 1:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 30);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 2:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 60);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 3:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 90);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 4:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 120);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            // 2 days
            case 5:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 3 days
            case 6:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 4 days
            case 7:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 5 days
            case 8:
                // this.tpTimeSelectionEnabled = true;
                // this.eventStartDisabled = false;
                // this.eventEndDisabled = false;
                // break;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 6 days
            case 9:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 7 days
            case 10:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 10 days
            case 11:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // custom
            case 12:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = false;
                break;
        }
        this.cdr.detectChanges();
    }

    checkingRecurenceAndDuration() {
        // this.repeatChoice.value > 0
        // this.repeatChoice.value == 0
        // this.selectedDuration.value >= 5 && this.selectedDuration.value <= 11
        // this.eventEndDisabled = false;

        if (
            this.repeatChoice.value == 0 &&
            this.selectedDuration.value >= 5 &&
            this.selectedDuration.value <= 11
        ) {
            this.eventEndDisabled = true;

            // 2 days
            if (this.selectedDuration.value == 5) {
                this.matchStartAndEndDate(2 * 24 * 60);
            }

            // 3 days
            if (this.selectedDuration.value == 6) {
                this.matchStartAndEndDate(3 * 24 * 60);
            }

            // 4 days
            if (this.selectedDuration.value == 7) {
                this.matchStartAndEndDate(4 * 24 * 60);
            }

            // 5 days
            if (this.selectedDuration.value == 8) {
                this.matchStartAndEndDate(5 * 24 * 60);
            }

            // 6 days
            if (this.selectedDuration.value == 9) {
                this.matchStartAndEndDate(6 * 24 * 60);
            }

            // 7 days
            if (this.selectedDuration.value == 10) {
                this.matchStartAndEndDate(7 * 24 * 60);
            }

            // 10 days
            if (this.selectedDuration.value == 11) {
                this.matchStartAndEndDate(10 * 24 * 60);
            }
        }

        if (
            this.repeatChoice.value > 0 &&
            this.selectedDuration.value >= 5 &&
            this.selectedDuration.value <= 11
        ) {
            this.eventEndDisabled = false;
        }
    }

    matchStartAndEndDate(additionalMinutes: number) {
        var tempDate = new Date(this.eventStart);
        tempDate.setMinutes(this.eventStart.getMinutes() + additionalMinutes);
        this.eventEnd = tempDate;
    }

    dateStartChange($event) {
        if (this.selectedDuration.value == 0) {
            this.eventStart.setHours(0, 0, 0, 0);
            var tempDate = new Date(this.eventStart);
            tempDate.setHours(23, 59, 59, 999);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 1) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 2) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 60);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 3) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 90);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 4) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 120);
            this.eventEnd = tempDate;
        }

        setTimeout(() => {
            this.checkingRecurenceAndDuration();
        }, 200);

        // ***** COMMENTED AFTER checkingRecurenceAndDuration() IS ADDED ...
        // // 2 days
        // if(this.selectedDuration.value == 5){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 1);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 3 days
        // if(this.selectedDuration.value == 6){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 4 days
        // if(this.selectedDuration.value == 7){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 5 days
        // if(this.selectedDuration.value == 8){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 4);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 6 days
        // if(this.selectedDuration.value == 9){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 5);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 7 days
        // if(this.selectedDuration.value == 10){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 6);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 10 days
        // if(this.selectedDuration.value == 11){
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 9);
        //   if(this.repeatChoice.value == 0){
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }
        // ...*****

        this.cdr.detectChanges();
    }

    save() {
        this.toSave = true;

        this.eventdtStartMills = this.eventdtStart.getTime();
        this.eventUntilMills = this.eventUntil.getTime();

        // NEVER
        if (this.repeatChoice.value == 0) {
        }

        // DAILY
        if (this.repeatChoice.value == 1) {
            this.rule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.rule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            this.rule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR
        if (this.repeatChoice.value == 5) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 7) {
            this.rule = new RRule({
                freq: RRule.MONTHLY,
                interval: 6,
            });
        }

        // SAME WEEK NUMBER EACH YEAR
        if (this.repeatChoice.value == 8) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.selectedWeekNumberYearly],
            });
        }

        // in case that no equipmnet is selected in cubicle, then all equipment in cubicle must be selected and all work instructions
        if (this.selectedEquipment.length == 0) {
            this.selectedEquipment = this.equipment;
            this.selectedEquipmentChanged();
            this.selectedWorkInstructions = this.filteredWorkInstructionsInfo;
        }

        // get mongo ids of selected instructons
        for (var i = 0; i < this.selectedWorkInstructions.length; i++) {
            this.selectedWorkInstructionsIds.push(this.selectedWorkInstructions[i].mongoId);
        }

        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    // recurring event start
    currentRule: RRule;
    currentRuleString: string;

    currentRuleText: string = '';

    weeklyByDay: any[] = [];
    dailyExcludeWeekend: boolean = false;

    updateRuleText() {
        // DAILY
        if (this.repeatChoice.value == 1) {
            var bywd: any[] = [];
            if (this.dailyExcludeWeekend) {
                bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            }

            this.currentRule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: bywd,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.weeklyByDay = [];
            if (this.weeklyOnMon) {
                this.weeklyByDay.push(RRule.MO);
            }
            if (this.weeklyOnTue) {
                this.weeklyByDay.push(RRule.TU);
            }
            if (this.weeklyOnWed) {
                this.weeklyByDay.push(RRule.WE);
            }
            if (this.weeklyOnThu) {
                this.weeklyByDay.push(RRule.TH);
            }
            if (this.weeklyOnFri) {
                this.weeklyByDay.push(RRule.FR);
            }
            if (this.weeklyOnSat) {
                this.weeklyByDay.push(RRule.SA);
            }
            if (this.weeklyOnSun) {
                this.weeklyByDay.push(RRule.SU);
            }

            this.currentRule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR
        if (this.repeatChoice.value == 5) {
            this.selectedMonthValue = this.selectedMonth.value;

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.selectedMonthValue = this.selectedMonth.value;

            if (this.yearlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.yearlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.yearlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.yearlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.yearlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.yearlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.yearlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // EVERY SIX MONTHS
        if (this.repeatChoice.value == 7) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: 6,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
            });
        }

        // SAME WEEK NUMBER EACH YEAR
        if (this.repeatChoice.value == 8) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.selectedWeekNumberYearly],
            });
        }

        this.currentRuleText = this.currentRule.toText();
        this.currentRuleString = this.currentRule.toString();

        this.cdr.detectChanges();
    }

    recurrenceOptionChange() {
        setTimeout(() => {
            this.checkingRecurenceAndDuration();
        }, 200);

        // ***** COMMENTED AFTER checkingRecurenceAndDuration() IS ADDED ...
        // this.selectedDuration = this.durationOptions[0];
        // this.durationOptionChange(this.durationOptions[0]);

        // if(this.repeatChoice.value > 0 && (this.selectedDuration.value >= 5 && this.selectedDuration.value <= 11 )){
        //   this.eventEndDisabled = false;
        // }

        // if(this.repeatChoice.value == 0){
        //   this.eventEndDisabled = true;
        // }
        // ...*****

        if (this.repeatChoice.value > 0) {
            this.updateRuleText();
        }
    }

    allLines: string[] = [];
    substations: string[] = [];
    cubicles: string[] = [];
    equipment: IomsDevice[] = [];

    selectedLine: string;
    selectedSubstation: string;
    selectedCubicles: string[];
    selectedEquipment: IomsDevice[] = [];

    // selectedDevices: IomsDevice[];

    lineChanged() {
        this.allSelected = false;
        this.substations = [];
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.selectedSubstation = undefined;
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            if (line == this.selectedLine) {
                if (!this.substations.includes(substation)) {
                    this.substations.push(substation);
                }
            }
        }
    }

    substationChanged() {
        this.allSelected = false;
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                if (!this.cubicles.includes(cubicle)) {
                    this.cubicles.push(cubicle);
                }
            }
        }
    }

    cubicleChanged() {
        this.allSelected = false;
        this.equipment = [];
        this.selectedEquipment = [];
        this.selectedWorkInstructions = [];
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            let equipment = this.allDevices[i].levelId.split('_')[3];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                for (let j = 0; j < this.selectedCubicles.length; j++) {
                    if (cubicle == this.selectedCubicles[j]) {
                        if (!this.equipment.includes(this.allDevices[i])) {
                            this.equipment.push(this.allDevices[i]);
                            this.location = this.allDevices[i].location;
                        }
                    }
                }
            }
        }
    }

    // for selecting all equipment with one checkbox (START)
    @ViewChild('select', { static: false }) select: MatSelect;

    allSelected = false;

    toggleAllSelection() {
        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    optionClick() {
        let newStatus = true;
        this.select.options.forEach((item: MatOption) => {
            if (!item.selected) {
                newStatus = false;
            }
        });
        this.allSelected = newStatus;
    }

    // for selecting all equipment with one checkbox (END)

    selectedEquipmentChanged() {
        this.filterWorkInstructions();
        this.selectedWorkInstructions = [];
    }

    filterWorkInstructions() {
        this.filteredWorkInstructionsInfo = [];
        for (var i = 0; i < this.selectedEquipment.length; i++) {
            if (
                this.selectedEquipment[i].workInstructionNames != undefined &&
                this.selectedEquipment[i].workInstructionNames.length > 0
            ) {
                for (
                    var j = 0;
                    j < this.selectedEquipment[i].workInstructionNameAndId.length;
                    j++
                ) {
                    for (var m = 0; m < this.workInstructionsInfo.length; m++) {
                        if (
                            this.selectedEquipment[i].workInstructionNameAndId[j].objectId ==
                            this.workInstructionsInfo[m].mongoId
                        ) {
                            if (
                                !this.foundWorkInstructionDuplicate(
                                    this.selectedEquipment[i].workInstructionNameAndId[j].objectId
                                )
                            ) {
                                this.filteredWorkInstructionsInfo.push(
                                    this.workInstructionsInfo[m]
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    foundWorkInstructionDuplicate(value: string): boolean {
        for (var i = 0; i < this.filteredWorkInstructionsInfo.length; i++) {
            if (value == this.filteredWorkInstructionsInfo[i].mongoId) {
                return true;
            }
        }
        return false;
    }

    createPowerBlockRequest() {}

    // assignToTeam(){ //ne treba
    //   // this.assigned = true;
    // }
}

// EDIT SCHEDULE ACTION
@Component({
    selector: 'dialogEditScheduleEvent',
    templateUrl: 'dialog-edit-schedule-event.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
})
export class DialogEditScheduleEvent {
    public user: any;

    public actionNumber: number;

    public creationDate: Date;
    public creationDateMills: number;
    public creationDateString: string;

    public actionTypes: any[];
    public selectedActionType: any;

    public allDevices: IomsDevice[];

    public briefDescription: string = '';
    public reportId: string = '';
    public location: string = '';

    // public teams: IomsTeamLevel[];
    // public selectedTeam: IomsTeamLevel;

    public workInstructionsInfo: any[];
    public filteredWorkInstructionsInfo: any[];
    public selectedWorkInstructions: any[];

    public assignedBy: any;
    // public assigned: boolean = false; // ne treba

    public approvalDate: Date;
    public approvalDateMills: number;
    public approvalDateString: string;

    //------------------
    public selectedEquipmentIds: string[] = [];
    public selectedWorkInstructionsIds: string[] = [];
    public selectedRepeatOption: number;
    public rrule: any;
    //------------------

    public selectedDuration: any;

    constructor(
        public dialogRef: MatDialogRef<DialogEditScheduleEvent>,
        public _dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.user = data.user;
        this.assignedBy = data.user;
        this.actionTypes = data.actionTypes;

        var crDate: Date = new Date();

        // match selected duration
        for (let i = 0; i < this.durationOptions.length; i++) {
            if (this.durationOptions[i].viewValue == data.selectedDuration) {
                this.selectedDuration = this.durationOptions[i];
                setTimeout(() => {
                    this.durationOptionChange(this.selectedDuration);
                }, 10);
                break;
            }
        }

        this.approvalDate = new Date();
        this.approvalDateMills = this.approvalDate.getTime();
        this.approvalDateString = this.datePipe.transform(this.approvalDate, 'dd/MM/yyyy');

        this.actionNumber = data.actionNumber;
        this.creationDateMills = data.creationDate;
        this.creationDateString = this.datePipe.transform(
            new Date(this.creationDateMills),
            'dd/MM/yyyy @ HH:mm'
        );

        this.allDevices = data.allDevices;

        for (var i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            if (!this.allLines.includes(line)) {
                this.allLines.push(line);
            }
        }
        this.allLines.sort(function (a, b) {
            var nameA = a.toLowerCase(),
                nameB = b.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        // this.teams = data.teams;
        // this.teams.sort(function (a, b) {
        //   var nameA = a.id.toLowerCase(), nameB = b.id.toLowerCase()
        //   if (nameA < nameB) //sort string ascending
        //     return -1
        //   if (nameA > nameB)
        //     return 1
        //   return 0
        // });

        this.workInstructionsInfo = data.workInstructionsInfo;

        this.clickedDate = new Date(data.clickedDate);
        if (data.view == 'month') {
            this.eventStart = new Date(this.clickedDate.setHours(0, 0, 0, 0));
            this.eventEnd = new Date(this.clickedDate.setHours(23, 59, 59, 999));
        }

        if (data.view == 'week' || data.view == 'day') {
            this.eventStart = this.clickedDate;
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
            this.selectedDuration = this.durationOptions[1];
            this.tpTimeSelectionEnabled = true;
            this.eventStartDisabled = false;
            this.eventEndDisabled = true;
        }

        this.eventdtStart = new Date(this.clickedDate.setHours(8, 0, 0, 0));
        var tempDate1 = new Date(this.eventdtStart);
        tempDate1.setFullYear(this.eventdtStart.getFullYear() + 3);
        this.eventUntil = new Date(tempDate1);

        //---------------
        this.reportId = data.reportId;
        this.location = data.location;
        this.briefDescription = data.description;

        let levelArr: string[] = data.deviceLevelId.split('_');

        this.selectedLine = levelArr[0];
        this.lineChanged();
        this.selectedSubstation = levelArr[1];
        this.substationChanged();
        let cubArr: string[] = [];
        if (levelArr[2].includes(';')) {
            cubArr = levelArr[2].split(';');
        } else {
            cubArr.push(levelArr[2]);
        }
        this.selectedCubicles = cubArr;
        this.cubicleChanged();

        this.selectedEquipmentIds = data.selectedEquipmentIds;
        for (var i = 0; i < this.selectedEquipmentIds.length; i++) {
            for (var j = 0; j < this.allDevices.length; j++) {
                if (this.selectedEquipmentIds[i].split(';')[1] == this.allDevices[j].mongoId) {
                    this.selectedEquipment.push(this.allDevices[j]);
                    break;
                }
            }
        }

        if (this.selectedEquipment.length == this.equipment.length) {
            this.allSelected = true;
        }

        this.filterWorkInstructions();
        this.selectedWorkInstructionsIds = data.selectedWorkInstructionsIds;
        for (var i = 0; i < this.selectedWorkInstructionsIds.length; i++) {
            for (var j = 0; j < this.workInstructionsInfo.length; j++) {
                if (this.selectedWorkInstructionsIds[i] == this.workInstructionsInfo[j].mongoId) {
                    this.selectedWorkInstructions.push(this.workInstructionsInfo[j]);
                }
            }
        }

        this.selectedRepeatOption = data.selectedRepeatOption;

        this.eventStart = data.eventStart;
        this.eventEnd = data.eventEnd;

        for (var i = 0; i < this.actionTypes.length; i++) {
            if (this.actionTypes[i].mongoId == data.actionTypeId) {
                this.selectedActionType = this.actionTypes[i];
            }
        }

        this.rrule = JSON.parse(data.rrule);
        var st: Date = new Date(this.rrule.dtstart);
        var et: Date = new Date(this.rrule.until);
        this.rrule.dtstart = st;
        this.rrule.until = et;

        for (var i = 0; i < this.recurrenceOptions.length; i++) {
            if (this.recurrenceOptions[i].value == this.selectedRepeatOption) {
                this.repeatChoice = this.recurrenceOptions[i];
            }
        }

        // DAILY
        if (this.selectedRepeatOption == 1) {
            this.intervalDays = this.rrule.interval;
            if (this.rrule.byweekday.length > 0) {
                this.dailyExcludeWeekend = true;
            } else {
                this.dailyExcludeWeekend = false;
            }
            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // WEEKLY
        if (this.selectedRepeatOption == 2) {
            this.intervalWeeks = this.rrule.interval;
            if (this.rrule.byweekday.length > 0) {
                for (var i = 0; i < this.rrule.byweekday.length; i++) {
                    if (this.rrule.byweekday[i].weekday == 0) {
                        this.weeklyOnMon = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 1) {
                        this.weeklyOnTue = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 2) {
                        this.weeklyOnWed = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 3) {
                        this.weeklyOnThu = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 4) {
                        this.weeklyOnFri = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 5) {
                        this.weeklyOnSat = true;
                    }
                    if (this.rrule.byweekday[i].weekday == 6) {
                        this.weeklyOnSun = true;
                    }
                }
            }
            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // SAME DAY EACH MONTH
        if (this.selectedRepeatOption == 3) {
            this.dayMonthly = this.rrule.bysetpos[0];
            this.intervalMonths = this.rrule.interval;
            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // SAME WEEK EACH MONTH
        if (this.selectedRepeatOption == 4) {
            // this.intervalMonths =
            // this.selectedWeek =
            // this.eventdtStart = new Date(this.rrule.dtstart);
            // this.eventUntil = new Date(this.rrule.until);
            // setTimeout(() => {
            //   this.updateRuleText();
            // }, 1000);
        }

        // SAME DAY EACH YEAR
        if (this.selectedRepeatOption == 5) {
            this.intervalYears = this.rrule.interval;
            for (var i = 0; i < this.months.length; i++) {
                if (this.months[i].value == this.rrule.bymonth[0]) {
                    this.selectedMonth = this.months[i];
                }
            }
            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // SAME WEEK EACH YEAR
        if (this.selectedRepeatOption == 6) {
            this.intervalYears = this.rrule.interval;

            this.selectedWeek = this.weeks[+this.rrule.bysetpos[0] - 1];

            this.yearlySelectedDay = this.days[this.rrule.byweekday[0].weekday];

            for (var i = 0; i < this.months.length; i++) {
                if (this.months[i].value == this.rrule.bymonth[0]) {
                    this.selectedMonth = this.months[i];
                }
            }

            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // EVERY 6 MONTHS
        if (this.selectedRepeatOption == 7) {
            this.eventdtStart = new Date(this.rrule.dtstart);
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        // SAME WEEK NUMBER EACH YEAR
        if (this.selectedRepeatOption == 8) {
            this.monthlySelectedDay = this.days[this.rrule.byweekday.weekday];
            this.intervalYears = this.rrule.interval;
            (this.selectedWeekNumberYearly = this.rrule.bysetpos[0]),
                (this.eventdtStart = new Date(this.rrule.dtstart));
            this.eventUntil = new Date(this.rrule.until);
            setTimeout(() => {
                this.updateRuleText();
            }, 1000);
        }

        //---------------
    }

    loadFromTemplate() {}

    public toSave: boolean = false;

    public description: string = '';

    public clickedDate: Date;

    public eventStart: Date;
    public eventEnd: Date;

    public eventdtStart: Date;
    public eventUntil: Date;

    public eventdtStartMills: number; // NOVO
    public eventUntilMills: number; // NOVO

    public eventStartDisabled: boolean = true;
    public eventEndDisabled: boolean = true;
    public tpTimeSelectionEnabled: boolean = false;
    public tpDateSelectionEnabled: boolean = true;

    public week: string[] = ['first', 'second', 'third', 'fourth', 'last'];
    public day: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // public months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Okt", "Nov", "Dec"];

    public rule: RRule;

    public selectedWeekNumberYearly: number = 1;

    public intervalDays: number = 1;
    public intervalWeeks: number = 1;
    public intervalMonths: number = 1;

    public recurrenceOptions: any[] = [
        { value: 0, viewValue: 'Never' },
        { value: 1, viewValue: 'Daily' },
        { value: 2, viewValue: 'Weekly' },
        { value: 3, viewValue: 'The same day each month' },
        // {value: 4, viewValue: "The same week each month"},
        { value: 8, viewValue: 'The same week number each year' },
        { value: 5, viewValue: 'The same day each year' },
        { value: 6, viewValue: 'The same week each year' },
        { value: 7, viewValue: 'Every 6 months' },
    ];

    public durationOptions: any[] = [
        { value: 0, viewValue: 'All day' },
        { value: 1, viewValue: '30 minutes' },
        { value: 2, viewValue: '1 hour' },
        { value: 3, viewValue: '90 minutes' },
        { value: 4, viewValue: '2 hours' },
        { value: 5, viewValue: '2 days' },
        { value: 6, viewValue: '3 days' },
        { value: 7, viewValue: '4 days' },
        { value: 8, viewValue: '5 days' },
        { value: 9, viewValue: '6 days' },
        { value: 10, viewValue: '7 days' },
        { value: 11, viewValue: '10 days' },
        { value: 12, viewValue: 'Custom' },
    ];

    // SAME DAY EACH MONTH...
    public dayMonthly: number = 1;
    // ...SAME WEEK EACH MONTH

    // SAME WEEK EACH MONTH...
    public weeks: any[] = [
        { value: 1, viewValue: 'first' },
        { value: 2, viewValue: 'second' },
        { value: 3, viewValue: 'third' },
        { value: 4, viewValue: 'fourth' },
        { value: 5, viewValue: 'last' },
    ];

    public selectedWeek: any = this.weeks[0];
    public sameWeekMonthlySetPos: number = this.weeks[0].value;

    public days: any[] = [
        { value: 0, viewValue: 'Monday' },
        { value: 1, viewValue: 'Tuesday' },
        { value: 2, viewValue: 'Wednesday' },
        { value: 3, viewValue: 'Thursday' },
        { value: 4, viewValue: 'Friday' },
        { value: 5, viewValue: 'Saturday' },
        { value: 6, viewValue: 'Sunday' },
    ];

    monthlySelectedDay: any = this.days[0];
    monthlyByDayRule: any = RRule.MO;
    // ...SAME WEEK EACH MONTH

    public repeatChoice: any = {};

    public weeklyOnMon: boolean = false;
    public weeklyOnTue: boolean = false;
    public weeklyOnWed: boolean = false;
    public weeklyOnThu: boolean = false;
    public weeklyOnFri: boolean = false;
    public weeklyOnSat: boolean = false;
    public weeklyOnSun: boolean = false;

    // SAME DAY YEARLY...
    dayOfTheMonthYearly: number = 1;
    intervalYears: number = 1;

    months: any[] = [
        { value: 1, viewValue: 'January' },
        { value: 2, viewValue: 'February' },
        { value: 3, viewValue: 'March' },
        { value: 4, viewValue: 'April' },
        { value: 5, viewValue: 'May' },
        { value: 6, viewValue: 'June' },
        { value: 7, viewValue: 'July' },
        { value: 8, viewValue: 'August' },
        { value: 9, viewValue: 'September' },
        { value: 10, viewValue: 'October' },
        { value: 11, viewValue: 'November' },
        { value: 12, viewValue: 'December' },
    ];

    selectedMonth: any = this.months[0];
    selectedMonthValue: number = this.selectedMonth.value;
    // ...SAME DAY YEARLY

    // SAME WEEK EACH YEAR...
    yearlySelectedDay: any = this.days[0];

    // ...SAME WEEK EACH YEAR

    durationOptionChange(event) {
        this.cdr.detectChanges();
        switch (event.value) {
            case 0:
                var tempDate1 = new Date(this.eventStart);
                tempDate1.setHours(0, 0, 0, 0);
                this.eventStart = tempDate1;
                var tempDate2 = new Date(this.eventStart);
                tempDate2.setHours(23, 59, 59, 999);
                this.eventEnd = tempDate2;
                this.eventStartDisabled = true;
                this.eventEndDisabled = true;
                this.tpTimeSelectionEnabled = false;
                break;

            case 1:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 30);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 2:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 60);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 3:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 90);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 4:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 120);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 5:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            case 6: // 3 days
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            case 7:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            case 8:
                // this.tpTimeSelectionEnabled = true;
                // this.eventStartDisabled = false;
                // this.eventEndDisabled = false;
                // break;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            case 9:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            case 10:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // 10 days
            case 11:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                if (this.repeatChoice.value == 0) {
                    this.eventEndDisabled = true;
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
                    this.eventEnd = tempDate;
                } else {
                    this.eventEndDisabled = false;
                }
                break;

            // custom
            case 12:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = false;
                break;
        }
        this.cdr.detectChanges();
    }

    checkingRecurenceAndDuration() {
        // this.repeatChoice.value > 0
        // this.repeatChoice.value == 0
        // this.selectedDuration.value >= 5 && this.selectedDuration.value <= 11
        // this.eventEndDisabled = false;

        if (
            this.repeatChoice.value == 0 &&
            this.selectedDuration.value >= 5 &&
            this.selectedDuration.value <= 11
        ) {
            this.eventEndDisabled = true;

            // 2 days
            if (this.selectedDuration.value == 5) {
                this.matchStartAndEndDate(2 * 24 * 60);
            }

            // 3 days
            if (this.selectedDuration.value == 6) {
                this.matchStartAndEndDate(3 * 24 * 60);
            }

            // 4 days
            if (this.selectedDuration.value == 7) {
                this.matchStartAndEndDate(4 * 24 * 60);
            }

            // 5 days
            if (this.selectedDuration.value == 8) {
                this.matchStartAndEndDate(5 * 24 * 60);
            }

            // 6 days
            if (this.selectedDuration.value == 9) {
                this.matchStartAndEndDate(6 * 24 * 60);
            }

            // 7 days
            if (this.selectedDuration.value == 10) {
                this.matchStartAndEndDate(7 * 24 * 60);
            }

            // 10 days
            if (this.selectedDuration.value == 11) {
                this.matchStartAndEndDate(10 * 24 * 60);
            }
        }

        if (
            this.repeatChoice.value > 0 &&
            this.selectedDuration.value >= 5 &&
            this.selectedDuration.value <= 11
        ) {
            this.eventEndDisabled = false;
        }
    }

    matchStartAndEndDate(additionalMinutes: number) {
        var tempDate = new Date(this.eventStart);
        tempDate.setMinutes(this.eventStart.getMinutes() + additionalMinutes);
        this.eventEnd = tempDate;
    }

    dateStartChange($event) {
        if (this.selectedDuration.value == 0) {
            this.eventStart.setHours(0, 0, 0, 0);
            var tempDate = new Date(this.eventStart);
            tempDate.setHours(23, 59, 59, 999);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 1) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 2) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 60);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 3) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 90);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 4) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 120);
            this.eventEnd = tempDate;
        }

        setTimeout(() => {
            this.checkingRecurenceAndDuration();
        }, 200);

        // ***** COMMENTED AFTER checkingRecurenceAndDuration() IS ADDED ...
        // // 2 days
        // if (this.selectedDuration.value == 5) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 1);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 3 days
        // if (this.selectedDuration.value == 6) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 4 days
        // if (this.selectedDuration.value == 7) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 5 days
        // if (this.selectedDuration.value == 8) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 4);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 6 days
        // if (this.selectedDuration.value == 9) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 5);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 7 days
        // if (this.selectedDuration.value == 10) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 6);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }

        // // 10 days
        // if (this.selectedDuration.value == 11) {
        //   // this.eventEnd.setDate(this.eventEnd.getDate() + 9);
        //   if (this.repeatChoice.value == 0) {
        //     var tempDate = new Date(this.eventStart);
        //     tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
        //     this.eventEnd = tempDate;
        //   }
        // }
        // ...*****

        this.cdr.detectChanges();
    }

    save() {
        this.toSave = true;

        this.eventdtStartMills = this.eventdtStart.getTime();
        this.eventUntilMills = this.eventUntil.getTime();

        // NEVER
        if (this.repeatChoice.value == 0) {
        }

        // DAILY
        if (this.repeatChoice.value == 1) {
            this.rule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.rule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            this.rule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR
        if (this.repeatChoice.value == 5) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 7) {
            this.rule = new RRule({
                freq: RRule.MONTHLY,
                interval: 6,
            });
        }

        // SAME WEEK NUMBER EACH YEAR
        if (this.repeatChoice.value == 8) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            this.rule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.selectedWeekNumberYearly],
            });
        }

        // in case that no equipmnet is selected in cubicle, then all equipment in cubicle must be selected and all work instructions
        if (this.selectedEquipment.length == 0) {
            this.selectedEquipment = this.equipment;
            this.selectedEquipmentChanged();
            this.selectedWorkInstructions = this.filteredWorkInstructionsInfo;
        }

        // get mongo ids of selected instructons
        this.selectedWorkInstructionsIds = [];
        for (var i = 0; i < this.selectedWorkInstructions.length; i++) {
            this.selectedWorkInstructionsIds.push(this.selectedWorkInstructions[i].mongoId);
        }

        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    // recurring event start
    currentRule: RRule;
    currentRuleString: string;

    currentRuleText: string = '';

    weeklyByDay: any[] = [];
    dailyExcludeWeekend: boolean = false;

    updateRuleText() {
        // DAILY
        if (this.repeatChoice.value == 1) {
            var bywd: any[] = [];
            if (this.dailyExcludeWeekend) {
                bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            }

            this.currentRule = new RRule({
                freq: RRule.DAILY,
                interval: this.intervalDays,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: bywd,
            });
        }

        // WEEKLY
        if (this.repeatChoice.value == 2) {
            this.weeklyByDay = [];
            if (this.weeklyOnMon) {
                this.weeklyByDay.push(RRule.MO);
            }
            if (this.weeklyOnTue) {
                this.weeklyByDay.push(RRule.TU);
            }
            if (this.weeklyOnWed) {
                this.weeklyByDay.push(RRule.WE);
            }
            if (this.weeklyOnThu) {
                this.weeklyByDay.push(RRule.TH);
            }
            if (this.weeklyOnFri) {
                this.weeklyByDay.push(RRule.FR);
            }
            if (this.weeklyOnSat) {
                this.weeklyByDay.push(RRule.SA);
            }
            if (this.weeklyOnSun) {
                this.weeklyByDay.push(RRule.SU);
            }

            this.currentRule = new RRule({
                freq: RRule.WEEKLY,
                interval: this.intervalWeeks,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.weeklyByDay,
            });
        }

        // SAME DAY EACH MONTH
        if (this.repeatChoice.value == 3) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bysetpos: [this.dayMonthly],
            });
        }

        // SAME WEEK EACH MONTH
        if (this.repeatChoice.value == 4) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: this.intervalMonths,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // SAME DAY EACH YEAR
        if (this.repeatChoice.value == 5) {
            this.selectedMonthValue = this.selectedMonth.value;

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.dayOfTheMonthYearly],
            });
        }

        // SAME WEEK EACH YEAR
        if (this.repeatChoice.value == 6) {
            this.selectedMonthValue = this.selectedMonth.value;

            if (this.yearlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.yearlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.yearlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.yearlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.yearlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.yearlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.yearlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            if (this.selectedWeek.value >= 1 && this.selectedWeek.value <= 4) {
                this.sameWeekMonthlySetPos = this.selectedWeek.value;
            } else {
                this.sameWeekMonthlySetPos = -1;
            }

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: [this.monthlyByDayRule],
                bymonth: [this.selectedMonthValue],
                bysetpos: [this.sameWeekMonthlySetPos],
            });
        }

        // EVERY SIX MONTHS
        if (this.repeatChoice.value == 7) {
            this.currentRule = new RRule({
                freq: RRule.MONTHLY,
                interval: 6,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
            });
        }

        // SAME WEEK NUMBER EACH YEAR
        if (this.repeatChoice.value == 8) {
            if (this.monthlySelectedDay.value == 0) {
                this.monthlyByDayRule = RRule.MO;
            }
            if (this.monthlySelectedDay.value == 1) {
                this.monthlyByDayRule = RRule.TU;
            }
            if (this.monthlySelectedDay.value == 2) {
                this.monthlyByDayRule = RRule.WE;
            }
            if (this.monthlySelectedDay.value == 3) {
                this.monthlyByDayRule = RRule.TH;
            }
            if (this.monthlySelectedDay.value == 4) {
                this.monthlyByDayRule = RRule.FR;
            }
            if (this.monthlySelectedDay.value == 5) {
                this.monthlyByDayRule = RRule.SA;
            }
            if (this.monthlySelectedDay.value == 6) {
                this.monthlyByDayRule = RRule.SU;
            }

            this.currentRule = new RRule({
                freq: RRule.YEARLY,
                interval: this.intervalYears,
                dtstart: this.eventdtStart,
                until: this.eventUntil,
                byweekday: this.monthlyByDayRule,
                bysetpos: [this.selectedWeekNumberYearly],
            });
        }

        this.currentRuleText = this.currentRule.toText();
        this.currentRuleString = this.currentRule.toString();

        this.cdr.detectChanges();
    }

    recurrenceOptionChange() {
        setTimeout(() => {
            this.checkingRecurenceAndDuration();
        }, 200);

        // ***** COMMENTED AFTER checkingRecurenceAndDuration() IS ADDED ...
        // this.selectedDuration = this.durationOptions[0];
        // this.durationOptionChange(this.durationOptions[0]);

        // if(this.repeatChoice.value > 0 && (this.selectedDuration.value >= 5 && this.selectedDuration.value <= 11 )){
        //   this.eventEndDisabled = false;
        // }

        // if(this.repeatChoice.value == 0){
        //   this.eventEndDisabled = true;
        // }
        // ...*****

        if (this.repeatChoice.value > 0) {
            this.updateRuleText();
        }
    }

    allLines: string[] = [];
    substations: string[] = [];
    cubicles: string[] = [];
    equipment: IomsDevice[] = [];

    selectedLine: string;
    selectedSubstation: string;
    selectedCubicles: string[];
    selectedEquipment: IomsDevice[] = [];

    // selectedDevices: IomsDevice[];

    lineChanged() {
        this.allSelected = false;
        this.substations = [];
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.filteredWorkInstructionsInfo = [];
        this.selectedSubstation = undefined;
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            if (line == this.selectedLine) {
                if (!this.substations.includes(substation)) {
                    this.substations.push(substation);
                }
            }
        }
    }

    substationChanged() {
        this.allSelected = false;
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.filteredWorkInstructionsInfo = [];
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                if (!this.cubicles.includes(cubicle)) {
                    this.cubicles.push(cubicle);
                }
            }
        }
    }

    cubicleChanged() {
        this.allSelected = false;
        this.equipment = [];
        this.selectedEquipment = [];
        this.selectedWorkInstructions = [];
        this.filteredWorkInstructionsInfo = [];
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            let equipmnent = this.allDevices[i].levelId.split('_')[3];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                for (let j = 0; j < this.selectedCubicles.length; j++) {
                    if (cubicle == this.selectedCubicles[j]) {
                        if (!this.equipment.includes(this.allDevices[i])) {
                            this.equipment.push(this.allDevices[i]);
                            this.location = this.allDevices[i].location;
                        }
                    }
                }
            }
        }
    }

    // for selecting all equipment with one checkbox (START)
    @ViewChild('select', { static: false }) select: MatSelect;

    allSelected = false;

    toggleAllSelection() {
        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    optionClick() {
        let newStatus = true;
        this.select.options.forEach((item: MatOption) => {
            if (!item.selected) {
                newStatus = false;
            }
        });
        this.allSelected = newStatus;
    }

    // for selecting all equipment with one checkbox (END)

    selectedEquipmentChanged() {
        this.filterWorkInstructions();
        this.selectedWorkInstructions = [];
    }

    filterWorkInstructions() {
        this.filteredWorkInstructionsInfo = [];
        for (var i = 0; i < this.selectedEquipment.length; i++) {
            if (
                this.selectedEquipment[i].workInstructionNames != undefined &&
                this.selectedEquipment[i].workInstructionNames.length > 0
            ) {
                for (
                    var j = 0;
                    j < this.selectedEquipment[i].workInstructionNameAndId.length;
                    j++
                ) {
                    for (var m = 0; m < this.workInstructionsInfo.length; m++) {
                        if (
                            this.selectedEquipment[i].workInstructionNameAndId[j].objectId ==
                            this.workInstructionsInfo[m].mongoId
                        ) {
                            if (
                                !this.foundWorkInstructionDuplicate(
                                    this.selectedEquipment[i].workInstructionNameAndId[j].objectId
                                )
                            ) {
                                this.filteredWorkInstructionsInfo.push(
                                    this.workInstructionsInfo[m]
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    foundWorkInstructionDuplicate(value: string): boolean {
        for (var i = 0; i < this.filteredWorkInstructionsInfo.length; i++) {
            if (value == this.filteredWorkInstructionsInfo[i].mongoId) {
                return true;
            }
        }
        return false;
    }

    createPowerBlockRequest() {}

    // assignToTeam(){ // ne treba
    //   this.assigned = true;
    // }
}

// DIALOG ASSIGN ACTION
@Component({
    selector: 'dialogAssignAction',
    templateUrl: 'dialog-assign-action.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
})
export class DialogAssignAction {
    asked: boolean;
    wait: boolean;
    approved: boolean;

    public mongoId: string;
    public user: any;
    public currentUser: any;

    public actionNumber: number;

    public creationDate: Date;
    public creationDateMills: number;
    public creationDateString: string;

    public actionTypes: any[];
    public selectedActionType: any;

    public allDevices: IomsDevice[];

    public briefDescription: string = '';
    public reportId: string = '';
    public location: string = '';

    public teams: IomsTeamLevel[];
    public selectedTeam: IomsTeamLevel;
    public selectedTeamId: string;

    public workInstructionsInfo: any[];
    public filteredWorkInstructionsInfo: any[];
    public selectedWorkInstructions: any[];

    public assignedBy: any;
    public assigned: boolean = false;

    public approvalDate: Date;
    public approvalDateMills: number;
    public approvalDateString: string;

    public selectedEquipmentIds: string[] = [];
    public selectedWorkInstructionsIds: string[] = [];
    public selectedRepeatOption: number;

    public allLines: string[] = [];
    public substations: string[] = [];
    public cubicles: string[] = [];
    public equipment: IomsDevice[] = [];

    public selectedLine: string;
    public selectedSubstation: string;
    public selectedCubicles: string[];
    public selectedEquipment: IomsDevice[] = [];

    public clickedDate: Date;

    public eventStart: Date;
    public eventEnd: Date;

    public eventStartDisabled: boolean = true;
    public eventEndDisabled: boolean = true;
    public tpTimeSelectionEnabled: boolean = false;
    public tpDateSelectionEnabled: boolean = true;

    public readOnlyUser: boolean;

    public status: string;
    public readOnly = '';

    public durationOptions: any[] = [
        { value: 0, viewValue: 'All day' },
        { value: 1, viewValue: '30 minutes' },
        { value: 2, viewValue: '1 hour' },
        { value: 3, viewValue: '90 minutes' },
        { value: 4, viewValue: '2 hours' },
        { value: 5, viewValue: '2 days' },
        { value: 6, viewValue: '3 days' },
        { value: 7, viewValue: '4 days' },
        { value: 8, viewValue: '5 days' },
        { value: 9, viewValue: '6 days' },
        { value: 10, viewValue: '7 days' },
        { value: 11, viewValue: '10 days' },
        { value: 12, viewValue: 'Custom' },
    ];

    public selectedDuration: any;

    constructor(
        public dialogRef: MatDialogRef<DialogAssignAction>,
        public _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        private _iomsDeviceManagementService: IomsDeviceManagerService, //SCHEDULE ACTION
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.status = data.status;

        this.readOnlyUser = data.readOnlyUser;

        if (this.status == 'COMPLETED' || this.readOnlyUser) {
            this.readOnly = 'read-only';
        }

        this.mongoId = data.mongoId;
        this.currentUser = data.currentUser;

        this.user = data.user;
        this.assignedBy = data.user;
        this.actionTypes = data.actionTypes;

        var crDate: Date = new Date();

        // match selected duration
        for (let i = 0; i < this.durationOptions.length; i++) {
            if (this.durationOptions[i].viewValue == data.selectedDuration) {
                this.selectedDuration = this.durationOptions[i];
                break;
            }
        }

        this.actionNumber = data.actionNumber;
        this.creationDateMills = data.creationDate;
        this.creationDateString = this.datePipe.transform(
            new Date(this.creationDateMills),
            'dd/MM/yyyy @ HH:mm'
        );

        this.allDevices = data.allDevices;

        for (var i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            if (!this.allLines.includes(line)) {
                this.allLines.push(line);
            }
        }
        this.allLines.sort(function (a, b) {
            var nameA = a.toLowerCase(),
                nameB = b.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.teams = data.teams;
        this.teams.sort(function (a, b) {
            var nameA = a.id.toLowerCase(),
                nameB = b.id.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.assigned = data.assigned;
        // if it is assigned, then set selected team and approval date
        if (this.assigned) {
            for (let i = 0; i < this.teams.length; i++) {
                if (this.teams[i].mongoId == this.data.teamId) {
                    this.selectedTeam = this.teams[i];
                }
            }
            this.approvalDate = new Date(data.approvalDate);
        } else {
            this.approvalDate = new Date();
        }
        this.approvalDateMills = this.approvalDate.getTime();
        this.approvalDateString = this.datePipe.transform(this.approvalDate, 'dd/MM/yyyy');

        this.workInstructionsInfo = data.workInstructionsInfo;

        this.clickedDate = new Date(data.clickedDate);
        if (data.view == 'month') {
            this.eventStart = new Date(this.clickedDate.setHours(0, 0, 0, 0));
            this.eventEnd = new Date(this.clickedDate.setHours(23, 59, 59, 999));
        }

        if (data.view == 'week' || data.view == 'day') {
            this.eventStart = this.clickedDate;
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
            this.selectedDuration = this.durationOptions[1];
            this.tpTimeSelectionEnabled = true;
            this.eventStartDisabled = false;
            this.eventEndDisabled = true;
        }

        this.reportId = data.reportId;
        this.location = data.location;
        this.briefDescription = data.description;

        let levelArr: string[] = data.deviceLevelId.split('_');

        this.selectedLine = levelArr[0];
        this.lineChanged();
        this.selectedSubstation = levelArr[1];
        this.substationChanged();
        let cubArr: string[] = [];
        if (levelArr[2].includes(';')) {
            cubArr = levelArr[2].split(';');
        } else {
            cubArr.push(levelArr[2]);
        }
        this.selectedCubicles = cubArr;
        this.cubicleChanged();

        this.selectedEquipmentIds = data.selectedEquipmentIds;
        for (var i = 0; i < this.selectedEquipmentIds.length; i++) {
            for (var j = 0; j < this.allDevices.length; j++) {
                if (this.selectedEquipmentIds[i].split(';')[1] == this.allDevices[j].mongoId) {
                    this.selectedEquipment.push(this.allDevices[j]);
                    break;
                }
            }
        }

        this.filterWorkInstructions();
        this.selectedWorkInstructionsIds = data.selectedWorkInstructionsIds;
        for (var i = 0; i < this.selectedWorkInstructionsIds.length; i++) {
            for (var j = 0; j < this.workInstructionsInfo.length; j++) {
                if (this.selectedWorkInstructionsIds[i] == this.workInstructionsInfo[j].mongoId) {
                    this.selectedWorkInstructions.push(this.workInstructionsInfo[j]);
                }
            }
        }
        this.selectedRepeatOption = data.selectedRepeatOption;

        this.eventStart = data.eventStart;
        this.eventEnd = data.eventEnd;

        for (var i = 0; i < this.actionTypes.length; i++) {
            if (this.actionTypes[i].mongoId == data.actionTypeId) {
                this.selectedActionType = this.actionTypes[i];
            }
        }
    }

    calculateClasses() {
        return this.readOnly;
    }

    loadFromTemplate() {}

    durationOptionChange(event) {
        this.cdr.detectChanges();
        switch (event.value) {
            case 0:
                var tempDate1 = new Date(this.eventStart);
                tempDate1.setHours(0, 0, 0, 0);
                this.eventStart = tempDate1;
                var tempDate2 = new Date(this.eventStart);
                tempDate2.setHours(23, 59, 59, 999);
                this.eventEnd = tempDate2;
                this.eventStartDisabled = true;
                this.eventEndDisabled = true;
                this.tpTimeSelectionEnabled = false;
                break;

            case 1:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 30);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 2:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 60);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 3:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 90);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 4:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 120);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 5:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = false;
                break;
        }
        this.cdr.detectChanges();
    }

    dateStartChange($event) {
        if (this.selectedDuration.value == 0) {
            this.eventStart.setHours(0, 0, 0, 0);
            var tempDate = new Date(this.eventStart);
            tempDate.setHours(23, 59, 59, 999);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 1) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 2) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 60);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 3) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 90);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 4) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 120);
            this.eventEnd = tempDate;
        }

        this.cdr.detectChanges();
    }

    lineChanged() {
        this.substations = [];
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.selectedSubstation = undefined;
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            if (line == this.selectedLine) {
                if (!this.substations.includes(substation)) {
                    this.substations.push(substation);
                }
            }
        }
    }

    substationChanged() {
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                if (!this.cubicles.includes(cubicle)) {
                    this.cubicles.push(cubicle);
                }
            }
        }
    }

    cubicleChanged() {
        this.equipment = [];
        this.selectedEquipment = [];
        this.selectedWorkInstructions = [];
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            let equipmnent = this.allDevices[i].levelId.split('_')[3];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                for (let j = 0; j < this.selectedCubicles.length; j++) {
                    if (cubicle == this.selectedCubicles[j]) {
                        if (!this.equipment.includes(this.allDevices[i])) {
                            this.equipment.push(this.allDevices[i]);
                            this.location = this.allDevices[i].location;
                        }
                    }
                }
            }
        }
    }

    selectedEquipmentChanged() {
        this.filterWorkInstructions();
        this.selectedWorkInstructions = [];
    }

    filterWorkInstructions() {
        this.filteredWorkInstructionsInfo = [];
        for (var i = 0; i < this.selectedEquipment.length; i++) {
            if (
                this.selectedEquipment[i].workInstructionNames != undefined &&
                this.selectedEquipment[i].workInstructionNames.length > 0
            ) {
                for (
                    var j = 0;
                    j < this.selectedEquipment[i].workInstructionNameAndId.length;
                    j++
                ) {
                    for (var m = 0; m < this.workInstructionsInfo.length; m++) {
                        if (
                            this.selectedEquipment[i].workInstructionNameAndId[j].objectId ==
                            this.workInstructionsInfo[m].mongoId
                        ) {
                            if (
                                !this.foundWorkInstructionDuplicate(
                                    this.selectedEquipment[i].workInstructionNameAndId[j].objectId
                                )
                            ) {
                                this.filteredWorkInstructionsInfo.push(
                                    this.workInstructionsInfo[m]
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    foundWorkInstructionDuplicate(value: string): boolean {
        for (var i = 0; i < this.filteredWorkInstructionsInfo.length; i++) {
            if (value == this.filteredWorkInstructionsInfo[i].mongoId) {
                return true;
            }
        }
        return false;
    }

    createPowerBlockRequest() {}

    assignToTeam() {
        // // in case that no equipmnet is selected in cubicle, then all equipment in cubicle must be selected and all work instructions
        // if(this.selectedEquipment.length == 0){
        //   this.selectedEquipment = this.equipment;
        //   this.selectedEquipmentChanged();
        //   this.selectedWorkInstructions = this.filteredWorkInstructionsInfo;
        // }

        // // get mongo ids of selected instructons
        // this.selectedWorkInstructionsIds = [];
        // for(var i = 0 ; i < this.selectedWorkInstructions.length ; i++){
        //   this.selectedWorkInstructionsIds.push(this.selectedWorkInstructions[i].mongoId);
        // }

        this._iomsDeviceManagementService
            .assignIndividualAction(
                this.mongoId,
                this.selectedTeam.mongoId,
                this.currentUser.id,
                this.selectedTeam.id
            )
            .subscribe(
                (res: any) => {
                    this.assigned = true;
                    this._snackBar.open('Action assigned', 'Ok', { duration: 5000 });
                },
                (error) => {
                    this._snackBar.open('Error while assigning!', 'Ok', { duration: 5000 });
                }
            );
    }

    close() {
        this.dialogRef.close(this);
    }
}

// EDIT SINGLE ACTION
@Component({
    selector: 'dialogEditSingleAction',
    templateUrl: 'dialog-edit-single-action.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-scheduler.component.scss'],
})
export class DialogEditSingleAction {
    public user: any;

    public actionNumber: number;

    public creationDate: Date;
    public creationDateMills: number;
    public creationDateString: string;

    public actionTypes: any[];
    public selectedActionType: any;

    public allDevices: IomsDevice[];

    public briefDescription: string = '';
    public reportId: string = '';
    public location: string = '';

    public workInstructionsInfo: any[];
    public filteredWorkInstructionsInfo: any[];
    public selectedWorkInstructions: any[];

    public selectedEquipmentIds: string[] = [];
    public selectedWorkInstructionsIds: string[] = [];
    public selectedRepeatOption: number;
    public rrule: any;

    public selectedDuration: any;

    constructor(
        public dialogRef: MatDialogRef<DialogEditScheduleEvent>,
        public _dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.user = data.user;
        this.actionTypes = data.actionTypes;

        var crDate: Date = new Date();

        // match selected duration
        for (let i = 0; i < this.durationOptions.length; i++) {
            if (this.durationOptions[i].viewValue == data.selectedDuration) {
                this.selectedDuration = this.durationOptions[i];
                setTimeout(() => {
                    this.durationOptionChange(this.selectedDuration);
                    this.durationChangeCalledFirstTime = true;
                }, 10);
                break;
            }
        }

        this.actionNumber = data.actionNumber;
        this.creationDateMills = data.creationDate;
        this.creationDateString = this.datePipe.transform(
            new Date(this.creationDateMills),
            'dd/MM/yyyy @ HH:mm'
        );

        this.allDevices = data.allDevices;

        for (var i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            if (!this.allLines.includes(line)) {
                this.allLines.push(line);
            }
        }
        this.allLines.sort(function (a, b) {
            var nameA = a.toLowerCase(),
                nameB = b.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.workInstructionsInfo = data.workInstructionsInfo;

        this.clickedDate = new Date(data.clickedDate);
        if (data.view == 'month') {
            this.eventStart = new Date(this.clickedDate.setHours(0, 0, 0, 0));
            this.eventEnd = new Date(this.clickedDate.setHours(23, 59, 59, 999));
        }

        if (data.view == 'week' || data.view == 'day') {
            this.eventStart = this.clickedDate;
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
            this.selectedDuration = this.durationOptions[1];
            this.tpTimeSelectionEnabled = true;
            this.eventStartDisabled = false;
            this.eventEndDisabled = true;
        }

        //---------------
        this.reportId = data.reportId;
        this.location = data.location;
        this.briefDescription = data.description;

        let levelArr: string[] = data.deviceLevelId.split('_');

        this.selectedLine = levelArr[0];
        this.lineChanged();
        this.selectedSubstation = levelArr[1];
        this.substationChanged();
        let cubArr: string[] = [];
        if (levelArr[2].includes(';')) {
            cubArr = levelArr[2].split(';');
        } else {
            cubArr.push(levelArr[2]);
        }
        this.selectedCubicles = cubArr;
        this.cubicleChanged();

        this.selectedEquipmentIds = data.selectedEquipmentIds;
        for (var i = 0; i < this.selectedEquipmentIds.length; i++) {
            for (var j = 0; j < this.allDevices.length; j++) {
                if (this.selectedEquipmentIds[i].split(';')[1] == this.allDevices[j].mongoId) {
                    this.selectedEquipment.push(this.allDevices[j]);
                    break;
                }
            }
        }

        if (this.selectedEquipment.length == this.equipment.length) {
            this.allSelected = true;
        }

        this.filterWorkInstructions();
        this.selectedWorkInstructionsIds = data.selectedWorkInstructionsIds;
        for (var i = 0; i < this.selectedWorkInstructionsIds.length; i++) {
            for (var j = 0; j < this.workInstructionsInfo.length; j++) {
                if (this.selectedWorkInstructionsIds[i] == this.workInstructionsInfo[j].mongoId) {
                    this.selectedWorkInstructions.push(this.workInstructionsInfo[j]);
                }
            }
        }

        this.selectedRepeatOption = data.selectedRepeatOption;

        this.eventStart = data.eventStart;
        this.eventEnd = data.eventEnd;

        for (var i = 0; i < this.actionTypes.length; i++) {
            if (this.actionTypes[i].mongoId == data.actionTypeId) {
                this.selectedActionType = this.actionTypes[i];
            }
        }
    }

    loadFromTemplate() {}

    public toSave: boolean = false;

    public description: string = '';

    public clickedDate: Date;

    public eventStart: Date;
    public eventEnd: Date;

    public eventdtStart: Date;
    public eventUntil: Date;

    public eventdtStartMills: number; // NOVO
    public eventUntilMills: number; // NOVO

    public eventStartDisabled: boolean = true;
    public eventEndDisabled: boolean = true;
    public tpTimeSelectionEnabled: boolean = false;
    public tpDateSelectionEnabled: boolean = true;

    durationChangeCalledFirstTime: boolean = false;

    public durationOptions: any[] = [
        { value: 0, viewValue: 'All day' },
        { value: 1, viewValue: '30 minutes' },
        { value: 2, viewValue: '1 hour' },
        { value: 3, viewValue: '90 minutes' },
        { value: 4, viewValue: '2 hours' },
        { value: 5, viewValue: '2 days' },
        { value: 6, viewValue: '3 days' },
        { value: 7, viewValue: '4 days' },
        { value: 8, viewValue: '5 days' },
        { value: 9, viewValue: '6 days' },
        { value: 10, viewValue: '7 days' },
        { value: 11, viewValue: '10 days' },
        { value: 12, viewValue: 'Custom' },
    ];

    durationOptionChange(event) {
        this.cdr.detectChanges();
        switch (event.value) {
            case 0:
                var tempDate1 = new Date(this.eventStart);
                tempDate1.setHours(0, 0, 0, 0);
                this.eventStart = tempDate1;
                var tempDate2 = new Date(this.eventStart);
                tempDate2.setHours(23, 59, 59, 999);
                this.eventEnd = tempDate2;
                this.eventStartDisabled = true;
                this.eventEndDisabled = true;
                this.tpTimeSelectionEnabled = false;
                break;

            case 1:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 30);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 2:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 60);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 3:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 90);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 4:
                var tempDate = new Date(this.eventStart);
                tempDate.setMinutes(this.eventStart.getMinutes() + 120);
                this.eventEnd = tempDate;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                break;

            case 5:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            case 6: // 3 days, durationOptionChange(event){...
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            case 7:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            case 8:
                // this.tpTimeSelectionEnabled = true;
                // this.eventStartDisabled = false;
                // this.eventEndDisabled = false;
                // break;
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            case 9:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            case 10:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            // 10 days
            case 11:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = true;
                if (this.durationChangeCalledFirstTime) {
                    var tempDate = new Date(this.eventStart);
                    tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
                    this.eventEnd = tempDate;
                }
                break;

            // custom
            case 12:
                this.tpTimeSelectionEnabled = true;
                this.eventStartDisabled = false;
                this.eventEndDisabled = false;
                break;
        }
        this.cdr.detectChanges();
    }

    dateStartChange($event) {
        if (this.selectedDuration.value == 0) {
            this.eventStart.setHours(0, 0, 0, 0);
            var tempDate = new Date(this.eventStart);
            tempDate.setHours(23, 59, 59, 999);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 1) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 30);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 2) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 60);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 3) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 90);
            this.eventEnd = tempDate;
        }

        if (this.selectedDuration.value == 4) {
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 120);
            this.eventEnd = tempDate;
        }

        // 2 days
        if (this.selectedDuration.value == 5) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 1);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 2 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 3 days
        if (this.selectedDuration.value == 6) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 3 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 4 days
        if (this.selectedDuration.value == 7) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 3);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 4 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 5 days
        if (this.selectedDuration.value == 8) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 4);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 5 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 6 days
        if (this.selectedDuration.value == 9) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 5);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 6 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 7 days
        if (this.selectedDuration.value == 10) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 6);

            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 7 * 24 * 60);
            this.eventEnd = tempDate;
        }

        // 10 days
        if (this.selectedDuration.value == 11) {
            // this.eventEnd.setDate(this.eventEnd.getDate() + 9);
            var tempDate = new Date(this.eventStart);
            tempDate.setMinutes(this.eventStart.getMinutes() + 10 * 24 * 60);
            this.eventEnd = tempDate;
        }

        this.cdr.detectChanges();
    }

    save() {
        this.toSave = true;

        // in case that no equipmnet is selected in cubicle, then all equipment in cubicle must be selected and all work instructions
        if (this.selectedEquipment.length == 0) {
            this.selectedEquipment = this.equipment;
            this.selectedEquipmentChanged();
            this.selectedWorkInstructions = this.filteredWorkInstructionsInfo;
        }

        // get mongo ids of selected instructons
        this.selectedWorkInstructionsIds = [];
        for (var i = 0; i < this.selectedWorkInstructions.length; i++) {
            this.selectedWorkInstructionsIds.push(this.selectedWorkInstructions[i].mongoId);
        }

        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    allLines: string[] = [];
    substations: string[] = [];
    cubicles: string[] = [];
    equipment: IomsDevice[] = [];

    selectedLine: string;
    selectedSubstation: string;
    selectedCubicles: string[];
    selectedEquipment: IomsDevice[] = [];

    // selectedDevices: IomsDevice[];

    lineChanged() {
        this.allSelected = false;
        this.substations = [];
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.filteredWorkInstructionsInfo = [];
        this.selectedSubstation = undefined;
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            if (line == this.selectedLine) {
                if (!this.substations.includes(substation)) {
                    this.substations.push(substation);
                }
            }
        }
    }

    substationChanged() {
        this.allSelected = false;
        this.cubicles = [];
        this.equipment = [];
        this.selectedWorkInstructions = [];
        this.filteredWorkInstructionsInfo = [];
        this.selectedCubicles = undefined;
        this.selectedEquipment = [];
        this.location = undefined;
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                if (!this.cubicles.includes(cubicle)) {
                    this.cubicles.push(cubicle);
                }
            }
        }
    }

    cubicleChanged() {
        this.allSelected = false;
        this.equipment = [];
        this.selectedEquipment = [];
        this.filteredWorkInstructionsInfo = [];
        this.selectedWorkInstructions = [];
        for (let i = 0; i < this.allDevices.length; i++) {
            let line = this.allDevices[i].levelId.split('_')[0];
            let substation = this.allDevices[i].levelId.split('_')[1];
            let cubicle = this.allDevices[i].levelId.split('_')[2];
            let equipmnent = this.allDevices[i].levelId.split('_')[3];
            if (line == this.selectedLine && substation == this.selectedSubstation) {
                for (let j = 0; j < this.selectedCubicles.length; j++) {
                    if (cubicle == this.selectedCubicles[j]) {
                        if (!this.equipment.includes(this.allDevices[i])) {
                            this.equipment.push(this.allDevices[i]);
                            this.location = this.allDevices[i].location;
                        }
                    }
                }
            }
        }
    }

    // for selecting all equipment with one checkbox (START)
    @ViewChild('select', { static: false }) select: MatSelect;

    allSelected = false;

    toggleAllSelection() {
        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    optionClick() {
        let newStatus = true;
        this.select.options.forEach((item: MatOption) => {
            if (!item.selected) {
                newStatus = false;
            }
        });
        this.allSelected = newStatus;
    }

    // for selecting all equipment with one checkbox (END)

    selectedEquipmentChanged() {
        this.filterWorkInstructions();
        this.selectedWorkInstructions = [];
    }

    filterWorkInstructions() {
        this.filteredWorkInstructionsInfo = [];
        for (var i = 0; i < this.selectedEquipment.length; i++) {
            if (
                this.selectedEquipment[i].workInstructionNames != undefined &&
                this.selectedEquipment[i].workInstructionNames.length > 0
            ) {
                for (
                    var j = 0;
                    j < this.selectedEquipment[i].workInstructionNameAndId.length;
                    j++
                ) {
                    for (var m = 0; m < this.workInstructionsInfo.length; m++) {
                        if (
                            this.selectedEquipment[i].workInstructionNameAndId[j].objectId ==
                            this.workInstructionsInfo[m].mongoId
                        ) {
                            if (
                                !this.foundWorkInstructionDuplicate(
                                    this.selectedEquipment[i].workInstructionNameAndId[j].objectId
                                )
                            ) {
                                this.filteredWorkInstructionsInfo.push(
                                    this.workInstructionsInfo[m]
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    foundWorkInstructionDuplicate(value: string): boolean {
        for (var i = 0; i < this.filteredWorkInstructionsInfo.length; i++) {
            if (value == this.filteredWorkInstructionsInfo[i].mongoId) {
                return true;
            }
        }
        return false;
    }

    createPowerBlockRequest() {}
}
