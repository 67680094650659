import { ISignalItem } from '../../../../services';
import { ICriteriaData, ISignalsChanged } from '../../../../services/signal.service';
import { SignalTreeItem } from '../reducer/criteria.state';

export class SetItemsEtcs {
    static readonly type = '[Criteria] Set Items Etcs';
    constructor(public items: string[]) {}
}

export class SetItemsEvent {
    static readonly type = '[Criteria] Set Items Event';
    constructor(public items: string[]) {}
}

export class SetItemsAnalog {
    static readonly type = '[Criteria] Set Items Analog';
    constructor(public items: string[]) {}
}

export class SetItemsDigital {
    static readonly type = '[Criteria] Set Items Digital';
    constructor(public items: string[]) {}
}

export class SetItemsOther {
    static readonly type = '[Criteria] Set Items Other';
    constructor(public items: string[]) {}
}

export class SetItemsStanding {
    static readonly type = '[Criteria] Set Items Standing';
    constructor(public items: string[]) {}
}

export class SetSignalsFull {
    static readonly type = '[Criteria] Set Signals Full';
    constructor(public signalsFull: ISignalItem[]) {}
}

export class ClearSignalsUpdated {
    static readonly type = '[Criteria] Clear Signals Updated';
}

export class SetSignalsUpdated {
    static readonly type = '[Criteria] Set Signals Updated';
    constructor(
        public vehicle: string,
        public recorder: string,
        public timefromSignals: string,
        public timetoSignals: string
    ) {}
}

export class SetItemsRequireMatchEtcs {
    static readonly type = '[Criteria] Set Items Require Match Etcs';
    constructor(public items: string[]) {}
}

export class SetItemsRequireMatchEvent {
    static readonly type = '[Criteria] Set Items Require Match Event';
    constructor(public items: string[]) {}
}

export class SetItemsRequireMatchAnalog {
    static readonly type = '[Criteria] Set Items Require Match Analog';
    constructor(public items: string[]) {}
}

export class SetItemsRequireMatchDigital {
    static readonly type = '[Criteria] Set Items Require Match Digital';
    constructor(public items: string[]) {}
}

export class SetItemsRequireMatchOther {
    static readonly type = '[Criteria] Set Items Require Match Other';
    constructor(public items: string[]) {}
}

export class SetItemsRequireMatchStanding {
    static readonly type = '[Criteria] Set Items Require Match Standing';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchAnalog {
    static readonly type = '[Criteria] Set Curr Items Require Match Analog';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchDigital {
    static readonly type = '[Criteria] Set Curr Items Require Match Digital';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchOther {
    static readonly type = '[Criteria] Set Curr Items Require Match Other';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchStanding {
    static readonly type = '[Criteria] Set Curr Items Require Match Standing';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchEtcs {
    static readonly type = '[Criteria] Set Curr Items Require Match Etcs';
    constructor(public items: string[]) {}
}

export class SetCurrItemsRequireMatchEvent {
    static readonly type = '[Criteria] Set Curr Items Require Match Event';
    constructor(public items: string[]) {}
}

export class AddCurrItemRequireMatchAnalog {
    static readonly type = '[Criteria] Add Curr Item Require Match Analog';
    constructor(public item: string) {}
}

export class AddCurrItemRequireMatchDigital {
    static readonly type = '[Criteria] Add Curr Item Require Match Digital';
    constructor(public item: string) {}
}

export class AddCurrItemRequireMatchOther {
    static readonly type = '[Criteria] Add Curr Item Require Match Other';
    constructor(public item: string) {}
}

export class AddCurrItemRequireMatchEtcs {
    static readonly type = '[Criteria] Add Curr Item Require Match Etcs';
    constructor(public item: string) {}
}

export class AddCurrItemRequireMatchEvent {
    static readonly type = '[Criteria] Add Curr Item Require Match Event';
    constructor(public item: string) {}
}

export class RemoveCurrItemRequireMatchAnalog {
    static readonly type = '[Criteria] Remove Curr Item Require Match Analog';
    constructor(public item: string) {}
}

export class RemoveCurrItemRequireMatchDigital {
    static readonly type = '[Criteria] Remove Curr Item Require Match Digital';
    constructor(public item: string) {}
}

export class RemoveCurrItemRequireMatchOther {
    static readonly type = '[Criteria] Remove Curr Item Require Match Other';
    constructor(public item: string) {}
}

export class RemoveCurrItemRequireMatchEtcs {
    static readonly type = '[Criteria] Remove Curr Item Require Match Etcs';
    constructor(public item: string) {}
}

export class RemoveCurrItemRequireMatchEvent {
    static readonly type = '[Criteria] Remove Curr Item Require Match Event';
    constructor(public item: string) {}
}

export class AddItemAnalog {
    static readonly type = '[Criteria] Add Item Analog';
    constructor(public item: string) {}
}

export class AddItemDigital {
    static readonly type = '[Criteria] Add Item Digital';
    constructor(public item: string) {}
}

export class AddItemsAnalog {
    static readonly type = '[Criteria] Add Items Analog';
    constructor(public items: string[]) {}
}

export class AddItemsDigital {
    static readonly type = '[Criteria] Add Items Digital';
    constructor(public items: string[]) {}
}

export class AddItemsOther {
    static readonly type = '[Criteria] Add Items Other';
    constructor(public items: string[]) {}
}

export class AddItems {
    static readonly type = '[Criteria] Add Items';
    constructor(public analog: string[], public digital: string[], public other: string[]) {}
}

export class ClearNodesMemory {
    static readonly type = '[Criteria] Clear Nodes Memory';
}

export class AddNodeMemory {
    static readonly type = '[Criteria] Add Node Memory';
    constructor(public node: SignalTreeItem) {}
}

export class SetNodesMemory {
    static readonly type = '[Criteria] Set Nodes Memory';
    constructor(public nodes: SignalTreeItem[]) {}
}

export class UpdateNodeMemory {
    static readonly type = '[Criteria] Update Node Memory';
    constructor(public nodeMemory: SignalTreeItem, public nodeSignalType: SignalTreeItem) {}
}

export class UpdateNodeMemorySignal {
    static readonly type = '[Criteria] Update Node Memory Signal';
    constructor(
        public nodeMemory: SignalTreeItem,
        public nodeSignalType: SignalTreeItem,
        public nodeSignal: SignalTreeItem
    ) {}
}

export class SetNodeChips {
    static readonly type = '[Criteria] Set Node Chips';
    constructor(public node: SignalTreeItem) {}
}

export class ClearNodesEvent {
    static readonly type = '[Criteria] Clear Nodes Event';
}

export class SetNodeEvent {
    static readonly type = '[Criteria] Add Node Event';
    constructor(public nodes: SignalTreeItem[]) {}
}

export class ModifyEtcsChip {
    static readonly type = '[Criteria] Modify Etcs Chip';
    constructor(public chip: string, public chipOperation: ChipOperation) {}
}

export class ModifyEventChip {
    static readonly type = '[Criteria] Modify Event Chip';
    constructor(public chip: string, public chipOperation: ChipOperation) {}
}

export class ModifyChip {
    static readonly type = '[Criteria] Modify Chip';
    constructor(
        public chip: string,
        public chipType: ChipType,
        public chipOperation: ChipOperation
    ) {}
}

export class UpdateAllChips {
    static readonly type = '[Criteria] Update All Chips';
}

export class UpdateSignalsUsed {
    static readonly type = '[Criteria] Update Signals Used';
}

export class UpdatePreviousAnalogSignals {
    static readonly type = '[Criteria] Update Previous Analog Signals';
}

export class UpdatePreviousDigitalSignals {
    static readonly type = '[Criteria] Update Previous Digital Signals';
}

export class UpdatePreviousOtherSignals {
    static readonly type = '[Criteria] Update Previous Other Signals';
}

export class UpdatePreviousEtcsSignals {
    static readonly type = '[Criteria] Update Previous Etcs Signals';
}

export class UpdatePreviousEventSignals {
    static readonly type = '[Criteria] Update Previous Event Signals';
}

export class ResetNodes {
    static readonly type = '[Criteria] Reset Nodes';
}

export class ResetItemsRequireMatch {
    static readonly type = '[Criteria] Reset Items Require Match';
}

export class CreateSignalTree {
    static readonly type = '[Criteria] Create Signal Tree';
    constructor(public reset: number) {}
}

export class NewVehicleHasSameSignals {
    static readonly type = '[Criteria] New Vehicle Has Same Signals';
}

export class ExpandSignalTree {
    static readonly type = '[Criteria] Expand Signal Tree';
}

export class GetCriteriaData {
    static readonly type = '[Criteria] Get Criteria Data';
}

export class ResetStoreCriteria {
    static readonly type = '[Criteria] Reset Store Criteria';
}

export enum ChipType {
    ANALOG = 'ANALOG',
    DIGITAL = 'DIGITAL',
    OTHER = 'OTHER',
    ETCS = 'ETCS',
}

export enum ChipOperation {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}
