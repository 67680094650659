import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { EvaPlusUser } from '../../app/store/signal-analytics/reducer/eva-plus-user.state';

@Injectable({ 
    providedIn: 'root' 
})
export class OauthTokenExtractorService {

    constructor(private oauthService: OAuthService) {

    }

    public createEvaPlusUserInfoFromToken(): EvaPlusUser {
        const claims: any = this.oauthService.getIdentityClaims();
        return {
            id: claims.sub,
            name: claims.preferred_username,
            email: claims.email,
            roles: claims.role,
            actions: claims.actions,
            lang: 'EN',
            survey_done: true,
        };
    }
}
