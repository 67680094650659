import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'; // NEW NOTIFICATIONS
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TdDialogService } from '@covalent/core/dialogs';
import { CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { NotificationModuleService, SignalEtcsService } from '../../../services';

@Component({
    selector: 'app-receiver-settings',
    templateUrl: './receiver-settings.component.html',
    styleUrls: ['./receiver-settings.component.scss'],
})
export class ReceiverSettingsComponent implements OnInit, OnDestroy {
    subscriptionRemoveDialog: Subscription = new Subscription(); // USED IN NEW NOTIFICATIONS
    subscriptionRemove: Subscription = new Subscription(); // USED IN NEW NOTIFICATIONS
    constructor(
        private _notificationModuleService: NotificationModuleService,
        private _dialogService: TdDialogService,
        private _snackBar: MatSnackBar,
        public _dialog: MatDialog,
        public _signalServiceEtcs: SignalEtcsService
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptionRemoveDialog.unsubscribe(); // USED IN NEW NOTIFICATIONS
        this.subscriptionRemove.unsubscribe();
        this.subLoadReceiversNew.unsubscribe(); // NEW NOTIFICATIONS
    }

    // NEW NOTIFICATIONS...

    subLoadReceiversNew: Subscription = new Subscription();

    private gridApi;
    private gridColumnApi;

    gridReady: boolean = false;

    selectedReceiverId: string = '';

    gridData: any[] = [];

    rowSelectionGrid = 'single';
    suppressRowClickSelection: boolean = false;
    noDataGridOverlay: string = 'No receivers';

    private oldVal: any;

    onCellEditingStarted(event: CellEditingStartedEvent) {
        this.oldVal = event.value;
    }

    oncellEditingStopped(event: CellEditingStoppedEvent) {
        if (event && event.api) {
            let rowNode = event.api.getRowNode(event.rowIndex.toString());
            if (rowNode) {
                rowNode.setDataValue(event.column, this.oldVal);
            }
        }
    }

    gridOptions: GridOptions = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    autoSizeAll() {}

    onFirstDataRendered(event) {}

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.showNoRowsOverlay();
        this.gridReady = true;
        this.loadReceiversNew();
    }

    columnDefs: any[] = [
        { field: 'type', headerName: 'Type', width: 75 },
        { field: 'comment', headerName: 'Comment', width: 220 },
        {
            field: 'enabled',
            headerName: 'Status',
            width: 90,
            cellRenderer: 'cellFormatterComponent',
            cellStyle: this.cellColorStatus.bind(this),
        },
        { field: 'sendTo', headerName: 'Receivers', width: 300 },
        { field: 'useForEventsGridView', headerName: 'Events', width: 350 },
        { field: 'useForVehiclesGridView', headerName: 'Vehicles', width: 270 },
        { field: 'dimensionsData', headerName: 'Filters', width: 500 },
    ];

    frameworkComponents = {
        cellFormatterComponent: ReceiverStatusTableCellNotifications,
    };

    cellColorStatus(params) {
        var status = params.value;
        if (status == true) {
            return {
                'color': 'green',
                'font-size': '14px',
                'font-weight': '500',
                'vertical-align': 'middle',
                'display': 'table-cell',
            };
        } else {
            return {
                'color': 'red',
                'font-size': '14px',
                'font-weight': '500',
                'vertical-align': 'middle',
                'display': 'table-cell',
            };
        }
    }

    onSelectionChanged(event) {
        var selectedRow = this.gridApi.getSelectedRows();
        if (selectedRow != undefined && selectedRow[0] != undefined) {
            this.selectedReceiverId = selectedRow[0].id;
        }
    }

    loadReceiversNew() {
        this.subLoadReceiversNew = this._notificationModuleService
            .loadReceiversNewSrv()
            .subscribe((res: ReceiverNew[]) => {
                var temp: ReceiverNew[] = res;

                for (var i = 0; i < temp.length; i++) {
                    temp[i].useForEventsGridView = [];
                    for (var j = 0; j < temp[i].useForEvents.length; j++) {
                        if (temp[i].useForEvents[j].value == true) {
                            temp[i].useForEventsGridView.push(temp[i].useForEvents[j].name);
                        }
                    }
                }

                for (var i = 0; i < temp.length; i++) {
                    temp[i].useForVehiclesGridView = [];
                    for (var j = 0; j < temp[i].useForVehicles.length; j++) {
                        temp[i].useForVehiclesGridView.push(temp[i].useForVehicles[j].id);
                    }
                }

                temp.sort(function (a, b) {
                    var nameA = a.type.toLowerCase(),
                        nameB = b.type.toLowerCase();
                    if (nameA < nameB) {
                        //sort string ascending
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                this.gridData = temp;

                this.formatDimensionsDataForGrid();

                this.gridApi.setRowData(this.gridData);

                this.clearSelection();
            });
    }

    formatDimensionsDataForGrid() {
        for (var i = 0; i < this.gridData.length; i++) {
            var dimObj: any = JSON.parse(this.gridData[i].dimensionsData);

            var strTemp = '';
            var endComma: string;

            if (dimObj.dimensions.length > 0) {
                for (var j = 0; j < dimObj.dimensions.length; j++) {
                    endComma = ',';
                    if (dimObj.dimensions[j].selectedValues.length > 0) {
                        strTemp = strTemp + dimObj.dimensions[j].signalId + ':';
                        for (var k = 0; k < dimObj.dimensions[j].selectedValues.length; k++) {
                            if (k == dimObj.dimensions[j].selectedValues.length - 1) {
                                endComma = '';
                            }
                            strTemp = strTemp + dimObj.dimensions[j].selectedValues[k] + endComma;
                        }
                        strTemp = strTemp + '; ';
                    }
                }

                this.gridData[i].dimensionsData = strTemp;
            } else {
                this.gridData[i].dimensionsData = '';
            }
        }
    }

    removeReceiverNew() {
        var selectedRow = this.gridApi.getSelectedRows();
        if (selectedRow != undefined && selectedRow[0] != undefined) {
            if (this.selectedReceiverId != '' && this.selectedReceiverId != undefined) {
                this.subscriptionRemoveDialog = this._dialogService
                    .openConfirm({
                        message: 'Are you sure you want to delete?',
                        acceptButton: 'Delete',
                        title: 'Delete receiver',
                    })
                    .afterClosed()
                    .subscribe((accept: boolean) => {
                        if (accept) {
                            this.subscriptionRemove = this._notificationModuleService
                                .removeReceiverNewSrv(this.selectedReceiverId)
                                .subscribe(
                                    () => {
                                        this._snackBar.open('Receiver deleted', 'Ok', {
                                            duration: 3000,
                                        });
                                        this.loadReceiversNew();
                                        this.clearSelection();
                                    },
                                    (error) => {
                                        this._dialogService.openAlert({
                                            message: 'Error while deleting.',
                                        });
                                    }
                                );
                        }
                    });
            }
        }
    }

    clearSelection() {
        this.gridApi.deselectAll();
        this.selectedReceiverId = '';
    }

    cardHeight: number = window.innerHeight - 45 - 10 - 10;
    tableHeight: number = window.innerHeight - 45 - 46 - 10 - 10;

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        setTimeout(() => {
            this.cardHeight = window.innerHeight - 45 - 10 - 10;
            this.tableHeight = this.cardHeight - 46;
        }, 100);
    }

    // ... NEW NOTIFICATIONS
}

export class ReceiverNew {
    // NEW NOTIFICATIONS
    id: string;
    type: string;
    sendTo: string;
    enabled: boolean;
    comment: string;
    mute: boolean;
    muteDuration: number;
    useForEvents: ReceiversAllEvents[];
    useForEventsGridView: string[];
    useForVehicles: Vehicle[];
    useForVehiclesGridView: string[];
    emailSubject: string;
    emailBody: string;
    dimensionsData: string;
}

export class ReceiversAllEvents {
    public name: string = '';
    public value: boolean = false;
}

export class User {
    name: string = '';
    email: string = '';
}

export class NotificationEvent {
    name: string;
    shortName: string;
}

export class Vehicle {
    public id: string = '';
}

// NEW NOTIFICATIONS
@Component({
    selector: 'receiver-status-cell-notifications',
    template: ` <div *ngIf="params.value == true">Enabled</div>
        <div *ngIf="params.value == false">Disabled</div>`,
})
export class ReceiverStatusTableCellNotifications {
    params: any;

    agInit(params: any): void {
        this.params = params;
    }
}
