import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dialogChangeLanguage',
    template: ` <h3>{{ 'MAIN.Select-language' | translate }}</h3>
        <select [(ngModel)]="templateName">
            <option value="ZH-CN">Chinese</option>
            <option value="CS">Czech</option>
            <option value="NL">Dutch</option>
            <option value="EN">English</option>
            <option value="FR">French</option>
            <option value="DE">German</option>
            <option value="HU">Hungarian</option>
            <option value="IT">Italian</option>
            <option value="JP">Japanese</option>
            <option value="PL">Polish</option>
            <option value="PT">Portuguese-Brazil</option>
            <option value="RO">Romanian</option>
            <option value="RU">Russian</option>
            <option value="SR">Serbian</option>
            <option value="ES">Spanish</option>
            <option value="SV">Swedish</option>
            <option value="TR">Turkish</option>
        </select>
        <br /><br />
        <button mat-raised-button (click)="cancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'MAIN.Cancel' | translate }}
        </button>
        <button mat-raised-button (click)="save()">
            <mat-icon>save</mat-icon>
            {{ 'MAIN.Save' | translate }}
        </button>`,
})
export class DialogChangeLanguage {
    public templateName: string = 'EN';
    public toSave: boolean = false;

    constructor(public dialogRef: MatDialogRef<any>, private _translate: TranslateService) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.templateName = currentUser && currentUser.lang;
        _translate.use(this.templateName.toLocaleLowerCase());
    }

    save() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }
}
