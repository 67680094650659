import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

export interface IMediaType {
    name: String;
    type: String;
    status: boolean;
    used_in_actions: String;
    details: IMediaTypeDetail[];
}

export interface IMediaTypeDetail {
    detailName: string;
    detailValue: string | number;
}

export class MediaType {
    type: string;
    name: string;
    status: boolean;
    used_in_actions: '';
    details: MediaTypeDetail[];
}

export class MediaTypeDetail {
    detailName: string;
    detailValue: string | number;
}

const baseUrl = '/EvaPlusRest/notification_module/';

@Injectable({ providedIn: 'root' })
export class NotificationModuleService {
    constructor(private http: EvaPlusHttpService) {}

    // FOR SERVERS
    // list of media types
    loadMediaTypes(): Observable<IMediaType[]> {
        return this.http.get(baseUrl + 'mediaTypesList');
    }

    //delete media types for sented list of names as paremeter
    deleteMediaTypes(names: string[]): Observable<any> {
        return this.http.get(baseUrl + 'mediaType/delete', { params: { names } });
    }

    //enable media with sented list of names as paremeter
    enableMediaTypes(names: string[]): Observable<any> {
        return this.http.get(baseUrl + 'mediaType/enable', { params: { names } });
    }

    //disable media with sented list of names as paremeter
    disableMediaTypes(names: string[]): Observable<any> {
        return this.http.get(baseUrl + 'mediaType/disable', { params: { names } });
    }

    //add media type
    addMediaType(
        type: string,
        name: string,
        status: boolean,
        details: MediaTypeDetail[]
    ): Observable<any> {
        let payload = JSON.stringify({ type: type, name: name, status: status, details: details });
        return this.http.post(baseUrl + 'addMediaType', payload);
    }

    //edit media type
    editMediaType(
        type: string,
        name: string,
        status: boolean,
        details: MediaTypeDetail[]
    ): Observable<any> {
        let payload = JSON.stringify({ type: type, name: name, status: status, details: details });
        return this.http.post(baseUrl + 'editMediaType', payload);
    }

    // FOR RECEIVERS...

    getUsersSrv() {
        return this.http.get(baseUrl + 'getusers');
    }

    getNotificationEventsSrv() {
        return this.http.get(baseUrl + 'getnotificationevents');
    }

    // NEW NOTIFICATIONS
    addReceiverNew(
        enabled: boolean,
        comment: string,
        mute: boolean,
        muteDuration: number,
        type: string,
        sendTo: string,
        useForEvents: Object[],
        useForVehicles: Object[],
        emailSubject: string,
        emailBody: string,
        dimensionsData: string
    ): Observable<any> {
        let payload = JSON.stringify({
            enabled: enabled,
            comment: comment,
            mute: mute,
            muteDuration: muteDuration,
            type: type,
            sendTo: sendTo,
            useForEvents: useForEvents,
            useForVehicles: useForVehicles,
            emailSubject: emailSubject,
            emailBody: emailBody,
            dimensionsData: dimensionsData,
        });
        return this.http.post(baseUrl + 'addReceiverNew', payload);
    }

    // NEW NOTIFICATIONS
    editReceiverNewSrv(
        id: string,
        enabled: boolean,
        comment: string,
        mute: boolean,
        muteDuration: number,
        type: string,
        sendTo: string,
        useForEvents: Object[],
        useForVehicles: Object[],
        emailSubject: string,
        emailBody: string,
        dimensionsData: string
    ): Observable<any> {
        let payload = JSON.stringify({
            id: id,
            enabled: enabled,
            comment: comment,
            mute: mute,
            muteDuration: muteDuration,
            type: type,
            sendTo: sendTo,
            useForEvents: useForEvents,
            useForVehicles: useForVehicles,
            emailSubject: emailSubject,
            emailBody: emailBody,
            dimensionsData: dimensionsData,
        });
        return this.http.post(baseUrl + 'editreceivernew', payload);
    }

    // NEW NOTIFICATIONS
    loadReceiversNewSrv() {
        return this.http.get(baseUrl + 'loadreceiversnew');
    }

    // NEW NOTIFICATIONS
    removeReceiverNewSrv(id: string): Observable<any> {
        return this.http.get(baseUrl + 'removereceivernew/remove', { params: { id } });
    }

    // NEW NOTIFICATIONS
    getReceiverForEditSrv(id: string) {
        return this.http.get(`${baseUrl}getReceiverToEdit/${id}`);
    }

    // NEW NOTIFICATIONS
    getEventDictionarySrv() {
        return this.http.get(baseUrl + 'geteventdictionary');
    }

    // NEW NOTIFICATIONS
    getInternalNotificationSrv(user: string, timeFrom: string, timeTo: string): Observable<any> {
        return this.http.get(baseUrl + 'getlistinternal', {
            params: {
                user,
                fimefrom: timeFrom,
                timeto: timeTo,
            },
        });
    }

    // NEW NOTIFICATIONS
    getEmailNotificationSrv(email: string, timeFrom: string, timeTo: string): Observable<any> {
        return this.http.get(baseUrl + 'getlistemail', {
            params: {
                email,
                timefrom: timeFrom,
                timeto: timeTo,
            },
        });
    }

    // NEW NOTIFICATIONS
    getEmailList(): Observable<any> {
        return this.http.get(baseUrl + 'emailListElastic');
    }

    // DATA COVERAGE NOTIFICATIONS

    loadDataCoverageReceiversSrv() {
        return this.http.get(baseUrl + 'loadDataCoverageReceivers');
    }

    removeDataCoverageReceiversSrv(id: string): Observable<any> {
        return this.http.get(baseUrl + 'removeDataCoverageReceivers/remove/' + id);
    }

    getCoverageReceiverForEditSrv(id: string) {
        return this.http.get(baseUrl + 'getCoverageReceiverToEdit/' + id);
    }

    getNotificationThresholdSrv(id: string) {
        return this.http.get(baseUrl + 'getNotificationThreshold/' + id);
    }

    addCoverageReceiverSrv(
        enabled: boolean,
        comment: string,
        type: string,
        sendTo: string,
        thresholdList: Object[],
        emailSubject: string,
        emailBody: string
    ): Observable<any> {
        let payload = JSON.stringify({
            enabled: enabled,
            comment: comment,
            type: type,
            sendTo: sendTo,
            thresholdList: thresholdList,
            emailSubject: emailSubject,
            emailBody: emailBody,
        });
        return this.http.post(baseUrl + 'addCoverageReceiver', payload);
    }

    editCoverageReceiverSrv(
        id: string,
        enabled: boolean,
        comment: string,
        type: string,
        sendTo: string,
        thresholdList: Object[],
        emailSubject: string,
        emailBody: string
    ): Observable<any> {
        let payload = JSON.stringify({
            id: id,
            enabled: enabled,
            comment: comment,
            type: type,
            sendTo: sendTo,
            thresholdList: thresholdList,
            emailSubject: emailSubject,
            emailBody: emailBody,
        });
        return this.http.post(baseUrl + 'editCoverageReceiver', payload);
    }

    unsubsribeEmail(id: string, email: string) {
        return this.http.get(baseUrl + 'unsubscribe', { params: { id, email } });
    }
}
