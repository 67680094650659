import {
    AgmMap,
    LatLng,
    LatLngBounds,
    LatLngLiteral,
    LazyMapsAPILoaderConfigLiteral,
} from '@agm/core';
import { ControlPosition, MapTypeControlOptions } from '@agm/core/';
import { DatePipe, PlatformLocation } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    Injectable,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TdDialogService } from '@covalent/core/dialogs';
import { TdMediaService } from '@covalent/core/media';
import { interval, Subscription } from 'rxjs';
import {
    DashboardsConfigurationService,
    ICriteriaDataItem,
    IDashboardsConfiguration,
    SignalService,
    UserMonitoringService,
} from '../../../services';
import {
    FleetManagementService,
    IFleetLocation,
    IInfrastructureElement,
    IInfrastructureType,
    LastSeenEventData,
    LastSeenEventForFleetLocation,
    LastSeenSignalData,
    LastSeenSignalForFleetLocation,
    MapLayerSettings,
    PoiLayerSettings,
    VehicleState,
} from '../../../services/fleetmanagement.service';
import { MomentTimezoneDatePipe } from '../../pipes/moment-timezone-date/moment-timezone-date.pipe';
import { FilterPipeInfrastructure } from './filter-infrastructure.pipe';
import { FilterPipe } from './filter.pipe';
import { MapIcons } from './mapIcons';
import { MapIconsPoiTypes } from './mapIconsPoiTypes';
import { mapStyleEnanceRailroads } from './mapStylesConfiguration';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    GridApi,
    GridReadyEvent,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { RouterHelperService } from '../../../services/router-helper.service';

declare var google: any;

@Injectable()
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {
    public apiKeyDefault: string = 'AIzaSyDK86fHHdWSpUxbCn_bvHiH5hwPRVgQlh4';
    public apiKey: string;

    constructor() {
        this.apiKey =
            JSON.parse(localStorage.getItem('googleApikey')) == null
                ? this.apiKeyDefault
                : swapStr(JSON.parse(localStorage.getItem('googleApikey')).apiKey, 3, 5);
    }
}

function swapStr(str: string, index1, index2) {
    if (str.length >= 10) {
        var arr = str.split('');
        var temp1 = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp1;
        index1 = index1 + 1;
        index2 = index2 + 2;
        temp1 = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp1;
        index1 = index1 - 2;
        index2 = index2 + 1;
        temp1 = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp1;
        return arr.join('');
    } else {
        return str;
    }
}

@Component({
    selector: 'dialogAddInfrastructureElement',
    templateUrl: 'dialog-add-infrastructure-element-template.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogAddInfrastructureElement {
    public toSave: boolean = false;
    public id: string;
    public name: string;
    public type: IInfrastructureType;
    public lat: number;
    public lon: number;
    public radius: number;
    public validTill: Date;
    public eventGen: boolean = false;
    public icons: any = new MapIcons().icons;

    constructor(
        public dialogRef: MatDialogRef<DialogAddInfrastructureElement>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    addInfrastructureElement() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }

    toggleGenerateEvents() {
        this.eventGen = !this.eventGen;
    }
}

@Component({
    selector: 'dialogEditInfrastructureElement',
    templateUrl: 'dialog-edit-infrastructure-element-template.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogEditInfrastructureElement {
    public toSave: boolean = false;
    public id: string;
    public name: string;
    public type: IInfrastructureType;
    public lat: number;
    public lon: number;
    public radius: number;
    public validTill: Date;
    public eventGen: boolean;
    public icons: any = new MapIcons().icons;

    constructor(
        public dialogRef: MatDialogRef<DialogEditInfrastructureElement>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    editInfrastructureElement() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }

    toggleGenerateEvents() {
        this.eventGen = !this.eventGen;
    }
}

@Component({
    selector: 'dialogDuplicatesInfrastructureElements',
    templateUrl: 'dialog-duplicates-infrastructure-elements-template.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogDuplicatesInfrastructureElements {
    public toSave: boolean = false;
    public id: string;
    public name: string;
    public type: string;
    public lat: number;
    public lon: number;
    public radius: number;
    public validTill: Date;
    public icons: any = new MapIcons().icons;

    constructor(
        public dialogRef: MatDialogRef<DialogDuplicatesInfrastructureElements>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    updateDuplicateInfrastructureElements() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }
}

@Component({
    selector: 'app-fleet-location-map',
    templateUrl: './fleet-location-map.component.html',
    styleUrls: ['./fleet-location-map.component.scss'],
    // encapsulation: ViewEncapsulation.None
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetLocationMapComponent implements OnInit, OnDestroy, AfterViewInit {
    // gpsAccuracy: number = 1000;
    toggleGroupValue: string = '1';

    vehicleViewOn: boolean = true;
    infrastructureViewOn: boolean = false;
    infrastructureTypeViewOn: boolean = false;
    settingsViewOn: boolean = false;

    tenantUrl: string = window.location.host;

    loadingIndicator: boolean = false;

    showVehiclesTable: boolean = false;
    showVehicleInfo: boolean = false;

    showInfrastructureTable: boolean = false;
    selectedRowTableInfrastructure: IInfrastructureElement[] = [];

    allInfraElements: IInfrastructureElement[] = [];
    filteredInfraElements: IInfrastructureElement[] = [];
    searchResultInfrastructure: IInfrastructureElement[] = [];
    filteredInfraMarkers: IInfrastructureMarker[] = [];

    allPoiTypes: IInfrastructureType[] = [];

    vehicleInfoBackButtonDisabled: boolean = true;

    @ViewChild('myMap', { static: false }) map1: AgmMap;

    searchResultVehicles: IMarker[];

    selectedVehicle: string = '';
    selectedVehicleTemp: string = '';

    lat: number = 46.93834499980909;
    lng: number = 7.398326396942139;
    vehicleInfo: string = '';
    warningInfo: string = '';
    errorInfo: string = '';
    title: string;
    zoom: number = 13;

    eventsShown: boolean = true;

    colorTimeoutNew: string = '#000000';
    colorTimeoutMiddle: string = '#7A7A7A';
    colorTimeoutOutdated: string = '#C2C2C2';

    linkToVehicleDetailsPage: string = '';

    subAutoRefresh: Subscription = new Subscription();
    subFleetLocation1: Subscription = new Subscription();
    subFleetLocation2: Subscription = new Subscription();
    subFleetLocation3: Subscription = new Subscription();
    subFleetLocation4: Subscription = new Subscription();
    subLoadMapSettings: Subscription = new Subscription();
    subGetAdditionalInfo: Subscription = new Subscription();
    subscriptionLoadInfrastructure: Subscription = new Subscription();
    subSideBar: Subscription = new Subscription();
    subSaveMapSettings: Subscription = new Subscription();
    subAnimateZoom: Subscription = new Subscription();
    subLoadInfrastructureTypes: Subscription = new Subscription();
    subIsTypeInDb: Subscription = new Subscription();
    subAddInfraType: Subscription = new Subscription();
    subDialogAddInfraType: Subscription = new Subscription();
    subEditInfraType: Subscription = new Subscription();
    subIsTypeInDb2: Subscription = new Subscription();
    subDialogEditInfraType: Subscription = new Subscription();
    subEditInfraType2: Subscription = new Subscription();
    subDeleteInfraType: Subscription = new Subscription();
    subDeleteInfraElement: Subscription = new Subscription();
    subAddInfraElement: Subscription = new Subscription();
    subEditInfraElement: Subscription = new Subscription();
    subUpdateInfraElement: Subscription = new Subscription();
    subDialogDuplicatesInfraElements: Subscription = new Subscription();
    subDialogAddInfraElement: Subscription = new Subscription();
    subDialogRef1: Subscription = new Subscription();
    subDialogDeleteInfraType: Subscription = new Subscription();
    subDialogDeleteInfraElement: Subscription = new Subscription();
    subDialogEditInfraElement: Subscription = new Subscription();
    subDownloadInfraElementsFile: Subscription = new Subscription();
    subGetSpeedThreshold: Subscription = new Subscription();
    subSaveSpeedThreshold: Subscription = new Subscription();
    subSaveMapLayerSettings: Subscription = new Subscription();
    subLoadMapLayerSettings: Subscription = new Subscription();
    subRefreshLatestMqttSignals: Subscription = new Subscription();
    subRefreshLatestEvents: Subscription = new Subscription();
    subGetEventList: Subscription = new Subscription();
    subSaveEventState: Subscription = new Subscription();
    subGetEventStates: Subscription = new Subscription();
    subDialogSetTimeouts: Subscription = new Subscription();
    subSaveTimeouts: Subscription = new Subscription();
    subGetTimeouts: Subscription = new Subscription();
    subSaveRequiredLiveSignals: Subscription = new Subscription();
    subGetRequiredLiveSignals: Subscription = new Subscription();
    subSaveRequiredLiveEvents: Subscription = new Subscription();
    subGetRequiredLiveEvents: Subscription = new Subscription();
    subSetToOkDialog: Subscription = new Subscription();
    subSetToOk: Subscription = new Subscription();
    subGetLatestMqttSignals: Subscription = new Subscription();
    subGetLatestEvents: Subscription = new Subscription();

    markers: IMarker[] = [];
    infrastructureMarkers: IInfrastructureMarker[] = [];

    markersWarning: IMarker[] = [];
    markersError: IMarker[] = [];

    mapStyleEnanceRailroads: any[] = mapStyleEnanceRailroads;

    fleetLocations: IFleetLocation[] = [];

    clickedVehicle: string = '';
    clickedVehiclesSerialNumber: string = '';

    lastMqttSignalsDataHeight: number = 200;
    lastEventsDataHeight: number = 200;

    vehicleTableContent: ITableContent[] = [];
    vehicleForShowingInfo: ITableContent[] = [];

    useMapSettingsFromDB: boolean = false;
    mapSettingLat: number;
    mapSettingLon: number;

    allPoiTypesMap: Map<string, string> = new Map();

    settingsTabHeight: number = 500;

    // POI grid...

    poiTableHeight: number = 500;
    rowSelectionGrid = 'multiple';
    suppressRowClickSelection: boolean = false;
    noDataGridOverlay: string = 'No POIs';

    gridOptions = {
        defaultColDef: {
            editable: true,
            resizable: true,
            sortable: true,
            filter: true,
            comparator: function (a, b) {
                if (typeof a === 'string') {
                    return a.localeCompare(b);
                } else {
                    return a > b ? 1 : a < b ? -1 : 0;
                }
            },
        },
    };

    private oldVal: any;

    onCellEditingStarted(event: CellEditingStartedEvent) {
        this.oldVal = event.value;
    }

    oncellEditingStopped(event: CellEditingStoppedEvent) {
        if (event && event.api) {
            let rowNode = event.api.getRowNode(event.rowIndex.toString());
            if (rowNode) {
                rowNode.setDataValue(event.column, this.oldVal);
            }
        }
    }

    private gridApi;
    private gridColumnApi;

    gridReady: boolean = false;
    searchFieldPlaceholderConst: string;

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData(this.searchResultInfrastructure);
        this.gridReady = true;
        this.autoSizeAll();
    }

    refreshPoiTable() {
        this.gridApi.setRowData(this.allInfraElements);
    }

    numberOfSelectePois: number = 0;

    onSelectionChanged(event) {
        this.selectedRowTableInfrastructure = this.gridApi.getSelectedRows();

        if (this.selectedRowTableInfrastructure[0] != undefined) {
            this.lat = this.selectedRowTableInfrastructure[0].lat;
            this.lng = this.selectedRowTableInfrastructure[0].lon;
            this.zoom = 16;
        }

        this.numberOfSelectePois = this.selectedRowTableInfrastructure.length;
        if (this.selectedRowTableInfrastructure.length > 0) {
            this.infrstructureElementSelected = true;
        } else {
            this.infrstructureElementSelected = false;
        }
    }

    onFirstDataRendered($event) {}

    autoSizeAll() {}

    columnDefs: any[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { field: 'type', headerName: 'Type', width: 100 },
        {
            field: 'typeicon',
            headerName: 'Icon',
            cellRenderer: 'imageFormatterComponent',
            width: 70,
        },
        { field: 'radius', headerName: 'Radius', width: 80 },
        { field: 'id', headerName: 'ID', width: 70 },
    ];

    frameworkComponents = {
        imageFormatterComponent: ImageFormatterComponent,
    };

    // ...POI grid

    // POI types grid...

    private gridApiPoiTypes;
    private gridColumnApiPoiTypes;

    poiTypesTableHeight: number = 500;
    rowSelectionGridPoiTypes = 'single';
    suppressRowClickSelectionPoiTypes: boolean = false;

    gridReadyPoiTypes: boolean = false;

    gridOptionsPoiTypes = {
        defaultColDef: {
            editable: true,
            resizable: true,
            sortable: true,
            filter: true,
            comparator: function (a, b) {
                if (typeof a === 'string') {
                    return a.localeCompare(b);
                } else {
                    return a > b ? 1 : a < b ? -1 : 0;
                }
            },
        },
    };

    noDataTypesGridOverlay: string = 'No POI types';

    onGridReadyPoiTypes(params) {
        this.gridApiPoiTypes = params.api;
        this.gridColumnApiPoiTypes = params.columnApi;
        this.gridApiPoiTypes.showNoRowsOverlay();
        this.gridApiPoiTypes.setRowData(this.allPoiTypes);
        this.gridReadyPoiTypes = true;
    }

    onRowClickedPoiTypes(event) {}

    onSelectionChangedPoiTypes(event) {
        this.selectedRowPoiTypesTable = this.gridApiPoiTypes.getSelectedRows();
        if (this.selectedRowPoiTypesTable[0].changeable) {
            this.deletePoiButonDisabled = false;
        } else {
            this.deletePoiButonDisabled = true;
        }
    }

    columnDefsPoiTypes: any[] = [
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'icon', headerName: 'Icon', cellRenderer: 'imageFormatterComponent', width: 65 },
        { field: 'zoomLimit', headerName: 'Zoom limit', width: 100 },
        { field: 'maxSpeed', headerName: 'Max speed', width: 120 },
    ];

    frameworkComponents2 = {
        imageFormatterComponent: ImageFormatterComponent,
    };

    // ... POI types grid

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        this.poiTableHeight = window.innerHeight - 215;
        this.poiTypesTableHeight = window.innerHeight - 131;
        this.vehiclesTableHeight = window.innerHeight - 179;
        this.settingsTabHeight = window.innerHeight - 100;
        this.liveSignalsTableHeight = window.innerHeight - 119;
        this.liveEventsTableHeight = window.innerHeight - 119;
        this.lastMqttSignalsDataHeight = Math.round((window.innerHeight - 381) / 2);
        this.lastEventsDataHeight = Math.round((window.innerHeight - 381) / 2);
    }

    map: any;

    mapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
            document.getElementById('sideNavButtonContainer')
        );
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
            document.getElementById('layersSelection')
        );
        this.loadInfrastructureTypes();
        this.startTracking();
        this.profUserName = this.getUserName();

        this.subLoadMapSettings = this._fleetManagementService
            .getDefaultMapViewSrv(this.profUserName)
            .subscribe((res: MapSettings) => {
                this.useMapSettingsFromDB = res.use_settings;
                if (this.useMapSettingsFromDB) {
                    this.lat = res.lat;
                    this.lng = res.lon;
                    this.zoom = res.zoom;
                }
            });
    }

    mapFullScreen: boolean = true;
    streetViewControl: boolean = false;

    noExecuteOnLoadIndicator: boolean = true;

    // bounds: LatLngBounds;
    bounds: any = false;

    mapTypeControlsOptions: MapTypeControlOptions = {
        position: ControlPosition.BOTTOM_CENTER,
    };

    public icons: any = new MapIcons().icons;

    imageRed: string = 'assets/images/red.png';
    imageGreen: string = 'assets/images/green.png';

    sidenavOpened: boolean = false;

    dialogRefAddInfrastructureElement: MatDialogRef<DialogAddInfrastructureElement>;
    dialogRefEditInfrastructureElement: MatDialogRef<DialogEditInfrastructureElement>;
    dialogRefDuplicatesInfrastructureElements: MatDialogRef<DialogDuplicatesInfrastructureElements>;
    dialogRefSetTimeouts: MatDialogRef<DialogSetTimeouts>;
    infrstructureElementSelected: boolean = false;

    typesFromDb: ICriteriaDataItem[];

    searchAfterDeletingElement: boolean = false;
    searchAfterAddingInfraElement: boolean = false;
    searchAfterEditInfraElement: boolean = false;
    searchAfterInfElUpdate: boolean = false;

    // time zone from config file
    timeZone: string;

    speedThreshold: number = 0;
    profUserName: string = ''; // name of current user

    timeoutColorStroke: string = ''; // for vehicle header
    vehicleStateColorFill: string = ''; // for vehicle header
    vehiclesEventStatesMap: Map<string, VehicleState> = new Map();

    vehicleTypesMap: Map<string, string> = new Map(); // for vehicle header
    selectedVehicleType: string = ''; // for vehicle header

    getUserName() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var name = currentUser && currentUser.name;
        return name;
    }

    roleActions: string = '';
    roleEnableModPOI: boolean = false;
    roleEnableDownloadPOI: boolean = false;

    pixelsHeightDiff: string = '155';

    subActivatedRoute: Subscription = new Subscription();

    roleEnableAll() {
        this.roleEnableModPOI = true;
        this.roleEnableDownloadPOI = true;
    }

    // fleet location map boundsChange event params
    upperLeftLat: string;
    upperLeftLon: string;
    lowerRightLat: string;
    lowerRightLon: string;
    direction: number | null;
    gradient: number | null;

    constructor(
        private _titleService: Title,
        public media: TdMediaService,
        private route: ActivatedRoute,
        private platformLocation: PlatformLocation,
        private _fleetManagementService: FleetManagementService,
        private datePipe: DatePipe,
        private momentTimezoneDatePipe: MomentTimezoneDatePipe,
        private searchPipe: FilterPipe,
        private searchPipeInfrastrusture: FilterPipeInfrastructure,
        private _dialogService: TdDialogService,
        private _snackBar: MatSnackBar,
        public _dialog: MatDialog,
        private _signalService: SignalService,
        private _dashboardsConfigurationService: DashboardsConfigurationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private userMonitoring: UserMonitoringService,
        private translate: TranslateService,
        private routerHelperService: RouterHelperService
    ) {
        this._dashboardsConfigurationService
            .getDashboards()
            .subscribe((dashboardsJSON: IDashboardsConfiguration) => {
                this.timeZone = dashboardsJSON.timeZone;
                this.upperLeftLat = dashboardsJSON.upperLeftLat;
                this.upperLeftLon = dashboardsJSON.upperLeftLon;
                this.lowerRightLat = dashboardsJSON.lowerRightLat;
                this.lowerRightLon = dashboardsJSON.lowerRightLon;
            });

        var currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        this.roleActions = currentUser && currentUser.actions;
        if (this.roleActions.indexOf('ALL') != -1) {
            this.roleEnableAll();
        } else {
            if (this.roleActions.indexOf('FL_MOD_POI') != -1) {
                this.roleEnableModPOI = true;
            }
            if (this.roleActions.indexOf('FL_DOWN_POI') != -1) {
                this.roleEnableDownloadPOI = true;
            }
            if (!this.roleEnableModPOI && !this.roleEnableDownloadPOI) {
                this.pixelsHeightDiff = '120';
            }
        }

        this.getConfiguration();
    }

    /**
     * Formula for the great-circle distance between two points on a sphere
     * @param latFirst Latitude of the first record
     * @param lonFirst Longitude of the first record
     * @param latSecond Latitude of the second record
     * @param lonSecond Longitude of the second record
     * @returns Distance of needed for gradient calculation
     */
    haversineDistance(
        latFirst: number,
        lonFirst: number,
        latSecond: number,
        lonSecond: number
    ): number {
        const EARTH_RADIUS = 6371;

        let diffLat = ((latSecond - latFirst) * Math.PI) / 180;
        let diffLon = ((lonSecond - lonFirst) * Math.PI) / 180;
        let a =
            Math.sin(diffLat / 2.0) * Math.sin(diffLat / 2.0) +
            Math.cos((latFirst * Math.PI) / 180) *
                Math.cos((latSecond * Math.PI) / 180) *
                Math.sin(diffLon / 2.0) *
                Math.sin(diffLon / 2.0);
        var c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a));
        return EARTH_RADIUS * c;
    }

    elevator: any;

    /**
     * Calculates gradient of the train from 2 GPS coordinates
     *
     * @param latFirst Latitude of the first record
     * @param lonFirst Longitude of the first record
     * @param latSecond Latitude of the second record
     * @param lonSecond Longitude of the second record
     * @returns Gradient of the train needed for the cmpass component
     */
    async calculateGradient(
        latFirst: number,
        lonFirst: number,
        latSecond: number,
        lonSecond: number
    ): Promise<number> {
        if (this.elevator == null) {
            this.elevator = new google.maps.ElevationService();
        }

        return new Promise<number>((resolve, reject) => {
            this.elevator.getElevationForLocations(
                {
                    locations: [
                        { lat: latFirst, lng: lonFirst },
                        { lat: latSecond, lng: lonSecond },
                    ],
                },
                (results: any, status: any) => {
                    if (status === 'OK' && results && results.length > 0) {
                        let elevationFirst = results[0].elevation;
                        let elevationLast = results[1].elevation;

                        let horizontalDistance = this.haversineDistance(
                            latFirst,
                            lonFirst,
                            latSecond,
                            lonSecond
                        );
                        let verticalDistance = elevationLast - elevationFirst;

                        resolve(verticalDistance / horizontalDistance);
                    } else {
                        console.error('Elevation service failed:', status);
                        reject(new Error('Elevation service failed'));
                    }
                }
            );
        });
    }

    // search vehicles and show them in table
    public searchItems() {
        if (this.vehicleViewOn) {
            this.searchResultVehicles = [];
            var currentTimeMS2: number = new Date().getTime();
            if (this.searchText == '') {
                this.searchResultVehicles = this.markers;
                this.vehicleTableContent = [];
                for (var i = 0; i < this.searchResultVehicles.length; i++) {
                    for (var j = 0; j < this.fleetLocations.length; j++) {
                        if (
                            this.searchResultVehicles[i].vehicleId ==
                            this.fleetLocations[j].vehicle_id
                        ) {
                            // <<< ENABLE THIS...
                            var timeD: Date = new Date(this.fleetLocations[j].locationtime);
                            // var tf:string = this.datePipe.transform(timeD, 'yyyy-MM-dd HH:mm:ss');
                            var tf: string = this.momentTimezoneDatePipe.transform(
                                timeD,
                                'yyyy-MM-dd HH:mm:ss',
                                this.timeZone
                            );

                            var vehicleIdT = this.fleetLocations[j].vehicle_id;
                            var updateTimeT: string = tf;
                            var speedT: number = this.fleetLocations[j].speed;
                            var delayT: number = this.fleetLocations[j].delay;
                            // ... ENABLE THIS >>>

                            //  // <<< DISABLE THIS...
                            // var vehicleIdT = this.fleetLocations[j].vehicle_id;
                            // var updateTimeT: string = "2018.11.03 16:55:38";
                            // var speedT: number = this.counter1 + 100.65;
                            // var delayT: number = (28*24*3600 + 22*3600 + 45*60 + 58);
                            // // ...DISABLE THIS >>>

                            this.vehicleTableContent.push({
                                vehicleId: vehicleIdT,
                                updateTime: updateTimeT,
                                speed: speedT,
                                delay: this.secondsToDHMS(delayT),
                                latitude: this.fleetLocations[j].latitude,
                                longitude: this.fleetLocations[j].longitude,
                                direction: this.fleetLocations[j].rotation,
                                composition: this.fleetLocations[j].composition,
                                serialNumber: this.fleetLocations[j].serialNumber,
                                updatedAgo: this.secondsToDHMS2(
                                    (currentTimeMS2 - this.fleetLocations[j].locationtime) / 1000
                                ),
                            });

                            break;
                        }
                    }
                }
            } else {
                this.searchResultVehicles = this.searchPipe.transform(
                    this.markers,
                    this.searchText
                );
                this.vehicleTableContent = [];
                for (var i = 0; i < this.searchResultVehicles.length; i++) {
                    for (var j = 0; j < this.fleetLocations.length; j++) {
                        if (
                            this.searchResultVehicles[i].vehicleId ==
                            this.fleetLocations[j].vehicle_id
                        ) {
                            // <<< ENABLE THIS...
                            var timeD: Date = new Date(this.fleetLocations[j].locationtime);
                            // var tf:string = this.datePipe.transform(timeD, 'yyyy-MM-dd HH:mm:ss');
                            var tf: string = this.momentTimezoneDatePipe.transform(
                                timeD,
                                'yyyy-MM-dd HH:mm:ss',
                                this.timeZone
                            );

                            var vehicleIdT = this.fleetLocations[j].vehicle_id;
                            var updateTimeT: string = tf;
                            var speedT: number = this.fleetLocations[j].speed;
                            var delayT: number = this.fleetLocations[j].delay;
                            // ... ENABLE THIS >>>

                            //  // <<< DISABLE THIS...
                            // var vehicleIdT = this.fleetLocations[j].vehicle_id;
                            // var updateTimeT: string = "2018.11.03 16:55:38";
                            // var speedT: number = this.counter1 + 100.65;
                            // var delayT: number = (28*24*3600 + 22*3600 + 45*60 + 58);
                            // // ...DISABLE THIS >>>

                            this.vehicleTableContent.push({
                                vehicleId: vehicleIdT,
                                updateTime: updateTimeT,
                                speed: speedT,
                                delay: this.secondsToDHMS(delayT),
                                latitude: this.fleetLocations[j].latitude,
                                longitude: this.fleetLocations[j].longitude,
                                direction: this.fleetLocations[j].rotation,
                                composition: this.fleetLocations[j].composition,
                                serialNumber: this.fleetLocations[j].serialNumber,
                                updatedAgo: this.secondsToDHMS2(
                                    (currentTimeMS2 - this.fleetLocations[j].locationtime) / 1000
                                ),
                            });

                            break;
                        }
                    }
                }
            }
            if (this.gridReadyVehicles == true) {
                this.gridApiVehicles.setRowData(this.vehicleTableContent);
                this.gridVehicleRowCount = this.gridApiVehicles.getDisplayedRowCount();
            }

            // this.showVehiclesTable = true;
            // this.showVehicleInfo = false;
        }

        this._changeDetectorRef.detectChanges();
    }

    showSearchedVehiclesInTable() {
        this.unsubscribeRefreshLatestMqttSignals();
        this.unsubscribeRefreshLatestEvents();
        this.searchItems();
        this.showVehiclesTable = true;
        this.showVehicleInfo = false;
        this._changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
        this._titleService.setTitle('Fleet Location Map');
        this.title = this._titleService.getTitle();

        this.getListAllLastSeenSignals();
        this.getListAllLastSeenEvents();

        this.subSideBar = this._fleetManagementService.sideNavButtonClicked.subscribe((click1) => {
            this.noExecuteOnLoadIndicator = false;
        });
    }

    changeTitle(newTitle: string) {
        this.title = newTitle;
    }

    ngOnInit() {
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','First Page'")
            .subscribe((result: any) => {});

        this.getVehicleTypes();

        this.getSpeedThreshold();

        this.subGetEventList = this._signalService
            .getEventList()
            .subscribe((events: ICriteriaDataItem[]) => {
                for (var i = 0; i < events.length; i++) {
                    this.events.push(events[i].value);
                }
                this.loadEventStates();
            });

        this.loadTimeouts();

        this.onResize({});
        this._fleetManagementService.selectedVehicle.subscribe((selectedVehicle) => {
            this.selectedVehicle = selectedVehicle;
        });

        // We must do this because of the next step where we set selectedVehicle to ''.
        this.selectedVehicleTemp = this.selectedVehicle;
        this._fleetManagementService.showVehicleService('');

        this.typesFromDb = [
            { 'viewValue': 'POI 1', 'value': 'POI 1' },
            { 'viewValue': 'POI 2', 'value': 'POI 2' },
            { 'viewValue': 'Stations 1', 'value': 'Stations 1' },
            { 'viewValue': 'Depot', 'value': 'Depot' },
            { 'viewValue': 'Neutral section', 'value': 'Neutral section' },
            { 'viewValue': 'Bridge', 'value': 'Bridge' },
            { 'viewValue': 'Crossing', 'value': 'Crossing' },
            { 'viewValue': 'Balise', 'value': 'Balise' },
            { 'viewValue': 'Signal', 'value': 'Signal' },
            { 'viewValue': 'Switch', 'value': 'Switch' },
        ].sort(function (a, b) {
            var nameA = a.value.toLowerCase(),
                nameB = b.value.toLowerCase();
            if (nameA < nameB) {
                //sort string ascending
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.getParamsFromPath();
    }

    // last seen signals and events...

    // last seen signals...

    lastSignalSelectionViewOn: boolean = false;

    listAllLastSeenSignals: LastSeenSignalForFleetLocation[] = []; // represents  all possible signals
    requiredLastSeenSignals: any[] = []; // signals that user wants to see in live data

    lastSeenSignalsData: LastSeenSignalData[] = []; // all latest signals for selected vehicle
    lastSeenSignalsForShowing: LastSeenSignalData[] = []; // only signals that will be shown for selected vehicle, based on selected signals

    noLatestMqttSignalsForVehicle: boolean = false;
    lastSeenSignalsForShowingIsEmpty: boolean = false;

    openLiveSignalSettings() {
        this.lastSignalSelectionViewOn = true;
    }

    closeLiveSignalSettings() {
        this.sidenavToggle();
        setTimeout(() => {
            this.lastSignalSelectionViewOn = false;
        }, 250);
    }

    getListAllLastSeenSignals() {
        this._fleetManagementService.getListLastSeenSignalsSrv().subscribe((res) => {
            this.listAllLastSeenSignals = res;
            setTimeout(() => {
                this.getRequiredLiveSignals();
            }, 50);
        });
    }

    saveRequiredLiveSignals(requiredSignals: string[]) {
        this.subSaveRequiredLiveSignals = this._fleetManagementService
            .saveRequiredLiveSignalsSrv(this.profUserName, requiredSignals)
            .subscribe(
                (res) => {
                    this._snackBar.open('Change saved.', 'Ok', { duration: 1000 });
                    this.getRequiredLiveSignals();
                },
                (error: any) => {
                    this._snackBar.open('ERROR while saving!', 'Ok', { duration: 10000 });
                }
            );
    }

    getRequiredLiveSignals() {
        this.subGetRequiredLiveSignals = this._fleetManagementService
            .getRequiredLiveSignalsSrv(this.profUserName)
            .subscribe((res: any) => {
                this.requiredLastSeenSignals = res;
            });
    }

    getLatestMqttSignalsForVehicle(vehicleId: string) {
        this.subGetLatestMqttSignals = this._fleetManagementService
            .getLatestMqttSignals(vehicleId)
            .subscribe((res: LastSeenSignalData[]) => {
                this.lastSeenSignalsData = res;

                if (res.length == 0) {
                    this.noLatestMqttSignalsForVehicle = true;
                    this.lastSeenSignalsForShowingIsEmpty = false;
                } else {
                    this.noLatestMqttSignalsForVehicle = false;
                    this.lastSeenSignalsForShowing = [];

                    for (var i = 0; i < this.lastSeenSignalsData.length; i++) {
                        for (var j = 0; j < this.requiredLastSeenSignals.length; j++) {
                            if (
                                this.lastSeenSignalsData[i].signalId ==
                                this.requiredLastSeenSignals[j]
                            ) {
                                this.lastSeenSignalsForShowing.push(this.lastSeenSignalsData[i]);
                                break;
                            }
                        }
                    }

                    if (this.lastSeenSignalsForShowing.length == 0) {
                        this.lastSeenSignalsForShowingIsEmpty = true;
                    } else {
                        this.lastSeenSignalsForShowingIsEmpty = false;
                    }
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    latestSignalsEnabled: boolean = false;
    selectedVehicleForLatestSignals: string = '';

    refreshLatestMqttSignalsForSelectedVehicle(vehicleId: string) {
        this.latestSignalsEnabled = true;
        this.selectedVehicleForLatestSignals = vehicleId;

        this.getLatestMqttSignalsForVehicle(vehicleId);
    }

    unsubscribeRefreshLatestMqttSignals() {
        this.latestSignalsEnabled = false;
        this.selectedVehicleForLatestSignals = '';

        // this.mqttForInfoSidebarPV = [];   // MOZDA MOZE DA SE OBRISE
    }

    private gridApiLiveSignals;
    private gridColumnApiLiveSignals;
    liveSignalsTableHeight: number = 500;
    rowSelectionGridLiveSignals = 'multiple';
    suppressRowClickSelectionLiveSignals: boolean = true;
    noDataLiveSignalsGridOverlay: string = 'No signals';

    columnDefsLiveSignals: any[] = [
        { field: 'signal', headerName: 'Signal name', width: 300, checkboxSelection: true },
    ];

    onGridReadyLiveSignals(params) {
        this.gridApiLiveSignals = params.api;
        this.gridColumnApiLiveSignals = params.columnApi;
        this.gridApiLiveSignals.showNoRowsOverlay();
        this.gridApiLiveSignals.setRowData(this.listAllLastSeenSignals);

        setTimeout(() => {
            for (var i = 0; i < this.requiredLastSeenSignals.length; i++) {
                var rowid: number = -1;
                var signal: string = this.requiredLastSeenSignals[i];
                this.gridApiLiveSignals.forEachNode(function (node, index) {
                    if (node.data.signal == signal) {
                        rowid = node.id;
                    }
                });
                if (rowid != -1) {
                    this.gridApiLiveSignals.getRowNode(rowid).setSelected(true);
                }
            }
        }, 50);
    }

    onSelectionChangedLiveSignals(event) {
        if (
            event.columnApi?.columnController.eventService?.firedEvents.cellMouseDown != undefined
        ) {
            if (event.columnApi?.columnController.eventService?.firedEvents.cellMouseDown == true) {
                var selected: any[] = [];
                selected = this.gridApiLiveSignals.getSelectedRows();
                var reqArr: string[] = [];
                for (var i = 0; i < selected.length; i++) {
                    reqArr.push(selected[i].signal);
                }
                this.saveRequiredLiveSignals(reqArr);
            }
        }
    }

    // ...last seen signals

    // last seen events...

    lastEventSelectionViewOn: boolean = false;

    listAllLastSeenEvents: LastSeenEventForFleetLocation[] = []; // represents all possible events
    requiredLastSeenEvents: any[] = []; // events that user wants to see in live data

    lastSeenEventsData: LastSeenEventData[] = []; // all latest events for selected vehicle
    lastSeenEventsForShowing: LastSeenEventData[] = []; // only events that will be shown for selected vehicle, based on selected events

    noLatestEventsForVehicle: boolean = false;
    lastSeenEventsForShowingIsEmpty: boolean = false;

    openLiveEventSettings() {
        this.lastEventSelectionViewOn = true;
    }

    closeLiveEventSettings() {
        this.sidenavToggle();
        setTimeout(() => {
            this.lastEventSelectionViewOn = false;
        }, 250);
    }

    getListAllLastSeenEvents() {
        this._fleetManagementService.getListLastSeenEventsSrv().subscribe((res) => {
            this.listAllLastSeenEvents = res;
            setTimeout(() => {
                this.getRequiredLiveEvents();
            }, 100);
        });
    }

    saveRequiredLiveEvents(requiredEvents: string[]) {
        this.subSaveRequiredLiveEvents = this._fleetManagementService
            .saveRequiredLiveEventsSrv(this.profUserName, requiredEvents)
            .subscribe(
                (res) => {
                    this._snackBar.open('Change saved.', 'Ok', { duration: 1000 });
                    this.getRequiredLiveEvents();
                },
                (error: any) => {
                    this._snackBar.open('ERROR while saving!', 'Ok', { duration: 10000 });
                }
            );
    }

    getRequiredLiveEvents() {
        this.subGetRequiredLiveEvents = this._fleetManagementService
            .getRequiredLiveEventsSrv(this.profUserName)
            .subscribe((res: any) => {
                this.requiredLastSeenEvents = res;
            });
    }

    getLatestEventsForVehicle(vehicleId: string) {
        this.subGetLatestEvents = this._fleetManagementService
            .getLatestEvents(vehicleId)
            .subscribe((res: LastSeenEventData[]) => {
                this.lastSeenEventsData = res;

                if (res.length == 0) {
                    this.noLatestEventsForVehicle = true;
                    this.lastSeenEventsForShowingIsEmpty = false;
                } else {
                    var currentTimeEv: number = new Date().getTime();

                    this.noLatestEventsForVehicle = false;
                    this.lastSeenEventsForShowing = [];

                    for (var i = 0; i < this.lastSeenEventsData.length; i++) {
                        for (var j = 0; j < this.requiredLastSeenEvents.length; j++) {
                            if (
                                this.lastSeenEventsData[i].eventType ==
                                this.requiredLastSeenEvents[j]
                            ) {
                                this.lastSeenEventsData[i].updatedAgo = this.secondsToDHMS2(
                                    (currentTimeEv - this.lastSeenEventsData[i].time) / 1000
                                );
                                this.lastSeenEventsForShowing.push(this.lastSeenEventsData[i]);
                                break;
                            }
                        }
                    }

                    if (this.lastSeenEventsForShowing.length == 0) {
                        this.lastSeenEventsForShowingIsEmpty = true;
                    } else {
                        this.lastSeenEventsForShowingIsEmpty = false;
                    }
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    refreshLatestEventsForSelectedVehicle(vehicleId: string) {
        this.getLatestEventsForVehicle(vehicleId);
        this.subRefreshLatestEvents = interval(5000).subscribe(() => {
            this.getLatestEventsForVehicle(vehicleId);
        });
    }

    unsubscribeRefreshLatestEvents() {
        // this.mqttForInfoSidebarPV = [];   // MOZDA MOZE DA SE OBRISE
        this.subRefreshLatestEvents.unsubscribe();
    }

    private gridApiLiveEvents;
    private gridColumnApiLiveEvents;
    liveEventsTableHeight: number = 500;
    rowSelectionGridLiveEvents = 'multiple';
    suppressRowClickSelectionLiveEvents: boolean = true;
    noDataLiveEventsGridOverlay: string = 'No events';

    columnDefsLiveEvents: any[] = [
        { field: 'event', headerName: 'Event name', width: 180, checkboxSelection: true },
        { field: 'category', headerName: 'Event category', width: 120 },
    ];

    onGridReadyLiveEvents(params) {
        this.gridApiLiveEvents = params.api;
        this.gridColumnApiLiveEvents = params.columnApi;
        this.gridApiLiveEvents.showNoRowsOverlay();
        this.gridApiLiveEvents.setRowData(this.listAllLastSeenEvents);

        setTimeout(() => {
            for (var i = 0; i < this.requiredLastSeenEvents.length; i++) {
                var rowid: number = -1;
                var event: string = this.requiredLastSeenEvents[i];
                this.gridApiLiveEvents.forEachNode(function (node, index) {
                    if (node.data.event == event) {
                        rowid = node.id;
                    }
                });
                if (rowid != -1) {
                    this.gridApiLiveEvents.getRowNode(rowid).setSelected(true);
                }
            }
        }, 50);
    }

    onSelectionChangedLiveEvents(event) {
        if (event.columnApi.columnController.eventService.firedEvents.cellMouseDown != undefined) {
            if (event.columnApi.columnController.eventService.firedEvents.cellMouseDown == true) {
                var selected: any[] = [];
                selected = this.gridApiLiveEvents.getSelectedRows();
                var reqArr: string[] = [];
                for (var i = 0; i < selected.length; i++) {
                    reqArr.push(selected[i].event);
                }
                this.saveRequiredLiveEvents(reqArr);
            }
        }
    }

    // ...last seen events

    // ...last seen signals and events

    getParamsFromPath() {
        this.subActivatedRoute = this.route.queryParams.subscribe((params) => {
            var vehicle = params['vehicle'];

            if (vehicle != undefined) {
                this.searchText = vehicle;
            } else {
                this.searchText = '';
            }

            this.searchTextPoi = '';

            if (vehicle != undefined) {
                this.sidenavOpened = true;
                this.vehicleViewOn = true;
                this.showSearchedVehiclesInTable();
            }
        });
    }

    ngOnDestroy() {
        this.subAutoRefresh.unsubscribe();
        this.subFleetLocation1.unsubscribe();
        this.subFleetLocation2.unsubscribe();
        this.subFleetLocation3.unsubscribe();
        this.subFleetLocation4.unsubscribe();
        this.subLoadMapSettings.unsubscribe();
        this.subGetAdditionalInfo.unsubscribe();
        this.subSideBar.unsubscribe();
        this.subscriptionLoadInfrastructure.unsubscribe();
        this.subSaveMapSettings.unsubscribe();
        this.subAnimateZoom.unsubscribe();
        this.subActivatedRoute.unsubscribe();
        this.subLoadInfrastructureTypes.unsubscribe();
        this.subIsTypeInDb.unsubscribe();
        this.subAddInfraType.unsubscribe();
        this.subDialogAddInfraType.unsubscribe();
        this.subDialogAddInfraType.unsubscribe();
        this.subIsTypeInDb2.unsubscribe();
        this.subDialogEditInfraType.unsubscribe();
        this.subEditInfraType2.unsubscribe();
        this.subDeleteInfraType.unsubscribe();
        this.subDeleteInfraElement.unsubscribe();
        this.subAddInfraElement.unsubscribe();
        this.subEditInfraElement.unsubscribe();
        this.subUpdateInfraElement.unsubscribe();
        this.subDialogDuplicatesInfraElements.unsubscribe();
        this.subDialogAddInfraElement.unsubscribe();
        this.subDialogRef1.unsubscribe();
        this.subDialogDeleteInfraType.unsubscribe();
        this.subDialogDeleteInfraElement.unsubscribe();
        this.subDialogEditInfraElement.unsubscribe();
        this.subDownloadInfraElementsFile.unsubscribe();
        this.subGetSpeedThreshold.unsubscribe();
        this.subSaveSpeedThreshold.unsubscribe();
        this.subSaveMapLayerSettings.unsubscribe();
        this.subLoadMapLayerSettings.unsubscribe();
        this.subRefreshLatestMqttSignals.unsubscribe();
        this.subRefreshLatestEvents.unsubscribe();
        this.subGetEventList.unsubscribe();
        this.subSaveEventState.unsubscribe();
        this.subGetEventStates.unsubscribe();
        this.subDialogSetTimeouts.unsubscribe();
        this.subSaveTimeouts.unsubscribe();
        this.subGetTimeouts.unsubscribe();
        this.subSaveRequiredLiveSignals.unsubscribe();
        this.subGetRequiredLiveSignals.unsubscribe();
        this.subSaveRequiredLiveEvents.unsubscribe();
        this.subGetRequiredLiveEvents.unsubscribe();
        this.subSetToOkDialog.unsubscribe();
        this.subSetToOk.unsubscribe();
        this.subGetLatestMqttSignals.unsubscribe();
        this.subGetLatestEvents.unsubscribe();
    }

    counter1: number = 0;

    startTracking(): void {
        this.subFleetLocation3 = this._fleetManagementService
            .getFleetLocation()
            .subscribe((res: IFleetLocation[]) => {
                this.fleetLocations = res;

                for (var i = 0; i < this.fleetLocations.length; i++) {
                    var vState: VehicleState = new VehicleState();
                    vState.vehicleId = this.fleetLocations[i].vehicle_id;
                    vState.state = this.fleetLocations[i].vehicleState;
                    vState.timestamp = this.fleetLocations[i].eventTimestamp;
                    this.vehiclesEventStatesMap.set(this.fleetLocations[i].vehicle_id, vState);
                }

                var currentTimeMS2: number = new Date().getTime();
                for (var i = 0; i < res.length; i++) {
                    if (res[i].vehicle_id === this.selectedVehicleTemp) {
                        // <<< ENABLE THIS...
                        var speed: number = res[i].speed;
                        var gpsAccu: number = res[i].gpsAccuracy;
                        // ... ENABLE THIS >>>

                        // // <<< DISABLE THIS...
                        // var speed:number = 120;
                        // var gpsAccu: number = 20;
                        // // ...DISABLE THIS >>>

                        var iconcur = this.icons.train.icon;

                        this.markers.push({
                            lat: Number(res[i].latitude),
                            lng: Number(res[i].longitude),
                            direction: Number(res[i].rotation),
                            draggable: false,
                            vehicleId: res[i].vehicle_id,
                            gpsAccuracy: gpsAccu,
                            icon: {
                                url: iconcur,
                                labelOrigin: new google.maps.Point(15, 37),
                            },
                            label: {
                                text: res[i].vehicle_id,
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '11px',
                            },
                            serialNumber: res[i].serialNumber,
                            // icon: this.icons.selectedTrain.icon
                        });
                        this.direction = Number(res[i].rotation);
                    } else {
                        // <<< ENABLE THIS...
                        var speed: number = res[i].speed;
                        var gpsAccu: number = res[i].gpsAccuracy;
                        // ... ENABLE THIS >>>

                        // // <<< DISABLE THIS...
                        // var speed:number = 120;
                        // var gpsAccu: number = 30;
                        // // ...DISABLE THIS >>>

                        var pathValue = {};
                        var strokeColorValue: string = '#0099ff';
                        var fillColorValue: string = this.vehicleStateGetColor(res[i].vehicle_id);
                        var scaleValue: number = 6;
                        var labelOrigin: any = {};
                        var anchor: any = {};
                        var rotation: any = 0;
                        if (
                            (this.speedThreshold == 0 && res[i].speed == 0) ||
                            (this.speedThreshold > 0 && res[i].speed < this.speedThreshold)
                        ) {
                            pathValue = google.maps.SymbolPath.CIRCLE;
                            scaleValue = 9;
                            labelOrigin = new google.maps.Point(0, 2.7);
                            anchor = new google.maps.Point(0, 0);
                        } else {
                            pathValue = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
                            scaleValue = 6;
                            rotation = res[i].rotation;
                            labelOrigin = new google.maps.Point(
                                this.pointTransformation1(rotation),
                                this.pointTransformation2(rotation)
                            );
                            anchor = new google.maps.Point(0, 2.5);
                        }

                        var locationTime = res[i].locationtime;
                        strokeColorValue = this.changeColorTimeouts(currentTimeMS2, locationTime);

                        if (res[i].vehicle_id == this.clickedVehicle) {
                            strokeColorValue = '#00ff00';
                        }

                        this.markers.push({
                            lat: Number(res[i].latitude),
                            lng: Number(res[i].longitude),
                            direction: Number(res[i].rotation),
                            draggable: false,
                            vehicleId: res[i].vehicle_id,
                            gpsAccuracy: gpsAccu,
                            // icon: {
                            //     url: iconcur,
                            //     labelOrigin: new google.maps.Point(15,37)},

                            icon: {
                                path: pathValue,
                                strokeColor: strokeColorValue,
                                strokeOpacity: 1,
                                strokeWeight: 4,
                                fillColor: fillColorValue,
                                fillOpacity: 1,
                                scale: scaleValue,
                                rotation: rotation,
                                // labelOrigin: new google.maps.Point(5,0)
                                labelOrigin: labelOrigin,
                                anchor: anchor,
                            },
                            label: {
                                text: res[i].vehicle_id,
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '11px',
                            },
                            serialNumber: res[i].serialNumber,
                            // icon: this.icons.train.icon
                        });
                    }
                }

                if (this.markers.length > 0) {
                    if (!this.useMapSettingsFromDB) {
                        this.bounds = new google.maps.LatLngBounds(); // FOR SHOWING ALL VEHICLES ON MAP
                        for (var i = 0; i < this.markers.length; i++) {
                            var lat1 = this.markers[i].lat;
                            var lng1 = this.markers[i].lng;
                            var latlng: LatLng = new google.maps.LatLng(lat1, lng1);
                            this.bounds.extend(latlng);
                        }
                    }
                }
                this._changeDetectorRef.detectChanges();
            });

        this.subAutoRefresh = interval(5000).subscribe((x) => {
            this.counter1++;
            this.refreshSelection();

            if (this.latestSignalsEnabled) {
                this.getLatestMqttSignalsForVehicle(this.selectedVehicleForLatestSignals);
            }
        });
    }

    getVehicleTypes() {
        this._fleetManagementService.getVehiclesTypesSrv().subscribe((res) => {
            for (var i = 0; i < res.length; i++) {
                this.vehicleTypesMap.set(res[i].vehicleId, res[i].type);
            }
        });
    }

    refreshSelection() {
        this.subFleetLocation4 = this._fleetManagementService
            .getFleetLocationMicro()
            .subscribe(async (res: IFleetLocation[]) => {
                this.fleetLocations = res;

                for (var i = 0; i < this.fleetLocations.length; i++) {
                    var vState: VehicleState = new VehicleState();
                    vState.vehicleId = this.fleetLocations[i].vehicle_id;
                    vState.state = this.fleetLocations[i].vehicleState;
                    vState.timestamp = this.fleetLocations[i].eventTimestamp;
                    this.vehiclesEventStatesMap.set(this.fleetLocations[i].vehicle_id, vState);
                }

                var currentTimeMS2: number = new Date().getTime();
                for (var i = 0; i < res.length; i++) {
                    var foundMarker: boolean = false;
                    for (var n = 0; n < this.markers.length; n++) {
                        // <<< ENABLE THIS...
                        var speed: number = res[i].speed;
                        var gpsAccu: number = res[i].gpsAccuracy;
                        // ... ENABLE THIS >>>

                        // // <<< DISABLE THIS...
                        // var speed:number = 120;
                        // var gpsAccu: number = 30;
                        // // ...DISABLE THIS >>>

                        // var iconcur = this.icons.train.icon;
                        // if ((speed == 0) && (isDelayed == 0)) {
                        //   iconcur = this.icons.train1.icon;
                        // } else if ((speed == 0) && (isDelayed == 1)) {
                        //   iconcur = this.icons.train2.icon;
                        // } else if ((speed > 0) && (isDelayed == 0)) {
                        //   iconcur = this.icons.train3.icon;
                        // } else if ((speed > 0) && (isDelayed == 1)) {
                        //   iconcur = this.icons.train4.icon;
                        // }

                        if (this.markers[n].vehicleId == res[i].vehicle_id) {
                            // <<< ENABLE THIS...
                            this.markers[n].lat = Number(res[i].latitude);
                            this.markers[n].lng = Number(res[i].longitude);
                            this.markers[n].direction = Number(res[i].rotation);
                            this.markers[n].gpsAccuracy = gpsAccu;
                            // ... ENABLE THIS >>>

                            // // <<< DISABLE THIS...
                            // this.markers[n].lat = this.markers[n].lat + Number(res[i].latitude);
                            // this.markers[n].lng = this.markers[n].lng + Number(res[i].longitude);
                            // this.markers[n].gpsAccuracy = gpsAccu;
                            // // ...DISABLE THIS >>>

                            // this.markers[n].icon = iconcur;
                            // this.markers[n].icon = {
                            //   url: iconcur,
                            //   labelOrigin: new google.maps.Point(15,37)
                            // };
                            var pathValue = {};
                            var strokeColorValue: string = '#0099ff';
                            var fillColorValue: string = this.vehicleStateGetColor(
                                res[i].vehicle_id
                            );
                            var scaleValue: number = 6;
                            var labelOrigin: any = {};
                            var anchor: any = {};
                            var rotation: any = 0;
                            if (
                                (this.speedThreshold == 0 && res[i].speed == 0) ||
                                (this.speedThreshold > 0 && res[i].speed < this.speedThreshold)
                            ) {
                                pathValue = google.maps.SymbolPath.CIRCLE;
                                scaleValue = 9;
                                labelOrigin = new google.maps.Point(0, 2.7);
                                anchor = new google.maps.Point(0, 0);
                            } else {
                                pathValue = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
                                scaleValue = 6;
                                rotation = res[i].rotation;
                                labelOrigin = new google.maps.Point(
                                    this.pointTransformation1(rotation),
                                    this.pointTransformation2(rotation)
                                );
                                anchor = new google.maps.Point(0, 2.5);
                            }

                            var locationTime = res[i].locationtime;
                            strokeColorValue = this.changeColorTimeouts(
                                currentTimeMS2,
                                locationTime
                            );

                            if (res[i].vehicle_id == this.clickedVehicle) {
                                strokeColorValue = '#00ff00';
                            }

                            this.markers[n].icon = {
                                path: pathValue,
                                strokeColor: strokeColorValue,
                                strokeOpacity: 1,
                                strokeWeight: 4,
                                fillColor: fillColorValue,
                                fillOpacity: 1,
                                scale: scaleValue,
                                rotation: rotation,
                                // labelOrigin: new google.maps.Point(5,0)
                                labelOrigin: labelOrigin,
                                anchor: anchor,
                            };
                            this.markers[n].serialNumber = res[i].serialNumber;
                            foundMarker = true;
                            break;
                        }
                    }
                    if (!foundMarker) {
                        var pathValue = {};
                        var strokeColorValue: string = '#0099ff';
                        var fillColorValue: string = '#33cc33';
                        var scaleValue: number = 6;
                        var labelOrigin: any = {};
                        var anchor: any = {};
                        var rotation: any = 0;
                        if (
                            (this.speedThreshold == 0 && res[i].speed == 0) ||
                            (this.speedThreshold > 0 && res[i].speed < this.speedThreshold)
                        ) {
                            pathValue = google.maps.SymbolPath.CIRCLE;
                            scaleValue = 9;
                            labelOrigin = new google.maps.Point(0, 2.7);
                            anchor = new google.maps.Point(0, 0);
                        } else {
                            pathValue = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
                            scaleValue = 6;
                            rotation = res[i].rotation;
                            labelOrigin = new google.maps.Point(
                                this.pointTransformation1(rotation),
                                this.pointTransformation2(rotation)
                            );
                            anchor = new google.maps.Point(0, 2.5);
                        }

                        var locationTime = res[i].locationtime;
                        strokeColorValue = this.changeColorTimeouts(currentTimeMS2, locationTime);

                        if (res[i].vehicle_id == this.clickedVehicle) {
                            strokeColorValue = '#00ff00';
                        }

                        this.markers.push({
                            lat: Number(res[i].latitude),
                            lng: Number(res[i].longitude),
                            direction: Number(res[i].rotation),
                            draggable: false,
                            vehicleId: res[i].vehicle_id,
                            gpsAccuracy: res[i].gpsAccuracy,
                            // icon: {
                            //     url: iconcur,
                            //     labelOrigin: new google.maps.Point(15,37)},

                            icon: {
                                path: pathValue,
                                strokeColor: strokeColorValue,
                                strokeOpacity: 1,
                                strokeWeight: 4,
                                fillColor: fillColorValue,
                                fillOpacity: 1,
                                scale: scaleValue,
                                rotation: rotation,
                                // labelOrigin: new google.maps.Point(5,0)
                                labelOrigin: labelOrigin,
                                anchor: anchor,
                            },
                            label: {
                                text: res[i].vehicle_id,
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '11px',
                            },
                            serialNumber: res[i].serialNumber,
                            // icon: this.icons.train.icon
                        });
                    }

                    // this.markers[i].lat = this.markers[i].lat + Number(res[i].latitude);
                    // this.markers[i].lng = this.markers[i].lng + Number(res[i].longitude);
                }

                // FOR REFRESHING DATA FOR VEHICLE INFO VIEW
                if (this.showVehicleInfo) {
                    for (var i = 0; i < this.fleetLocations.length; i++) {
                        if (this.fleetLocations[i].vehicle_id == this.clickedVehicle) {
                            // <<< ENABLE THIS...
                            var timeD: Date = new Date(this.fleetLocations[i].locationtime);
                            // var tf:string = this.datePipe.transform(timeD, 'yyyy-MM-dd HH:mm:ss');
                            var tf: string = this.momentTimezoneDatePipe.transform(
                                timeD,
                                'yyyy-MM-dd HH:mm:ss',
                                this.timeZone
                            );

                            var vehicleIdT = this.fleetLocations[i].vehicle_id;
                            var updateTimeT: string = tf;
                            var speedT: number = Math.round(this.fleetLocations[i].speed);
                            var delayT: number = this.fleetLocations[i].delay;
                            // ... ENABLE THIS >>>

                            // // <<< DISABLE THIS...
                            // var vehicleIdT = this.fleetLocations[i].vehicle_id;
                            // var updateTimeT: string = "2018.11.03 16:55:38";
                            // var speedT: number = 10000000000 + parseInt(vehicleIdT + "0000") + this.counter1;
                            // var delayT: number = 0;
                            // // ...DISABLE THIS >>>

                            this.timeoutColorStroke = this.changeColorTimeouts(
                                currentTimeMS2,
                                this.fleetLocations[i].locationtime
                            );
                            this.vehicleStateColorFill = this.vehicleStateGetColor(
                                this.fleetLocations[i].vehicle_id
                            );
                            this.selectedVehicleType = this.vehicleTypesMap.get(
                                this.clickedVehicle
                            );

                            this._changeDetectorRef.detectChanges();

                            this.vehicleForShowingInfo[0] = {
                                vehicleId: vehicleIdT,
                                updateTime: updateTimeT,
                                speed: speedT,
                                delay: this.secondsToDHMS(delayT),
                                latitude: this.fleetLocations[i].latitude,
                                longitude: this.fleetLocations[i].longitude,
                                direction: this.fleetLocations[i].rotation,
                                composition: this.fleetLocations[i].composition,
                                serialNumber: this.fleetLocations[i].serialNumber,
                                updatedAgo: this.secondsToDHMS2(
                                    (currentTimeMS2 - this.fleetLocations[i].locationtime) / 1000
                                ),
                            };

                            var currentTime: Date = new Date();
                            var currentTimeMS: number = currentTime.getTime();
                            this.vehicleLastSeenMS =
                                currentTimeMS - this.fleetLocations[i].locationtime;
                            this.vehicleLastSeenTimeAgo = this.secondsToDHMS2(
                                this.vehicleLastSeenMS / 1000
                            );

                            if (
                                this.latFirst != null &&
                                this.lonFirst != null &&
                                (this.latFirst != Number(this.fleetLocations[i].latitude) ||
                                    this.lonFirst != Number(this.fleetLocations[i].longitude))
                            ) {
                                this.gradient = await this.calculateGradient(
                                    this.latFirst,
                                    this.lonFirst,
                                    Number(this.fleetLocations[i].latitude),
                                    Number(this.fleetLocations[i].longitude)
                                );
                            } else {
                                this.gradient = null;
                            }

                            if (this.gradient != null) {
                                this.direction = this.fleetLocations[i].rotation;
                            }
                            this._changeDetectorRef.detectChanges();
                            break;
                        }
                    }
                }

                if (this.showVehiclesTable) {
                    this.searchItems();
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    changeColorTimeouts(currentTimeMS: number, timeMS: number): string {
        if (+this.timeoutsNewValue == 0 && +this.timeoutsMiddleValue == 0) {
            return this.colorTimeoutNew; // return new
        } else {
            var time1new: number = 0;
            var time1middle: number = 0;
            var time1outdated: number = 0;

            var timeRanges: any[] = [];

            if (+this.timeoutsNewValue != 0) {
                if (this.timeoutsNewUnit == 's') {
                    time1new = +this.timeoutsNewValue * 1000;
                }
                if (this.timeoutsNewUnit == 'm') {
                    time1new = +this.timeoutsNewValue * 60 * 1000;
                }
                if (this.timeoutsNewUnit == 'h') {
                    time1new = +this.timeoutsNewValue * 3600 * 1000;
                }
                if (this.timeoutsNewUnit == 'd') {
                    time1new = +this.timeoutsNewValue * 24 * 3600 * 1000;
                }
                var tNew: any = {};
                tNew.status = 'new';
                tNew.value = time1new;
                timeRanges.push(tNew);
            }

            if (+this.timeoutsMiddleValue != 0) {
                if (this.timeoutsMiddleUnit == 's') {
                    time1middle = +this.timeoutsMiddleValue * 1000;
                }
                if (this.timeoutsMiddleUnit == 'm') {
                    time1middle = +this.timeoutsMiddleValue * 60 * 1000;
                }
                if (this.timeoutsMiddleUnit == 'h') {
                    time1middle = +this.timeoutsMiddleValue * 3600 * 1000;
                }
                if (this.timeoutsMiddleUnit == 'd') {
                    time1middle = +this.timeoutsMiddleValue * 24 * 3600 * 1000;
                }
                var tMiddle: any = {};
                tMiddle.status = 'middle';
                tMiddle.value = time1middle;
                timeRanges.push(tMiddle);
            }

            if (currentTimeMS - timeMS > timeRanges[timeRanges.length - 1].value) {
                return this.getColorForTimeouts('outdated');
            } else {
                for (var i = 0; i < timeRanges.length; i++) {
                    if (currentTimeMS - timeMS <= timeRanges[i].value) {
                        return this.getColorForTimeouts(timeRanges[i].status);
                    }
                }
            }

            return this.colorTimeoutOutdated; // return outdated
        }
    }

    getColorForTimeouts(status: string): string {
        var outlineColor: string = this.colorTimeoutOutdated; // outdated
        switch (status) {
            case 'new':
                outlineColor = this.colorTimeoutNew;
                break;
            case 'middle':
                outlineColor = this.colorTimeoutMiddle;
                break;
            case 'outdatd':
                outlineColor = this.colorTimeoutOutdated;
                break;
        }
        return outlineColor;
    }

    showHideEvents() {
        this.eventsShown = !this.eventsShown;
    }

    setLinkWithVehicleId(vehicleId: string, mlat: number, mlng: number): void {
        this.linkToVehicleDetailsPage =
            '/fleet-location/fleetLocationVehicleInfo?vehicleId=' +
            vehicleId +
            '&lat=' +
            mlat +
            '&lng=' +
            mlng;
    }

    public latFirst: number | null;
    public lonFirst: number | null;

    async clickedMarker(vehicleId: string, mlat: number, mlng: number, serialNumber: string) {
        this.resetCompass();
        this.clickedVehicle = vehicleId;
        this.clickedVehiclesSerialNumber = serialNumber;
        this.sidenavOpened = true;
        this.vehicleInfoBackButtonDisabled = true;
        var currentTimeMS2: number = new Date().getTime();

        this.c1();

        this.refreshSelection();

        for (var i = 0; i < this.fleetLocations.length; i++) {
            if (this.fleetLocations[i].vehicle_id == vehicleId) {
                // <<< ENABLE THIS...
                var timeD: Date = new Date(this.fleetLocations[i].locationtime);
                // var tf:string = this.datePipe.transform(timeD, 'yyyy-MM-dd HH:mm:ss');
                var tf: string = this.momentTimezoneDatePipe.transform(
                    timeD,
                    'yyyy-MM-dd HH:mm:ss',
                    this.timeZone
                );

                if (
                    this.latFirst != null &&
                    this.lonFirst != null &&
                    (this.latFirst != Number(this.fleetLocations[i].latitude) ||
                        this.lonFirst != Number(this.fleetLocations[i].longitude))
                ) {
                    this.gradient = await this.calculateGradient(
                        this.latFirst,
                        this.lonFirst,
                        Number(this.fleetLocations[i].latitude),
                        Number(this.fleetLocations[i].longitude)
                    );
                } else {
                    this.gradient = null;
                }

                this.latFirst = Number(this.fleetLocations[i].latitude);
                this.lonFirst = Number(this.fleetLocations[i].longitude);

                this.vehicleInfo =
                    'Vehicle ID: ' +
                    vehicleId +
                    ' Location update time: ' +
                    tf +
                    ' Speed is: ' +
                    this.fleetLocations[i].speed +
                    ' Delay: ' +
                    this.fleetLocations[i].delay +
                    ' sec';

                this.setLinkWithVehicleId(vehicleId, mlat, mlng);

                // *** for showing info in the side bar ***

                var vehicleIdT = this.fleetLocations[i].vehicle_id;
                var updateTimeT: string = tf;
                var speedT: number = this.fleetLocations[i].speed;
                var delayT: number = this.fleetLocations[i].delay;
                // ... ENABLE THIS >>>

                // // <<< DISABLE THIS...
                // var vehicleIdT = this.fleetLocations[i].vehicle_id;
                // var updateTimeT: string = "2018.11.03 16:55:38";
                // var speedT: number = 10000000000 + parseInt(vehicleIdT + "0000") + this.counter1;
                // var delayT: number = 0;
                // // ...DISABLE THIS >>>

                this.vehicleForShowingInfo[0] = {
                    vehicleId: vehicleIdT,
                    updateTime: updateTimeT,
                    speed: speedT,
                    delay: this.secondsToDHMS(delayT),
                    latitude: this.fleetLocations[i].latitude,
                    longitude: this.fleetLocations[i].longitude,
                    direction: this.fleetLocations[i].rotation,
                    composition: this.fleetLocations[i].composition,
                    serialNumber: this.fleetLocations[i].serialNumber,
                    updatedAgo: this.secondsToDHMS2(
                        (currentTimeMS2 - this.fleetLocations[i].locationtime) / 1000
                    ),
                };
                if (this.gradient != null) {
                    this.direction = this.fleetLocations[i].rotation;
                }
                this._changeDetectorRef.detectChanges();
                break;
            }
        }

        this.vehicleTableContent = [];
        this.showVehiclesTable = false;
        this.showVehicleInfo = true;

        this.refreshLatestMqttSignalsForSelectedVehicle(vehicleId);
        this.refreshLatestEventsForSelectedVehicle(vehicleId);
        this._changeDetectorRef.detectChanges();
    }

    openVehicleDetailPageInNewWindow(): void {
        let pathArray = window.location.pathname.split('/');
        let secondLevelLocation = pathArray[1];
        if (secondLevelLocation !== '') {
            secondLevelLocation = '/' + secondLevelLocation;
        }
        window.open(this.linkToVehicleDetailsPage);
    }

    clickedWarning(warningId: string): void {
        this.warningInfo = 'Warning id is: ' + warningId;
    }

    clickedError(errorId: string): void {
        this.errorInfo = 'Error id is: ' + errorId;
    }

    lastMapClickLat: number;
    lastMapClickLng: number;

    mapClick($event) {
        this.lastMapClickLat = $event.coords.lat;
        this.lastMapClickLng = $event.coords.lng;
    }

    openAnalyticsNow() {
        const currentTime: string = Date.now().toString();
        const queryParams = {
            vehicleid: this.clickedVehicle,
            time: currentTime,
        };

        this.routerHelperService.openLinkInNewTab('signal', queryParams);
    }

    openVehicleDashboard() {
        this.routerHelperService.openLinkInNewTab('fleet-management/vehicle-dashboard', {
            vehicleid: this.clickedVehicle,
        });
    }

    openDeviceOverview() {
        const queryParams = {
            vehicle: this.clickedVehicle,
            recorder: this.clickedVehiclesSerialNumber,
        };
        this.routerHelperService.openLinkInNewTab('teloc/details', queryParams);
    }

    parseDateForSignalAnalytics(oldFormat: string): string {
        var t1: string[] = oldFormat.split(' ');
        var date: string[] = t1[0].split('-');
        return date[2] + '-' + date[1] + '-' + date[0] + ' ' + t1[1];
    }

    parseTime(time: string): string {
        var t1: string[] = time.split(' ');
        switch (t1[1]) {
            case 'Jan':
                t1[1] = '01';
                break;
            case 'Feb':
                t1[1] = '02';
                break;
            case 'Mar':
                t1[1] = '03';
                break;
            case 'Apr':
                t1[1] = '04';
                break;
            case 'May':
                t1[1] = '05';
                break;
            case 'Jun':
                t1[1] = '06';
                break;
            case 'Jul':
                t1[1] = '07';
                break;
            case 'Aug':
                t1[1] = '08';
                break;
            case 'Sep':
                t1[1] = '09';
                break;
            case 'Oct':
                t1[1] = '10';
                break;
            case 'Nov':
                t1[1] = '11';
                break;
            case 'Dec':
                t1[1] = '12';
                break;
        }
        return t1[3] + '-' + t1[1] + '-' + t1[2] + ' ' + t1[4];
    }

    async showInfoForTrainClickedOnTable(id: string) {
        this.refreshSelection();

        this.clickedVehicle = id;
        this.vehicleInfoBackButtonDisabled = false;

        for (var i = 0; i < this.fleetLocations.length; i++) {
            if (this.fleetLocations[i].vehicle_id == id) {
                // <<< ENABLE THIS...
                var timeD: Date = new Date(this.fleetLocations[i].locationtime);
                // var tf:string = this.datePipe.transform(timeD, 'yyyy-MM-dd HH:mm:ss');
                var tf: string = this.momentTimezoneDatePipe.transform(
                    timeD,
                    'yyyy-MM-dd HH:mm:ss',
                    this.timeZone
                );

                var vehicleIdT = this.fleetLocations[i].vehicle_id;
                var updateTimeT: string = tf;
                var speedT: number = this.fleetLocations[i].speed;
                var delayT: number = this.fleetLocations[i].delay;
                // ... ENABLE THIS >>>

                // // <<< DISABLE THIS...
                // var vehicleIdT = this.fleetLocations[i].vehicle_id;
                // var updateTimeT: string = "2018.11.03 16:55:38";
                // var speedT: number = 10000000000 + parseInt(vehicleIdT + "0000") + this.counter1;
                // var delayT: number = 0;
                // // ...DISABLE THIS >>>

                if (
                    this.latFirst != null &&
                    this.lonFirst != null &&
                    (this.latFirst != Number(this.fleetLocations[i].latitude) ||
                        this.lonFirst != Number(this.fleetLocations[i].longitude))
                ) {
                    this.gradient = await this.calculateGradient(
                        this.latFirst,
                        this.lonFirst,
                        Number(this.fleetLocations[i].latitude),
                        Number(this.fleetLocations[i].longitude)
                    );
                } else {
                    this.gradient = null;
                }

                this.latFirst = Number(this.fleetLocations[i].latitude);
                this.lonFirst = Number(this.fleetLocations[i].longitude);

                this.vehicleForShowingInfo[0] = {
                    vehicleId: vehicleIdT,
                    updateTime: updateTimeT,
                    speed: speedT,
                    delay: this.secondsToDHMS(delayT),
                    latitude: this.fleetLocations[i].latitude,
                    longitude: this.fleetLocations[i].longitude,
                    direction: this.fleetLocations[i].rotation,
                    composition: this.fleetLocations[i].composition,
                    serialNumber: this.fleetLocations[i].serialNumber,
                    updatedAgo: this.secondsToDHMS2(
                        (new Date().getTime() - this.fleetLocations[i].locationtime) / 1000
                    ),
                };

                if (this.gradient != null) {
                    this.direction = this.fleetLocations[i].rotation;
                }
                break;
            }
        }

        for (var j = 0; j < this.markers.length; j++) {
            if (this.markers[j].vehicleId === id) {
                this.zoom = 14;
                this.lat = this.markers[j].lat;
                this.lng = this.markers[j].lng;
                break;
            }
        }
        this.showVehiclesTable = false;
        this.showVehicleInfo = true;
        this._changeDetectorRef.detectChanges();
    }

    sidenavToggle() {
        this.searchText = '';
        this.searchTextPoi = '';
        if (this.sidenavOpened) {
            this.resetCompass();
        }
        this.sidenavOpened = !this.sidenavOpened;
        this.vehicleTableContent = [];
        this.clickedVehicle = '';
        this.vehicleInfoBackButtonDisabled = true;

        if (this.sidenavOpened == false && this.showVehicleInfo == true) {
            this.unsubscribeRefreshLatestMqttSignals();
            this.unsubscribeRefreshLatestEvents();
        }

        this.showVehiclesTable = false;
        this.showVehicleInfo = false;

        this.showInfrastructureTable = false;

        if (this.sidenavOpened && this.vehicleViewOn) {
            // this.searchItems();
            this.showSearchedVehiclesInTable();
        }

        if (this.sidenavOpened && this.infrastructureViewOn) {
            this.searchItemsPoi();
        }

        if (this.sidenavOpened == false) {
            this.gridReady = false;
        }
    }

    resetCompass() {
        this.gradient = null;
        this.direction = null;
        this.latFirst = null;
        this.lonFirst = null;
    }

    vehicleInfoBack() {
        this.resetCompass();
        this.unsubscribeRefreshLatestMqttSignals();
        this.unsubscribeRefreshLatestEvents();
        if (this.vehicleTableContent.length > 0) {
            this.showVehiclesTable = true;
            this.showVehicleInfo = false;
        }
    }

    animateZoom(zoomFrom, zoomTo) {
        // var interval = 80; // [ms]
        // setTimeout(() => {
        //   var stopCondition = false;
        //   if(zoomFrom>zoomTo){
        //     this.subAnimateZoom = Observable.interval(interval).takeWhile(() => !stopCondition).subscribe(i => {
        //       if(zoomFrom > zoomTo){
        //         this.zoom = --zoomFrom;
        //       }else{
        //         stopCondition = true;
        //       }
        //     });
        //   }
        //   if(zoomFrom<zoomTo){
        //     this.subAnimateZoom = Observable.interval(interval).takeWhile(() => !stopCondition).subscribe(i => {
        //       if(zoomFrom < zoomTo){
        //         this.zoom = ++zoomFrom;
        //       }else{
        //         stopCondition = true;
        //       }
        //     });
        //   }
        // },1250);
    }

    saveMapView() {
        var settingsTemp: MapSettings = new MapSettings();

        settingsTemp.user = this.profUserName;
        settingsTemp.lat = this.mapSettingLat;
        settingsTemp.lon = this.mapSettingLon;
        settingsTemp.zoom = this.zoom;
        settingsTemp.use_settings = true;

        this.subSaveMapSettings = this._fleetManagementService
            .saveMapViewSrv(JSON.stringify(settingsTemp))
            .subscribe(
                (res: any) => {
                    this._snackBar.open('Default view saved.', 'Ok', { duration: 3000 });
                },
                (error: any) => {
                    this._snackBar.open('ERROR while saving view!', 'Ok', { duration: 10000 });
                }
            );
    }

    removeMapView() {
        var settingsTemp: MapSettings = new MapSettings();

        settingsTemp.user = this.profUserName;
        settingsTemp.lat = this.mapSettingLat;
        settingsTemp.lon = this.mapSettingLon;
        settingsTemp.zoom = this.zoom;
        settingsTemp.use_settings = false;

        this.subSaveMapSettings = this._fleetManagementService
            .saveMapViewSrv(JSON.stringify(settingsTemp))
            .subscribe(
                (res: string) => {
                    this._snackBar.open('Default view removed.', 'Ok', { duration: 3000 });
                },
                (error: any) => {
                    this._snackBar.open('ERROR removing default view!', 'Ok', { duration: 10000 });
                }
            );
    }

    centerChange(event: LatLng) {
        this.mapSettingLat = Number(event.lat);
        this.mapSettingLon = Number(event.lng);
    }

    saveSpeedThreshold() {
        this.subSaveSpeedThreshold = this._fleetManagementService
            .saveSpeedThresholdSrv(this.speedThreshold)
            .subscribe(
                (res: any) => {
                    this._snackBar.open('Speed threshold is set.', 'Ok', { duration: 3000 });
                    this.getSpeedThreshold();
                },
                (error: any) => {
                    this._snackBar.open('Speed threshold savingERROR!', 'Ok', { duration: 5000 });
                }
            );
    }

    getSpeedThreshold() {
        this.subGetSpeedThreshold = this._fleetManagementService
            .getSpeedThresholdSrv()
            .subscribe((speedThresholdJSON) => {
                this.speedThreshold = speedThresholdJSON.speedThreshold;
                this._changeDetectorRef.detectChanges();
            });
    }

    searchFieldPlaceholder: string;
    searchText: string = '';

    changingInput() {
        if (this.searchText == '') {
            this.searchFieldPlaceholder = this.searchFieldPlaceholderConst;
        } else {
            this.searchItems();
        }
    }

    searchFieldPlaceholderPoi: string = 'Search POI...';
    searchTextPoi: string = '';

    changingInputPoi() {
        if (this.searchTextPoi == '') {
            this.searchFieldPlaceholderPoi = 'Search POI...';
        } else {
            this.searchItemsPoi();
        }
    }

    showSearchedPoiInTable() {
        this.searchItemsPoi();
    }

    public searchItemsPoi() {
        if (this.infrastructureViewOn) {
            this.showInfrastructureTable = true;
            this.searchResultInfrastructure = [];
            this.showInfrastructureTable = true;
            if (this.searchTextPoi == '') {
                this.searchResultInfrastructure = this.allInfraElements;
            } else {
                this.searchResultInfrastructure = this.searchPipeInfrastrusture.transform(
                    this.allInfraElements,
                    this.searchTextPoi
                );
            }
        }

        if (this.gridReady == true) {
            this.gridApi.setRowData(this.searchResultInfrastructure);
        }

        this._changeDetectorRef.detectChanges();
    }

    c1() {
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','Vehicles Location','" + this.selectedVehicle + "'")
            .subscribe((result: any) => {});

        this.toggleGroupValue = '1';

        this.vehicleViewOn = true;
        this.infrastructureViewOn = false;
        this.infrastructureTypeViewOn = false;
        this.lastEventSelectionViewOn = false;
        this.lastSignalSelectionViewOn = false;
        this.settingsViewOn = false;

        this.showInfrastructureTable = false;

        this.gridReady = false;
        this.searchAfterFileUpload = false;
        this.searchAfterDeletingElement = false;
        this.searchAfterEditInfraElement = false;
        this.searchAfterAddingInfraElement = false;
        this.searchAfterInfElUpdate = false;

        // this.searchItems();
        this.showSearchedVehiclesInTable();
        this.numberOfSelectePois = 0;
        this._changeDetectorRef.detectChanges();
    }

    c2() {
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','Fleet Location POI'")
            .subscribe((result: any) => {});

        this.unsubscribeRefreshLatestMqttSignals();
        this.unsubscribeRefreshLatestEvents();
        this.toggleGroupValue = '2';

        this.vehicleViewOn = false;
        this.infrastructureViewOn = true;
        this.infrastructureTypeViewOn = false;
        this.lastEventSelectionViewOn = false;
        this.lastSignalSelectionViewOn = false;
        this.settingsViewOn = false;

        this.vehicleTableContent = [];
        this.showVehiclesTable = false;
        this.showVehicleInfo = false;
        this.clickedVehicle = '';

        // check if infrastructure element is selected before
        this.infrstructureElementSelected = false;

        this.searchAfterFileUpload = false;
        this.searchAfterDeletingElement = false;
        this.searchAfterEditInfraElement = false;
        this.searchAfterAddingInfraElement = false;
        this.searchAfterInfElUpdate = false;

        this.numberOfSelectePois = 0;

        setTimeout(() => {
            this.searchItemsPoi();
        }, 500);

        this._changeDetectorRef.detectChanges();
    }

    c3() {
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','Fleet Location Bookmarks'")
            .subscribe((result: any) => {});

        this.unsubscribeRefreshLatestMqttSignals();
        this.unsubscribeRefreshLatestEvents();
        this.toggleGroupValue = '3';
        this.searchAfterFileUpload = false;
        this.searchAfterDeletingElement = false;
        this.searchAfterEditInfraElement = false;
        this.searchAfterAddingInfraElement = false;
        this.searchAfterInfElUpdate = false;
        this.numberOfSelectePois = 0;
        this.gridReady = false;

        this._changeDetectorRef.detectChanges();
    }

    c4() {
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','Fleet Location Settings'")
            .subscribe((result: any) => {});

        this.unsubscribeRefreshLatestMqttSignals();
        this.unsubscribeRefreshLatestEvents();
        this.toggleGroupValue = '4';

        this.vehicleViewOn = false;
        this.infrastructureViewOn = false;
        this.infrastructureTypeViewOn = false;
        this.lastEventSelectionViewOn = false;
        this.lastSignalSelectionViewOn = false;
        this.settingsViewOn = true;

        this.showInfrastructureTable = false;

        this.searchResultVehicles = [];
        this.vehicleTableContent = [];
        this.showVehiclesTable = false;
        this.showVehicleInfo = false;
        this.clickedVehicle = '';
        this.infrstructureElementSelected = false;

        this.searchAfterFileUpload = false;
        this.searchAfterDeletingElement = false;
        this.searchAfterEditInfraElement = false;
        this.searchAfterAddingInfraElement = false;
        this.searchAfterInfElUpdate = false;
        this.numberOfSelectePois = 0;

        this.gridReady = false;

        this._changeDetectorRef.detectChanges();
    }

    // ag grid vehicles...

    private gridApiVehicles: GridApi;
    private gridColumnApiVehicles;

    gridReadyVehicles: boolean = false;
    vehiclesTableHeight: number = 500;

    vehicleIdHeader: string = '';
    columnDefsVehicles: any[] = [
        {
            field: 'vehicleId',
            headerName: this.vehicleIdHeader,
            width: 120,
            cellStyle: this.cellColorChange.bind(this),
        },
        { field: 'speed', headerName: 'Speed', width: 86 },
        {
            field: 'updatedAgo',
            headerName: 'Last update',
            width: 91,
            cellStyle: this.cellFontColor.bind(this),
            comparator: this.updateComparator,
        },
    ];

    updateComparator(date1, date2) {
        var date1Level = date1.substr(date1.length - 1);
        var date2Level = date2.substr(date2.length - 1);
        var date1Number = date1.substr(0, date1.length - 1);
        var date2Number = date2.substr(0, date2.length - 1);
        if (date1Level == date2Level) {
            return date1Number - date2Number;
        }
        var d1 = 4;
        if (date1Level == 'h') {
            d1 = 3;
        }
        if (date1Level == 'm') {
            d1 = 2;
        }
        if (date1Level == 's') {
            d1 = 1;
        }
        var d2 = 4;
        if (date2Level == 'h') {
            d2 = 3;
        }
        if (date2Level == 'm') {
            d2 = 2;
        }
        if (date2Level == 's') {
            d2 = 1;
        }
        return d1 - d2;
    }

    cellColorChange(params) {
        var color = this.vehicleStateGetColor(params.value);
        if (color == '#FFFFFF') {
            return {};
        } else {
            return {
                'border-color': color,
                'border-width': '2px',
                'vertical-align': 'middle',
                'display': 'table-cell',
            };
            // return { "background-color": color };
        }
    }

    vehicleStateGetColor(vehicleId): string {
        var state = this.getVehicleState(vehicleId);
        var color = this.getStateColor(state);
        return color;
    }

    getVehicleState(vehicleId: string): any {
        var state: any = this.vehiclesEventStatesMap.get(vehicleId).state;
        return state;
    }

    getStateColor(state: any): string {
        switch (state) {
            case 'ok':
                return '#FFFFFF'; // white
            case 'warning':
                return '#FFE600'; // yellow
            case 'critical':
                return '#FF0000'; // red
            default:
                return '#FFFFFF'; //white
        }
    }

    setStateToOK() {
        this.subSetToOkDialog = this._dialogService
            .openConfirm({
                message: 'Vehicle will be set to OK state.',
                acceptButton: 'Confirm',
                title: 'Vehicle state change',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.subSetToOk = this._fleetManagementService
                        .setVehicleStateToOKSrv(this.clickedVehicle)
                        .subscribe(
                            (res) => {
                                this._snackBar.open('Vehicle set to OK state.', 'Ok', {
                                    duration: 3000,
                                });
                            },
                            (error: any) => {
                                this._snackBar.open('ERROR while changing state!', 'Ok', {
                                    duration: 10000,
                                });
                            }
                        );
                }
            });
    }

    rowSelectionGridVehicles = 'single';
    suppressRowClickSelectionVehicles: boolean = false;
    noVehiclesGridOverlay: string = 'No vehicles';
    filterModel: any = {};

    async onRowClickedVehicles($event) {
        this.resetCompass();
        var selectedRow = this.gridApiVehicles.getSelectedRows();

        if (this.latFirst != null && this.lonFirst != null) {
            this.gradient = await this.calculateGradient(
                this.latFirst,
                this.lonFirst,
                Number(selectedRow[0].latitude),
                Number(selectedRow[0].longitude)
            );
        }

        this.latFirst = Number(selectedRow[0].latitude);
        this.lonFirst = Number(selectedRow[0].longitude);

        this.showInfoForTrainClickedOnTable(selectedRow[0].vehicleId);
        this.clickedVehiclesSerialNumber = selectedRow[0].serialNumber;
        this.subRefreshLatestMqttSignals.unsubscribe();
        this.subRefreshLatestEvents.unsubscribe();
        this.refreshLatestMqttSignalsForSelectedVehicle(selectedRow[0].vehicleId);
        this.refreshLatestEventsForSelectedVehicle(selectedRow[0].vehicleId);
    }

    gridVehicleRowCount: number = 0;

    onFilterChangeGirdVehicles() {
        this.filterModel = this.gridApiVehicles.getFilterModel();
        this.gridVehicleRowCount = this.gridApiVehicles.getDisplayedRowCount();
        this._changeDetectorRef.detectChanges();
    }

    onGridReadyVehicles(params: GridReadyEvent) {
        this.gridApiVehicles = params.api;
        this.gridColumnApiVehicles = params.columnApi;
        this.gridApiVehicles.showNoRowsOverlay();
        this.gridApiVehicles.setRowData(this.vehicleTableContent);
        this.gridReadyVehicles = true;
        this.gridApiVehicles.setFilterModel(this.filterModel);
        this.gridVehicleRowCount = this.gridApiVehicles.getDisplayedRowCount();

        const vehicle_id = this.gridApiVehicles.getColumnDef('vehicleId');
        setTimeout(() => {
            vehicle_id.headerName = this.vehicleIdHeader;
            this.gridApiVehicles.refreshHeader();
        }, 50);
    }

    frameworkComponents3 = {
        imageFormatterComponentVehicles: ImageFormatterComponentVehicles,
    };

    gridOptionsVehicles = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
        onFilterChanged: this.onFilterChangeGirdVehicles.bind(this),
    };

    gridOptionsLiveSignals = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    gridOptionsLiveEvents = {
        defaultColDef: {
            editable: true,
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    getConfiguration() {
        this.translate.get('HEADER-AG-GRID').subscribe((headerAgGrid) => {
            this.vehicleIdHeader = headerAgGrid.VehicleId;
        });

        this.translate.get('FLEET-LOCATION').subscribe((fleetLocation) => {
            this.searchFieldPlaceholderConst = fleetLocation.searchFieldPlaceholder;
            this.searchFieldPlaceholder = this.searchFieldPlaceholderConst;
        });
    }

    // ...ag grid vehicles

    // POI types...

    infrstructureElementTypeSelected: boolean = false;
    selectedRowTableInfrastructureType: IInfrastructureType[] = [];

    dialogRefAddInfrastructureType: MatDialogRef<DialogAddInfrastructureType>;
    dialogRefEditInfrastructureType: MatDialogRef<DialogEditInfrastructureType>;

    public iconsPoiTypes: any[] = new MapIconsPoiTypes().icons;

    selectedRowPoiTypesTable: IInfrastructureType[] = [];

    deletePoiButonDisabled: boolean = true;

    latLngBounds: LatLngBounds;

    displaySidebarButton: string = '';

    boundsChange(event: LatLngBounds) {
        if (document.getElementById('mainMap').scrollHeight + 45 == window.screen.height) {
            this.displaySidebarButton = 'none';
        } else {
            this.displaySidebarButton = '';
        }

        this.latLngBounds = new google.maps.LatLngBounds();
        const eventJson = event.toJSON();

        const latLngUpperLeft: LatLngLiteral = {
            lat: eventJson.north,
            lng: eventJson.west,
        };
        this.latLngBounds.extend(latLngUpperLeft);

        const latLngDownRight: LatLngLiteral = {
            lat: eventJson.south,
            lng: eventJson.east,
        };
        this.latLngBounds.extend(latLngDownRight);
    }

    idle($event) {
        this.filteredInfraMarkers = this.filterInfraMarkers2(this.infrastructureMarkers);
    }

    filterInfraMarkers(infraMarkers: IInfrastructureMarker[]): IInfrastructureMarker[] {
        let infraMarkersLength = infraMarkers.length;
        let filteredInfraMarkers: IInfrastructureMarker[] = [];
        let filteredInfraMarkersByArea: IInfrastructureMarker[] = [];
        let latLngInfraMarker: LatLng;

        for (let i = 0; i < infraMarkersLength; i++) {
            latLngInfraMarker = new google.maps.LatLng(infraMarkers[i].lat, infraMarkers[i].lng);

            if (this.latLngBounds.contains(latLngInfraMarker)) {
                filteredInfraMarkersByArea.push(infraMarkers[i]);
            }
        }

        let filteredInfraMarkersByAreaLength = filteredInfraMarkersByArea.length;
        let filteredInfMarkersByZoom: IInfrastructureMarker[] = [];

        if (filteredInfraMarkersByAreaLength <= 50) {
            filteredInfraMarkers = filteredInfraMarkersByArea;
        } else {
            // filteredInfMarkers.sort((a, b) => a.zoomLevel - b.zoomLevel);
            // filteredInfElementsByZoom = filteredInfElements.
            //                             filter(infElement => infElement.zoomLevel <= this.zoom);

            for (let i = 0; i < filteredInfraMarkersByAreaLength; i++) {
                if (filteredInfraMarkersByArea[i].zoomLevel <= this.zoom) {
                    filteredInfMarkersByZoom.push(filteredInfraMarkersByArea[i]);
                }
            }

            let filteredInfraMarkersByZoomLength = filteredInfMarkersByZoom.length;

            if (filteredInfraMarkersByZoomLength <= 50) {
                filteredInfraMarkers = filteredInfMarkersByZoom;
            } else {
                filteredInfraMarkers = filteredInfMarkersByZoom
                    .sort((a, b) => a.radius - b.radius)
                    .slice(1, 50);
            }
        }
        return filteredInfraMarkers;
    }

    filterInfraMarkers2(infraMarkers: IInfrastructureMarker[]): IInfrastructureMarker[] {
        const infraMarkersLimit = 50;
        let infraMarkersLength = infraMarkers.length;
        let filteredInfraMarkers: IInfrastructureMarker[] = [];
        let filteredInfraMarkersByArea: IInfrastructureMarker[] = [];
        let latLngInfraMarker: LatLng;

        for (let i = 0; i < infraMarkersLength; i++) {
            latLngInfraMarker = new google.maps.LatLng(infraMarkers[i].lat, infraMarkers[i].lng);
            if (this.latLngBounds && this.latLngBounds.contains(latLngInfraMarker)) {
                filteredInfraMarkersByArea.push(infraMarkers[i]);
            }
        }

        let filteredInfraMarkersByAreaLength = filteredInfraMarkersByArea.length;

        let filteredInfraMarkersByZoom: IInfrastructureMarker[] = [];

        if (filteredInfraMarkersByAreaLength <= infraMarkersLimit) {
            filteredInfraMarkers = filteredInfraMarkersByArea;
        } else {
            for (let i = 0; i < filteredInfraMarkersByAreaLength; i++) {
                if (filteredInfraMarkersByArea[i].zoomLevel <= this.zoom) {
                    filteredInfraMarkersByZoom.push(filteredInfraMarkersByArea[i]);
                }
            }

            let filteredInfraMarkersByZoomLength = filteredInfraMarkersByZoom.length;

            if (filteredInfraMarkersByZoomLength === 0) {
                filteredInfraMarkers = this.secondLevelFilter(filteredInfraMarkersByArea);
            } else if (
                0 < filteredInfraMarkersByZoomLength &&
                filteredInfraMarkersByZoomLength <= infraMarkersLimit
            ) {
                filteredInfraMarkers = filteredInfraMarkersByZoom;
            } else {
                filteredInfraMarkers = this.secondLevelFilter(filteredInfraMarkersByZoom);
            }
        }

        return filteredInfraMarkers;
    }

    getUniqueValuesForKey(array: Object[], keyName: string) {
        let flags = [],
            output = [],
            l = array.length;
        for (let i = 0; i < l; i++) {
            if (flags[array[i][keyName]]) {
                continue;
            }
            flags[array[i][keyName]] = true;
            output.push(array[i][keyName]);
        }
        return output;
    }

    getRandomIndexes(
        infraMarkers: IInfrastructureMarker[],
        count: number
    ): IInfrastructureMarker[] {
        let randomArr: IInfrastructureMarker[] = [],
            usedNums = {},
            randomIndex;
        while (randomArr.length < count) {
            while (usedNums[randomIndex] === true || randomIndex === undefined) {
                randomIndex = Math.floor(Math.random() * infraMarkers.length);
            }
            usedNums[randomIndex] = true;
            randomArr.push(infraMarkers[randomIndex]);
        }
        return randomArr;
    }

    secondLevelFilter(infraMarkers: IInfrastructureMarker[]): IInfrastructureMarker[] {
        const infraMarkersLimit = 50;
        let filteredInfraMarkersHelper: IInfrastructureMarker[] = [];
        let radiuses = this.getUniqueValuesForKey(infraMarkers, 'radius').sort((a, b) => b - a);
        let sortedInfraMarkersByRadius = infraMarkers.sort((a, b) => b.radius - a.radius);

        let arrayOfArrays = [];
        let numberOfInfraMarkers = 0;
        let sortedInfraMarkersByRadiusLength = sortedInfraMarkersByRadius.length;
        for (let i = 0; i < radiuses.length; i++) {
            let infraMarkersHelper = [];
            for (let j = 0; j < sortedInfraMarkersByRadiusLength; j++) {
                if (sortedInfraMarkersByRadius[j].radius === radiuses[i]) {
                    // arrayOfArrays[i].push(sortedInfraMarkersByRadius[j]);
                    infraMarkersHelper.push(sortedInfraMarkersByRadius[j]);
                    numberOfInfraMarkers++;
                }
            }
            arrayOfArrays.push(infraMarkersHelper);
            if (numberOfInfraMarkers >= infraMarkersLimit) {
                break;
            }
        }

        const arrayOfArraysLength = arrayOfArrays.length;
        let filteredInfraMarkersHelperLength = filteredInfraMarkersHelper.length;
        let diff = infraMarkersLimit - filteredInfraMarkersHelperLength;
        for (let i = 0; i < arrayOfArraysLength; i++) {
            if (arrayOfArrays[i].length < diff) {
                filteredInfraMarkersHelper.push(...arrayOfArrays[i]);
            } else {
                filteredInfraMarkersHelper.push(...this.getRandomIndexes(arrayOfArrays[i], diff));
            }
            filteredInfraMarkersHelperLength = filteredInfraMarkersHelper.length;
            diff = infraMarkersLimit - filteredInfraMarkersHelperLength;
        }
        return filteredInfraMarkersHelper;
    }

    // MAP LAYERS SETTINGS ...

    poiLayerSettings: PoiLayerSettings[] = [];
    poiLayerSettingsMap: Map<string, boolean> = new Map();

    vehicleLayerShown: boolean = true;
    allInfraElementsLayersApplied: IInfrastructureElement[] = [];

    toggleChangePoiLayer(poi: PoiLayerSettings, event) {
        for (var i = 0; i < this.poiLayerSettings.length; i++) {
            if (this.poiLayerSettings[i].poiId == poi.poiId) {
                this.poiLayerSettings[i].shown = event.checked;
                break;
            }
        }
        this.saveMapLayersSettings(true);
        this.applyPoiLayerSettings();
    }

    toggleChangeVehiclesLayer(event) {
        this.vehicleLayerShown = event.checked;
        this.saveMapLayersSettings(true);
    }

    saveMapLayersSettings(showSnackbar: boolean) {
        this.subSaveMapLayerSettings = this._fleetManagementService
            .saveMapLayersSettingsSrv(
                this.profUserName,
                this.poiLayerSettings,
                this.vehicleLayerShown
            )
            .subscribe(
                (res: any) => {
                    if (showSnackbar) {
                        this._snackBar.open('Map layer settings saved', 'Ok', { duration: 2000 });
                    }
                },
                (error: any) => {
                    if (showSnackbar) {
                        this._snackBar.open('Map layer saving ERROR!', 'Ok', { duration: 10000 });
                    }
                }
            );
    }

    loadMapLayersSettings() {
        this.subLoadMapLayerSettings = this._fleetManagementService
            .loadMapLayersSettingsSrv(this.profUserName)
            .subscribe((result: MapLayerSettings) => {
                this.poiLayerSettings = [];
                var poiTypesAreChanged: boolean = false;

                if (result.poiLayerSettings == undefined && result.vehicleLayerShown == undefined) {
                    poiTypesAreChanged = true;
                    for (var i = 0; i < this.allPoiTypes.length; i++) {
                        var temp: PoiLayerSettings = new PoiLayerSettings();
                        temp.poiId = this.allPoiTypes[i].id;
                        temp.poiType = this.allPoiTypes[i].type;
                        temp.shown = true;
                        this.poiLayerSettings.push(temp);
                    }

                    this.vehicleLayerShown = true;
                } else {
                    this.poiLayerSettings = result.poiLayerSettings;
                    this.vehicleLayerShown = result.vehicleLayerShown;
                }

                // checking if new poi types are added and if some poi type name is changed
                for (var i = 0; i < this.allPoiTypes.length; i++) {
                    var missingPoiType: boolean = true;
                    for (var j = 0; j < this.poiLayerSettings.length; j++) {
                        if (this.allPoiTypes[i].id == this.poiLayerSettings[j].poiId) {
                            missingPoiType = false;
                            if (this.poiLayerSettings[j].poiType != this.allPoiTypes[i].type) {
                                poiTypesAreChanged = true;
                                this.poiLayerSettings[j].poiType = this.allPoiTypes[i].type;
                            }
                            break;
                        }
                    }
                    if (missingPoiType == true) {
                        poiTypesAreChanged = true;
                        var tempPoi: PoiLayerSettings = new PoiLayerSettings();
                        tempPoi.poiId = this.allPoiTypes[i].id;
                        tempPoi.poiType = this.allPoiTypes[i].type;
                        tempPoi.shown = true;
                        this.poiLayerSettings.push(tempPoi);
                    }
                }

                // checking if some POI types are deleted
                var tempArrayPoiLayerSetting: PoiLayerSettings[] = [];
                for (var i = 0; i < this.poiLayerSettings.length; i++) {
                    var poiTypeFound: boolean = false;
                    for (var j = 0; j < this.allPoiTypes.length; j++) {
                        if (this.poiLayerSettings[i].poiId == this.allPoiTypes[j].id) {
                            poiTypeFound = true;
                            break;
                        }
                    }
                    if (poiTypeFound == true) {
                        tempArrayPoiLayerSetting.push(this.poiLayerSettings[i]);
                    }
                }
                if (this.poiLayerSettings.length != tempArrayPoiLayerSetting.length) {
                    poiTypesAreChanged = true;
                }

                this.poiLayerSettings = tempArrayPoiLayerSetting;
                tempArrayPoiLayerSetting = [];

                this.poiLayerSettings.sort(function (a, b) {
                    var nameA = a.poiType.toLowerCase(),
                        nameB = b.poiType.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                // if some poi types are added or deleted or have its name changed, than save poi layer settings with those changes
                if (poiTypesAreChanged == true) {
                    this.saveMapLayersSettings(false);
                }

                // create map of POI layer setting elements
                for (var i = 0; i < this.poiLayerSettings.length; i++) {
                    this.poiLayerSettingsMap.set(
                        this.poiLayerSettings[i].poiType,
                        this.poiLayerSettings[i].shown
                    );
                }

                this.loadInfrastructureElements();
            });
    }

    applyPoiLayerSettings() {
        this.poiLayerSettingsMap = new Map();
        for (var i = 0; i < this.poiLayerSettings.length; i++) {
            this.poiLayerSettingsMap.set(
                this.poiLayerSettings[i].poiType,
                this.poiLayerSettings[i].shown
            );
        }

        this.allInfraElementsLayersApplied = [];

        for (let i = 0; i < this.allInfraElements.length; i++) {
            if (this.poiLayerSettingsMap.get(this.allInfraElements[i].type)) {
                this.allInfraElementsLayersApplied.push(this.allInfraElements[i]);
            }
        }

        this.infrastructureMarkers = [];
        this.infrastructureMarkers = this.createPoiMarkers(this.allInfraElementsLayersApplied);

        this.filteredInfraMarkers = this.filterInfraMarkers2(this.infrastructureMarkers);
        this._changeDetectorRef.detectChanges();
    }

    // ... MAP LAYERS SETTINGS

    loadInfrastructureTypes() {
        this.deletePoiButonDisabled = true;
        this.subLoadInfrastructureTypes = this._fleetManagementService
            .loadInfrastructureTypesSrv()
            .subscribe((res: IInfrastructureType[]) => {
                this.allPoiTypes = res;

                var pathName = window.location.pathname;

                for (var i = 0; i < this.allPoiTypes.length; i++) {
                    if (this.allPoiTypes[i].icon != 'noIcon') {
                        if (this.allPoiTypes[i].icon.indexOf('/assets') != -1) {
                            var tmpStr: string[] = [];
                            tmpStr = this.allPoiTypes[i].icon.split('assets');
                            this.allPoiTypes[i].icon = 'assets' + tmpStr[1];
                        }
                        this.allPoiTypes[i].icon = this.allPoiTypes[i].icon;
                    }
                }

                this.allPoiTypes.sort(function (a, b) {
                    var nameA = a.type.toLowerCase(),
                        nameB = b.type.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                this.loadMapLayersSettings();

                if (this.gridApiPoiTypes) {
                    this.gridApiPoiTypes.setRowData(this.allPoiTypes);
                }

                for (const iterator of this.allPoiTypes) {
                    this.allPoiTypesMap.set(iterator.type, iterator.zoomLimit);
                }
            });
    }

    addInfrastructureType() {
        this.dialogRefAddInfrastructureType = this._dialog.open(DialogAddInfrastructureType, {
            height: '410px',
            width: '250px',
        });
        this.subDialogAddInfraType = this.dialogRefAddInfrastructureType
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        this.subIsTypeInDb = this._fleetManagementService
                            .isInfrastructureTypeInDb(dialogData.type)
                            .subscribe(
                                (response) => {
                                    if (response[0] == 0) {
                                        var maxSpeedData: string = 'No max speed';
                                        if (dialogData.maxSpeed != '') {
                                            maxSpeedData = dialogData.maxSpeed;
                                        }

                                        var pathName = window.location.pathname;
                                        var dialogDataIcon: string = dialogData.icon;
                                        var poiIcon: string = '';
                                        poiIcon = dialogDataIcon.replace(pathName, '');

                                        this.subAddInfraType = this._fleetManagementService
                                            .addInfrastructureType(
                                                dialogData.type,
                                                poiIcon,
                                                dialogData.zoomLimit,
                                                maxSpeedData
                                            )
                                            .subscribe(
                                                (response) => {
                                                    this.loadInfrastructureTypes();

                                                    if (response[0] == 'insert success') {
                                                        this._snackBar.open(
                                                            'POI type saved',
                                                            'Ok',
                                                            { duration: 3000 }
                                                        );
                                                    } else {
                                                        this._snackBar.open(
                                                            'Error while saving',
                                                            'Ok',
                                                            { duration: 3000 }
                                                        );
                                                    }
                                                },
                                                (error) => {
                                                    this._snackBar.open(
                                                        'Error while saving',
                                                        'Ok',
                                                        { duration: 3000 }
                                                    );
                                                }
                                            );
                                    } else {
                                        this._dialogService.openAlert({
                                            message:
                                                'Entered type already exists! Please choose another type name.',
                                            closeButton: 'Close',
                                        });
                                    }
                                },
                                (error) => {
                                    this._dialogService.openAlert({
                                        message: 'Issue on the server side.',
                                        closeButton: 'Close',
                                    });
                                }
                            );
                    }
                    this.dialogRefAddInfrastructureType = null;
                }
            });
    }

    editInfrastructureType() {
        this.dialogRefEditInfrastructureType = this._dialog.open(DialogEditInfrastructureType, {
            height: '410px',
            width: '250px',
            data: {
                type: this.selectedRowPoiTypesTable[0].type,
                icon: this.selectedRowPoiTypesTable[0].icon,
                id: this.selectedRowPoiTypesTable[0].id,
                zoomLimit: this.selectedRowPoiTypesTable[0].zoomLimit,
                maxSpeed:
                    this.selectedRowPoiTypesTable[0].maxSpeed == 'No max speed'
                        ? ''
                        : this.selectedRowPoiTypesTable[0].maxSpeed,
            },
        });
        this.subDialogEditInfraType = this.dialogRefEditInfrastructureType
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        var pathName = window.location.pathname;
                        var dialogDataIcon: string = dialogData.data.icon;
                        var poiIcon: string = '';
                        poiIcon = dialogDataIcon.replace(pathName, '');

                        if (this.selectedRowPoiTypesTable[0].type == dialogData.data.type) {
                            var maxSpeedData1: string = 'No max speed';
                            if (dialogData.data.maxSpeed != '') {
                                maxSpeedData1 = dialogData.data.maxSpeed;
                            }

                            this.subEditInfraType = this._fleetManagementService
                                .editInfrastructureType(
                                    dialogData.data.type,
                                    poiIcon,
                                    dialogData.data.id,
                                    dialogData.data.zoomLimit,
                                    maxSpeedData1
                                )
                                .subscribe(
                                    (response) => {
                                        this.loadInfrastructureTypes();
                                        this._snackBar.open('POI type saved', 'Ok', {
                                            duration: 3000,
                                        });
                                    },
                                    () => {
                                        this._snackBar.open('Error while saving', 'Ok', {
                                            duration: 3000,
                                        });
                                    }
                                );
                        } else {
                            this.subIsTypeInDb2 = this._fleetManagementService
                                .isInfrastructureTypeInDb(dialogData.data.type)
                                .subscribe(
                                    (response) => {
                                        if (response[0] == 0) {
                                            var maxSpeedData2: string = 'No max speed';
                                            if (dialogData.data.maxSpeed != '') {
                                                maxSpeedData2 = dialogData.data.maxSpeed;
                                            }

                                            this.subEditInfraType2 = this._fleetManagementService
                                                .editInfrastructureType(
                                                    dialogData.data.type,
                                                    poiIcon,
                                                    dialogData.data.id,
                                                    dialogData.data.zoomLimit,
                                                    maxSpeedData2
                                                )
                                                .subscribe(
                                                    (response) => {
                                                        this.loadInfrastructureTypes();
                                                        this._snackBar.open(
                                                            'POI type saved',
                                                            'Ok',
                                                            { duration: 3000 }
                                                        );
                                                    },
                                                    () => {
                                                        this._snackBar.open(
                                                            'Error while saving',
                                                            'Ok',
                                                            { duration: 3000 }
                                                        );
                                                    }
                                                );
                                        } else {
                                            this._dialogService.openAlert({
                                                message:
                                                    'Entered type already exists! Please choose another type name.',
                                                closeButton: 'Close',
                                            });
                                        }
                                    },
                                    (error) => {
                                        this._dialogService.openAlert({
                                            message: 'Issue on the server side.',
                                            closeButton: 'Close',
                                        });
                                    }
                                );
                        }
                    }
                    this.dialogRefEditInfrastructureType = null;
                }
            });
    }

    deleteInfrastructureType() {
        this.subDialogDeleteInfraType = this._dialogService
            .openConfirm({
                message: 'Are you sure you want to delete POI type?',
                acceptButton: 'Delete',
                title: 'Delete type',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    this.subDeleteInfraType = this._fleetManagementService
                        .deleteInfrastructureType(this.selectedRowPoiTypesTable[0])
                        .subscribe(
                            (res: string) => {
                                this._snackBar.open('POI type deleted', 'Ok', { duration: 3000 });
                                this.loadInfrastructureTypes();
                                this.selectedRowPoiTypesTable = null;
                                this.deletePoiButonDisabled = true;
                            },
                            (error) => {
                                this._dialogService.openAlert({
                                    message: 'There was an error trying to delete the POI type.',
                                });
                            }
                        );
                }
            });
    }

    getIconForPoiElement(elementType: string): string {
        for (var i = 0; i < this.allPoiTypes.length; i++) {
            if (elementType == this.allPoiTypes[i].type) {
                return this.allPoiTypes[i].icon;
            }
        }
        return 'assets/icons/notificationError.png';
    }

    openPoiSettings() {
        this.infrastructureTypeViewOn = true;
    }

    goBackToSettings() {
        this.infrastructureTypeViewOn = false;
        this.lastEventSelectionViewOn = false;
        this.lastSignalSelectionViewOn = false;
    }

    closePoiSettings() {
        this.sidenavToggle();
        setTimeout(() => {
            this.infrastructureTypeViewOn = false;
        }, 250);
    }

    closeVehicleInfo() {
        this.sidenavToggle();
        setTimeout(() => {
            this.showVehicleInfo = false;
        }, 250);
    }

    //... POI types

    searchAfterFileUpload: boolean = false;

    createPoiMarkers(infraElements: IInfrastructureElement[]): IInfrastructureMarker[] {
        let infraElementsLength = infraElements.length;
        let poiMarkers: IInfrastructureMarker[] = [];
        let scaleTemp = 25;

        for (let i = 0; i < infraElementsLength; i++) {
            let iconUrl = this.getIconForPoiElement(infraElements[i].type);
            if (iconUrl == 'noIcon') {
                iconUrl = 'assets/icons/icon_infra01.png';
                scaleTemp = 0;
            }

            infraElements[i].zoomLevel = Number(this.allPoiTypesMap.get(infraElements[i].type));

            poiMarkers.push({
                lat: Number(infraElements[i].lat),
                lng: Number(infraElements[i].lon),
                draggable: false,
                id: infraElements[i].id,
                icon: {
                    url: iconUrl,
                    labelOrigin: new google.maps.Point(15, 37),
                    anchor: new google.maps.Point(13, 13),
                    scaledSize: new google.maps.Size(scaleTemp, scaleTemp),
                },
                label: {
                    text: infraElements[i].name,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '11px',
                },
                zoomLevel: infraElements[i].zoomLevel,
                radius: infraElements[i].radius,
            });
        }
        return poiMarkers;
    }

    loadInfrastructureElements() {
        this.numberOfSelectePois = 0;
        this.subscriptionLoadInfrastructure = this._fleetManagementService
            .loadInfrastructureElementsSrv()
            .subscribe((res: IInfrastructureElement[]) => {
                this.allInfraElements = res;
                for (let i = 0; i < res.length; i++) {
                    this.allInfraElements[i].typeicon = this.getIconForPoiElement(res[i].type);
                }

                this.allInfraElementsLayersApplied = [];
                for (let i = 0; i < this.allInfraElements.length; i++) {
                    if (this.poiLayerSettingsMap.get(this.allInfraElements[i].type)) {
                        this.allInfraElementsLayersApplied.push(this.allInfraElements[i]);
                    }
                }

                this.infrastructureMarkers = this.createPoiMarkers(
                    this.allInfraElementsLayersApplied
                );

                this.filteredInfraMarkers = this.filterInfraMarkers2(this.infrastructureMarkers);
                this._changeDetectorRef.detectChanges();

                if (this.gridReady) {
                    this.gridApi.setRowData(this.allInfraElements);
                }

                if (this.searchAfterFileUpload) {
                    this.searchAfterFileUpload = false;
                    this.searchItems();
                }
                if (this.searchAfterDeletingElement) {
                    this.searchAfterDeletingElement = false;
                    this.searchItems();
                    this.selectedRowTableInfrastructure = null;
                }
                if (this.searchAfterEditInfraElement) {
                    this.searchAfterEditInfraElement = false;
                    this.searchItems();
                }
                if (this.searchAfterAddingInfraElement) {
                    this.searchAfterAddingInfraElement = false;
                    this.searchItems();
                }
                if (this.searchAfterInfElUpdate) {
                    this.searchAfterInfElUpdate = false;
                    this.searchItems();
                }
            });
    }

    deleteInfrastructureElement() {
        this.subDialogDeleteInfraElement = this._dialogService
            .openConfirm({
                message: 'Are you sure you want to delete infrastructure element?',
                acceptButton: 'Delete',
                title: 'Delete element',
            })
            .afterClosed()
            .subscribe((accept: boolean) => {
                if (accept) {
                    var deleteElementsIds: string[] = [];
                    for (var i = 0; i < this.selectedRowTableInfrastructure.length; i++) {
                        deleteElementsIds.push(this.selectedRowTableInfrastructure[i].id);
                    }
                    this.subDeleteInfraElement = this._fleetManagementService
                        .deleteInfrastructureElement(deleteElementsIds)
                        .subscribe(
                            () => {
                                this._snackBar.open('Infrastructure element deleted', 'Ok', {
                                    duration: 3000,
                                });
                                this.searchAfterDeletingElement = true;
                                this.infrstructureElementSelected = false;
                                this.loadInfrastructureElements();
                                this.numberOfSelectePois = 0;
                            },
                            (error) => {
                                this._dialogService.openAlert({
                                    message:
                                        'There was an error trying to delete the infrastructure element.',
                                });
                            }
                        );
                }
            });
    }

    addInfrastructureElement() {
        this.dialogRefAddInfrastructureElement = this._dialog.open(DialogAddInfrastructureElement, {
            height: '470px',
            width: '300px',
            data: { types: this.allPoiTypes, lat: this.lastMapClickLat, lon: this.lastMapClickLng },
            // disableClose: true,
        });
        this.subDialogAddInfraElement = this.dialogRefAddInfrastructureElement
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        var validTillValue;
                        if (dialogData.validTill != undefined) {
                            validTillValue = dialogData.validTill.getTime();
                        } else {
                            validTillValue = -1;
                        }
                        this.subAddInfraElement = this._fleetManagementService
                            .addInfrastructureElement(
                                dialogData.name,
                                dialogData.type,
                                dialogData.data.lat,
                                dialogData.data.lon,
                                dialogData.radius,
                                -1,
                                dialogData.eventGen
                            )
                            .subscribe((response) => {
                                this.searchAfterAddingInfraElement = true;
                                this.loadInfrastructureElements();
                            });
                    }
                    this.dialogRefAddInfrastructureElement = null;
                }
            });
    }

    editInfrastructureElement() {
        var validTillValue;
        if (isNaN(+this.selectedRowTableInfrastructure[0].validTill)) {
            validTillValue = -1;
        } else {
            validTillValue = new Date(+this.selectedRowTableInfrastructure[0].validTill);
        }

        this.dialogRefEditInfrastructureElement = this._dialog.open(
            DialogEditInfrastructureElement,
            {
                height: '530px',
                width: '300px',
                data: {
                    id: this.selectedRowTableInfrastructure[0].id,
                    name: this.selectedRowTableInfrastructure[0].name,
                    type: this.selectedRowTableInfrastructure[0].type,
                    types: this.allPoiTypes,
                    lat: this.selectedRowTableInfrastructure[0].lat,
                    lon: this.selectedRowTableInfrastructure[0].lon,
                    radius: this.selectedRowTableInfrastructure[0].radius,
                    validTill: validTillValue,
                    eventGen: this.selectedRowTableInfrastructure[0].eventGen,
                },
            }
        );

        this.subDialogEditInfraElement = this.dialogRefEditInfrastructureElement
            .afterClosed()
            .subscribe((dialogData: any) => {
                if (dialogData != undefined) {
                    var validTillValue2;
                    if (
                        dialogData.data.validTill != undefined &&
                        dialogData.data.validTill != null
                    ) {
                        if (dialogData.data.validTill == -1) {
                            validTillValue2 = -1;
                        } else {
                            validTillValue2 = dialogData.data.validTill.getTime();
                        }
                    } else {
                        validTillValue2 = -1;
                    }

                    if (dialogData.toSave) {
                        // send data from dialog to service
                        this.subEditInfraElement = this._fleetManagementService
                            .editInfrastructureElement(
                                dialogData.data.id,
                                dialogData.data.name,
                                dialogData.data.type,
                                dialogData.data.lat,
                                dialogData.data.lon,
                                dialogData.data.radius,
                                -1,
                                dialogData.data.eventGen
                            )
                            .subscribe((response) => {
                                this.searchAfterEditInfraElement = true;
                                this.loadInfrastructureElements();
                            });

                        // set global variable "infrstructureElementSelected" to false to be unable to click to edit or delete
                        // after dialog is closed after click on save button
                        this.infrstructureElementSelected = false;
                    }
                    this.dialogRefEditInfrastructureElement = null;
                }
            });
    }

    downloadInfrastructureElements() {
        var ids: string[] = [];
        for (var i = 0; i < this.selectedRowTableInfrastructure.length; i++) {
            ids.push(this.selectedRowTableInfrastructure[i].id);
        }

        this.subDownloadInfraElementsFile = this._fleetManagementService
            .downloadFile(ids)
            .subscribe((res: any) => {
                this.saveContentToFile(
                    JSON.stringify(res),
                    'Infrastructure-elements-' + new Date().getTime() + '.txt'
                );
            });
        this.userMonitoring
            .sendDummyRequest("'Fleet Location','Download POI','" + ids + "'")
            .subscribe((result: any) => {});
    }

    // LAST SEEN MQTT MESSAGE...

    vehicleLastSeenMS: number = 0;
    vehicleLastSeenTimeAgo: string = '';

    // ...LAST SEEN MQTT MESSAGE

    // VEHICLE STATES BY EVENTS...

    events: any[] = []; // list of all events

    eventStatesWarning: any[] = [];
    eventStatesCritical: any[] = [];

    selectedEventWarning: string[] = [];
    selectedEventCritical: string[] = [];

    onEventWarningChange(event) {
        this.selectedEventWarning = event.value;
        this.warningChangedThenReflectCritical();

        this.subSaveEventState = this._fleetManagementService
            .saveEventStates('warninig', this.selectedEventWarning)
            .subscribe(
                (res) => {
                    this._snackBar.open('Warning state updated.', 'Ok', { duration: 2000 });
                },
                () => {
                    this._snackBar.open('ERROR!', 'Ok', { duration: 10000 });
                }
            );
    }

    onEventCriticalChange(event) {
        this.selectedEventCritical = event.value;
        this.criticalChangedThenReflectWarning();

        this.subSaveEventState = this._fleetManagementService
            .saveEventStates('critical', this.selectedEventCritical)
            .subscribe(
                (res) => {
                    this._snackBar.open('Critical state updated.', 'Ok', { duration: 2000 });
                },
                () => {
                    this._snackBar.open('ERROR!', 'Ok', { duration: 10000 });
                }
            );
    }

    warningChangedThenReflectCritical() {
        this.eventStatesCritical = [];
        for (var i = 0; i < this.events.length; i++) {
            var exists = false;
            for (var j = 0; j < this.selectedEventWarning.length; j++) {
                if (this.events[i] == this.selectedEventWarning[j]) {
                    exists = true;
                    break;
                }
            }
            if (exists == false) {
                this.eventStatesCritical.push(this.events[i]);
            }
        }
    }

    criticalChangedThenReflectWarning() {
        this.eventStatesWarning = [];
        for (var i = 0; i < this.events.length; i++) {
            var exists = false;
            for (var j = 0; j < this.selectedEventCritical.length; j++) {
                if (this.events[i] == this.selectedEventCritical[j]) {
                    exists = true;
                    break;
                }
            }
            if (exists == false) {
                this.eventStatesWarning.push(this.events[i]);
            }
        }
    }

    initEventStatesWarningAfterLoad(states: string[]) {
        this.eventStatesWarning = [];
        var exists: boolean;
        for (var i = 0; i < this.events.length; i++) {
            exists = false;
            for (var j = 0; j < states.length; j++) {
                if (this.events[i] == states[j]) {
                    exists = true;
                    break;
                }
            }
            if (exists == false) {
                this.eventStatesWarning.push(this.events[i]);
            }
        }
    }

    initEventStatesCriticalAfterLoad(states: string[]) {
        this.eventStatesCritical = [];
        var exists: boolean;
        for (var i = 0; i < this.events.length; i++) {
            exists = false;
            for (var j = 0; j < states.length; j++) {
                if (this.events[i] == states[j]) {
                    exists = true;
                    break;
                }
            }
            if (exists == false) {
                this.eventStatesCritical.push(this.events[i]);
            }
        }
    }

    loadEventStates() {
        this.subGetEventStates = this._fleetManagementService
            .getEventStates()
            .subscribe((eventStatesResponse) => {
                if (eventStatesResponse != undefined) {
                    if (eventStatesResponse.length > 0) {
                        if (eventStatesResponse.length == 1) {
                            if (eventStatesResponse[0].eventState == 'warninig') {
                                this.selectedEventWarning = eventStatesResponse[0].events;
                                this.selectedEventCritical = [];
                            }
                            if (eventStatesResponse[0].eventState == 'critical') {
                                this.selectedEventCritical = eventStatesResponse[0].events;
                                this.selectedEventWarning = [];
                            }
                        }

                        if (eventStatesResponse.length == 2) {
                            for (var i = 0; i < eventStatesResponse.length; i++) {
                                if (eventStatesResponse[i].eventState == 'warninig') {
                                    this.selectedEventWarning = eventStatesResponse[i].events;
                                }
                                if (eventStatesResponse[i].eventState == 'critical') {
                                    this.selectedEventCritical = eventStatesResponse[i].events;
                                }
                            }
                        }

                        this.initEventStatesWarningAfterLoad(this.selectedEventCritical);
                        this.initEventStatesCriticalAfterLoad(this.selectedEventWarning);
                    } else {
                        this.eventStatesWarning = this.events;
                        this.eventStatesCritical = this.events;
                    }
                }
            });
    }

    // ...VEHICLE STATES BY EVENTS

    // TIMEOUTS...

    timeoutsNewValue: string = '0';
    timeoutsNewUnit: string = 's';

    timeoutsMiddleValue: string = '0';
    timeoutsMiddleUnit: string = 's';

    editTimeouts(timeoutType: string) {
        var mode: string = '';
        var value: string;
        var unit: string;

        if (timeoutType == 'timeoutNew') {
            mode = 'New';
            value = this.timeoutsNewValue;
            unit = this.timeoutsNewUnit;
        }

        if (timeoutType == 'timeoutMiddle') {
            mode = 'Middle';
            value = this.timeoutsMiddleValue;
            unit = this.timeoutsMiddleUnit;
        }

        this.dialogRefSetTimeouts = this._dialog.open(DialogSetTimeouts, {
            height: '240px',
            width: '265px',
            data: { value: value, timeUnit: unit, mode: mode },
        });
        this.subDialogSetTimeouts = this.dialogRefSetTimeouts
            .afterClosed()
            .subscribe((dialogData) => {
                if (dialogData != undefined) {
                    if (dialogData.toSave) {
                        if (timeoutType == 'timeoutNew') {
                            this.timeoutsNewValue = dialogData.data.value;
                            this.timeoutsNewUnit = dialogData.data.timeUnit;
                            this.saveTimeouts('new', this.timeoutsNewValue, this.timeoutsNewUnit);
                        }

                        if (timeoutType == 'timeoutMiddle') {
                            this.timeoutsMiddleValue = dialogData.data.value;
                            this.timeoutsMiddleUnit = dialogData.data.timeUnit;
                            this.saveTimeouts(
                                'middle',
                                this.timeoutsMiddleValue,
                                this.timeoutsMiddleUnit
                            );
                        }

                        this._changeDetectorRef.detectChanges();
                    }
                }
            });
    }

    saveTimeouts(status: string, value: string, unit: string) {
        this.subSaveTimeouts = this._fleetManagementService
            .saveTimeouts(status, value, unit)
            .subscribe(
                (response) => {
                    this._snackBar.open('Timeout value saved.', 'Ok', { duration: 2000 });
                },
                () => {
                    this._snackBar.open('ERROR while saving!', 'Ok', { duration: 10000 });
                }
            );
    }

    loadTimeouts() {
        this.subGetTimeouts = this._fleetManagementService.getTimeouts().subscribe((response) => {
            if (response != undefined) {
                if (response.length > 0) {
                    if (response.length == 1) {
                        if (response[0].status == 'new') {
                            this.timeoutsNewValue = response[0].value;
                            this.timeoutsNewUnit = response[0].unit;
                            this.timeoutsMiddleValue = '0';
                            this.timeoutsMiddleUnit = 's';
                        }
                        if (response[0].status == 'middle') {
                            this.timeoutsNewValue = '0';
                            this.timeoutsNewUnit = 's';
                            this.timeoutsMiddleValue = response[0].value;
                            this.timeoutsMiddleUnit = response[0].unit;
                        }
                    }

                    if (response.length == 2) {
                        if (response[0].status == 'new' && response[1].status == 'middle') {
                            this.timeoutsNewValue = response[0].value;
                            this.timeoutsNewUnit = response[0].unit;
                            this.timeoutsMiddleValue = response[1].value;
                            this.timeoutsMiddleUnit = response[1].unit;
                        }
                        if (response[0].status == 'middle' && response[1].status == 'new') {
                            this.timeoutsMiddleValue = response[0].value;
                            this.timeoutsMiddleUnit = response[0].unit;
                            this.timeoutsNewValue = response[1].value;
                            this.timeoutsNewUnit = response[1].unit;
                        }
                    }
                } else {
                    this.timeoutsNewValue = '0';
                    this.timeoutsNewUnit = 's';
                    this.timeoutsMiddleValue = '0';
                    this.timeoutsMiddleUnit = 's';
                }
            }
            this._changeDetectorRef.detectChanges();
        });
    }

    // ...TIMEOUTS

    saveContentToFile(fileContents, fileName) {
        var link = document.createElement('a');
        link.download = fileName;
        link.href = 'data:,' + fileContents;
        link.click();
    }

    dialogRef1: MatDialogRef<any>;

    subRefreshPoiUploadResult: Subscription = new Subscription();

    public uploadedOk: boolean = false;

    infrastructureElementsFileUpload() {
        this.dialogRef1 = this._dialog.open(DialogInfrastructureFileUpload, {
            height: '170px',
            width: '240px',
            disableClose: false,
        });
        this.subDialogRef1 = this.dialogRef1.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.subRefreshPoiUploadResult = interval(250).subscribe((x) => {
                    if (result.responseFinished) {
                        this.subRefreshPoiUploadResult.unsubscribe();

                        if (result != undefined) {
                            if (result.uploadedOk) {
                                this._snackBar.open('POIs uploaded', 'Ok', { duration: 5000 });
                                this.searchAfterFileUpload = true;
                                this.loadInfrastructureElements();
                            } else {
                                if (!result.uploadCanceled) {
                                    this.dialogRefDuplicatesInfrastructureElements =
                                        this._dialog.open(DialogDuplicatesInfrastructureElements, {
                                            height: '570px',
                                            width: '350px',
                                            data: { dialogDuplData: result.idOfDuplicateArray },
                                        });

                                    this.subDialogDuplicatesInfraElements =
                                        this.dialogRefDuplicatesInfrastructureElements
                                            .afterClosed()
                                            .subscribe((dialogData) => {
                                                if (dialogData != undefined) {
                                                    if (dialogData.toSave) {
                                                        this.subUpdateInfraElement =
                                                            this._fleetManagementService
                                                                .updateInfrastructureElements(
                                                                    result.duplicatesInfElData
                                                                )
                                                                .subscribe(
                                                                    (res) => {
                                                                        this._snackBar.open(
                                                                            'POIs uploaded',
                                                                            'Ok',
                                                                            { duration: 5000 }
                                                                        );
                                                                        this.searchAfterInfElUpdate =
                                                                            true;
                                                                        this.loadInfrastructureElements();
                                                                    },
                                                                    () => {
                                                                        this._snackBar.open(
                                                                            'Some or all POIs not uploaded',
                                                                            'Ok',
                                                                            { duration: 5000 }
                                                                        );
                                                                    }
                                                                );
                                                    } else {
                                                        this.searchAfterInfElUpdate = true;
                                                        this.loadInfrastructureElements();
                                                    }

                                                    this.dialogRefDuplicatesInfrastructureElements =
                                                        null;
                                                } else {
                                                    this.searchAfterInfElUpdate = true;
                                                    this.loadInfrastructureElements();
                                                }
                                            });
                                }
                            }
                            this.dialogRef1 = null;
                        }
                    }
                });
            }
        });
    }

    secondsToDHMS(seconds: number): string {
        // seconds = 15*3600+26*60+59;
        var daysT: number;
        var days: number;
        var daysR: number;
        var hoursT: number;
        var hours: number;
        var hoursR: number;
        var minutesT: number;
        var minutes: number;
        var minutesR: number;
        var seconds: number;

        daysT = seconds / (24 * 3600);
        days = Math.floor(daysT);
        daysR = daysT - days;

        hoursT = daysR * 24;
        hours = Math.floor(hoursT);
        hoursR = hoursT - hours;

        minutesT = hoursR * 60;
        minutes = Math.floor(minutesT);
        minutesR = minutesT - minutes;

        seconds = Math.round(minutesR * 60);

        return days + 'd, ' + hours + 'h, ' + minutes + 'm, ' + seconds + 's';
    }

    secondsToDHMS2(seconds: number): string {
        // seconds = 15*3600+26*60+59;
        var daysT: number;
        var days: number;
        var daysR: number;
        var hoursT: number;
        var hours: number;
        var hoursR: number;
        var minutesT: number;
        var minutes: number;
        var minutesR: number;
        var seconds: number;

        daysT = seconds / (24 * 3600);
        days = Math.floor(daysT);
        daysR = daysT - days;

        if (days > 0) {
            return days + 'd';
        }

        hoursT = daysR * 24;
        hours = Math.floor(hoursT);
        hoursR = hoursT - hours;

        if (hours > 0) {
            return hours + 'h';
        }

        minutesT = hoursR * 60;
        minutes = Math.floor(minutesT);
        minutesR = minutesT - minutes;

        if (minutes > 0) {
            return minutes + 'm';
        }

        seconds = Math.round(minutesR * 60);
        return seconds + 's';
    }

    cellFontColor(params): any {
        if (+this.timeoutsNewValue == 0 && +this.timeoutsMiddleValue == 0) {
            return { 'color': this.colorTimeoutNew };
        } else {
            if (
                this.toSeconds1(params.value) <=
                    this.toSeconds2(this.timeoutsNewValue, this.timeoutsNewUnit) &&
                +this.timeoutsNewValue != 0
            ) {
                return { 'color': this.colorTimeoutNew };
            }
            if (
                this.toSeconds1(params.value) <=
                    this.toSeconds2(this.timeoutsMiddleValue, this.timeoutsMiddleUnit) &&
                +this.timeoutsMiddleValue != 0
            ) {
                return { 'color': this.colorTimeoutMiddle };
            }
            return { 'color': this.colorTimeoutOutdated };
        }
    }

    // takes parameters in formats like 3s, 5m, 1h, 10d
    toSeconds1(value: string): number {
        var valueToSec: number = 0;

        if (value.indexOf('s') != -1) {
            valueToSec = +value.substring(0, value.length - 1);
        }

        if (value.indexOf('m') != -1) {
            valueToSec = +value.substring(0, value.length - 1) * 60;
        }

        if (value.indexOf('h') != -1) {
            valueToSec = +value.substring(0, value.length - 1) * 3600;
        }

        if (value.indexOf('d') != -1) {
            valueToSec = +value.substring(0, value.length - 1) * 3600 * 24;
        }

        return valueToSec;
    }

    // first parameter is the value, second parameter i unit
    toSeconds2(value: string, unit: string): number {
        if (unit == 's') {
            return +value;
        }
        if (unit == 'm') {
            return +value * 60;
        }
        if (unit == 'h') {
            return +value * 3600;
        }
        if (unit == 'd') {
            return +value * 3600 * 24;
        }
    }

    pointTransformation1(degres: number): number {
        var res: number;
        res = 4 * Math.sin((degres * Math.PI) / 180);
        return res;
    }

    pointTransformation2(degres: number): number {
        var res: number;
        res = 4 * Math.cos((degres * Math.PI) / 180) + 2.5;
        return res;
    }

    openLocationOnGoogleMap() {
        // var link = "https://www.google.com/maps/preview/@"+ this.mapSettingLat +"," + this.mapSettingLon +"," + this.zoom +"z";
        var link =
            'https://www.google.com/maps/search/?api=1&query=' +
            this.vehicleForShowingInfo[0].latitude +
            ',' +
            this.vehicleForShowingInfo[0].longitude;
        window.open(link);
    }
}

@Component({
    selector: 'dialogInfrastructureFileUpload',
    styleUrls: ['./fleet-location-map.component.scss'],
    template: `
        <h3>Select file</h3>
        <br />

        <td-file-input
            *ngIf="!selectedFile"
            #singleFileUpload
            [(ngModel)]="selectedFile"
            (cancel)="cancelEvent()"
            disabled="false"
            required
        >
            <mat-icon>file_upload</mat-icon>
            <span>Choose a file...</span>
        </td-file-input>
        <button
            mat-button
            *ngIf="selectedFile"
            (click)="uploadSelectedFileButtonClickHandler()"
            color="warn"
            mat-raised-button
        >
            <mat-icon>file_upload</mat-icon>
            START UPLOAD
        </button>
        <button
            mat-button
            *ngIf="selectedFile"
            id="removeSelectedFileButton"
            (click)="removeSelectedFileButtonClickHandler()"
            color="basic"
            mat-mini-fab
            mat-stroked-button
            class="remove-selected-file-button"
        >
            <mat-icon>cancel</mat-icon>
        </button>
    `,
})
export class DialogInfrastructureFileUpload {
    public toUpload: boolean = false;

    public uploadedOk: boolean = false;
    public selectedFile: File;
    public idOfDuplicateArray: string[];
    public uploadCanceled: boolean = false;
    public responseFinished: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public duplicatesInfElData: any
    ) {}

    subInfraFileUpload: Subscription = new Subscription();

    onNoClick(): void {
        //this.toSave = false;
        this.dialogRef.close();
    }

    cancelEvent() {
        this.uploadCanceled = true;
        this.dialogRef.close(this);
    }

    removeSelectedFileButtonClickHandler() {
        this.selectedFile = undefined;
    }

    uploadSelectedFileButtonClickHandler() {
        var fileReader = new FileReader();
        fileReader.readAsText(this.selectedFile);

        var content: String | ArrayBuffer | null;
        var pomArray = [];

        fileReader.onload = (e) => {
            content = fileReader.result;
            this.subInfraFileUpload = this._fleetManagementService
                .infrastructureFileUploadSrv(content)
                .subscribe((res) => {
                    this.uploadedOk = res.hasNoDuplicates;
                    if (!res.hasNoDuplicates) {
                        this.duplicatesInfElData = res.duplicates;
                        for (var i = 0; i < this.duplicatesInfElData.length; i++) {
                            // this.idOfDuplicateList[i] = (this.duplicatesInfElData[i].id);
                            pomArray.push(this.duplicatesInfElData[i].id);
                        }
                    }
                    this.idOfDuplicateArray = pomArray;
                    this.responseFinished = true;
                });
        };
        this.dialogRef.close(this);
    }
}

export interface IMarker {
    lat: number;
    lng: number;
    direction?: number;
    label?: Object;
    draggable: boolean;
    title?: string;
    icon: Object;
    vehicleId: string;
    gpsAccuracy: number;
    serialNumber: string;
}

export interface IVehicleInfo {
    vehicleId: string;
    speed: number;
    routeCode: string;
    driverId: string;
}

interface IMapsDefaultSettings {
    lat: number;
    lng: number;
    zoom: number;
}

interface ITableContent {
    vehicleId: string;
    updateTime: string;
    speed: number;
    delay: string;
    latitude: string;
    longitude: string;
    direction?: number;
    composition: string;
    serialNumber: string;
    updatedAgo: string;
}

export interface IInfrastructureElementDialogData {
    toSave: boolean;
    id: string;
    name: string;
    type: string;
    lat: number;
    lon: number;
    radius: number;
    validTill: Date;
    eventGen: boolean;
}

export interface IInfrastructureMarker {
    lat: number;
    lng: number;
    label?: Object;
    draggable: boolean;
    title?: string;
    icon: Object;
    id: string;
    zoomLevel?: number;
    radius?: number;
}

export interface IInfrastructureElementInDb {
    existsInDb: boolean;
}

export interface IInfrastructureUploadedOK {
    savingElementsOK: boolean;
}

export interface Label {
    text: string;
    color: string;
    fontWeight: string;
    fontSize: string;
}

export class MapSettings {
    user: string;
    lat: number;
    lon: number;
    zoom: number;
    use_settings: boolean;
}

interface IAllOk {
    allOk: boolean;
}

@Component({
    selector: 'app-image-formatter-cell',
    template: `<img
        *ngIf="params.value != 'noIcon'"
        style="margin-top: 3px;"
        border="0"
        width="22"
        height="22"
        [src]="params.value"
    />`,
})
export class ImageFormatterComponent {
    params: any;

    agInit(params: any): void {
        this.params = params;
    }
}

@Component({
    selector: 'app-image-formatter-cell-vehicles',
    template: `<img *ngIf="params.value" style="margin-top: 3px;" src="{{ imageRed }}"/>
    <img *ngIf="!params.value" style="margin-top: 3px;" src="{{ imageGreen }}"/>`,
})
export class ImageFormatterComponentVehicles {
    params: any;

    imageRed: string = 'assets/images/red.png';
    imageGreen: string = 'assets/images/green.png';

    agInit(params: any): void {
        this.params = params;
    }
}

// POI types...
@Component({
    selector: 'dialogAddInfrastructureType',
    templateUrl: 'dialog-add-infrastructure-type-template.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogAddInfrastructureType {
    public toSave: boolean = false;
    public type: string;
    public icon: string;
    public icons: any = new MapIconsPoiTypes().icons;
    public zoomLimit: string = 'No limit';
    public zoomValues: string[] = [
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
    ];
    public maxSpeed: string = '';

    fControl = new FormControl();
    saveButtonDisabled: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<DialogAddInfrastructureType>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    addInfrastructureType() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }

    selectionChanged(event) {
        this.saveButtonStatus();
    }

    inputChanged() {
        this.saveButtonStatus();
    }

    isMaxSpeedNumberOrEmpty(value: string): boolean {
        var isnum = /^(\s*|\d+)$/.test(value);
        return isnum;
    }

    saveButtonStatus() {
        if (
            this.icon != null &&
            this.icon != undefined &&
            this.icon != '' &&
            this.type != null &&
            this.type != undefined &&
            this.type.length > 0 &&
            this.isMaxSpeedNumberOrEmpty(this.maxSpeed)
        ) {
            this.saveButtonDisabled = false;
        } else {
            this.saveButtonDisabled = true;
        }
    }
}

@Component({
    selector: 'dialogEditInfrastructureType',
    templateUrl: 'dialog-edit-infrastructure-type-template.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogEditInfrastructureType {
    public toSave: boolean = false;
    public type: string;
    public icon: string;
    public id: string;
    public icons: any = new MapIconsPoiTypes().icons;
    public zoomLimit: string = 'No limit';
    public zoomValues: string[] = [
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
    ];
    public maxSpeed: string;

    fControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<DialogEditInfrastructureType>,
        private _fleetManagementService: FleetManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    editInfrastructureType() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }

    selectionChanged(event) {
    }
}

// ...POI types

@Component({
    selector: 'dialogSetTimeouts',
    templateUrl: 'dialog-set-timeouts.html',
    styleUrls: ['./fleet-location-map.component.scss'],
})
export class DialogSetTimeouts {
    public toSave: boolean = false;

    public timeUnitValues: string[] = ['s', 'm', 'h', 'd'];
    public mode: string = '';

    constructor(
        public dialogRef: MatDialogRef<DialogSetTimeouts>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    save() {
        this.toSave = true;
        this.dialogRef.close(this);
    }

    cancel() {
        this.toSave = false;
        this.dialogRef.close(this);
    }

    onNoClick(): void {
        this.dialogRef.close(this);
    }
}
