import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';
import { ICriteriaDataItem } from './signal.service';

export interface ITimeFromTo {
    timeFrom: number;
    timeTo: number;
}

export interface IReportsEnabled {
    reportsEnabled: boolean;
}

@Injectable({ providedIn: 'root' })
export class ReportingService {
    constructor(private http: EvaPlusHttpService) {}

    generateReport(
        reportType: string,
        events: string[],
        timeFrom: string,
        timeTo: string,
        driverId: string,
        vehicleId: string
    ) {
        var uriString =
            '/EvaPlusRest/reports/generateReport?from=' +
            timeFrom +
            '&to=' +
            timeTo +
            '&reportType=' +
            reportType +
            '&driverId=' +
            driverId +
            '&vehicleId=' +
            vehicleId;

        events.forEach((element) => {
            uriString += '&events=' + element;
        });

        return this.http.get(uriString);
    }

    downloadFile(fileName: string) {
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;
        var port = window.location.port;
        if (port == '4200') {
            port = '8080';
        }
        var url =
            protocol +
            '//' +
            hostname +
            ':' +
            port +
            '/EvaPlusRest/reports/downloadFile?fileName=' +
            fileName;
        window.open(url);
    }

    adjustTime(
        reportType: string,
        timeFrom: string,
        timeTo: string,
        enteredValue: string,
        events: string[]
    ) {
        let uriString: string =
            '/EvaPlusRest/reports/adjustTime?reportType=' +
            reportType +
            '&from=' +
            timeFrom +
            '&to=' +
            timeTo +
            '&enteredValue=' +
            enteredValue;

        events.forEach((element) => {
            uriString += '&events=' + element;
        });

        return this.http.get(uriString);
    }

    getKTZEvents(): Observable<ICriteriaDataItem[]> {
        let uriString: string = '/EvaPlusRest/reports/getKTZEvents';

        return this.http.get(uriString);
    }
}
