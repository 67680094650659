import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ManalUploadService } from '../services';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ManualUploadInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthenticationService,
        private _manualUploadService: ManalUploadService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /***
    // Get the auth header from the service.
    const authHeader = this.auth.token;
    let authReq = req.clone(req);
    if (authHeader) {
      // Clone the request to add the new header.
      authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
    }
      // Pass on the cloned request instead of the original request.
      return next.handle(authReq).pipe(
        takeUntil(this._manualUploadService.onCancelPendingRequest()),
      );  
      
      ***/

        let authReq = req.clone(req);
        return next.handle(authReq);
    }
}
