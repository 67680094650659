import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IBookmarkMarkerData } from '../../../../services/signal.service';
import {
    CalculatePreviewChart,
    ResetStoreCanvas,
    SetAnalogTimeMilisOne,
    SetCanvasId,
    SetIntervalInMinutes,
    SetMoveLeftOrRight,
    SetPageOpenedFromOtherPage,
    SetPreviewChartTimeSelected,
    SetPreviewClickDetected,
    SetPreviewProgresMode,
    SetPreviewTimeLimit,
    SetRelativePostionOn,
    SetShowPreview,
    SetTimeFromParam,
    SetTimeSignalsMillis,
} from '../action/canvas.actions';
import { GetData, OpenSaForParams } from '../action/signal-analytics-module.actions';
import { SetSelectedSignalBookmark } from '../action/signal-bookmarks.actions';

export interface SaCanvasStateModel {
    showPreview: boolean;
    previewProgresMode: string;
    canvasId: string;
    previewClickDetected: boolean;
    intervalInMinutes: number;
    analogTimeMilisOne: number;
    previewChartTimeSelected: number;
    relativePositionOn: boolean;
    previewChartDisplayed: boolean;
    moveLeftOrRight: string;
    pageOpenedFromOtherPage: boolean;
    timeFromParam: number;
    previewTimeMin: number;
    previewTimeMax: number;
    timeFromSignalsMillis: number;
    timeToSignalsMillis: number;
    bookmarkMarkerData: IBookmarkMarkerData;
}

const defaultState: SaCanvasStateModel = {
    showPreview: false,
    previewProgresMode: 'determinate',
    canvasId: 'analog-canvas',
    previewClickDetected: false,
    intervalInMinutes: 10,
    analogTimeMilisOne: null,
    previewChartTimeSelected: 0,
    relativePositionOn: false,
    previewChartDisplayed: false,
    moveLeftOrRight: '',
    pageOpenedFromOtherPage: false,
    timeFromParam: null,
    previewTimeMax: 0,
    previewTimeMin: 0,
    timeFromSignalsMillis: null,
    timeToSignalsMillis: null,
    bookmarkMarkerData: null,
};

@Injectable()
@State<SaCanvasStateModel>({
    name: 'saCanvas',
    defaults: defaultState,
})
export class SaCanvasState {
    @Action(SetShowPreview)
    setShowPreview(ctx: StateContext<SaCanvasStateModel>, action: SetShowPreview) {
        ctx.patchState({ showPreview: action.show });
    }

    @Action(SetPreviewClickDetected)
    setPreviewClickDetected(
        ctx: StateContext<SaCanvasStateModel>,
        action: SetPreviewClickDetected
    ) {
        ctx.patchState({ previewClickDetected: action.previewClickDetected });
    }

    @Action(SetPreviewChartTimeSelected)
    setPreviewChartTimeSelected(
        ctx: StateContext<SaCanvasStateModel>,
        action: SetPreviewChartTimeSelected
    ) {
        ctx.patchState({ previewChartTimeSelected: action.previewChartTimeSelected });
    }

    @Action(SetPageOpenedFromOtherPage)
    setPageOpenedFromOtherPage(
        ctx: StateContext<SaCanvasStateModel>,
        action: SetPageOpenedFromOtherPage
    ) {
        ctx.patchState({ pageOpenedFromOtherPage: action.pageOpenedFromOtherPage });
    }

    @Action(OpenSaForParams)
    openSaForParams(ctx: StateContext<SaCanvasStateModel>, action: OpenSaForParams) {
        const selectedTs: number = Number(action.time).valueOf();
        ctx.patchState({
            pageOpenedFromOtherPage: true,
            timeFromParam: selectedTs,
            previewClickDetected: false,
            previewChartTimeSelected: selectedTs,
        });
    }

    @Action(SetTimeFromParam)
    setTimeFromParam(ctx: StateContext<SaCanvasStateModel>, action: SetTimeFromParam) {
        ctx.patchState({ timeFromParam: action.timeFromParam });
    }

    @Action(SetMoveLeftOrRight)
    setMoveLeftOrRight(ctx: StateContext<SaCanvasStateModel>, action: SetMoveLeftOrRight) {
        ctx.patchState({ moveLeftOrRight: action.moveLeftOrRight });
    }

    @Action(CalculatePreviewChart)
    setPreviewChartDisplayed(ctx: StateContext<SaCanvasStateModel>, action: CalculatePreviewChart) {
        ctx.patchState({ previewChartDisplayed: true });
    }

    @Action(SetTimeSignalsMillis)
    setTimeSignalsMillis(ctx: StateContext<SaCanvasStateModel>, action: SetTimeSignalsMillis) {
        ctx.patchState({
            timeFromSignalsMillis: action.timeFromSignalsMillis,
            timeToSignalsMillis: action.timeToSignalsMillis,
        });
    }

    @Action(SetPreviewTimeLimit)
    setPreviewTimeLimit(ctx: StateContext<SaCanvasStateModel>, action: SetPreviewTimeLimit) {
        ctx.patchState({ previewTimeMin: action.min, previewTimeMax: action.max });
    }

    @Action(SetIntervalInMinutes)
    setIntervalInMinutes(ctx: StateContext<SaCanvasStateModel>, action: SetIntervalInMinutes) {
        ctx.patchState({ intervalInMinutes: action.intervalInMinutes });
    }

    @Action(SetRelativePostionOn)
    setRelativePositionOn(ctx: StateContext<SaCanvasStateModel>, action: SetRelativePostionOn) {
        ctx.patchState({ relativePositionOn: action.relativePositionOn });
    }

    @Action(SetPreviewProgresMode)
    setPreviewProgresMode(ctx: StateContext<SaCanvasStateModel>, action: SetPreviewProgresMode) {
        ctx.patchState({ previewProgresMode: action.previewProgresMode });
    }

    @Action(GetData)
    setPreviewProgresModeLoading(ctx: StateContext<SaCanvasStateModel>, action: GetData) {
        ctx.patchState({ previewProgresMode: 'indeterminate' });
    }

    @Action(SetCanvasId)
    setCanvasId(ctx: StateContext<SaCanvasStateModel>, action: SetCanvasId) {
        ctx.patchState({ canvasId: action.id });
    }

    @Action(SetAnalogTimeMilisOne)
    setAnalogTimeMilisOne(ctx: StateContext<SaCanvasStateModel>, action: SetAnalogTimeMilisOne) {
        ctx.patchState({ analogTimeMilisOne: action.analogTimeMilisOne });
    }

    @Action(ResetStoreCanvas)
    resetStoreCanvas(ctx: StateContext<SaCanvasStateModel>, action: ResetStoreCanvas) {
        ctx.setState(defaultState);
    }

    @Action(SetSelectedSignalBookmark)
    setSelectedBookmark(
        ctx: StateContext<SaCanvasStateModel>,
        { selectedBookmark }: SetSelectedSignalBookmark
    ) {
        if (selectedBookmark) {
            const bookmarkMarkerData: IBookmarkMarkerData = {
                markerSignalTextDeviatian:
                    selectedBookmark.chartData.drawing.signalChartOptions.markerSignalTextDeviatian,
                markerSignalTextRight:
                    selectedBookmark.chartData.drawing.signalChartOptions.markerSignalTextRight,
                markerSignalTextLeft:
                    selectedBookmark.chartData.drawing.signalChartOptions.markerSignalTextLeft,
                timeMin: selectedBookmark.chartData.scales.xAxes[0].time.min,
            };
            ctx.patchState({
                canvasId: selectedBookmark ? selectedBookmark.canvasId : 'analog-canvas',
                previewClickDetected: true,
                bookmarkMarkerData: bookmarkMarkerData,
            });
        }
    }

    @Selector()
    static previewProgressMode(state: SaCanvasStateModel) {
        return state.previewProgresMode;
    }

    @Selector()
    static intervalInMinutes(state: SaCanvasStateModel) {
        return state.intervalInMinutes;
    }
}
