import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { SignalNewService } from '../../../../services';
import { SignalType } from '../../../../services/signal-data.service';
import { TransferOK } from '../../../dashboard-signal-analytics/dashboard-signal-analytics.component';
import { AddNotificationMessage } from '../action/notification-message.actions';
import { AddNewSignalChartDrawingOption } from '../action/signal-chart-drawing-options.actions';
import {
    AddTemplateSignalSettings,
    AddUserSignalSettings,
    ApplySingleSignalSettings,
    LoadTemplateSignalSettings,
    LoadUserSignalSettings,
    RemoveAllUserSignalSettings,
    RemoveTemplateSignalSettings,
    RemoveUserSignalSettings,
    ResetSignalSettings,
    ResetTemplateSignalSettings,
    ResetUserSignalSettings,
    SaveAllChangedTemplateSignalSettingsInState,
    SaveTemplateSignalSettings,
    SaveUserSignalSettings,
    SetApplyUserSettingsPressed,
    SetLoadSignalSettings,
    SetSelectedTemplateSignalSettings,
    SetSelectedUserSignalSettings,
    SetSettingsView,
    SetTemplateSignalSettingsFilter,
    SetTemplateSignalsSettings,
    SetUserSignalSettingsFilter,
    SetUserSignalsSettings,
    SignalSettingsLoaded,
    WaitingSignalDataToApplySettings,
} from '../action/signal-settings.actions';
import { LoadTemplateData, SetVehicle } from '../action/toolbar.actions';
import { SingleNotificationMessage } from './notification-message.state';
import { SingleSignalChartDrawingOption } from './signal-chart-drawing-options.state';
import { SingleSignalSettingStateModel } from './single-signal-setting.state';

export interface SignalSettingsStateModel {
    templateSignalsSettings: SingleSignalSettingStateModel[];
    userSignalsSettings: SingleSignalSettingStateModel[];
    templateSignalsSettingsFilter: string;
    userSignalsSettingsFilter: string;
    selectedTemplateSignalSetting: SingleSignalSettingStateModel | null;
    selectedUserSignalSetting: SingleSignalSettingStateModel | null;
    settingsView: string;
    loadTemplateSignalSettings: boolean;
    loadUserSignalSettings: boolean;
    applyUserSettingsPressed: boolean;
    waitingSignalDataToApplySettings: boolean;
}

const defaultState = {
    templateSignalsSettings: [],
    userSignalsSettings: [],
    templateSignalsSettingsFilter: '',
    userSignalsSettingsFilter: '',
    selectedTemplateSignalSetting: null,
    selectedUserSignalSetting: null,
    settingsView: 'template',
    loadTemplateSignalSettings: false,
    loadUserSignalSettings: false,
    applyUserSettingsPressed: false,
    waitingSignalDataToApplySettings: false,
};

@Injectable()
@State<SignalSettingsStateModel>({
    name: 'signalSettings',
    defaults: defaultState,
})
export class SignalSettingsState {
    constructor(private signalService: SignalNewService, private store: Store) {}

    @Action(ApplySingleSignalSettings)
    applyUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: ApplySingleSignalSettings
    ) {
        let signalSettings: SingleSignalSettingStateModel = action.signalSettings;
        this.store.dispatch(
            new AddNewSignalChartDrawingOption(
                {
                    signalColor: signalSettings.color,
                    signalId: signalSettings.id,
                } as SingleSignalChartDrawingOption,
                signalSettings.signalType
            )
        );
    }

    @Action(SetSettingsView)
    setSettingsView(ctx: StateContext<SignalSettingsStateModel>, action: SetSettingsView) {
        ctx.patchState({ settingsView: action.settingsView });
    }

    @Action(SetVehicle)
    setVehicle(ctx: StateContext<SignalSettingsStateModel>, action: SetVehicle) {
        ctx.patchState({ settingsView: 'template' });
    }

    @Action(SetApplyUserSettingsPressed)
    setApplyUserSettingsPressed(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetApplyUserSettingsPressed
    ) {
        ctx.patchState({ applyUserSettingsPressed: action.pressed });
    }

    @Action(WaitingSignalDataToApplySettings)
    waitingSignalDataToApplySettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: WaitingSignalDataToApplySettings
    ) {
        ctx.patchState({ waitingSignalDataToApplySettings: true });
    }

    @Action(SignalSettingsLoaded)
    signalSettingsLoaded(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SignalSettingsLoaded
    ) {
        ctx.patchState({
            loadTemplateSignalSettings: false,
            loadUserSignalSettings: false,
            waitingSignalDataToApplySettings: false,
        });
    }

    @Action(SetLoadSignalSettings)
    setLoadSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetLoadSignalSettings
    ) {
        if (action.settingType == 'template') {
            ctx.patchState({ loadTemplateSignalSettings: action.load });
        } else {
            ctx.patchState({ loadUserSignalSettings: action.load });
        }
    }

    @Action(SaveTemplateSignalSettings)
    saveTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SaveTemplateSignalSettings
    ) {
        this.signalService
            .saveAllUserSettingsToTemplateSrv(
                action.templateId,
                action.signalsSettings.map((s) => {
                    return {
                        name: s.originalName,
                        color: s.color,
                        name2: s.newName,
                        rangemin: s.rangeMin,
                        rangemax: s.rangeMax,
                        comment: s.comment,
                        datatype: s.dataType,
                        type: s.signalType,
                        unit: s.unit,
                    };
                })
            )
            .subscribe(
                (response: TransferOK) => {
                    if (response.transferOK) {
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'Template settings saved',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    } else {
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'Saving template signal settings failed',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    }
                },
                (error) => {
                    this.store.dispatch(
                        new AddNotificationMessage({
                            message: 'Saving template signal settings failed',
                            autoCloseDuration: 3000,
                        } as SingleNotificationMessage)
                    );
                }
            );
    }

    @Action(SaveAllChangedTemplateSignalSettingsInState)
    saveAllChangedTemplateSignalSettingsInState(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SaveAllChangedTemplateSignalSettingsInState
    ) {
        this.signalService
            .saveAllUserSettingsToTemplateSrv(
                action.templateId,
                ctx
                    .getState()
                    .templateSignalsSettings.filter(
                        (s) =>
                            s.newName || s.color || s.comment || s.rangeMax || s.rangeMin || s.unit
                    )
                    .map((s) => {
                        return {
                            name: s.originalName,
                            color: s.color,
                            name2: s.newName,
                            rangemin: s.rangeMin,
                            rangemax: s.rangeMax,
                            comment: s.comment,
                            datatype: s.dataType,
                            type: s.signalType,
                            unit: s.unit,
                        };
                    })
            )
            .subscribe(
                (response: TransferOK) => {
                    if (response.transferOK) {
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'Template settings saved',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    } else {
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'Saving template signal settings failed',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    }
                },
                (error) => {
                    this.store.dispatch(
                        new AddNotificationMessage({
                            message: 'Saving template signal settings failed',
                            autoCloseDuration: 3000,
                        } as SingleNotificationMessage)
                    );
                }
            );
    }

    @Action(SaveUserSignalSettings)
    saveUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SaveUserSignalSettings
    ) {
        return this.signalService
            .saveUserSignalSettingsSrv(
                action.signalSettings.color,
                action.userName,
                action.signalSettings.originalName,
                action.signalSettings.newName,
                action.signalSettings.signalType,
                action.signalSettings.comment,
                action.signalSettings.unit,
                action.signalSettings.dataType,
                action.signalSettings.rangeMin,
                action.signalSettings.rangeMax
            )
            .pipe(
                tap((response: TransferOK) => {
                    if (action.saveFromTemplate && response.transferOK) {
                        ctx.patchState({
                            userSignalsSettings: [
                                ...ctx
                                    .getState()
                                    .userSignalsSettings.filter(
                                        (signal) => signal.id !== action.signalSettings.id
                                    ),
                                action.signalSettings,
                            ],
                        });
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'User signal settings saved',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    } else {
                        this.store.dispatch(
                            new AddNotificationMessage({
                                message: 'Saving user signal settings failed',
                                autoCloseDuration: 3000,
                            } as SingleNotificationMessage)
                        );
                    }
                }),
                catchError((err) =>
                    this.store.dispatch(
                        new AddNotificationMessage({
                            message: 'Saving user signal settings failed',
                            autoCloseDuration: 3000,
                        } as SingleNotificationMessage)
                    )
                )
            );
    }

    @Action(LoadTemplateSignalSettings)
    loadTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: LoadTemplateSignalSettings
    ) {
        return this.signalService.getTemplateSignalSettingsSrv(action.templateId).pipe(
            tap((response) => {
                this.setTemplateSignalSettings(ctx, response);
            })
        );
    }

    @Action(LoadTemplateData)
    loadTemplateData(ctx: StateContext<SignalSettingsStateModel>, action: LoadTemplateData) {
        let signalSettings =
            action.templateData && action.templateData.signalSettings
                ? action.templateData.signalSettings
                : [];
        this.setTemplateSignalSettings(ctx, signalSettings);
    }

    setTemplateSignalSettings(ctx: StateContext<SignalSettingsStateModel>, signalSettings) {
        let signalList = this.store.selectSnapshot((state: any) => {
            return {
                itemsAnalog: state.saCriteria.itemsAnalog,
                itemsDigital: state.saCriteria.itemsDigital,
                itemsOther: state.saCriteria.itemsOther,
                itemsEtcs: state.saCriteria.itemsEtcs,
            };
        });

        let allTemplateSignals: SingleSignalSettingStateModel[] = [
            ...this.createSignalsObjectListFromNameList(signalList.itemsAnalog, SignalType.Analog),
            ...this.createSignalsObjectListFromNameList(
                signalList.itemsDigital,
                SignalType.Digital
            ),
            ...this.createSignalsObjectListFromNameList(signalList.itemsOther, SignalType.Other),
            ...this.createSignalsObjectListFromNameList(signalList.itemsEtcs, SignalType.Etcs),
        ];

        for (let i = 0, c = signalSettings.length; i < c; i++) {
            let templateSignal = signalSettings[i];
            let existingSignal = allTemplateSignals.find((item) => item.id === templateSignal.name);

            if (existingSignal) {
                existingSignal.color = templateSignal.color;
                existingSignal.newName = templateSignal.name2;
                existingSignal.comment = templateSignal.comment;
                existingSignal.rangeMin = templateSignal.rangemin;
                existingSignal.rangeMax = templateSignal.rangemax;
                existingSignal.unit = templateSignal.unit;
            }
        }

        ctx.patchState({ templateSignalsSettings: allTemplateSignals });
    }

    private createSignalsObjectListFromNameList(
        signalNamesList: string[],
        signalType: string
    ): SingleSignalSettingStateModel[] {
        let signalsList: SingleSignalSettingStateModel[] = [];

        for (let i = 0, c = signalNamesList.length; i < c; i++) {
            let signal = signalNamesList[i];

            signalsList.push({
                id: signal,
                originalName: signal,
                originalDisplayName: signal.split(' [')[0],
                signalType: signalType,
            } as SingleSignalSettingStateModel);
        }

        return signalsList;
    }

    @Action(LoadUserSignalSettings)
    loadUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: LoadUserSignalSettings
    ) {
        this.signalService.getUserSignalSettingsSrv(action.userName).subscribe((response: any) => {
            let signalsList: SingleSignalSettingStateModel[] = [];

            for (let i = 0, c = response.length; i < c; i++) {
                let signal = response[i];

                signalsList.push({
                    id: signal.name,
                    originalName: signal.name,
                    originalDisplayName: signal.name.split(' [')[0],
                    color: signal.color,
                    newName: signal.name2,
                    rangeMin: signal.rangemin,
                    rangeMax: signal.rangemax,
                    comment: signal.comment,
                    dataType: signal.datatype,
                    signalType: signal.type,
                    unit: signal.unit,
                } as SingleSignalSettingStateModel);
            }

            ctx.patchState({ userSignalsSettings: signalsList });
        });
    }

    @Action(SetTemplateSignalSettingsFilter, { cancelUncompleted: true })
    setTemplateSignalSettingsFilter(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetTemplateSignalSettingsFilter
    ) {
        ctx.patchState({ templateSignalsSettingsFilter: action.signalFilter });
    }

    @Action(SetUserSignalSettingsFilter, { cancelUncompleted: true })
    setUserSignalSettingsFilter(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetUserSignalSettingsFilter
    ) {
        ctx.patchState({ userSignalsSettingsFilter: action.signalFilter });
    }

    @Action(AddTemplateSignalSettings)
    addTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: AddTemplateSignalSettings
    ) {
        ctx.patchState({
            templateSignalsSettings: [
                ...ctx
                    .getState()
                    .templateSignalsSettings.filter(
                        (signalSettings) => signalSettings.id !== action.signalSettings.id
                    ),
                action.signalSettings,
            ],
        });
    }

    @Action(RemoveTemplateSignalSettings)
    removeTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: RemoveTemplateSignalSettings
    ) {
        ctx.patchState({
            templateSignalsSettings: [
                ...ctx
                    .getState()
                    .templateSignalsSettings.filter(
                        (signalSettings) => signalSettings.id !== action.signalSettingsId
                    ),
            ],
        });
    }

    @Action(SetTemplateSignalsSettings)
    seTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetTemplateSignalsSettings
    ) {
        ctx.patchState({ templateSignalsSettings: action.templateSignalsSettings });
    }

    @Action(AddUserSignalSettings)
    addUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: AddUserSignalSettings
    ) {
        ctx.patchState({
            userSignalsSettings: [
                ...ctx
                    .getState()
                    .userSignalsSettings.filter(
                        (signalSettings) =>
                            signalSettings.originalName !== action.signalSettings.originalName
                    ),
                action.signalSettings,
            ],
        });
    }

    @Action(RemoveUserSignalSettings)
    removeUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: RemoveUserSignalSettings
    ) {
        this.signalService
            .removeUserSignalSettingsSrv(action.userName, action.signalSettingsId)
            .subscribe((respone) => {
                ctx.patchState({
                    userSignalsSettings: [
                        ...ctx
                            .getState()
                            .userSignalsSettings.filter(
                                (signalSettings) => signalSettings.id !== action.signalSettingsId
                            ),
                    ],
                });
            });
    }

    @Action(RemoveAllUserSignalSettings)
    removeAllUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: RemoveAllUserSignalSettings
    ) {
        this.signalService.removeAllSignalSettingsUserSrv(action.userName).subscribe((respone) => {
            ctx.patchState({ userSignalsSettings: [] });
        });
    }

    @Action(SetUserSignalsSettings)
    setUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetUserSignalsSettings
    ) {
        ctx.patchState({ userSignalsSettings: action.userSignalsSettings });
    }

    @Action(SetSelectedTemplateSignalSettings)
    setSelectedTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetSelectedTemplateSignalSettings
    ) {
        ctx.patchState({ selectedTemplateSignalSetting: action.signalSettings });
    }

    @Action(SetSelectedUserSignalSettings)
    setSelectedUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: SetSelectedUserSignalSettings
    ) {
        ctx.patchState({ selectedUserSignalSetting: action.signalSettings });
    }

    @Action(ResetTemplateSignalSettings)
    resetTemplateSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: ResetTemplateSignalSettings
    ) {
        let signalSettings = ctx
            .getState()
            .templateSignalsSettings.find(
                (signalSettings) => signalSettings.id === action.signalSettingsId
            );

        if (signalSettings) {
            signalSettings.color = '';
            signalSettings.newName = '';
            signalSettings.unit = '';
            signalSettings.comment = '';
            signalSettings.rangeMin = '';
            signalSettings.rangeMax = '';
            signalSettings.dataType = '';
        }
    }

    @Action(ResetUserSignalSettings)
    resetUserSignalSettings(
        ctx: StateContext<SignalSettingsStateModel>,
        action: ResetUserSignalSettings
    ) {
        let signalSettings = ctx
            .getState()
            .userSignalsSettings.find(
                (signalSettings) => signalSettings.id === action.signalSettingsId
            );

        if (signalSettings) {
            signalSettings.color = '';
            signalSettings.newName = '';
            signalSettings.unit = '';
            signalSettings.comment = '';
            signalSettings.rangeMin = '';
            signalSettings.rangeMax = '';
            signalSettings.dataType = '';
        }
    }

    @Action(ResetSignalSettings)
    resetSignalSettings(ctx: StateContext<SignalSettingsStateModel>, action: ResetSignalSettings) {
        ctx.setState(defaultState);
    }

    @Selector()
    static templateSignalSettings(state: SignalSettingsStateModel) {
        if (state.templateSignalsSettingsFilter.length > 0) {
            let filter: string = state.templateSignalsSettingsFilter.toLowerCase();
            return state.templateSignalsSettings.filter(
                (s) =>
                    s.originalName.toLowerCase().indexOf(filter) >= 0 ||
                    (s.newName && s.newName.toLowerCase().indexOf(filter) >= 0)
            );
        } else {
            return state.templateSignalsSettings;
        }
    }

    @Selector()
    static userSignalSettings(state: SignalSettingsStateModel) {
        if (state.userSignalsSettingsFilter.length > 0) {
            let filter: string = state.userSignalsSettingsFilter.toLowerCase();
            return state.userSignalsSettings.filter(
                (s) =>
                    s.originalName.toLowerCase().indexOf(filter) >= 0 ||
                    (s.newName && s.newName.toLowerCase().indexOf(filter) >= 0)
            );
        } else {
            return state.userSignalsSettings;
        }
    }
}
