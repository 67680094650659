import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface IPreset {
    text: string;
    no: number;
}

@Component({
    selector: 'hr-datetime-picker2',
    templateUrl: './hr-datetime-picker2.component.html',
    styleUrls: ['./hr-datetime-picker2.component.scss'],
})
export class HrDatetimePicker2Component {
    @Input() dateFrom: Date = new Date();
    @Input() dateTo: Date = new Date();
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    presets: IPreset[] = [
        { text: this.translate.instant('TIME-PICKER.TODAY'), no: 1 },
        { text: `${this.translate.instant('TIME-PICKER.LAST')} 24h`, no: 2 },
        { text: `${this.translate.instant('TIME-PICKER.LAST')} 48h`, no: 3 },
        {
            text: `${this.translate.instant('TIME-PICKER.LAST')} 3 ${this.translate.instant('TIME-PICKER.DAYS')}`,
            no: 4,
        },
        {
            text: `${this.translate.instant('TIME-PICKER.LAST')} 10 ${this.translate.instant('TIME-PICKER.DAYS')}`,
            no: 5,
        },
        {
            text: `${this.translate.instant('TIME-PICKER.LAST')} 15 ${this.translate.instant('TIME-PICKER.DAYS')}`,
            no: 6,
        },
        {
            text: `${this.translate.instant('TIME-PICKER.LAST')} 30 ${this.translate.instant('TIME-PICKER.DAYS')}`,
            no: 7,
        },
        {
            text: `${this.translate.instant('TIME-PICKER.LAST')} 100 ${this.translate.instant('TIME-PICKER.DAYS')}`,
            no: 8,
        },
    ];

    selectedPresetNumber: number | null = null;
    step = 0;

    timeRangeText: string = `${this.translate.instant('TIME-PICKER.SELECT-TIME')}`;
    timeRangeTextShort: string = 'Select time range';
    rangeValue: { from: Date; to: Date };
    selectTimeFrame: boolean = false;
    relativeFrom: string = '';
    relativeTo: string = '';

    absOrRel: string = '';

    canBeSetAbsolute: boolean = false;
    canBeSetRelative: boolean = false;

    todaySelected: boolean = false;
    language: string = 'en';

    public get isPlaceholder(): boolean {
        return this.timeRangeText === 'Select time range' || this.timeRangeTextShort === 'Select time range';
    }

    constructor(private datePipe: DatePipe, private cd: ChangeDetectorRef, private translate: TranslateService) {
        let currentUser = localStorage.getItem('currentUser');
        const parsedUser = currentUser ? JSON.parse(currentUser) : null;
        this.language = parsedUser && parsedUser.lang;
        this.translate.use(this.language.toLocaleLowerCase());
    }

    public setDateTimeRemote(timefrom, timeto, showAbsOrRel) {
        if (timefrom != null && timeto != null) {
            this.dateFrom = timefrom;
            this.dateTo = timeto;
            this.presetRelative();
            this.rangeValue = { 'from': timefrom, 'to': timeto };

            if (showAbsOrRel == 'abs') {
                this.setLabelAbsolute();
            } else if (showAbsOrRel == 'rel') {
                this.setLabelRelative();
            } else if (showAbsOrRel == 'td') {
                this.todaySelected = true;
                this.setLabelRelative();
            }
        } else {
            this.relativeFrom = '';
            this.relativeTo = '';
            this.timeRangeText = 'Select time range';
        }
    }

    setStep(index: number) {
        this.step = index;
    }

    showTimeFrameToolbar() {
        this.selectTimeFrame = !this.selectTimeFrame;
    }

    absoluteDateChangedRange() {
        if (this.rangeValue.from && this.rangeValue.to) {
            this.dateFrom = this.rangeValue.from;
            this.dateTo = this.rangeValue.to;
            this.canBeSetAbsolute = true;
        } else {
            this.canBeSetAbsolute = false;
        }

        this.unsetPresetNumber();
    }

    relativeDateChanged() {
        const relativeFromDate: Date = this.getRelativeDate(this.relativeFrom);
        const relativeToDate: Date = this.getRelativeDate(this.relativeTo);

        this.canBeSetRelative =
            relativeFromDate.toString() != 'Invalid Date' && relativeToDate.toString() != 'Invalid Date';
        this.unsetPresetNumber();
    }

    setLabelAbsolute() {
        const dFrom = this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd HH:mm:ss');
        const dTo = this.datePipe.transform(this.dateTo, 'yyyy-MM-dd HH:mm:ss');
        this.timeRangeText = dFrom + ' to ' + dTo;

        const dFroms = this.datePipe.transform(this.dateFrom, 'yy-MM-dd');
        const dTos = this.datePipe.transform(this.dateTo, 'yy-MM-dd');
        this.timeRangeTextShort = dFroms + ' to ' + dTos;
    }

    setLabelRelative() {
        const fromSplited: string[] = this.relativeFrom.split(' ');
        const toSplited: string[] = this.relativeTo.split(' ');
        let newFromRelative: string = '';
        let newToRelative: string = '';

        if (this.todaySelected == true) {
            this.todaySelected = false;
            newFromRelative = 'Today';
        }

        if (newFromRelative != 'Today') {
            if (
                fromSplited[0].split('d')[0].match(/^0*$/) &&
                fromSplited[1].split('h')[0].match(/^0*$/) &&
                fromSplited[2].split('m')[0].match(/^0*$/) &&
                fromSplited[3].split('s')[0].match(/^0*$/)
            ) {
                newFromRelative = 'now';
            } else {
                for (let i = 0; i < fromSplited.length; i++) {
                    let sep = ' ';
                    if (i == 3) {
                        sep = '';
                    }

                    if (!fromSplited[i].substring(0, fromSplited[i].length - 1).match(/^0*$/)) {
                        if (fromSplited[i][0] == '0' && fromSplited[i].length > 2) {
                            // for removing leading zero if length is greater than 2
                            newFromRelative =
                                newFromRelative + fromSplited[i].substr(1, fromSplited[i].length - 1) + sep;
                        } else {
                            newFromRelative = newFromRelative + fromSplited[i] + sep;
                        }
                    }
                }
            }
        }

        if (
            toSplited[0].split('d')[0].match(/^0*$/) &&
            toSplited[1].split('h')[0].match(/^0*$/) &&
            toSplited[2].split('m')[0].match(/^0*$/) &&
            toSplited[3].split('s')[0].match(/^0*$/)
        ) {
            newToRelative = 'now';
        } else {
            for (let i = 0; i < toSplited.length; i++) {
                let sep = ' ';
                if (i == 3) {
                    sep = '';
                }

                if (!toSplited[i].substring(0, toSplited[i].length - 1).match(/^0*$/)) {
                    if (toSplited[i][0] == '0' && toSplited[i].length > 2) {
                        // for removing leading zero if length is greater than 2
                        newToRelative = newToRelative + toSplited[i].substr(1, toSplited[i].length - 1) + sep;
                    } else {
                        newToRelative = newToRelative + toSplited[i] + sep;
                    }
                }
            }
        }

        if (newFromRelative == 'Today') {
            this.timeRangeText = 'Today';
            this.timeRangeTextShort = 'Today';
        } else {
            this.timeRangeText = newFromRelative + ' to ' + newToRelative;
            this.timeRangeTextShort = newFromRelative + ' to ' + newToRelative;
        }
    }

    setLabelAndClose() {
        if (this.absOrRel == 'abs') {
            this.setLabelAbsolute();
        } else {
            this.setLabelRelative();
        }
        this.selectTimeFrame = false;
    }

    setPresetDate(presetNumber: number) {
        this.absOrRel = 'rel';
        this.dateTo = new Date();
        this.dateFrom = new Date();
        this.selectedPresetNumber = presetNumber;

        if (presetNumber == 1) {
            this.dateFrom = new Date();
            this.dateFrom.setHours(0, 0, 0, 0);
            this.todaySelected = true;
            this.absOrRel = 'td';
        }
        if (presetNumber == 2) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24);
        }
        if (presetNumber == 3) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 48);
        }
        if (presetNumber == 4) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24 * 3);
        }
        if (presetNumber == 5) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24 * 10);
        }
        if (presetNumber == 6) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24 * 15);
        }
        if (presetNumber == 7) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24 * 30);
        }
        if (presetNumber == 8) {
            this.dateFrom = new Date(this.dateFrom.getTime() - 1000 * 60 * 60 * 24 * 100);
        }

        this.presetRelative();
        this.setLabelAndClose();
        this.rangeValue = { 'from': this.dateFrom, 'to': this.dateTo };

        this.onChange.emit([this.dateFrom, this.dateTo, this.absOrRel]);
    }

    setAbsoluteDate() {
        this.absOrRel = 'abs';
        this.setLabelAndClose();
        this.presetRelative();
        this.canBeSetAbsolute = false;
        this.onChange.emit([this.dateFrom, this.dateTo, this.absOrRel]);
    }

    setRelativeDate() {
        this.absOrRel = 'rel';
        this.dateFrom = this.getRelativeDate(this.relativeFrom);
        this.dateTo = this.getRelativeDate(this.relativeTo);
        this.rangeValue = { 'from': this.dateFrom, 'to': this.dateTo };
        this.setLabelAndClose();
        this.canBeSetRelative = false;
        this.unsetPresetNumber();
        this.onChange.emit([this.dateFrom, this.dateTo, this.absOrRel]);
    }

    presetRelative() {
        this.relativeFrom = this.getRelativeString(this.dateFrom);
        this.relativeTo = this.getRelativeString(this.dateTo);
    }

    getRelativeString(date: Date) {
        const now = new Date().getTime();
        const the = date.getTime();
        let difference_ms = (now - the) / 1000;
        const seconds = Math.floor(difference_ms % 60);
        difference_ms = difference_ms / 60;
        const minutes = Math.floor(difference_ms % 60);
        difference_ms = difference_ms / 60;
        const hours = Math.floor(difference_ms % 24);
        const days = Math.floor(difference_ms / 24);
        return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
    }

    getRelativeDate(indate: string) {
        let part = indate.toLocaleLowerCase();
        let res: Date = new Date();
        const d_ind = part.indexOf('d');
        if (d_ind > 0) {
            const dpart = part.substring(0, d_ind).trim();
            const d = parseInt(dpart);
            res = new Date(res.getTime() - 1000 * 60 * 60 * 24 * d);
            part = part.substring(d_ind + 1);
        }

        const h_ind = part.indexOf('h');
        if (h_ind > 0) {
            const hpart = part.substring(0, h_ind).trim();
            const h = parseInt(hpart);
            res = new Date(res.getTime() - 1000 * 60 * 60 * h);
            part = part.substring(h_ind + 1);
        }

        const m_ind = part.indexOf('m');
        if (m_ind > 0) {
            const mpart = part.substring(0, m_ind).trim();
            const m = parseInt(mpart);
            res = new Date(res.getTime() - 1000 * 60 * m);
            part = part.substring(m_ind + 1);
        }

        const s_ind = part.indexOf('s');
        if (s_ind > 0) {
            const spart = part.substring(0, s_ind).trim();
            const s = parseInt(spart);
            res = new Date(res.getTime() - 1000 * s);
            part = part.substring(s_ind + 1);
        }
        return res;
    }

    isDescendant(el, parentId) {
        let isChild = false;

        if (el.id === parentId) {
            isChild = true;
        }

        while ((el = el.parentNode)) {
            if (el.id == parentId) {
                isChild = true;
                break;
            }
        }

        return isChild;
    }

    private unsetPresetNumber(): void {
        this.selectedPresetNumber = null;
    }

    @HostListener('window:click', ['$event.target'])
    onClick(e) {
        const openTpButtonClicked: boolean = this.isDescendant(e, 'tpButton1');
        const calendarDialog = document.querySelector('ngx-dialog');

        setTimeout(() => {
            const elementParent = document.getElementById('tpDropBar');

            if (elementParent != null && !openTpButtonClicked && calendarDialog == null) {
                if (!this.isDescendant(e, 'tpDropBar')) {
                    this.selectTimeFrame = false;
                    this.cd.detectChanges();
                }
            }
        }, 250);
    }
}
