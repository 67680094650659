export class MapIcons {
    public icons: any = {
        train: {
            icon: 'assets/icons/train1.png',
        },
        selectedTrain: {
            icon: 'assets/icons/trainRed.png',
        },
        warning: {
            icon: 'assets/icons/warningIcon.png',
        },
        error: {
            icon: 'assets/icons/errorIcon.png',
        },
        train1: {
            icon: 'assets/icons/train_stay_on.png',
        },
        train2: {
            icon: 'assets/icons/train_stay_off.png',
        },
        train3: {
            icon: 'assets/icons/train_move_on.png',
        },
        train4: {
            icon: 'assets/icons/train_move_off.png',
        },
        icon_infra01: {
            icon: 'assets/icons/icon_infra01.png', // Infrastructure, POI 1
        },
        icon_infra02: {
            icon: 'assets/icons/icon_infra02.png', // Infrastructure, POI 2
        },
        icon_infra03: {
            icon: 'assets/icons/icon_infra03.png', // Infrastructure, Stations 1
        },
        icon_infra04: {
            icon: 'assets/icons/icon_infra04.png', // Infrastructure, Depot
        },
        icon_infra05: {
            icon: 'assets/icons/icon_infra05.png', // Infrastructure, Neutral section
        },
        icon_infra06: {
            icon: 'assets/icons/icon_infra06.png', // Infrastructure, Bridge
        },
        icon_infra07: {
            icon: 'assets/icons/icon_infra07.png', // Infrastructure, Crossing
        },
        icon_infra08: {
            icon: 'assets/icons/icon_infra07.png', // Infrastructure, Balise
        },
        icon_infra09: {
            icon: 'assets/icons/icon_infra07.png', // Infrastructure, Signal
        },
        icon_infra10: {
            icon: 'assets/icons/icon_infra07.png', // Infrastructure, Switch
        },
    };
}
