import {
    ArrowObject,
    RectangleResponse,
    TextObject,
} from '../../../components/hr-draw-tool/hr-draw-tool.component';

export class SetBookmarkComment {
    static readonly type = '[DrawingTool] Set Bookmark Comment';
    constructor(public bookmarkComment: string) {}
}

export class SetDTCanvasId {
    static readonly type = '[DrawingTool] Set Canvas Id';
    constructor(public canvasId: string) {}
}

export class SetSelectDrawPane {
    static readonly type = '[DrawingTool] Set Select Draw Pane';
    constructor(public selectDrawPane: boolean) {}
}

export class SetArrowSelected {
    static readonly type = '[DrawingTool] Set Arrow Selected';
    constructor(public arrowSelected: boolean) {}
}

export class SetLatestEnterdText {
    static readonly type = '[DrawingTool] Set Latest Enterd Text';
    constructor(public latestEnterdText: TextObject) {}
}

export class SetBookmarkMarker {
    static readonly type = '[DrawingTool] Set bookmarkMarker';
    constructor(public bookmarkMarker: boolean) {}
}

export class SetMultipleMarkers {
    static readonly type = '[DrawingTool] Set multipleMarkers';
    constructor(public multipleMarkers: boolean) {}
}
export class SetRectangleSelected {
    static readonly type = '[DrawingTool] Set rectangleSelected';
    constructor(public rectangleSelected: boolean) {}
}
export class SetTextDrawSelected {
    static readonly type = '[DrawingTool] Set textDrawSelected';
    constructor(public textDrawSelected: boolean) {}
}

export class SetSelectedDrawTool {
    static readonly type = '[DrawingTool] Set selectedDrawTool';
    constructor(public selectedDrawTool: string) {}
}

export class SetSelectedSingnalsText {
    static readonly type = '[DrawingTool] Set selectedSingnalsText';
    constructor(public selectedSingnalsText: string[]) {}
}

export class AddSelectedSingnalsText {
    static readonly type = '[DrawingTool] Add selectedSingnalsText';
    constructor(public selectedSingnalsText: string) {}
}

export class RemoveSelectedSingnalsText {
    static readonly type = '[DrawingTool] Remove selectedSingnalsText';
    constructor(public selectedSingnalsText: string) {}
}

export class SetLastDrawnArrow {
    static readonly type = '[DrawingTool] Set lastDrawnArrow';
    constructor(public lastDrawnArrow: ArrowObject) {}
}

export class SetLastDrawnRectangle {
    static readonly type = '[DrawingTool] Set lastDrawnRectangle';
    constructor(public lastDrawnRectangle: RectangleResponse) {}
}

export class ResetStoreDrawingTool {
    static readonly type = '[DrawingTool] Reset Store Drawing Tool';
}
