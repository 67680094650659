import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'add-notif-threshold-dialog.component',
    templateUrl: 'add-notif-threshold-dialog.component.html',
    styleUrls: ['./add-notif-threshold-dialog.component.scss'],
})
export class DialogNotificationThreshold {
    selectedVehicleMemories: string = '';
    thresholdDays: number = 1;

    constructor(
        public dialogRef: MatDialogRef<DialogNotificationThreshold>,
        @Inject(MAT_DIALOG_DATA) public data: any[],
        public dialog: MatDialog
    ) {}

    onChangeVehicle($event) {
        this.selectedVehicleMemories = $event;
    }

    onNoClick(): void {
        this.dialogRef.close({ data: 'canceled' });
    }

    save() {
        if (this.thresholdDays > 31 || this.thresholdDays < 1) {
            this.dialogRef.close({ data: 'threshold-limit' });
        } else if (this.selectedVehicleMemories == '') {
            this.dialogRef.close({ data: 'vehicle-not-selected' });
        } else {
            this.dialogRef.close({
                data: 'confirmed',
                'vehicleMemories': this.selectedVehicleMemories,
                'thresholdDays': this.thresholdDays,
            });
        }
    }
}
