import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TENANT } from '../config/api.config';
import { HttpClient } from '@angular/common/http';
import {
    IChartData,
    IChartDataComplete,
    ICriteriaData,
    ICriteriaDataItem,
    IEventData,
    ILastTs,
    ITelocData,
} from './signal.service';
import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';
import { EvaPlusHttpService } from './http/eva-http.service';

export interface IFileTransferOK {
    transferOK: boolean;
}

const baseUrl = '/EvaPlusRest/signalanalyticsnew/';
const baseTelocUrl = '/EvaPlusRest/telocfile/';
const baseTemplateUrl = '/EvaPlusRest/templates/';
const baseManualImportUrl = '/EvaPlusRest/manualimport/';

export interface IEventEntity {
    event_category: string;
    event_category_color: string;
    event_type: string;
    event_description: string;
    event_state: string;
    vehicle_id: string;
    memory: string;
    time: string;
    elapsed_time: string;
    date: string;
    week: string;
    hour: string;
    weekday: string;
    monthday: string;
    month: string;
    year: string;
    'duration[H]': string;
    'duration[s.ms]': string;
    'duration[ms]': string;
    'odometer[m]': string;
    'distance[m]': string;
    'odometer[km]': string;
    'distance[km]': string;
    'odometer[miles]': string;
    'distance[miles]': string;
    driver_id: string;
    route_code: string;
    gps_location: string;
    processing_info: string;
    Vist_FLG: string;
    'Zug-Nr': string;
    confIssue: string;
    signals: string;
    telocfile: string;
    telocfilefull: string;
    timeStr: string;
    template: string;
    recorderid: string;
    POI: string;
}

export interface ISignalAnalyticsTemplateId {
    timestamp: number;
    counter: number;
    randomValue1: number;
    randomValue2: number;
}

export interface ISignalAnalyticsTemplate {
    _id: ISignalAnalyticsTemplateId;
    name: string;
    id: string;
}

export interface EventCategory {
    category: string;
    color: string;
}

@Injectable({ providedIn: 'root' })
export class SignalNewService {
    constructor(private http: EvaPlusHttpService, private httpClient: HttpClient) {}

    signalsQuery(
        vehicleId: string,
        memoryType: string,
        timefrom: string,
        timeto: string,
        signals: string[]
    ): Observable<IChartData[]> {
        var uriString =
            '/EvaPlusRest/chartdata/getSignalsQuery?tenant=' +
            TENANT +
            '&vehicleId=' +
            vehicleId +
            '&memoryType=' +
            memoryType +
            '&from=' +
            timefrom +
            '&to=' +
            timeto;
        signals.forEach((element) => {
            uriString += '&signals=' + element;
        });

        return this.http.get(baseUrl + uriString);
    }

    signalsQueryFull(
        vehicleId: string,
        memoryType: string,
        timefrom: string,
        timeto: string,
        signalsAnalog: string[],
        signalsDigital: string[],
        signalsOther: string[]
    ): Observable<IChartDataComplete> {
        var uriString =
            'getSignalsQueryFull?tenant=' +
            TENANT +
            '&vehicleId=' +
            vehicleId +
            '&memoryType=' +
            memoryType +
            '&from=' +
            timefrom +
            '&to=' +
            timeto;
        signalsAnalog.forEach((element) => {
            uriString += '&signalsAnalog=' + element;
        });
        signalsDigital.forEach((element) => {
            uriString += '&signalsDigital=' + element;
        });
        signalsOther.forEach((element) => {
            uriString += '&signalsOther=' + element;
        });

        return this.http.get(baseUrl + uriString);
    }

    signalsPreview(
        fleetId: string,
        vehicleId: string,
        timeFrom: string,
        timeTo: string
    ): Observable<IChartDataComplete> {
        return this.http.get(baseUrl + 'getSignalsPreview', {
            params: { tenant: TENANT, fleetId, vehicleId, from: timeFrom, to: timeTo },
        });
    }

    lastDateForVehicle(vehicleId: string): Observable<ILastTs> {
        return this.http.get(baseUrl + 'getLastDateForVehicle', {
            params: { tenant: TENANT, vehicleId },
        });
    }

    criteriaQuery(): Observable<ICriteriaData> {
        return this.http.get(baseUrl + 'getSignalsCriteria', { params: { tenant: TENANT } });
    }

    telocByDriver(driverId: string, timeFrom: string, timeTo: string, vehicleId: string): Observable<ITelocData[]> {
        return this.http.get(baseTelocUrl + 'getTelocForDriver', {
            params: {
                tenant: TENANT,
                driverId,
                from: timeFrom,
                to: timeTo,
                vehicleId,
            },
        });
    }

    getDriverList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseTelocUrl + 'getDriverList', { params: { tenant: TENANT } });
    }

    telocByRoute(routeCode: string, timeFrom: string, timeTo: string, vehicleId: string): Observable<ITelocData[]> {
        return this.http.get(baseTelocUrl + 'getTelocForRoute', {
            params: {
                tenant: TENANT,
                routeCode,
                from: timeFrom,
                to: timeTo,
                vehicleId,
            },
        });
    }

    getRouteList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseTelocUrl + 'getRouteList', { params: { tenant: TENANT } });
    }

    telocByEvent(
        eventType: string,
        timeFrom: string,
        timeTo: string,
        vehicleId: string,
        keyword?: string
    ): Observable<IEventData[]> {
        let params: any = {
            tenant: TENANT,
            eventType,
            from: timeFrom,
            to: timeTo,
            vehicleId,
        };

        if (keyword) {
            params.keyword = keyword;
        }

        return this.http.get(baseTelocUrl + 'getTelocForEvent', { params: params });
    }

    telocByEventEA2(
        eventType: string[],
        timeFrom: string,
        timeTo: string,
        vehicleId: string[]
    ): Observable<IEventData[]> {
        return this.http.get(baseTelocUrl + 'getTelocForEventEA2', {
            params: {
                tenant: TENANT,
                eventType,
                from: timeFrom,
                to: timeTo,
                vehicleId,
            },
        });
    }

    telocByEventPivotEA2(
        eventType: string[],
        timeFrom: string,
        timeTo: string,
        vehicleId: string[],
        testBenchEnabled: boolean
    ): Observable<IEventEntity[]> {
        // We have to format vehicles because backed is expecting a string ("357_A, 357_B, 321_A, ..."), not an array
        const formattedVehicles = vehicleId.toString().replace(' ', ',');

        // We have to format eventType as well like vehicles above
        const formattedEventTypes = eventType.join(',');

        return this.http.get(baseTelocUrl + 'getTelocForEventPivot', {
            params: {
                tenant: TENANT,
                eventType: formattedEventTypes,
                from: timeFrom,
                to: timeTo,
                vehicleId: formattedVehicles,
                testBenchEnabled,
            },
        });
    }

    getEventColors(): Observable<EventCategory[]> {
        return this.http.get(baseTelocUrl + 'getEventCategoryColors', {
            params: {
                tenant: TENANT,
            },
        });
    }

    telocByEventPivotEA2LandingPage(
        eventType: string[],
        timeFrom: string,
        timeTo: string,
        vehicleId: string[],
        testBenchEnabled: boolean
    ): Observable<IEventEntity[]> {
        // We have to format vehicles because backed is expecting a string ("357_A, 357_B, 321_A, ..."), not an array
        const formattedVehicles = vehicleId.toString().replace(' ', ',');

        // We have to format eventType as well like vehicles above
        const formattedEventTypes = eventType.join(',');

        return this.http.get(baseTelocUrl + 'getTelocForEventPivotLandingPage', {
            params: {
                tenant: TENANT,
                eventType: formattedEventTypes,
                from: timeFrom,
                to: timeTo,
                vehicleId: formattedVehicles,
                testBenchEnabled,
            },
        });
    }

    telocByEventPivot(
        eventType: string,
        timeFrom: string,
        timeTo: string,
        vehicleId: string[],
        keyword?: string
    ): Observable<IEventData[]> {
        let params: any = {
            tenant: TENANT,
            eventType,
            from: timeFrom,
            to: timeTo,
            vehicleId,
        };

        if (keyword) {
            params.keyword = keyword;
        }

        return this.http.get(baseTelocUrl + 'getTelocForEventPivot', { params: params });
    }

    getEventList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseTelocUrl + 'getEventList', {
            params: {
                tenant: TENANT,
            },
        });
    }

    getTelocFile(telocFile: string, confIssue: string, vehicleId: string): Observable<any> {
        return this.http.get(baseTelocUrl + 'getFileBin', {
            params: {
                filepath: telocFile,
                confIssue: confIssue,
                vehicleId,
            },
        });
    }

    //templates
    getTemplateList(): Observable<ICriteriaDataItem[]> {
        return this.http.get(baseTemplateUrl + 'list');
    }

    getAllTemplateList(): Observable<ISignalAnalyticsTemplate[]> {
        return this.http.get(baseTemplateUrl + 'all');
    }

    getTemplate(name: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'single/' + name);
    }

    createTemplateEtcs(
        name: string,
        analogSignals: string[],
        digitalSignals: string[],
        otherSignals: string[],
        etcsSignals: string[],
        eventSignals: string[],
        fleetvalue: string,
        columnState: ColumnState[],
        filterModel: any
    ) {
        let payload = JSON.stringify({
            name: name,
            analogSignals: analogSignals,
            digitalSignals: digitalSignals,
            otherSignals: otherSignals,
            etcsSignals: etcsSignals,
            eventSignals: eventSignals,
            isDefault: false,
            fleet: fleetvalue,
            columnState: columnState,
            filterModel: filterModel,
        });

        return this.http.post(baseTemplateUrl + 'save', payload);
    }

    deleteTemplate(name: string) {
        return this.http.get(baseTemplateUrl + 'delete/' + name);
    }

    isTemplateInDb(name: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'existsInDb/' + name);
    }

    setAsDefaultSrv(name: string, fleet: string) {
        return this.http.get(baseTemplateUrl + 'setasdefault', { params: { name, fleet } });
    }

    importTemplates(file: File): Observable<any> {
        let uriString = baseTemplateUrl + 'importtemplates';

        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(uriString, formData, {
            disableAutoContentTypeHader: true,
        });
    }

    exportTemplate(templateId: string) {
        return this.http.get(baseTemplateUrl + 'exporttemplate/' + templateId);
    }

    exportAllTemplates() {
        return this.http.get(baseTemplateUrl + 'exportalltemplates');
    }

    updateTemplateEtcs(
        name: string,
        analogSignals: string[],
        digitalSignals: string[],
        otherSignals: string[],
        etcsSignals: string[],
        eventSignals: string[],
        selectedFleetValue: string,
        columnState: ColumnState[],
        filterModel: any
    ) {
        let uriString = baseTemplateUrl + 'update';
        let payload = JSON.stringify({
            name: name,
            analogSignals: analogSignals,
            digitalSignals: digitalSignals,
            otherSignals: otherSignals,
            etcsSignals: etcsSignals,
            eventSignals: eventSignals,
            fleet: selectedFleetValue,
            columnState: columnState,
            filterModel: filterModel,
        });

        return this.http.post(uriString, payload);
    }

    getDefaultTemplate(fleet: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'default', { params: { fleet } });
    }

    // manual import (single file)
    fileUploadSrv(file: File): Observable<any> {
        let formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(baseManualImportUrl + 'single', formData);
    }

    // manual import (multiple files)
    batchFileUploadSrv(files: FileList | File): Observable<any> {
        let formData = new FormData();
        if (files instanceof FileList) {
            for (let i: number = 0; i < files.length; i++) {
                formData.append('file', files[i], files[i].name);
            }
        } else {
            formData.append('file', files, files.name);
        }
        return this.http.post(baseManualImportUrl + 'batch', formData);
    }

    // manual import with track upload progress (multiple files)
    batchFileUploadTrackProgress(files: FileList | File, user: string): Observable<any> {
        let formData = new FormData();
        if (files instanceof FileList) {
            for (let i: number = 0; i < files.length; i++) {
                formData.append('file', files[i], files[i].name);
            }
        } else {
            formData.append('file', files, files.name);
        }
        //   return this.http.post(uriString, formData).pipe(
        //     map((res: Response) => {
        //         return res.json();
        //     })
        // );
        // return this.httpNative.post('https://file.io', formData, {reportProgress: true, observe: 'events'});
        return this.httpClient.post(baseManualImportUrl + 'batchForUser?user=' + user, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    importRailMLFilesCancel: Subject<void> = new Subject<void>();

    importRailMLUploadSrv(files: FileList | File): Observable<any> {
        let formData = new FormData();
        if (files instanceof FileList) {
            for (let i: number = 0; i < files.length; i++) {
                formData.append('file', files[i], files[i].name);
            }
        } else {
            formData.append('file', files, files.name);
        }
        return this.http.post(baseManualImportUrl + 'railml', formData, {
            disableAutoContentTypeHader: true,
            cancelSubject: this.importRailMLFilesCancel,
        });
    }

    cancelRailMLUpload() {
        console.log('CANCEL RAIL ML UPLOAD REQUEST');
        this.importRailMLFilesCancel.next();
        this.importRailMLFilesCancel.complete();
        console.log(this.importRailMLFilesCancel);
    }

    saveUserSignalSettingsSrv(
        color: string,
        username: string,
        name: string,
        name2: string,
        type: string,
        comment: string,
        unit: string,
        datatype: string,
        rangemin: string,
        rangemax: string
    ) {
        let payload = JSON.stringify({
            color: color,
            username: username,
            name: name,
            name2: name2,
            type: type,
            comment: comment,
            unit: unit,
            datatype: datatype,
            rangemin: rangemin,
            rangemax: rangemax,
        });
        return this.http.post(baseTemplateUrl + 'updatesettings', payload);
    }

    getUserSignalSettingsSrv(userName: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'loadusersettings', { params: { user: userName } });
    }

    downloadSpadEventConfigurationFile() {
        var url = baseUrl + '/EvaPlusRest/eventGenerator/downloadSpadEventConfigurationFile';
        window.open(url);
    }

    saveAllUserSettingsToTemplateSrv(selectedtemplate: string, signalsettings: any[]) {
        let payload = JSON.stringify({
            selectedtemplate: selectedtemplate,
            signalSettings: signalsettings,
        });

        return this.http.post(baseTemplateUrl + 'savesignalsettings', payload);
    }

    // removeAllSignalSettingsFromTemplateSrv(template: string){
    //   var uriString = '/EvaPlusRest/templates/removesettingstemplate?template=' + template;
    //   return this.http.get(baseUrl + uriString)
    //     .map((res: Response) => {
    //       return res.json();
    //   });
    // }

    removeAllSignalSettingsUserSrv(user: string) {
        return this.http.get(baseTemplateUrl + 'removesettingsuser', { params: { user } });
    }

    removeUserSignalSettingsSrv(user: string, signal: string) {
        return this.http.get(baseTemplateUrl + 'removeusersettings1sig', {
            params: { user, signal },
        });
    }

    getTemplateSignalSettingsSrv(template: string): Observable<any> {
        return this.http.get(baseTemplateUrl + 'loadsettingstemplate', { params: { template } });
    }
}
