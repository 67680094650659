import { EvaPlusUser } from '../reducer/eva-plus-user.state';

export class SetAuthorizationToken {
    static readonly type = '[EvaPlusUser] Set Authroization Token';
    constructor(public authorizationToken: string) {}
}

export class SetCurrentUser {
    static readonly type = '[EvaPlusUser] Set Current User';
    constructor(public currentUser: EvaPlusUser | null) {}
}
