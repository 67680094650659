import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

export interface IChartData {
    name: string;
    series: IChartDataItem[];
}

export interface IChartDataItem {
    value: number;
    name: string;
}

export interface IChartDataNew {
    data: IChartDataItemNew[];
    label: string;
}

export interface IChartDataItemNew {
    x: string;
    y: number;
}

const baseUrl = '/EvaPlusRest/chartdata/';

@Injectable({ providedIn: 'root' })
export class ChartdataService {
    constructor(private http: EvaPlusHttpService) {}

    staticQuery(): Observable<any> {
        return this.http.get(baseUrl + 'getSignals');
    }

    staticQueryNew(): Observable<any> {
        return this.http.get(baseUrl + 'getSignalsNew');
    }
}
