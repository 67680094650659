import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';
import { AuthConfig } from 'angular-oauth2-oidc';
import { map, take } from 'rxjs/operators';

export interface RealmConfig {
  clientId: string;
  clientSecret: string;
  scope: string;
  issuer: string
}

@Injectable({
  providedIn: 'root'
})
export class OauthConfigService {

  private readonly BASE_URL = '/EvaPlusRest/oauth/config';

  constructor(private evaHttp: EvaPlusHttpService) { }

  public getConfig(): Observable<AuthConfig> {
    return this.fetchConfig().pipe(
      take(1),
      map((config: RealmConfig): AuthConfig => this.mapToAuthConfig(config))
    )
  }

  private fetchConfig(): Observable<RealmConfig> {
    return this.evaHttp.get(this.BASE_URL);
  }

  private mapToAuthConfig(config: RealmConfig): AuthConfig {
    return {
      clientId: config.clientId,
      dummyClientSecret: config.clientSecret,
      scope: config.scope,
      issuer: config.issuer,
      redirectUri: `${window.location.origin}/acquire-token`,
      responseType: 'code'
    }
  }
}
