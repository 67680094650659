import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';
import produce from 'immer';
import {
    ClearRecordForDetails,
    ResetStoreGrid,
    SetColumnState,
    SetEtcsRowSelected,
    SetFilterModel,
    SetLatestRecordFromGrid,
    SetNodeCounter,
    SetRowAgGridData,
    SetRecordForDetails,
    SetSelectedNewGrid,
    SetTableColumns,
    SetTableData,
    SetTableDataLength,
    SetAndSortSelectedNewGrid,
} from '../action/grid.actions';
import { LoadTemplateData, SetVehicle } from '../action/toolbar.actions';

export interface SaGridStateModel {
    nodeCounter: number;
    tableDataLength: number;
    columnState: ColumnState[];
    filterModel: any;
    latestRecordFromGrid: any;
    recordForDetails: any[];
    etcsRowSelected: boolean;
    selectedNewGrid: any[];
    rowAgGridData: any[];
    tableData: any[];
    tableColumns: any[];
}

const defaultState: SaGridStateModel = {
    nodeCounter: 0,
    tableDataLength: 0,
    columnState: [],
    filterModel: {},
    latestRecordFromGrid: {},
    recordForDetails: [],
    etcsRowSelected: false,
    selectedNewGrid: [],
    rowAgGridData: [],
    tableData: [],
    tableColumns: [],
};

@Injectable()
@State<SaGridStateModel>({
    name: 'saGrid',
    defaults: defaultState,
})
export class SaGridState {
    @Action(SetNodeCounter)
    setNodeCounter(ctx: StateContext<SaGridStateModel>, action: SetNodeCounter) {
        ctx.patchState({ nodeCounter: action.num });
    }

    @Action(SetTableDataLength)
    setTableDataLength(ctx: StateContext<SaGridStateModel>, action: SetTableDataLength) {
        ctx.patchState({ tableDataLength: action.num });
    }

    @Action(SetColumnState)
    setColumnState(ctx: StateContext<SaGridStateModel>, action: SetColumnState) {
        ctx.patchState({ columnState: action.columnState });
    }

    @Action(SetFilterModel)
    setFilterModel(ctx: StateContext<SaGridStateModel>, action: SetFilterModel) {
        ctx.patchState({ filterModel: action.filterModel });
    }

    @Action(SetLatestRecordFromGrid)
    setLatestRecordFromGrid(ctx: StateContext<SaGridStateModel>, action: SetLatestRecordFromGrid) {
        ctx.patchState({ latestRecordFromGrid: action.latestRecordFromGrid });
    }

    @Action(ClearRecordForDetails)
    clearRecordForDetails(ctx: StateContext<SaGridStateModel>, action: ClearRecordForDetails) {
        ctx.patchState({ recordForDetails: [] });
    }

    @Action(SetRecordForDetails)
    setRecordForDetails(ctx: StateContext<SaGridStateModel>, action: SetRecordForDetails) {
        ctx.patchState({ recordForDetails: action.items });
    }

    @Action(SetEtcsRowSelected)
    setEtcsRowSelected(ctx: StateContext<SaGridStateModel>, action: SetEtcsRowSelected) {
        ctx.patchState({ etcsRowSelected: action.etcsRowSelected });
    }

    @Action(SetSelectedNewGrid)
    setSelectedNewGrid(
        ctx: StateContext<SaGridStateModel>,
        { selectedNewGrid }: SetSelectedNewGrid
    ) {
        ctx.patchState({ selectedNewGrid: selectedNewGrid });
        if (selectedNewGrid.length > 0) {
            ctx.patchState({ latestRecordFromGrid: selectedNewGrid[selectedNewGrid.length - 1] });
        }
    }

    @Action(LoadTemplateData)
    loadTemplateData(ctx: StateContext<SaGridStateModel>, { templateData }: LoadTemplateData) {
        if (templateData) {
            ctx.patchState({
                filterModel: templateData.filterModel,
                columnState: templateData.columnState,
            });
        }
    }

    @Action(SetAndSortSelectedNewGrid)
    setAndSortSelectedNewGrid(
        ctx: StateContext<SaGridStateModel>,
        { selectedNewGrid }: SetAndSortSelectedNewGrid
    ) {
        selectedNewGrid.sort(function (a, b) {
            var nameA = a.recordid,
                nameB = b.recordid;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        ctx.patchState({ selectedNewGrid: selectedNewGrid });
        if (selectedNewGrid.length > 0) {
            ctx.patchState({ latestRecordFromGrid: selectedNewGrid[selectedNewGrid.length - 1] });
        }
    }

    @Action(SetRowAgGridData)
    setRowAgGridData(ctx: StateContext<SaGridStateModel>, action: SetRowAgGridData) {
        ctx.patchState({ rowAgGridData: action.rowAgGridData });
    }

    @Action(SetTableData)
    setTableData(ctx: StateContext<SaGridStateModel>, action: SetTableData) {
        ctx.patchState({ tableData: action.tableData });
    }

    @Action(SetTableColumns)
    setTableColumns(ctx: StateContext<SaGridStateModel>, action: SetTableColumns) {
        ctx.patchState({ tableColumns: action.tableColumns });
    }

    @Action(SetVehicle)
    setVehicle(ctx: StateContext<SaGridStateModel>, action: SetVehicle) {
        ctx.patchState({
            latestRecordFromGrid: {},
            recordForDetails: [],
            etcsRowSelected: false,
        });
    }

    @Action(ResetStoreGrid)
    resetStoreChart(ctx: StateContext<SaGridStateModel>, action: ResetStoreGrid) {
        ctx.setState(defaultState);
    }

    @Selector()
    static recordForDetails(state: SaGridStateModel) {
        return state.recordForDetails;
    }

    @Selector()
    static etcsRowSelected(state: SaGridStateModel) {
        return state.etcsRowSelected;
    }
}
