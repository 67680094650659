import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from 'primeng/dialog';

export interface DialogData {
    text: string;
    name: string;
}

@Component({
    selector: 'preview-click-dialog',
    templateUrl: 'preview-click-dialog.html',
})
export class PreviewClickDialog {
    constructor(
        public dialogRef: MatDialogRef<PreviewClickDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close({ data: 'canceled' });
    }
    onConfirmed(): void {
        this.dialogRef.close({ data: 'confirmed' });
    }
}
