import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl = '/EvaPlusRest/dashboards/';

@Injectable({ providedIn: 'root' })
export class DashboardSignalService {
    constructor(private http: EvaPlusHttpService) {}

    query(): any {
        return this.http.get(baseUrl + 'getSignalChart');
    }

    get(id: string): any {
        return this.http.get(baseUrl + 'getSignalChart'); /*** .pipe(
         map((res) => {
     let item: any;

     res.json().forEach((s: any) => {

       if (s.item_id === id) {
         item = s;
       }
     });

     return item;
   })
         );*/
    }

    myGet(): any {
        return this.http.get(baseUrl + 'getSignalChart');
    }

    myHeatMapGet(event_type: string): any {
        if (!event_type) {
            event_type = 'ALL';
        }
        return this.http.get(baseUrl + 'getEventHeatmap', { params: { event: event_type } });
    }

    GetBrushHeatMap(): any {
        //  http://localhost:8080/EvaPlusRest/dashboards/getBrushHeatmap
        return this.http.get(baseUrl + 'getBrushHeatmap');
    }

    getBrushVehicleList(): Observable<any> {
        return this.http.get(baseUrl + 'getBrushVehicleList');
    }

    GetBrushFiles(vehicle: string, tf: string, tt: string, keyword: string): Observable<any> {
        let options = {
            vehicle,
            from: tf,
            to: tt,
        };

        if (keyword) {
            options['keyword'] = keyword;
        }

        return this.http.get(baseUrl + 'getBrushFiles', { params: options });
    }

    /*public showFile2(fileName: string): void {
    //let reqOpt: RequestOptions = this.getAcmOptions();  //  getAcmOptions is our helper method. Change this line according to request headers you need.
    //reqOpt.responseType = ResponseContentType.Blob;
    let myHeaders = new Headers({ 'Accept': 'application/octet-stream'});
    let options = new RequestOptions({ headers: myHeaders});
    this.http
      .get(this.getMockApi() + "/EvaPlusRest/dashboards/getBrushFile?name=" + fileName, options)
      .subscribe(
        data => this.downloadFile(data),
        error => alert("Error downloading file!"),
        () => console.log("OK!!")
      );
  }*/

    // public showFile(fileName: string): void {
    //   let url =  this.getMockApi() + "/EvaPlusRest/dashboards/getBrushFile?name=" + fileName
    //     window.open(url);
    // }

    public showFile(fileName: string): Observable<any> {
        return this.http.get(baseUrl + 'getBrushFile', {
            params: { responseType: 2, name: fileName },
        });
    }

    myDataExplorerMap(eventType: string): any {
        return this.http.get('/EvaPlusRest/dataexplorer/getMap', {
            params: { period: eventType ? eventType : 'ALL' },
        });
    }

    myDataCoverageMap(data_type: string, memory_type: string = '', date: string = ''): any {
        let options = {
            period: data_type,
        };

        if (memory_type.length > 0) {
            options['memory'] = memory_type;
        }

        if (date.length > 0) {
            options['yearMonth'] = date;
        }

        return this.http.get('/EvaPlusRest/datacoverage/getMap', { params: options });
    }

    insertTelocFileEvaPlus(
        name: string,
        date: string,
        memory: string,
        confIssue: string,
        vehicleId: string
    ): Observable<any> {
        let payload = JSON.stringify({
            file: name,
            date: date,
            memory: memory,
            confIssue: confIssue,
            vehicleId: vehicleId,
        });
        return this.http.post('/EvaPlusRest/eva2api/insert', payload);
    }
}
