import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';

const baseUrl: string = '/EvaPlusRest/usermonitoring/';

@Injectable({ providedIn: 'root' })
export class UserMonitoringService {
    constructor(private http: EvaPlusHttpService) {}

    getUserList(): Observable<any> {
        return this.http.get(baseUrl + 'getUserList');
    }

    getUserActivitiesLogList(users: string[], timefrom: string, timeto: string): Observable<any> {
        return this.http.get(baseUrl + 'getUserActivitiesLogList', {
            params: {
                users,
                timefrom,
                timeto,
            },
        });
    }

    getUserStatusList(): Observable<any> {
        return this.http.get(baseUrl + 'getUserStatusList');
    }

    disconnectUser(users: string[]): Observable<any> {
        return this.http.get(baseUrl + 'disconnectUser', {
            params: { users: users },
            responseType: 'text',
        });
    }

    isQueryTemplateInDb(name: string): Observable<any> {
        return this.http.get(baseUrl + 'queryTemplateExistsInDb', {
            params: { name },
        });
    }

    saveQueryToTemplateSrvNew(
        userName: string,
        timefrom: Date,
        timeto: Date,
        templateName,
        update: boolean,
        selectedUserValue: string[] = []
    ) {
        let payload = JSON.stringify({
            user: userName,
            timefrom: timefrom,
            timeto: timeto,
            name: templateName,
            update: update,
            selectedUsers: selectedUserValue,
        });
        return this.http.post(baseUrl + 'saveQueryTemplateNew', payload);
    }

    etcsLoadQueryTemplateListSrvNew(user: string) {
        return this.http.get(baseUrl + 'getTemplateListNew', {
            params: { user },
        });
    }

    etcsLoadQueryTemplateSrvNew(name: string, user: string) {
        return this.http.get(baseUrl + 'getTemplateNew', {
            params: { name, user },
        });
    }

    userDeleteTemplateSrvNew(name: string, user: string) {
        return this.http.get(baseUrl + 'deleteUserTemplateNew', {
            params: { name, user },
        });
    }

    sendDummyRequest(data: string) {
        return this.http.get('/EvaPlusRest/userMonitoringDummy/dummyService', {
            params: { data },
        });
    }

    getConcurrentUsers(granulation: string, timefrom: string, timeto: string) {
        return this.http.get(baseUrl + 'getConcurrentUsers', {
            params: { granuation: granulation, timefrom, timeto },
        });
    }

    getLicenceLimit() {
        return this.http.get(baseUrl + 'getLicenceLimit');
    }

    updateEmailService(old_email, email: string) {
        let payload = JSON.stringify({ oldEmail: old_email, email: email });
        return this.http.post(baseUrl + 'updateEmail', payload);
    }

    getLastSettings() {
        return this.http.get(baseUrl + 'getLastSettings');
    }

    checkIfUSerMonitoringIsEnables() {
        return this.http.get(baseUrl + 'getIsUserMonitoringActive');
    }
}
