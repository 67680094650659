import { SingleSignalChartDrawingOption } from '../reducer/signal-chart-drawing-options.state';

export class SetPreviewSignalChartDrawingOptions {
    static readonly type = '[SignalChartDrawingOptions] Set Preview Signal Chart Drawing Options';
    constructor(public chartOptions: SingleSignalChartDrawingOption[]) {}
}

export class SetAnalogSignalChartDrawingOptions {
    static readonly type = '[SignalChartDrawingOptions] Set Analog Signal Chart Drawing Options';
    constructor(public chartOptions: SingleSignalChartDrawingOption[]) {}
}

export class SetDigitalSignalChartDrawingOptions {
    static readonly type = '[SignalChartDrawingOptions] Set Digital Signal Chart Drawing Options';
    constructor(public chartOptions: SingleSignalChartDrawingOption[]) {}
}

export class SetEventSignalChartDrawingOptions {
    static readonly type = '[SignalChartDrawingOptions] Set Event Signal Chart Drawing Options';
    constructor(public chartOptions: SingleSignalChartDrawingOption[]) {}
}

export class AddNewSignalChartDrawingOption {
    static readonly type = '[SignalChartDrawingOptions] Add New Signal Chart Drawing Option';
    constructor(
        public chartDrawingOption: SingleSignalChartDrawingOption,
        public signalType: string
    ) {}
}
