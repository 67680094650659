import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ITreeOptions, KEYS, TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { Subscription } from 'rxjs';
import { FleetManagementService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hr-vehicle-fleet',
    templateUrl: './hr-vehicle-fleet.component.html',
    styleUrls: ['./hr-vehicle-fleet.component.scss'],
})
export class HrVehicleFleetComponent implements OnInit, OnChanges {
    @Input() multiple: boolean;
    @Input() fleet: boolean;
    @Input() minimal: boolean;
    @Input() all: boolean;
    @Input() defaultAll: boolean;
    @Input() vehicles: string | string[] = [];
    @Input() isNewUI: boolean = false;
    @Output() vehicleFleetSelected = new EventEmitter();
    @Output() vehicleFleetNameSelected = new EventEmitter();
    @Output() vehicleFleetStructureSelected = new EventEmitter();

    iconVehicle: string;
    isLine: boolean = false;
    isCompact: boolean = false;
    changeBySet: boolean = false;
    placeholderText = '';
    quietSetTree2: any = [];
    markedToBeCleared: boolean = false;

    @ViewChild('tree1', { static: false }) tree1: TreeComponent;
    @ViewChild('tree2', { static: false }) tree2: TreeComponent;
    @ViewChild('tree3', { static: false }) tree3: TreeComponent;

    @ViewChild('singletoolbar', { static: false }) singletoolbar: ElementRef<HTMLElement>;
    @ViewChild('multitoolbar', { static: false }) multitoolbar: ElementRef<HTMLElement>;
    @ViewChild('fleettoolbar', { static: false }) fleettoolbar: ElementRef<HTMLElement>;

    state1: any;
    state2: any;
    state3: any;

    keyboardArrow: boolean = false;
    showSingle: boolean = false;
    showMulti: boolean = false;
    showFleet: boolean = false;
    filterValue = '';
    nodes = [];
    data: any;
    readyData: any = [];
    fleetData: any = [];
    init: boolean = false;

    subGetFleetManagementSettings: Subscription = new Subscription();

    public get showPlaceholder(): boolean {
        return !this.vehicles || this.vehicles?.length === 0;
    }

    constructor(private _fleetManagementService: FleetManagementService, private translate: TranslateService) {
        this.getConfiguration();
        this.subGetFleetManagementSettings = this._fleetManagementService
            .getFleetMagementSettings()
            .subscribe((res: any) => {
                res.forEach((element) => {
                    if (element.children && element.children?.length > 0) {
                        element.children.sort((a, b) => {
                            const nameA = a.name.toLowerCase();
                            const nameB = b.name.toLowerCase();

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    if (element.id != 999) {
                        const name = element.name;
                        const children = element.children?.map((child): { name: string } => ({
                            name: child.name,
                        }));

                        this.readyData.push({ name, children });
                        this.fleetData.push({ name });
                    }
                });
            });
    }

    filterVehicles(filter: any) {
        this.tree1.treeModel.filterNodes(filter);
        this.filterValue = filter;
        if (this.filterValue == '') {
            this.tree1.treeModel.collapseAll();
        }
    }

    getConfiguration() {
        this.translate.get('ICON').subscribe((icon) => {
            this.iconVehicle = icon.Vehicle;
        });
    }

    ngOnDestroy() {
        this.subGetFleetManagementSettings.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.changeBySet) {
            this.changeBySet = false;
            return;
        }
        this.quietSetTree2 = changes.vehicles?.currentValue ?? [];
    }

    showToolbar() {
        this.keyboardArrow = !this.keyboardArrow;
        if (this.multiple) {
            this.showMulti = !this.showMulti;
            if (this.showMulti) {
                setTimeout(() => {
                    this.multitoolbar.nativeElement.focus();
                }, 10);
            }
            return;
        }

        if (this.fleet) {
            this.showFleet = !this.showFleet;
            if (this.showFleet) {
                setTimeout(() => {
                    this.fleettoolbar.nativeElement.focus();
                }, 0);
            }
            return;
        }

        this.showSingle = !this.showSingle;
        if (this.showSingle) {
            setTimeout(() => {
                this.singletoolbar.nativeElement.focus();
            }, 0);
        }
    }

    gotFocus: boolean = false;

    hideSingle($event) {
        this.gotFocus = false;
        setTimeout(() => {
            if (!this.gotFocus) {
                this.clearFilters(this.tree1);
                this.showSingle = false;
                this.keyboardArrow = false;
            }
        }, 170);
    }

    hideFleet($event) {
        this.gotFocus = false;
        setTimeout(() => {
            if (!this.gotFocus) {
                this.clearFilters(this.tree3);
                this.showFleet = false;
                this.keyboardArrow = false;
            }
        }, 170);
    }

    hide($event) {
        this.gotFocus = false;
    }

    show($event) {
        this.gotFocus = true;
    }

    showTB($event) {
        this.gotFocus = true;
    }

    hideTB($event) {
        this.gotFocus = false;
        setTimeout(() => {
            if (!this.gotFocus) {
                this.clearFilters(this.tree2);
                this.showMulti = false;
                this.keyboardArrow = false;
            }
        }, 200);
    }

    showIN($event) {
        this.gotFocus = true;
    }

    hideIN($event) {
        this.gotFocus = false;
    }

    showDV($event) {
        this.gotFocus = true;
    }

    hideDV($event) {
        this.gotFocus = false;
    }

    showTR($event) {
        this.gotFocus = true;
    }

    hideTR($event) {
        this.gotFocus = false;
    }

    onEventevent($event) {
        this.gotFocus = true;
    }

    getId($event) {
        if ($event.srcElement == undefined) {
            return $event;
        }
        return $event.srcElement.id;
    }

    ngOnInit() {
        if (this.multiple == undefined) {
            this.multiple = false;
        }
        if (this.fleet == undefined) {
            this.fleet = false;
        }
        if (this.minimal == undefined) {
            this.minimal = false;
        }
        if (this.defaultAll == undefined) {
            this.defaultAll = false;
        }
        if (this.all == undefined) {
            this.all = false;
        }
        // if (this.vehicles == undefined) {
        //     this.vehicles = '';
        // }

        if (typeof this.multiple == 'string') {
            this.multiple = true;
        }
        if (typeof this.fleet == 'string') {
            this.fleet = true;
        }
        if (typeof this.minimal == 'string') {
            this.minimal = true;
        }
        if (typeof this.defaultAll == 'string') {
            this.defaultAll = true;
        }
        if (typeof this.all == 'string') {
            this.all = true;
        }

        this.minimal ? (this.isCompact = true) : (this.isLine = true);
        this.fleet ? (this.nodes = this.fleetData) : (this.nodes = this.readyData);

        if (this.defaultAll) {
            this.vehicles = ['ALL'];
            this.init = true;
        }

        if (this.defaultAll || this.all) {
            this.nodes.unshift({ name: 'ALL' });
        }

        this.translate.get('HR-VEHICLE-FLEET').subscribe((l) => {
            const labelVehicle = l.labelVehicle;
            const labelVehicles = l.labelVehicles;
            const labelFleet = l.labelFleet;
            const labelFleets = l.labelFleets;
            if (this.multiple && !this.fleet) {
                this.placeholderText = labelVehicles; // Select vehicles
            } else if (!this.multiple && this.fleet) {
                this.placeholderText = labelFleet; // Select fleet
            } else if (this.multiple && this.fleet) {
                this.placeholderText = labelFleets; // Select fleets
            } else {
                this.placeholderText = labelVehicle; // Select vehicle
            }
        });
    }

    setMarkedToBeCleared() {
        this.markedToBeCleared = true;
    }

    initialized() {
        if (this.markedToBeCleared) {
            this.markedToBeCleared = false;
            this.clearAllNodes();
            this.tree2.treeModel.collapseAll();
        }

        if (this.quietSetTree2.length > 0) {
            if (this.quietSetTree2.length === 1 && this.quietSetTree2[0] === 'ALL') {
                this.tree2.treeModel.doForAll((node: TreeNode) => node.setIsSelected(true));
                this.quietSetTree2 = [];
            } else {
                this.tree2.treeModel.doForAll((node: TreeNode) => {
                    node.setIsSelected(this.quietSetTree2.includes(node.data.name));
                });
                setTimeout(() => {
                    this.quietSetTree2 = [];
                }, 200);
            }
        }

        if (!this.init) {
            return;
        }
        if (this.tree2) {
            this.tree2.treeModel.doForAll((node: TreeNode) => node.setIsSelected(true));
        }
        if (this.tree3) {
            this.tree3.treeModel.doForAll((node: TreeNode) => node.setIsSelected(true));
        }
        this.init = false;
    }

    optionsSingle = {
        actionMapping: {
            mouse: {
                click: (tree, node, $event) => {
                    if (node.hasChildren) {
                        node.expandAll();
                    } else {
                        this.vehicles = node.data.name;
                        this.changeBySet = true;
                        this.vehicleFleetNameSelected.emit(node.parent.data.name);
                        this.vehicleFleetSelected.emit(this.vehicles);
                        this.showSingle = false;
                        this.keyboardArrow = false;
                    }
                },
            },
            keys: {
                [KEYS.ENTER]: (tree, node, $event) => {
                    node.expandAll();
                },
            },
        },
    };

    optionsMulti: ITreeOptions = {
        useCheckbox: true,
        actionMapping: {
            mouse: {
                click: (tree, node: TreeNode, $event) => {
                    node.isSelected ? node.setIsSelected(false) : node.setIsSelected(true);

                    if (node.data.name == 'ALL') {
                        !node.isSelected ? this.clearAllNodes() : this.selectAllNodes();
                    } else {
                        if (!node.isSelected) {
                            this.unselectAllNodeIfSelected();
                        }
                    }
                },
                checkboxClick: (tree, node: TreeNode, $event) => {
                    this.gotFocus = true;
                    node.isSelected ? node.setIsSelected(false) : node.setIsSelected(true);

                    // Check is clicked checkbox is related to 'ALL' node
                    if (node.data.name == 'ALL') {
                        !node.isSelected ? this.clearAllNodes() : this.selectAllNodes();
                    } else {
                        if (!node.isSelected) {
                            this.unselectAllNodeIfSelected();
                        } else {
                            const nodesLength = this.tree2.treeModel.nodes.length - 1;
                            let selectedNodesLength = 0;
                            let allNodeId: number | null = null;

                            this.tree2.treeModel.nodes.forEach((rNode) => {
                                if (rNode.name !== 'ALL') {
                                    const id = rNode.id;
                                    if (this.tree2.treeModel.getNodeById(id).isSelected) {
                                        selectedNodesLength++;
                                    }
                                } else {
                                    allNodeId = rNode.id;
                                }
                            });

                            if (allNodeId && nodesLength === selectedNodesLength) {
                                this.tree2.treeModel.getNodeById(allNodeId).setIsSelected(true);
                            }
                        }
                    }
                },
            },
        },
    };

    optionsFleet: ITreeOptions = {
        actionMapping: {
            mouse: {
                click: (tree, node, $event) => {
                    this.vehicles = node.data.name;
                    this.changeBySet = true;
                    this.vehicleFleetSelected.emit(this.vehicles);
                    this.showFleet = false;
                    this.keyboardArrow = false;
                },
            },
            keys: {
                [KEYS.ENTER]: (tree, node, $event) => {
                    node.expandAll();
                },
            },
        },
    };

    isNodeDisabled(node: TreeNode) {
        return node.data.children == undefined;
    }

    clickMultiSelected() {
        const selectedVehicles: string[] = [];
        const selectedVehicleFleets: string[] = [];
        const selectedVehicleFleetStructure: { [key: string]: string[] } = {};

        // Get all names from the selected vehicles
        this.tree2.treeModel.selectedLeafNodes.forEach((node: TreeNode) => {
            selectedVehicles.push(node.data.name);

            if (!selectedVehicleFleets.includes(node.parent.data.name)) {
                selectedVehicleFleets.push(node.parent.data.name);
            }

            if (selectedVehicleFleetStructure.hasOwnProperty(node.parent.data.name)) {
                selectedVehicleFleetStructure[node.parent.data.name].push(node.data.name);
            } else {
                selectedVehicleFleetStructure[node.parent.data.name] = [node.data.name];
            }
        });

        this.vehicles = selectedVehicles;

        this.vehicleFleetSelected.emit(selectedVehicles);
        this.vehicleFleetNameSelected.emit(selectedVehicleFleets);
        this.vehicleFleetStructureSelected.emit(selectedVehicleFleetStructure);

        this.changeBySet = true;
        this.clearFilters(this.tree2);
        this.keyboardArrow = false;
        this.showMulti = false;
    }

    selectAllNodes() {
        if (this.tree2) {
            this.tree2.treeModel.doForAll((node: TreeNode) => node.setIsSelected(true));
        }
    }

    clearAllNodes() {
        if (this.tree2) {
            this.tree2.treeModel.doForAll((node: TreeNode) => node.setIsSelected(false));
        }
    }

    private clearFilters(tree: TreeComponent): void {
        if (tree) {
            tree?.treeModel.clearFilter();
        }
        this.filterValue = '';
    }

    private unselectAllNodeIfSelected(): void {
        // Get ALL node id
        const allNodeId: number | undefined = this.tree2.treeModel.nodes.find(
            (node: { name: string; id: number; children?: any[] }) => node.name === 'ALL'
        )?.id;

        if (allNodeId) {
            // Get node by id
            const allNode: TreeNode | undefined = this.tree2.treeModel.getNodeById(allNodeId);

            // If All node is selected, unselect ALL node
            if (allNode && allNode.isSelected) {
                allNode.setIsSelected(false);
            }
        }
    }
}
