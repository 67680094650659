import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DashboardsConfigurationService, SignalEtcsService } from '../../../../services';
import { SaUtilsService } from '../../../../services/sa-utils.service';
import {
    IChartDataComplete,
    ILastTs,
    IPreviewChartParams,
    ISelectedSignals,
    IVehicleConfigurationParams,
} from '../../../../services/signal.service';
import {
    ClearSignalData,
    GetData,
    GetLastDateForVehicle,
    GetPreviewData,
    GetTimeZone,
    OpenSaForParams,
    ResetSignalAnalyticsModule,
    SetCdConfigIssue,
    SetLastTimestampForVehicle,
    SetSelectedTemplate,
    SetSignalSelectedTime,
    SetSignalsTime,
    SetTimeStampFromSearchResultAdjusted,
    SetUserTemplates,
} from '../action/signal-analytics-module.actions';
import { SetSelectedSignalBookmark } from '../action/signal-bookmarks.actions';
import { SaCriteriaState } from './criteria.state';
import { SignalBookmarksStateModel } from './signal-bookmarks.state';
import { SignalTemplateStateModel } from './signal-template.state';
import { SaToolbarState } from './toolbar.state';
import * as momentTimezone from 'moment-timezone';
import { LoadTemplateData, SetVehicle } from '../action/toolbar.actions';
import { MomentTimezoneDatePipe } from '../../../pipes/moment-timezone-date/moment-timezone-date.pipe';
import { SaCanvasState } from './canvas.state';
import { CalculatePreviewChart } from '../action/canvas.actions';
import { SignalSettingsStateModel } from './signal-settings.state';
import { SignalChartDrawingOptionsStateModel } from './signal-chart-drawing-options.state';

export interface SignalAnalyticsModuleStateModel {
    selectedTemplate: SignalTemplateStateModel | null; // selected template,
    userTemplates: SignalTemplateStateModel[];
    signalSettings: SignalSettingsStateModel;
    bookmarks: SignalBookmarksStateModel;
    signalChartDrawingOptions: SignalChartDrawingOptionsStateModel;
    signalsTime: {
        from: string;
        to: string;
    } | null;
    timeZone: string;
    signalSelectedTime: number;
    lastTimestampForVehicle: number;
    timeStampFromSearchResultAdjusted: number;
    cdConfigIssue: string;
    previewChartData: IChartDataComplete | null;
    chartData: IChartDataComplete | null;
    toggleSignalSettingsButtonsDisabled: boolean;
    signalSettingLoadedInOnInit: boolean;
}

const defaultState = {
    selectedTemplate: null,
    userTemplates: [],
    signalSettings: {} as SignalSettingsStateModel,
    bookmarks: {} as SignalBookmarksStateModel,
    signalChartDrawingOptions: {} as SignalChartDrawingOptionsStateModel,
    signalsTime: null,
    timeZone: null,
    signalSelectedTime: 0,
    lastTimestampForVehicle: 0,
    timeStampFromSearchResultAdjusted: 0,
    cdConfigIssue: '',
    previewChartData: null,
    chartData: null,
    toggleSignalSettingsButtonsDisabled: false,
    signalSettingLoadedInOnInit: false,
};

@Injectable()
@State<SignalAnalyticsModuleStateModel>({
    name: 'signalAnalyticsModule',
    defaults: defaultState,
})
export class SignalAnalyticsModuleState {
    constructor(
        private _signalServiceNew: SignalEtcsService,
        private store: Store,
        private _saUtils: SaUtilsService,
        private _dashboardsConfigurationService: DashboardsConfigurationService,
        private momentTimezoneDatePipe: MomentTimezoneDatePipe,
        private datePipe: DatePipe
    ) {}

    @Action(SetSelectedTemplate)
    setSelectedTemplate(ctx: StateContext<SignalAnalyticsModuleStateModel>, { template }: SetSelectedTemplate) {
        ctx.patchState({ selectedTemplate: template });
    }

    @Action(SetUserTemplates)
    setUserTemplates(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetUserTemplates) {
        ctx.patchState({ userTemplates: action.userTemplates });
    }

    @Action(SetSignalsTime)
    setTimeSignals(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetSignalsTime) {
        ctx.patchState({ signalsTime: { from: action.timeFrom, to: action.timeTo } });
    }

    @Action(SetSelectedSignalBookmark)
    setSelectedBookmark(
        ctx: StateContext<SignalAnalyticsModuleStateModel>,
        { selectedBookmark }: SetSelectedSignalBookmark
    ) {
        if (selectedBookmark) {
            ctx.patchState({
                signalsTime: {
                    from: selectedBookmark.timefromSignals,
                    to: selectedBookmark.timetoSignals,
                },
            });
        }
    }

    @Action(SetVehicle)
    setVehicle(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetVehicle) {
        ctx.patchState({ toggleSignalSettingsButtonsDisabled: true });
    }

    @Action(SetSignalSelectedTime)
    setSignalSelectedTime(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetSignalSelectedTime) {
        ctx.patchState({ signalSelectedTime: action.signalSelectedTime });
    }

    @Action(SetLastTimestampForVehicle)
    setLastTimeStampForVehicle(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetLastTimestampForVehicle) {
        ctx.patchState({ lastTimestampForVehicle: action.lastTimestampForVehicle });
    }

    @Action(SetTimeStampFromSearchResultAdjusted)
    setTimeStampFromSearchResultAdjusted(
        ctx: StateContext<SignalAnalyticsModuleStateModel>,
        action: SetTimeStampFromSearchResultAdjusted
    ) {
        ctx.patchState({
            timeStampFromSearchResultAdjusted: action.timeStampFromSearchResultAdjusted,
        });
    }

    @Action(SetCdConfigIssue)
    setSetCdConfigIssue(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: SetCdConfigIssue) {
        ctx.patchState({ cdConfigIssue: action.cdConfigIssue });
    }

    @Action(LoadTemplateData)
    loadTemplateData(ctx: StateContext<SignalAnalyticsModuleStateModel>, { templateData }: LoadTemplateData) {
        if (!templateData) {
            ctx.patchState({
                toggleSignalSettingsButtonsDisabled: false,
                signalSettingLoadedInOnInit: true,
            });
        } else {
            let template = ctx.getState().userTemplates.find((template) => template.id == templateData.id);
            if (template) {
                ctx.patchState({ selectedTemplate: template });
            }
        }
    }

    @Action(GetPreviewData, { cancelUncompleted: true })
    async getPreviewData(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: GetPreviewData) {
        ctx.patchState({ toggleSignalSettingsButtonsDisabled: true });
        const vehicleConfiguration: IVehicleConfigurationParams = this.store.selectSnapshot(
            SaToolbarState.vehicleConfiguration
        );
        const fleetId = this.store.selectSnapshot(SaToolbarState.fleet);
        const previewChartParams: IPreviewChartParams = this.store.selectSnapshot(SaToolbarState.previewChartParams);
        let previewChartDataResponse: IChartDataComplete;
        try {
            previewChartDataResponse = await this._signalServiceNew
                .signalsPreview(
                    fleetId,
                    vehicleConfiguration.vehicle,
                    //this._saUtils.getRecorderSNvalue(vehicleConfiguration.recorder),
                    this.datePipe.transform(previewChartParams.timeFrom, 'dd-MM-yyyy HH:mm'),
                    this.datePipe.transform(previewChartParams.timeTo, 'dd-MM-yyyy HH:mm')
                )
                .toPromise();
        } catch (e) {
            previewChartDataResponse = null;
        }

        ctx.patchState({ previewChartData: previewChartDataResponse });
    }

    @Action(GetData, { cancelUncompleted: true })
    async loadData(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: GetData) {
        const vehicleConfiguration: IVehicleConfigurationParams = this.store.selectSnapshot(
            SaToolbarState.vehicleConfiguration
        );
        const selectedSignals: ISelectedSignals = this.store.selectSnapshot(SaCriteriaState.selectedSignals);
        const time = ctx.getState().signalsTime;
        const fleet = this.store.selectSnapshot(SaToolbarState.fleet);
        if (!time) {
            ctx.patchState({ chartData: null });
            return;
        }

        const timeFromSignals = this.datePipe.transform(time.from, 'yyyy-MM-dd HH:mm:ss');
        const timeToSignals = this.datePipe.transform(time.to, 'yyyy-MM-dd HH:mm:ss');

        try {
            const chartData: IChartDataComplete = await this._signalServiceNew
                .signalsQueryFull(
                    fleet,
                    vehicleConfiguration.vehicle,
                    vehicleConfiguration.memory, // recorder type
                    timeFromSignals,
                    timeToSignals,
                    selectedSignals.analog,
                    selectedSignals.digital,
                    selectedSignals.other,
                    selectedSignals.standing,
                    selectedSignals.etcs,
                    selectedSignals.event,
                    vehicleConfiguration.etcsType
                )
                .toPromise();
            ctx.patchState({ chartData: chartData });
        } catch (e) {
            ctx.patchState({ chartData: null });
        }
    }

    @Action(ClearSignalData)
    clearData(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: ClearSignalData) {
        ctx.patchState({ chartData: null });
    }

    @Action(GetTimeZone)
    async loadTimeZone(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: GetTimeZone) {
        const dashboardJson = await this._dashboardsConfigurationService.getDashboards().toPromise();
        ctx.patchState({ timeZone: dashboardJson.timeZone });
    }

    @Action(GetLastDateForVehicle)
    async getLastDateForVehicle(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: GetLastDateForVehicle) {
        const selectedVehicle: string = this.store.selectSnapshot(SaToolbarState.vehicle);

        // Todo: Handle if server returns an empty object
        const ts: ILastTs = await this._signalServiceNew.lastDateForVehicle(selectedVehicle).toPromise();
        const lastTsForVehicle = ts.timestamp;
        ctx.patchState({ lastTimestampForVehicle: lastTsForVehicle });

        let intervalInMinutes = this.store.selectSnapshot(SaCanvasState.intervalInMinutes);
        let timefromSignals = this.momentTimezoneDatePipe.transform(
            new Date(lastTsForVehicle - (intervalInMinutes / 2) * 60 * 1000),
            'yyyy-MM-dd HH:mm:ss',
            ctx.getState().timeZone
        );

        let timetoSignals = this.momentTimezoneDatePipe.transform(
            new Date(lastTsForVehicle + (intervalInMinutes / 2) * 60 * 1000),
            'yyyy-MM-dd HH:mm:ss',
            ctx.getState().timeZone
        );
        ctx.patchState({ signalsTime: { from: timefromSignals, to: timetoSignals } });
    }

    @Action(CalculatePreviewChart)
    calculatePreviewChart(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: CalculatePreviewChart) {
        ctx.patchState({ lastTimestampForVehicle: null });
    }

    @Action(OpenSaForParams)
    openSaForParams(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: OpenSaForParams) {
        const selectedTs: number = Number(action.time).valueOf();
        let timefromSignals = momentTimezone(selectedTs - 1000 * 60 * 5)
            .tz(ctx.getState().timeZone)
            .format('YYYY-MM-DD HH:mm:ss');
        let timetoSignals = momentTimezone(selectedTs + 1000 * 60 * 5)
            .tz(ctx.getState().timeZone)
            .format('YYYY-MM-DD HH:mm:ss');
        ctx.patchState({ signalsTime: { from: timefromSignals, to: timetoSignals } });
    }

    @Action(ResetSignalAnalyticsModule)
    resetStoreCriteria(ctx: StateContext<SignalAnalyticsModuleStateModel>, action: ResetSignalAnalyticsModule) {
        ctx.setState(defaultState);
    }

    @Selector()
    static templateById(state: SignalAnalyticsModuleStateModel) {
        return (templateId: string) => {
            return state.userTemplates.find((template) => template.id === templateId);
        };
    }

    @Selector()
    static selectedTemplate(state: SignalAnalyticsModuleStateModel) {
        return state.selectedTemplate;
    }

    @Selector()
    static userTemplates(state: SignalAnalyticsModuleStateModel) {
        return state.userTemplates;
    }

    @Selector()
    static timeZone(state: SignalAnalyticsModuleStateModel) {
        return state.timeZone;
    }

    @Selector()
    static timeStamp(state: SignalAnalyticsModuleStateModel) {
        return state.lastTimestampForVehicle;
    }

    @Selector()
    static signalsTime(state: SignalAnalyticsModuleStateModel) {
        return state.signalsTime;
    }

    @Selector()
    static templateByName(state: SignalAnalyticsModuleStateModel) {
        return (templateName: string) => {
            return state.userTemplates.find((template) => template.templateName === templateName);
        };
    }

    resetDateTime(dateTime: Date) {
        dateTime.setHours(0);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
    }
}
