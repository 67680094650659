import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Headers, Response } from '@angular/http';
// import { HttpInterceptorService, RESTService } from '@covalent/http';
import { EvaPlusHttpService } from '../services/http/eva-http.service';

import { Observable } from 'rxjs';

export class IomsAction {
    mongoId: string;
    actionReportId: string;
    appl_user_id: string;
    id: string;
    status: string;
    creationDateMills: number;
    start: number;
    end: number;
    duration: string;
    actionType: string;
    ioms_device_level_id: string;
    request: string;
    description: string;
    reportId: string;
    location: string;
    priority: string;
    approvalDate: number;
    teamId: string;
    assigned: boolean;
    assignedBy: string;
    equipmentIds: string[];
    workInstructionsIds: string;
}

export class IomsActionReportData {
    actionNumber: string;
    startTime: string;
    endTime: string;
    lineName: string;
    substation: string;
    personInCharge: string;
    description: string;
    equipment: string = '';
}

export class IomsWorkInstructionInfo {
    mongoId: string;
    name: string;
}

export class IomsWorkInstructionFull {
    id: string;
    name: string;
    comment: string;
    parentId: string;
    parentObjectId: string;
    preRequirements: string[];
    procedures: string[];
    replacedItems: string[];
    text: string;
    type: string;
}

export interface IomsSingleAction {
    mongoId: string; //"61f3cc8a50aa481d4b4400e0";
    userId: string; //"615d6ad050aa4800b8e6d0be";
    actionReportId: string; //"61f3cc8a50aa481d4b4400df";
    seriesActionNumber: number; //1643367449726;
    actionNumber: string; //"1643367449726";
    creationDateMills: number; //1643367449726;
    start: number; //1642028400000;"2022-01-12T23:00:00.000Z"
    end: number; //1642114799999;  "2022-01-13T22:59:59.999Z
    duration: string; // "All day";
    actionType: string; // "614c52f419beae2fabb1496a";
    deviceLevelId: string; //"Line 1_BYPH_OVPDs";
    description: string;
    reportId: string;
    location: string;
    selectedEquipmentIds: string[]; //["OVPDs;61f14d4750aa481bc57ca38a"];
    equipmentIds: string[];
    workInstructionsIds: string[]; //["61f11cb450aa481bc57c9d7e"];
    selectedWorkInstructionsIds: string[];
    assigned: boolean;
    status: string; //"COMPLETED";
    // Extra data for GUI
    actions?: any[];
    approvalDate?: string;
    teamId?: string;
    rrule?: string;
}

export interface IomsSeriesPutI {
    userId: string;
    actionNumber: 1647951875072;
    creationDateMills: number;
    eventStart: number;
    eventEnd: number;
    selectedDuration: string;
    rrule: string;
    actionType: string;
    deviceLevelId: string;
    briefDescription: string;
    reportId: string;
    location: string;
    selectedEquipmentIds: string[];
    selectedWorkInstructionsIds: string[];
    selectedRepeatOption: number;
}

export interface IomsSeriesGetI {
    mongoId: string;
    userId: string;
    actionNumber: number;
    creationDate: number;
    start: number;
    end: number;
    duration: string;
    repeat: string;
    actionType: string;
    deviceLevelId: string;
    description: string;
    reportId: string;
    location: string;
    assigned: boolean;
    iomsDevices: string[];
    workInstructions: string[];
    selectedRepeatOption: number;
}

export enum IomsGesturesE {
    EDIT_SERIES = 0,
    DELETE_SERIES,
    ASSIGN,
    RESCHEDULE_ACTION,
    DELETE_ACTION,
    SEE_ACTION,
    SHOW_REPORT,
}

@Injectable({ providedIn: 'root' })
export class IomsActionService {
    constructor(private _http: EvaPlusHttpService) {}

    // getMockApi(): string {
    //     var protocol = window.location.protocol;
    //     var hostname = window.location.hostname;
    //     var port = window.location.port;
    //     if (port == '4200') {
    //         port = '8080';
    //     }
    //     var url = protocol + '//' + hostname + ':' + port;
    //     return url;
    // }

    // // load ongoing actions
    // loadOngoingActionSrv(): Observable<IomsAction[]> {
    //     let uriString = this.getMockApi() + '/EvaPlusRest/iomsActionService/ongoing';
    //     return this._http.get(uriString).pipe(
    //         map((res: Response) => {
    //             return res.json();
    //         })
    //     );
    // }

    // // load waiting actions
    // loadWaitingActionSrv(): Observable<IomsAction[]> {
    //     let uriString = this.getMockApi() + '/EvaPlusRest/iomsActionService/waiting';
    //     return this._http.get(uriString).pipe(
    //         map((res: Response) => {
    //             return res.json();
    //         })
    //     );
    // }

    loadActionsSrv(type: String): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/actions?type=' + type;
        return this._http.get(uriString);
    }

    updateActionStatus(mongoId: string, newStatus: string, changedBy: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/updateActionStatus';
        let payload = JSON.stringify({
            mongoId: mongoId,
            newStatus: newStatus,
            changedBy: changedBy,
        });

        return this._http.post(uriString, payload);
    }

    saveMaintenanceReportOnStartAction(
        raportMaintenance: any[],
        actionNumber: number,
        mongoId: String,
        currentUserId: String,
        toolBoxData: any
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/saveMaintenanceReportOnStartAction';
        let payload = JSON.stringify({
            reportMaintenanceListData: raportMaintenance,
            actionNumber: actionNumber,
            mongoId: mongoId,
            currentUserId: currentUserId,
            toolBoxData: toolBoxData,
        });
        return this._http.post(uriString, payload);
    }

    //sb1

    updateMaintenanceReportOnSaveButton(
        raportMaintenance: any[],
        actionNumber: number,
        mongoId: String,
        currentUserId: String,
        toolBoxData: any,
        reportFilesChanged: boolean
    ): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/updateMaintenanceReportOnSaveButton';
        let payload = JSON.stringify({
            reportMaintenanceListData: raportMaintenance,
            actionNumber: actionNumber,
            toolBoxData: toolBoxData,
            mongoId: mongoId,
            currentUserId: currentUserId,
            reportFilesChanged: reportFilesChanged,
        });
        return this._http.post(uriString, payload);
    }

    uploadFilesSrv(data: FormData): Observable<HttpEvent<Object>> {
        let uriString = '/EvaPlusRest/iomsActionService/fileUpload';
        return this._http.post(uriString, data);
    }

    getHistory(mongoId: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/getHistory';
        let payload = JSON.stringify({ mongoId: mongoId });
        return this._http.post(uriString, payload);
    }

    getReportData(actionReportId: string): Observable<IomsActionReportData> {
        let uriString =
            '/EvaPlusRest/iomsMaintenanceReportService/getReportData?actionReportId=' +
            actionReportId;

        return this._http.get(uriString);
    }

    getWorkInstructionsInfo(
        workInstructionsIdsString: string
    ): Observable<IomsWorkInstructionInfo[]> {
        let uriString =
            '/EvaPlusRest/iomsMaintenanceReportService/getWorkInstructionsInfo?workInstructionsIds=' +
            workInstructionsIdsString;

        return this._http.get(uriString);
    }

    getReportTemplate(): Observable<any> {
        let uriString = '/EvaPlusRest/iomsMaintenanceReportService/getReportTemplate';
        return this._http.get(uriString);
    }

    getWorkInstructionsInfoPost(
        workInstructionsIdsString: string
    ): Observable<IomsWorkInstructionInfo[]> {
        let uriString = '/EvaPlusRest/iomsMaintenanceReportService/getWorkInstructionsInfoPost';
        //let payload = JSON.stringify({mongoId: [workInstructionsIdsString]});
        let payload = workInstructionsIdsString;
        return this._http.post(uriString, payload);
    }

    getDevices(deviceIds: string): Observable<any[]> {
        let uriString = '/EvaPlusRest/iomsMaintenanceReportService/getDevices';
        let payload = deviceIds;
        return this._http.post(uriString, payload);
    }

    getWorkInstructionsFullById(workInstructionsId: string): Observable<IomsWorkInstructionFull[]> {
        let uriString =
            '/EvaPlusRest/knowledgebaseservice/workInstructionsFullById?id=' + workInstructionsId;

        return this._http.get(uriString);
    }

    getSavedWorkInstructionById(
        workInstructionsId: string,
        actionNumber: string
    ): Observable<IomsWorkInstructionFull[]> {
        let uriString =
            '/EvaPlusRest/knowledgebaseservice/getSavedWorkInstructionById?id=' +
            workInstructionsId +
            '&actionNumber=' +
            actionNumber;

        return this._http.get(uriString);
    }

    getSavedWorkInstructionByIdPost(
        workInstructionsId: string,
        actionNumber: string
    ): Observable<IomsWorkInstructionFull[]> {
        let uriString = '/EvaPlusRest/knowledgebaseservice/getSavedWorkInstructionByIdPost';
        let payload = workInstructionsId + '@' + actionNumber; //sb1
        return this._http.post(uriString, payload);
    }

    getSavedWorkInstructionByIdPostPrint(
        workInstructionsId: string,
        actionNumber: string
    ): Observable<IomsWorkInstructionFull[]> {
        let uriString = '/EvaPlusRest/knowledgebaseservice/getSavedWorkInstructionByIdPostPrint';
        let payload = workInstructionsId + '@' + actionNumber;
        return this._http.post(uriString, payload);
    }

    getToolBoxTalkData(actionNumber: string): Observable<any> {
        let uriString = '/EvaPlusRest/iomsActionService/toolBoxTalk';
        let payload = JSON.stringify({
            actionNumber: actionNumber,
        });
        return this._http.post(uriString, payload);
    }
}
