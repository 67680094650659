import { Injectable } from '@angular/core';
import { RRule } from 'rrule';
import {
    IomsDevice,
    IomsDeviceManagerService,
} from '../../../../services/iomsdevicemanger.service';
import { CreateIndividualFromSeries } from './create-individual-from-series.service';

@Injectable({
    providedIn: 'root',
})
export class SaveNewActionService {
    //NS*

    constructor(
        private _iomsDeviceManagementService: IomsDeviceManagerService,
        private _createIndividualFromSeries: CreateIndividualFromSeries
    ) {}

    public async saveAction(dialogData: any): Promise<boolean> {
        if (dialogData != undefined) {
            if (dialogData.toSave) {
                let eventStart: number;
                let eventEnd: number;

                eventStart = dialogData.eventStart.getTime();
                eventEnd = dialogData.eventEnd.getTime();

                var rrule = {};
                let selectedRepeatOption: number = 0;

                // DAILY
                if (dialogData.toSave && dialogData.repeatChoice.value == 1) {
                    selectedRepeatOption = 1;
                    var bywd: any = [];
                    if (dialogData.dailyExcludeWeekend) {
                        bywd = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
                    }
                    rrule = {
                        freq: RRule.DAILY,
                        interval: dialogData.intervalDays,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: bywd,
                    };
                }

                // WEEKLY
                if (dialogData.toSave && dialogData.repeatChoice.value == 2) {
                    selectedRepeatOption = 2;
                    rrule = {
                        freq: RRule.WEEKLY,
                        interval: dialogData.intervalWeeks,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: dialogData.weeklyByDay,
                    };
                }

                // SAME DAY EACH MONTH
                if (dialogData.toSave && dialogData.repeatChoice.value == 3) {
                    selectedRepeatOption = 3;
                    rrule = {
                        freq: RRule.MONTHLY,
                        interval: dialogData.intervalMonths,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: [
                            RRule.MO,
                            RRule.TU,
                            RRule.WE,
                            RRule.TH,
                            RRule.FR,
                            RRule.SA,
                            RRule.SU,
                        ],
                        bysetpos: [dialogData.dayMonthly],
                    };
                }

                // SAME WEEK EACH MONTH
                if (dialogData.toSave && dialogData.repeatChoice.value == 4) {
                    selectedRepeatOption = 4;
                    rrule = {
                        freq: RRule.MONTHLY,
                        interval: dialogData.intervalMonths,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: dialogData.monthlyByDayRule,
                        bysetpos: [dialogData.sameWeekMonthlySetPos],
                    };
                }

                // SAME DAY EACH YEAR
                if (dialogData.toSave && dialogData.repeatChoice.value == 5) {
                    selectedRepeatOption = 5;
                    rrule = {
                        freq: RRule.YEARLY,
                        interval: dialogData.intervalYears,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: [
                            RRule.MO,
                            RRule.TU,
                            RRule.WE,
                            RRule.TH,
                            RRule.FR,
                            RRule.SA,
                            RRule.SU,
                        ],
                        bymonth: [dialogData.selectedMonthValue],
                        bysetpos: [dialogData.dayOfTheMonthYearly],
                    };
                }

                // SAME WEEK EACH YEAR
                if (dialogData.toSave && dialogData.repeatChoice.value == 6) {
                    selectedRepeatOption = 6;
                    rrule = {
                        freq: RRule.YEARLY,
                        interval: dialogData.intervalYears,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: [dialogData.monthlyByDayRule],
                        bymonth: [dialogData.selectedMonthValue],
                        bysetpos: [dialogData.sameWeekMonthlySetPos],
                    };
                }

                // EVERY 6 MONTHS
                if (dialogData.toSave && dialogData.repeatChoice.value == 7) {
                    selectedRepeatOption = 7;
                    rrule = {
                        freq: RRule.MONTHLY,
                        interval: 6,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                    };
                }

                // SAME WEEK NUMBER EACH YEAR
                if (dialogData.toSave && dialogData.repeatChoice.value == 8) {
                    selectedRepeatOption = 8;
                    rrule = {
                        freq: RRule.YEARLY,
                        interval: dialogData.intervalYears,
                        dtstart: dialogData.eventdtStartMills,
                        until: dialogData.eventUntilMills,
                        byweekday: dialogData.monthlyByDayRule,
                        bysetpos: [dialogData.selectedWeekNumberYearly],
                    };
                }

                let deviceLevelId: string = '';

                deviceLevelId = dialogData.selectedLine + '_' + dialogData.selectedSubstation;

                let cubArrayTemp: string[] = dialogData.selectedCubicles;

                for (let i = 0; i < cubArrayTemp.length; i++) {
                    if (i == 0) {
                        deviceLevelId = deviceLevelId + '_' + cubArrayTemp[i];
                    } else {
                        deviceLevelId = deviceLevelId + ';' + cubArrayTemp[i];
                    }
                }

                let selectedEquipment1: IomsDevice[] = dialogData.selectedEquipment;
                let selectedEquipmentIds: string[] = [];
                for (var i = 0; i < selectedEquipment1.length; i++) {
                    selectedEquipmentIds.push(
                        selectedEquipment1[i].levelId.split('_')[2] +
                            ';' +
                            selectedEquipment1[i].mongoId
                    );
                }

                if (dialogData.toSave && dialogData.repeatChoice.value == 0) {
                    let eventsTemp: any[] = [];

                    eventsTemp.push({
                        userId: dialogData.user.id,
                        seriesActionNumber: dialogData.actionNumber,
                        actionNumber: '' + dialogData.actionNumber,
                        creationDateMills: dialogData.creationDateMills,
                        start: eventStart,
                        end: eventEnd,
                        duration: dialogData.selectedDuration.viewValue,
                        actionType: dialogData.selectedActionType.mongoId,
                        deviceLevelId: deviceLevelId,
                        description: dialogData.briefDescription,
                        reportId: dialogData.reportId,
                        location: dialogData.location,
                        selectedEquipmentIds: selectedEquipmentIds,
                        selectedWorkInstructionsIds: dialogData.selectedWorkInstructionsIds,
                    });

                    // save individual
                    try {
                        let res = await this._iomsDeviceManagementService
                            .saveIndividualScheduleEvents(eventsTemp)
                            .toPromise();
                        return true;
                    } catch (e) {}
                } else {
                    // save series
                    try {
                        let res2 = await this._iomsDeviceManagementService
                            .saveActionSeries(
                                dialogData.user.id,
                                dialogData.actionNumber,
                                dialogData.creationDateMills,
                                eventStart,
                                eventEnd,
                                dialogData.selectedDuration.viewValue,
                                JSON.stringify(rrule),
                                dialogData.selectedActionType.mongoId,
                                deviceLevelId,
                                dialogData.briefDescription,
                                dialogData.reportId,
                                dialogData.location,
                                selectedEquipmentIds,
                                dialogData.selectedWorkInstructionsIds,
                                selectedRepeatOption
                            )
                            .toPromise();

                        let res4 =
                            await this._createIndividualFromSeries.saveRecurringActionSeriesAsSingleActions(
                                dialogData.user.id,
                                dialogData.actionNumber,
                                dialogData.creationDateMills,
                                eventStart,
                                eventEnd,
                                dialogData.selectedDuration.viewValue,
                                rrule,
                                dialogData.selectedActionType.mongoId,
                                deviceLevelId,
                                dialogData.briefDescription,
                                dialogData.reportId,
                                dialogData.location,
                                selectedEquipmentIds,
                                dialogData.selectedWorkInstructionsIds
                            );

                        if (res4) {
                            return true;
                        } else {
                            return false;
                        }
                    } catch (e) {
                        return false;
                    }
                }
            }
        }
        return false;
    }
}
