import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterHelperService {
    constructor(private router: Router) {}

    /**
     * Opens a specific url in the new tab with forwarded query params
     * @param url: string
     * @param queryParams?: { [key: string]: string | number | boolean }
     */
    openLinkInNewTab(
        url: string,
        queryParams?: { [key: string]: string | number | boolean }
    ): void {
        const link = this.router.serializeUrl(
            this.router.createUrlTree([url], {
                queryParams,
            })
        );

        window.open(link, '_blank');
    }
}
