import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { EvaPlusHttpService } from './http/eva-http.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ContentLevel {
    id: string;
    name: string;
    parent: string;
}

const baseUrl = '/EvaPlusRest/knowledgebaseservice/';

@Injectable({ providedIn: 'root' })
export class KnowledgeBaseService {
    constructor(private _http: EvaPlusHttpService, private _platformLocation: PlatformLocation) {}

    getMockApi(): string {
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;
        var port = window.location.port;
        if (port == '4200') {
            port = '8080';
        }
        var url = protocol + '//' + hostname + ':' + port;
        return url;
    }

    // contents ...

    // load content levels
    loadContentLevelsSrv(): Observable<ContentLevel[]> {
        return this._http.get(baseUrl + 'contentlist');
    }

    // add ioms device level to db
    addContentLevel(
        contentItem: string,
        description: string,
        parentId: string,
        parentObjectId: string,
        contentType: string
    ): Observable<any> {
        let payload = JSON.stringify({
            name: contentItem,
            comment: description,
            parentId: parentId,
            parentObjectId: parentObjectId,
            type: contentType,
        });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(baseUrl + 'saveKnowledgeBaseItem', payload);
    }

    saveContentText(
        contentItem: string,
        description: string,
        parentId: string,
        text: string,
        objectId: string,
        id: string,
        parentObjectId: string,
        preRequirements: string[],
        procedures: string[],
        replacedItems: string[]
    ): Observable<any> {
        let payload = JSON.stringify({
            name: contentItem,
            comment: description,
            parentId: parentId,
            text: text,
            objectId: objectId,
            id: id,
            parentObjectId: parentObjectId,
            preRequirements: preRequirements,
            procedures: procedures,
            replacedItems: replacedItems,
        });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(baseUrl + 'saveEditedKnowledgeBaseItem', payload);
    }

    getInstructionData(objectId: string): Observable<any> {
        let payload = JSON.stringify({ objectId: objectId });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(baseUrl + 'getInstructionData', payload);
    }

    getContentText(objectId: string): Observable<any> {
        let payload = JSON.stringify({ objectId: objectId });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(baseUrl + 'getKnowledgeBaseItem', payload);
    }

    deleteContentItem(objectId: string): Observable<any> {
        let payload = JSON.stringify({ objectId: objectId });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return this._http.post(baseUrl + 'deleteKnowledgeBaseItem', payload);
    }

    // read config file for ag grid
    getInstructionFormTemplate(): Observable<any> {
        return this._http.getAsset(
            this._platformLocation.pathname + 'assets/ioms_templates/instruction_form.html'
        );
    }

    // read config file for ag grid
    getEventAnalyticsGridConfig(): Observable<any> {
        return this._http.getAsset(
            this._platformLocation.pathname + 'assets/configs/instruction_form.html',
            { observe: 'response', responseType: 'text' }
        );
    }

    // load content levels
    loadWorkInstructionsSrv(): Observable<any> {
        //SCHEDULE ACTION
        let uriString = '/EvaPlusRest/knowledgebaseservice/workInstructionsInfo';
        return this._http.get(uriString);
    }

    uploadFileToS3Srv(data: FormData): Observable<any> {
        let uriString = '/EvaPlusRest/knowledgebaseservice/fileUploadToS3';
        return this._http.post(uriString, data);
    }

    downloadKnowledgeBaseFileFromS3(filePath: string) {
        var uriString =
            '/EvaPlusRest/knowledgebaseservice/downloadKBFileFromS3?filePath=' + filePath;
        window.open(this.getMockApi() + uriString);
    }

    downloadAndShowFileFromS3(filePath: string, fileExtension: string): any {
        var uriString =
            '/EvaPlusRest/knowledgebaseservice/downloadKBFileFromS3?filePath=' + filePath;
        return this._http
            .get(uriString, { responseType: 2 })
            .pipe(map((res: Response) => res.blob()));
    }

    deleteKnowledgeBaseFileFromS3(objectId: string, filePath: string): Observable<any> {
        let uriString = '/EvaPlusRest/knowledgebaseservice/deleteKBFileFromS3';
        let payload = JSON.stringify({ text: filePath, objectId: objectId });

        return this._http.post(uriString, payload);
    }
}
