import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IomsSingleAction } from '../../../../services/iomsAction.service';
import {
    IomsActionType,
    IomsDevice,
    IomsDeviceManagerService,
} from '../../../../services/iomsdevicemanger.service';
import {
    DialogScheduleAction,
    DialogScheduleActionDataI,
} from '../dialog/dialog-schedule-action/dialog-schedule-action.component';
import { deviceTreeI } from './load-devices.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleSeriesService {
    constructor(
        private _dialog: MatDialog,
        private _iomsDeviceManagementService: IomsDeviceManagerService,
        private _snackBar: MatSnackBar
    ) {}

    /**
     * Schedules a new series
     * @param data to save
     * @returns true if success, false otherwise
     */
    public async scheduleSeries(
        action: IomsSingleAction,
        user: any,
        actionTypes: IomsActionType[],
        lines: deviceTreeI[],
        devices: IomsDevice[]
    ): Promise<boolean> {
        const data: DialogScheduleActionDataI = {
            // Initial selected data in dialog
            start: new Date(action.start),
            end: new Date(action.end),
            duration: isNaN(+`0${action.duration}`) ? 0 : +action.duration,
            description: action.description,
            user,
            actionType: action.actionType,
            device: action.deviceLevelId,
            equipment: action.equipmentIds,
            workInstructionsIds: action.workInstructionsIds,
            // General Information
            actionTypes,
            lines,
            devices,
            isSeries: true,
            rrule: action.rrule,
        };
        const dialog = this._dialog.open(DialogScheduleAction, {
            height: '900px',
            width: '900px',
            autoFocus: false,
            data,
            // disableClose: true,
        });
        const dialogData: DialogScheduleActionDataI = await dialog.afterClosed().toPromise();

        if (dialogData) {
            let res: string;
            try {
                if (!action.mongoId) {
                    // New action
                    res = await this._iomsDeviceManagementService
                        .saveActionSeries(
                            action.userId,
                            +action.actionNumber,
                            +action.creationDateMills,
                            dialogData.start.getTime(),
                            dialogData.end.getTime(),
                            dialogData.duration.toString(),
                            dialogData.rrule || '',
                            dialogData.actionType,
                            dialogData.device,
                            dialogData.description,
                            '',
                            dialogData.location,
                            dialogData.equipment,
                            dialogData.workInstructionsIds,
                            0
                        )
                        .toPromise();
                } else {
                    // Existing action
                    res = await this._iomsDeviceManagementService
                        .editScheduleAction(
                            action.mongoId,
                            action.userId,
                            +action.actionNumber,
                            +action.creationDateMills,
                            dialogData.start.getTime(),
                            dialogData.end.getTime(),
                            dialogData.duration.toString(),
                            dialogData.rrule || '',
                            dialogData.actionType,
                            dialogData.device,
                            dialogData.description,
                            '',
                            dialogData.location,
                            dialogData.equipment,
                            dialogData.workInstructionsIds,
                            0,
                            new Date().getTime()
                        )
                        .toPromise();
                }
                this._snackBar.open('Action saved', 'Ok', { duration: 5000 });
                return true;
            } catch (e) {
                this._snackBar.open('Error while saving!', 'Ok', { duration: 5000 });
                return false;
            }
        }
    }
}
