import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FleetManagementService, SignalEtcsService } from '../../../../services';
import { SaUtilsService } from '../../../../services/sa-utils.service';
import { IPreviewChartParams, IVehicleConfigurationParams } from '../../../../services/signal.service';
import { GetCriteriaData } from '../action/criteria.actions';
import { GetLastDateForVehicle, OpenSaForParams } from '../action/signal-analytics-module.actions';
import { SetSelectedSignalBookmark } from '../action/signal-bookmarks.actions';
import {
    LoadTemplateData,
    ResetStoreToolbar,
    SetAnotherVehicleSelected,
    SetDefaultTemplateName,
    SetEtcsType,
    SetRecorder,
    SetRecordersAndVehicleDistanceUnits,
    SetSelectedFleet,
    SetTemplateFleet,
    SetTime,
    SetTimeForTimestamp,
    SetVehicle,
} from '../action/toolbar.actions';
import { SignalAnalyticsModuleState } from './signal-analytics-module.state';

export interface SaToolbarStateModel {
    selectedVehicleValue: string;
    selectedRecorderValue: string;
    selectedEtcsType: string;
    timeFrom: string;
    timeTo: string;
    recorders: any[];
    vehicleDistanceUnits: {
        [id: string]: string;
    };
    gpsSignalIds: {
        [id: string]: string;
    };
    anotherVehicleSelected: boolean;
    selectedVehicleFleet: string;
    firstTimeVehicleSelected: boolean;
    defaultTemplateName: string;
    selectedTemplateFleet: string;
}

const defaultState = {
    selectedVehicleValue: null,
    selectedRecorderValue: null,
    selectedEtcsType: null,
    timeFrom: null,
    timeTo: null,
    recorders: [],
    vehicleDistanceUnits: {},
    gpsSignalIds: {},
    anotherVehicleSelected: false,
    selectedVehicleFleet: '',
    firstTimeVehicleSelected: false,
    defaultTemplateName: '',
    selectedTemplateFleet: '',
};

@Injectable()
@State<SaToolbarStateModel>({
    name: 'saToolbar',
    defaults: defaultState,
})
export class SaToolbarState {
    constructor(
        private _signalServiceNew: SignalEtcsService,
        private store: Store,
        private _saUtils: SaUtilsService,
        private _fleetManagementService: FleetManagementService
    ) {}

    @Action(SetSelectedFleet)
    setSelectedFleet(ctx: StateContext<SaToolbarStateModel>, action: SetSelectedFleet) {
        let selectedFleet = '';
        if (action.fleet) {
            selectedFleet = action.fleet;
        }
        ctx.patchState({ selectedVehicleFleet: selectedFleet });
    }

    @Action(SetTemplateFleet)
    setTemplateFleet(ctx: StateContext<SaToolbarStateModel>, action: SetTemplateFleet) {
        let templateFleet = '';
        if (action.fleet) {
            templateFleet = action.fleet;
        }
        ctx.patchState({ selectedTemplateFleet: templateFleet });
    }

    @Action(SetSelectedSignalBookmark)
    async setSelectedBookmark(ctx: StateContext<SaToolbarStateModel>, { selectedBookmark }: SetSelectedSignalBookmark) {
        if (selectedBookmark == null) {
            return;
        }
        let timefrom: string = selectedBookmark.timeFrom != null ? selectedBookmark.timeFrom.toJSON() : null;
        let timeto: string = selectedBookmark.timeTo != null ? selectedBookmark.timeTo.toJSON() : null;
        ctx.patchState({
            selectedVehicleValue: selectedBookmark.vehicleId,
            timeFrom: timefrom,
            timeTo: timeto,
        });
        const recs = await this._signalServiceNew.getRecordersForVehicleSA(selectedBookmark.vehicleId).toPromise();
        this.setRecordersAndVehicaleDistance(ctx, recs);
    }

    selectRecorderValue(recorders: string[], value: string): string {
        for (var i = 0; i < recorders.length; i++) {
            if (recorders[i].indexOf(value) != -1) {
                return recorders[i];
            }
        }
        return value;
    }

    @Action(SetAnotherVehicleSelected)
    setAnotherVehicleSelected(
        ctx: StateContext<SaToolbarStateModel>,
        { anotherVehicleSelected }: SetAnotherVehicleSelected
    ) {
        ctx.patchState({ anotherVehicleSelected: anotherVehicleSelected });
    }

    @Action(GetLastDateForVehicle)
    getLastDateForVehicle(ctx: StateContext<SaToolbarStateModel>, action: GetLastDateForVehicle) {
        ctx.patchState({ firstTimeVehicleSelected: true });
    }

    @Action(SetVehicle)
    async setVehicle(ctx: StateContext<SaToolbarStateModel>, action: SetVehicle) {
        ctx.patchState({ selectedVehicleValue: action.vehicle, anotherVehicleSelected: true });
        if (action.vehicle == null) {
            return;
        }
        let recorders = await this._signalServiceNew.getRecordersForVehicleSA(action.vehicle).toPromise();
        this.setRecordersAndVehicaleDistance(ctx, recorders);
    }

    @Action(OpenSaForParams)
    openSaForParams(ctx: StateContext<SaToolbarStateModel>, action: OpenSaForParams) {
        const selectedTs: number = Number(action.time).valueOf();
        let previewTime = this._saUtils.getPreviewTimeForTimestamp(
            selectedTs,
            this.store.selectSnapshot(SignalAnalyticsModuleState.timeZone)
        );
        ctx.patchState({
            selectedVehicleValue: action.vehicleId,
            firstTimeVehicleSelected: true,
            timeFrom: previewTime.timeFrom,
            timeTo: previewTime.timeTo,
            selectedEtcsType: '24h',
        });
        let fleet = this._fleetManagementService.findFleetForVehicleSrv(action.vehicleId);
        let recorders = this._signalServiceNew.getRecordersForVehicleSA(action.vehicleId);
        return zip(fleet, recorders).pipe(
            tap(([fleetRes, recordersRes]) => {
                let selectedVehilceFleet = '';
                if (fleetRes.value != undefined) {
                    selectedVehilceFleet = fleetRes.value.name;
                }
                ctx.patchState({ selectedVehicleFleet: selectedVehilceFleet });
                this.setRecordersAndVehicaleDistance(ctx, recordersRes);
            })
        );
    }

    @Action(GetCriteriaData)
    getCriteriaData(ctx: StateContext<SaToolbarStateModel>, action: GetCriteriaData) {
        ctx.patchState({ selectedEtcsType: '24h' });
    }

    @Action(LoadTemplateData)
    loadTemplateData(ctx: StateContext<SaToolbarStateModel>, { templateData, isDefault }: LoadTemplateData) {
        let template = this.store
            .selectSnapshot(SignalAnalyticsModuleState.userTemplates)
            .find((template) => template.id == templateData.id);
        if (template) {
            ctx.patchState({ selectedTemplateFleet: templateData.fleet });
            if (isDefault) {
                ctx.patchState({ defaultTemplateName: templateData.name });
            }
        }
    }

    @Action(SetDefaultTemplateName)
    setDefaultTemplateName(ctx: StateContext<SaToolbarStateModel>, action: SetDefaultTemplateName) {
        ctx.patchState({ defaultTemplateName: action.templateName });
    }

    @Action(SetRecorder)
    setRecorder(ctx: StateContext<SaToolbarStateModel>, action: SetRecorder) {
        ctx.patchState({ selectedRecorderValue: action.recorder });
    }

    @Action(SetEtcsType)
    setEtcsType(ctx: StateContext<SaToolbarStateModel>, action: SetEtcsType) {
        ctx.patchState({ selectedEtcsType: action.etcsType });
    }

    @Action(SetTime)
    setTime(ctx: StateContext<SaToolbarStateModel>, action: SetTime) {
        let timefrom: string = action.timeFrom != null ? action.timeFrom.toJSON() : null;
        let timeto: string = action.timeTo != null ? action.timeTo.toJSON() : null;
        ctx.patchState({ timeFrom: timefrom, timeTo: timeto });
    }

    @Action(SetTimeForTimestamp)
    setTimeForTimestamp(ctx: StateContext<SaToolbarStateModel>, action: SetTimeForTimestamp) {
        let previewTime = this._saUtils.getPreviewTimeForTimestamp(
            this.store.selectSnapshot(SignalAnalyticsModuleState.timeStamp),
            this.store.selectSnapshot(SignalAnalyticsModuleState.timeZone)
        );
        ctx.patchState({ timeFrom: previewTime.timeFrom, timeTo: previewTime.timeTo });
    }

    @Action(SetRecordersAndVehicleDistanceUnits)
    setRecordersValues(ctx: StateContext<SaToolbarStateModel>, action: SetRecordersAndVehicleDistanceUnits) {
        this.setRecordersAndVehicaleDistance(ctx, action.recs);
    }

    setRecordersAndVehicaleDistance(ctx: StateContext<SaToolbarStateModel>, recs: string[]) {
        let vehicleDistanceUnits = {};
        for (var i = 0; i < recs.length; i++) {
            var tmp2: string[] = [];
            tmp2 = recs[i].split(' || ');
            recs[i] = tmp2[0];
            vehicleDistanceUnits[this.getRecorderSNvalue(tmp2[0])] = tmp2[1];
        }

        let recorders: string[] = [];
        let gpsSignalIds = {};

        // if vehicle has only one recorder then show only recorder serial number
        if (recs.length == 1) {
            var tmp: string[] = [];
            tmp = recs[0].split(' | ');
            recorders[0] = tmp[0];
            gpsSignalIds[tmp[0]] = tmp[3];
            ctx.patchState({
                vehicleDistanceUnits: vehicleDistanceUnits,
                recorders: recorders,
                gpsSignalIds: gpsSignalIds,
            });
        } else {
            // checking if multiple recorders for the vehicle are of the same type
            var sameType: boolean = true;
            var typeArr: string[] = [];
            for (var i = 0; i < recs.length; i++) {
                var valueType: string = recs[i].split(' | ')[1];
                if (valueType != undefined) {
                    typeArr.push(valueType);
                }
            }
            for (var i = 0; i < typeArr.length - 1; i++) {
                if (typeArr[i] != typeArr[i + 1]) {
                    sameType = false;
                    break;
                }
            }

            if (sameType) {
                // if all recorders for vehice are of the same type, then show recorder serial number and time of update
                for (var i = 0; i < recs.length; i++) {
                    var tmp: string[] = recs[i].split(' | ');
                    recorders[i] = tmp[0];
                    gpsSignalIds[tmp[0]] = tmp[3];
                    if (tmp[2] != undefined) {
                        recorders[i] = recorders[i] + ' | ' + tmp[2];
                    }
                }
            } else {
                // if vehicle has multiple recorders with different types, then show recorder serial number, vehicle type and time of update
                for (var i = 0; i < recs.length; i++) {
                    var tmp: string[] = recs[i].split(' | ');
                    recorders[i] = tmp[0];
                    gpsSignalIds[tmp[0]] = tmp[3];
                    if (tmp[1] != undefined) {
                        if (tmp[1] == 'null') {
                            recorders[i] = recorders[i] + ' | ' + 'no type';
                        } else {
                            recorders[i] = recorders[i] + ' | ' + tmp[1];
                        }
                    }
                    if (tmp[2] != undefined) {
                        recorders[i] = recorders[i] + ' | ' + tmp[2];
                    }
                }
            }
            ctx.patchState({
                vehicleDistanceUnits: vehicleDistanceUnits,
                recorders: recorders,
                gpsSignalIds: gpsSignalIds,
            });
        }
    }

    @Action(ResetStoreToolbar)
    resetStoreToolbar(ctx: StateContext<SaToolbarStateModel>, action: ResetStoreToolbar) {
        ctx.setState(defaultState);
    }

    getRecorderSNvalue(value: string): string {
        if (value.indexOf('|') != -1) {
            return value.split(' | ')[0];
        }
        return value;
    }

    @Selector()
    static vehicle(state: SaToolbarStateModel) {
        return state.selectedVehicleValue;
    }

    @Selector()
    static fleet(state: SaToolbarStateModel) {
        return state.selectedVehicleFleet;
    }

    @Selector()
    static recorder(state: SaToolbarStateModel) {
        return state.selectedRecorderValue;
    }

    @Selector()
    static etcsType(state: SaToolbarStateModel) {
        return state.selectedEtcsType;
    }

    @Selector()
    static vehicleConfiguration(state: SaToolbarStateModel): IVehicleConfigurationParams {
        return {
            vehicle: state.selectedVehicleValue,
            recorder: state.selectedRecorderValue,
            etcsType: state.selectedEtcsType,
            memory: '',
        };
    }

    @Selector()
    static previewChartParams(state: SaToolbarStateModel): IPreviewChartParams {
        return {
            timeFrom: state.timeFrom,
            timeTo: state.timeTo,
        };
    }
}
