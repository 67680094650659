import { Injectable } from '@angular/core';
import { IChartDataComplete, IChartDataFull, IChartDataItem } from '.';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
export class SignalDataService {
    constructor() {}

    COLOR_LIST = [
        '#ff3300',
        '#0033cc',
        '#33cc33',
        '#cc00cc',
        '#ff9900',
        '#cc3300',
        '#336699',
        '#006600',
        '#993366',
        '#00ff00',
        '#ffff00',
        '#663300',
    ];

    mergeData(chartData1: IChartDataComplete, chartData2: IChartDataComplete): IChartDataComplete {
        if (chartData2.tableDataRaw.length > 0) {
            let lastRow =
                chartData1.tableDataRaw.length == 0
                    ? null
                    : chartData1.tableDataRaw[chartData1.tableDataRaw.length - 1];
            chartData2.tableDataRaw.forEach((row) => {
                if (lastRow == null || lastRow.time != row.time) {
                    chartData1.tableDataRaw.push(row);
                }
            });
        }

        if (chartData1.tableColumns == null || chartData1.tableColumns.length == 0) {
            chartData1.tableColumns = chartData2.tableColumns;
        }

        if (chartData1.columns == null || chartData1.columns.length == 0) {
            chartData1.columns = chartData2.columns;
        }

        chartData1.chartDataAnalog = this.mergeChartData(
            chartData1.chartDataAnalogFull,
            chartData2.chartDataAnalogFull
        );
        chartData1.chartDataAnalog = chartData1.chartDataAnalogFull;
        chartData1.chartDataAnalog2Full = this.mergeChartData(
            chartData1.chartDataAnalog2Full,
            chartData2.chartDataAnalog2Full
        );
        chartData1.chartDataAnalog2 = chartData1.chartDataAnalog2Full;
        chartData1.chartDataDigital = this.mergeChartData(chartData1.chartDataDigital, chartData2.chartDataDigital);
        chartData1.chartDataEtcs = this.mergeChartData(chartData1.chartDataEtcs, chartData2.chartDataEtcs);
        chartData1.chartDataEvent = this.mergeChartData(chartData1.chartDataEvent, chartData2.chartDataEvent);

        return chartData1;
    }

    mergeChartData(chData1: IChartDataFull[], chData2: IChartDataFull[]): IChartDataFull[] {
        if (chData2.length > 0) {
            if (chData1.length == 0) {
                chData1 = chData2;
            } else {
                for (let i = 0; i < chData2[0].chartdata.length; i++) {
                    let originalChartData = chData1[0].chartdata[i];
                    let lastVal =
                        originalChartData.data.length == 0
                            ? null
                            : originalChartData.data[originalChartData.data.length - 1];
                    chData2[0].chartdata[i].data.forEach((dt) => {
                        if (lastVal == null || lastVal.x != dt.x) {
                            originalChartData.data.push(dt);
                        }
                    });
                }
            }
        }
        return chData1;
    }

    filterSignals(chartData, timeFrom, timeTo) {
        if (chartData.chartDataAnalogFull != null && chartData.chartDataAnalogFull.length != 0) {
            this.filterData(chartData.chartDataAnalogFull, timeFrom, timeTo);
            chartData.chartDataAnalog = chartData.chartDataAnalogFull;
        }
        if (chartData.chartDataAnalog2Full != null && chartData.chartDataAnalog2Full.length != 0) {
            this.filterData(chartData.chartDataAnalog2Full, timeFrom, timeTo);
            chartData.chartDataAnalog2 = chartData.chartDataAnalog2Full;
        }
        if (chartData.chartDataDigital != null && chartData.chartDataDigital.length != 0) {
            this.filterData(chartData.chartDataDigital, timeFrom, timeTo);
        }
        if (chartData.chartDataEtcs != null && chartData.chartDataEtcs.length != 0) {
            this.filterData(chartData.chartDataEtcs, timeFrom, timeTo);
        }
        if (chartData.chartDataOther != null && chartData.chartDataOther.length != 0) {
            this.filterData(chartData.chartDataOther, timeFrom, timeTo);
        }
        if (chartData.chartDataEvent != null && chartData.chartDataEvent.length != 0) {
            this.filterData(chartData.chartDataEvent, timeFrom, timeTo);
        }
        this.createTableData(chartData);
        chartData.tableData = this.filterTableData(chartData.tableData, timeFrom, timeTo);
        chartData.filterFromTime = timeFrom;
        chartData.filterToTime = timeTo;
    }

    filterTableData(tableData: any[], timeFrom, timeTo): any[] {
        let low = 0,
            high = tableData.length - 1;
        let startIndex = 0;
        let found = false;
        while (low <= high) {
            let mid = Math.floor((high + low) / 2);
            if (tableData[mid].time == timeFrom) {
                startIndex = mid;
                found = true;
                break;
            } else if (tableData[mid].time > timeFrom) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }
        let resultTable: any[] = [];
        let id = 1;
        if (!found) {
            let firstValue = null;
            if (low > high && high >= 0) low = high;
            if (tableData[low].time < timeFrom) {
                firstValue = tableData[low];
                startIndex = low + 1;
            } else if (low != 0) {
                firstValue = tableData[low - 1];
                startIndex = low;
            }
            if (firstValue != null) {
                firstValue.recordid = id++;
                firstValue.time = timeFrom;
                resultTable.push(firstValue);
            }
        }

        for (let i = startIndex; i < tableData.length; i++) {
            let row = tableData[i];
            if (row.time >= timeFrom && row.time <= timeTo) {
                row.recordid = id++;
                resultTable.push(row);
            } else if (row.time > timeTo) {
                break;
            }
        }
        return resultTable;
    }

    findStartIndex(data: IChartDataItem[], timeFrom): number {
        let low = 0,
            high = data.length - 1;
        let startIndex = 0;
        let found = false;
        while (low < high) {
            let mid = Math.floor((high + low) / 2);
            if (data[mid].x == timeFrom) {
                startIndex = mid;
                found = true;
                break;
            } else if (data[mid].x > timeFrom) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        if (!found) {
            startIndex = low;
        }

        return startIndex;
    }

    filterData(chartData: IChartDataFull[], timeFrom, timeTo) {
        chartData[0].timefrom = timeFrom;
        chartData[0].timeto = timeTo;
        if (chartData[0].chartoptions != null && chartData[0].chartoptions.scales != null) {
            let xTime = chartData[0].chartoptions.scales.xAxes.time;
            if (xTime != null) {
                xTime.min = timeFrom;
                xTime.max = timeTo;
            }
        }
        for (let i = 0; i < chartData[0].chartdata.length; i++) {
            let chData = chartData[0].chartdata[i];
            let newData: IChartDataItem[] = [];
            let startIndex = this.findStartIndex(chData.data, timeFrom);
            if (chData.data[startIndex].x != timeFrom) {
                if (chData.data[startIndex].x < timeFrom) {
                    chData.data[startIndex].x = timeFrom;
                    newData.push(chData.data[startIndex]);
                    startIndex++;
                } else if (startIndex != 0) {
                    chData.data[startIndex - 1].x = timeFrom;
                    newData.push(chData.data[startIndex - 1]);
                }
            }

            for (let j = startIndex; j < chData.data.length; j++) {
                let dataItem = chData.data[j];
                if (dataItem.x >= timeFrom && dataItem.x <= timeTo) {
                    newData.push(dataItem);
                } else if (dataItem.x > timeTo) {
                    break;
                }
            }
            if (newData.length > 0) {
                let lastValue: IChartDataItem = _.cloneDeep(newData[newData.length - 1]);
                lastValue.x = timeTo;
                newData.push(lastValue);
            }
            chData.data = newData;
        }
    }

    createTableData(chartData: IChartDataComplete) {
        let tableData: any[] = [];
        let tableDataRaw: any[] = chartData.tableDataRaw;
        let columns: string[] = chartData.columns;
        let lastSeenValue: Map<string, any> = new Map();
        let sameMilis: Map<string, any[]> = new Map();

        let prevRow = null;
        for (let currRow of tableDataRaw) {
            if (prevRow != null && prevRow.time == currRow.time && prevRow.type != 'etcs' && currRow.type != 'etcs') {
                if (currRow.signal != prevRow.signal || currRow[currRow.signal] == prevRow[prevRow.signal]) {
                    if (sameMilis.has(currRow.time)) {
                        sameMilis.get(currRow.time).push(currRow);
                    } else {
                        let sameM = [currRow];
                        sameMilis.set(currRow.time, sameM);
                    }
                } else {
                    tableData.push(_.cloneDeep(currRow));
                }
            } else {
                tableData.push(_.cloneDeep(currRow));
            }
            prevRow = currRow;
        }

        let prevDistance = 0;
        let counterSignal = 1;

        for (let row of tableData) {
            row.recordid = counterSignal++;
            if (row.distance == 0) {
                row.distance = prevDistance;
            } else {
                prevDistance = row.distance;
            }

            for (let column of columns) {
                if (column !== row.signal) {
                    let foundColumn: boolean = false;
                    if (sameMilis.has(row.time)) {
                        for (let sameMilisRow of sameMilis.get(row.time)) {
                            if (sameMilisRow.signal == column) {
                                foundColumn = true;
                                lastSeenValue.set(column, sameMilisRow);
                                row[column] = sameMilisRow[column];
                                break;
                            }
                        }
                    }
                    if (!foundColumn) {
                        if (column == 'ETCS') {
                            row[column] = ' ';
                        } else if (lastSeenValue.has(column)) {
                            row[column] = lastSeenValue.get(column)[column];
                        } else {
                            row[column] = ' ';
                        }
                    }
                } else {
                    lastSeenValue.set(row.signal, row);
                }
            }
            if (sameMilis.has(row.time)) {
                let fullSignalName = row.signal;
                sameMilis.get(row.time).forEach((rowSameMilis) => (fullSignalName += ' - ' + rowSameMilis.signal));
                row.signal = fullSignalName;
            }
        }

        chartData.tableData = tableData;
    }

    getAnalogData(analogData: IChartDataFull[], analogFullData: IChartDataFull[]): IChartDataFull[] {
        // in cases of many data points, analog data needs to be presented in compact form
        if (analogFullData === null || analogFullData.length == 0) {
            return [];
        }

        for (let i = 0; i < analogFullData[0].chartdata.length; i++) {
            let chartDataCompact = analogData[0].chartdata[i];
            let chartDataFull = analogFullData[0].chartdata[i];
            if (chartDataCompact.data.length > 3000) {
                let moduo = Math.floor(chartDataCompact.data.length / 2000);
                let indexInCompactData = 0;
                for (let indexInFullData = 0; indexInFullData < chartDataFull.data.length; indexInFullData++) {
                    if (indexInFullData % moduo != 0) {
                        chartDataCompact.data.splice(indexInCompactData, 1);
                    } else {
                        indexInCompactData++;
                    }
                }
            }
        }

        return analogData;
    }

    // OVDE JEBANIJADA ZA MENJANJE SIGNALA KADA SE DODAJE SAMO JEDAN!!!
    addNewSignal(originalChartData: IChartDataComplete, newChartData: IChartDataComplete) {
        if (newChartData.columns.length > 0) {
            for (let i = 0; i < newChartData.columns.length; i++) {
                if (!originalChartData.columns.includes(newChartData.columns[i])) {
                    originalChartData.columns.push(newChartData.columns[i]);
                    originalChartData.tableColumns.push(
                        newChartData.tableColumns[newChartData.tableColumns.length - newChartData.columns.length + i]
                    );
                }
            }
        }
        if (newChartData.tableDataRaw.length > 0) {
            newChartData.tableDataRaw.forEach((td) => originalChartData.tableDataRaw.push(td));
            originalChartData.tableDataRaw.sort((td1, td2) => (td1.time > td2.time ? 1 : td1.time < td2.time ? -1 : 0));
        }
        if (newChartData.chartDataAnalog.length > 0) {
            if (originalChartData.chartDataAnalog.length == 0) {
                originalChartData.chartDataAnalog.push(newChartData.chartDataAnalog[0]);
            } else {
                let n1 = originalChartData.chartDataAnalog[0].chartdata.length;
                let n2 =
                    originalChartData.chartDataAnalog2 == null || originalChartData.chartDataAnalog2.length == 0
                        ? 0
                        : originalChartData.chartDataAnalog2[0].chartdata.length;
                for (let i = 0; i < newChartData.chartDataAnalog[0].chartdata.length; i++) {
                    let cdAnalog = newChartData.chartDataAnalog[0].chartdata[i];
                    let scales = newChartData.chartDataAnalog[0].chartoptions.scales.yAxes[i];
                    if (n1 < 4) {
                        cdAnalog.yAxisID = 'y-axis-' + n1;
                        originalChartData.chartDataAnalog[0].chartdata.push(cdAnalog);
                        scales.ticks.fontColor = this.COLOR_LIST[n1];
                        scales.scaleLabel.fontColor = this.COLOR_LIST[n1];
                        scales.id = cdAnalog.yAxisID;
                        if (n1 % 2 == 0) {
                            scales.position = 'left';
                        } else {
                            scales.position = 'right';
                        }
                        originalChartData.chartDataAnalog[0].chartoptions.scales.yAxes.push(scales);
                        n1++;
                    } else if (n2 == 0) {
                        originalChartData.chartDataAnalog2 = [
                            {
                                chartcolors: newChartData.chartDataAnalog[0].chartcolors,
                                chartheight: newChartData.chartDataAnalog[0].chartheight,
                                chartoptions: newChartData.chartDataAnalog[0].chartoptions,
                                chartwidth: newChartData.chartDataAnalog[0].chartwidth,
                                timefrom: newChartData.chartDataAnalog[0].timefrom,
                                timeto: newChartData.chartDataAnalog[0].timeto,
                                chartid: newChartData.chartDataAnalog[0].chartid,
                                chartdata: [],
                            },
                        ];
                        originalChartData.chartDataAnalog2[0].chartdata.push(cdAnalog);
                        scales.ticks.fontColor = this.COLOR_LIST[n1 + n2];
                        scales.scaleLabel.fontColor = this.COLOR_LIST[n1 + n2];
                        scales.id = cdAnalog.yAxisID;
                        n2++;
                    } else if (n2 < 4) {
                        cdAnalog.yAxisID = 'y-axis-' + n2;
                        originalChartData.chartDataAnalog2[0].chartdata.push(cdAnalog);
                        scales.ticks.fontColor = this.COLOR_LIST[n1 + n2];
                        scales.scaleLabel.fontColor = this.COLOR_LIST[n1 + n2];
                        scales.id = cdAnalog.yAxisID;
                        if (n2 % 2 == 0) {
                            scales.position = 'left';
                        } else {
                            scales.position = 'right';
                        }
                        originalChartData.chartDataAnalog2[0].chartoptions.scales.yAxes.push(scales);
                        n2++;
                    }
                }
            }
        }
        if (newChartData.chartDataDigital.length > 0) {
            if (originalChartData.chartDataDigital.length == 0) {
                originalChartData.chartDataDigital.push(newChartData.chartDataDigital[0]);
            } else {
                let n = originalChartData.chartDataDigital[0].chartdata.length;
                for (let i = 0; i < newChartData.chartDataDigital[0].chartdata.length; i++) {
                    let currData = newChartData.chartDataDigital[0].chartdata[i];
                    currData.data.forEach((val) => {
                        val.y = 2 * n + val.y;
                    });
                    currData.label = this.updateLabel(currData.signal, n, i);
                    originalChartData.chartDataDigital[0].chartdata.push(currData);
                }
            }
        }
        if (newChartData.chartDataEtcs.length > 0) {
            if (originalChartData.chartDataEtcs.length == 0) {
                originalChartData.chartDataEtcs.push(newChartData.chartDataEtcs[0]);
            } else {
                let n = originalChartData.chartDataEtcs[0].chartdata.length;
                for (let i = 0; i < newChartData.chartDataEtcs[0].chartdata.length; i++) {
                    let currData = newChartData.chartDataEtcs[0].chartdata[i];
                    currData.data.forEach((val) => {
                        val.y = 2 * n + val.y;
                    });
                    currData.label = this.updateLabel(currData.signal, n, i);
                    originalChartData.chartDataEtcs[0].chartdata.push(currData);
                }
            }
        }
        if (newChartData.chartDataEvent.length > 0) {
            if (originalChartData.chartDataEvent.length == 0) {
                originalChartData.chartDataEvent.push(newChartData.chartDataEvent[0]);
            } else {
                let n = originalChartData.chartDataEvent[0].chartdata.length;
                for (let i = 0; i < newChartData.chartDataEvent[0].chartdata.length; i++) {
                    let currData = newChartData.chartDataEvent[0].chartdata[i];
                    currData.data.forEach((val) => {
                        val.y = 2 * n + val.y;
                    });
                    originalChartData.chartDataEvent[0].chartdata.push(currData);
                }
            }
        }
        if (newChartData.chartDataOther != null && newChartData.chartDataOther.length > 0) {
            if (originalChartData.chartDataOther == null) {
                originalChartData.chartDataOther = newChartData.chartDataOther;
            } else if (originalChartData.chartDataOther.length == 0) {
                originalChartData.chartDataOther.push(newChartData.chartDataOther[0]);
            } else {
                let n = originalChartData.chartDataOther[0].chartdata.length;
                for (let i = 0; i < newChartData.chartDataOther[0].chartdata.length; i++) {
                    let currData = newChartData.chartDataOther[0].chartdata[i];
                    currData.data.forEach((val) => {
                        val.y = 2 * n + val.y;
                    });
                    currData.label = this.updateLabel(currData.label, n, i);
                    originalChartData.chartDataOther[0].chartdata.push(currData);
                }
            }
        }
    }

    updateLabel(signal, n, i) {
        let id = n + i;
        return (id <= 9 ? '0' : '') + id + '. ' + signal;
    }

    updateSignalsRemove(chartData: IChartDataComplete, items, signalType: SignalType) {
        let removed = false;
        if (signalType == SignalType.Analog) {
            let ind = 0;
            if (chartData.chartDataAnalog.length < 1) return;
            let chartDataSize = chartData.chartDataAnalog[0].chartdata.length;
            while (ind < chartDataSize) {
                if (chartData.chartDataAnalog[0].chartdata[ind].signal.includes(items[0])) {
                    removed = true;
                    chartData.chartDataAnalog[0].chartdata.splice(ind, 1);
                    chartData.chartDataAnalog[0].chartoptions.scales.yAxes.splice(ind, 1);
                    chartDataSize = chartData.chartDataAnalog[0].chartdata.length;
                    for (let i = ind; i < chartDataSize; i++) {
                        let id = 'y-axis-' + i;
                        chartData.chartDataAnalog[0].chartdata[i].yAxisID = id;
                        let scales = chartData.chartDataAnalog[0].chartoptions.scales.yAxes[i];
                        scales.ticks.fontColor = this.COLOR_LIST[i];
                        scales.scaleLabel.fontColor = this.COLOR_LIST[i];
                        scales.id = id;
                        if (i % 2 == 0) {
                            scales.position = 'left';
                        } else {
                            scales.position = 'right';
                        }
                    }
                    if (
                        chartDataSize < 4 &&
                        chartData.chartDataAnalog2.length > 0 &&
                        chartData.chartDataAnalog2[0].chartdata.length > 0
                    ) {
                        chartData.chartDataAnalog[0].chartdata[chartDataSize] =
                            chartData.chartDataAnalog2[0].chartdata[chartData.chartDataAnalog2[0].chartdata.length - 1];
                        chartData.chartDataAnalog[0].chartoptions.scales.yAxes[chartDataSize] =
                            chartData.chartDataAnalog2[0].chartoptions.scales.yAxes[
                                chartData.chartDataAnalog2[0].chartoptions.scales.yAxes.length - 1
                            ];
                        chartData.chartDataAnalog2[0].chartdata.splice(
                            chartData.chartDataAnalog2[0].chartdata.length - 1,
                            1
                        );
                        chartData.chartDataAnalog2[0].chartoptions.scales.yAxes.splice(
                            chartData.chartDataAnalog2[0].chartoptions.scales.yAxes.length - 1,
                            1
                        );
                        let id = 'y-axis-' + chartDataSize;
                        chartData.chartDataAnalog[0].chartdata[chartDataSize].yAxisID = id;
                        let scales = chartData.chartDataAnalog[0].chartoptions.scales.yAxes[chartDataSize];
                        scales.ticks.fontColor = this.COLOR_LIST[chartDataSize];
                        scales.scaleLabel.fontColor = this.COLOR_LIST[chartDataSize];
                        scales.id = id;
                        if (chartDataSize % 2 == 0) {
                            scales.position = 'left';
                        } else {
                            scales.position = 'right';
                        }

                        if (chartData.chartDataAnalog2[0].chartdata.length == 0) {
                            chartData.chartDataAnalog2 = [];
                        }
                    }
                    if (chartData.chartDataAnalog[0].chartdata.length == 0) {
                        chartData.chartDataAnalog = [];
                    }
                    break;
                }
                ind++;
            }
            if (!removed && chartData.chartDataAnalog2.length > 0) {
                ind = 0;
                chartDataSize = chartData.chartDataAnalog2[0].chartdata.length;
                while (ind < chartDataSize) {
                    if (chartData.chartDataAnalog2[0].chartdata[ind].signal.includes(items[0])) {
                        chartData.chartDataAnalog2[0].chartdata.splice(ind, 1);
                        chartData.chartDataAnalog2[0].chartoptions.scales.yAxes.splice(ind, 1);
                        for (let i = ind; i < chartData.chartDataAnalog2[0].chartdata.length; i++) {
                            let id = 'y-axis-' + i;
                            chartData.chartDataAnalog2[0].chartdata[i].yAxisID = id;
                            let scales = chartData.chartDataAnalog2[0].chartoptions.scales.yAxes[i];
                            scales.ticks.fontColor = this.COLOR_LIST[4 + i];
                            scales.scaleLabel.fontColor = this.COLOR_LIST[4 + i];
                            scales.id = id;
                            if (i % 2 == 0) {
                                scales.position = 'left';
                            } else {
                                scales.position = 'right';
                            }
                        }

                        if (chartData.chartDataAnalog2[0].chartdata.length == 0) {
                            chartData.chartDataAnalog2 = [];
                        }
                        break;
                    }
                    ind++;
                }
            }
        } else if (signalType == SignalType.Digital) {
            let ind = 0;
            if (chartData.chartDataDigital.length < 1) return;
            let chartDataSize = chartData.chartDataDigital[0].chartdata.length;
            while (ind < chartDataSize) {
                if (chartData.chartDataDigital[0].chartdata[ind].signal.includes(items[0])) {
                    if (ind != chartDataSize - 1) {
                        chartData.chartDataDigital[0].chartdata[ind] =
                            chartData.chartDataDigital[0].chartdata[chartDataSize - 1];
                        chartData.chartDataDigital[0].chartdata.splice(chartDataSize - 1, 1);
                        chartData.chartDataDigital[0].chartdata[ind].data.forEach(
                            (item) => (item.y = 2 * ind + (item.y % 2 == 0 ? 0 : 1))
                        );
                    } else {
                        chartData.chartDataDigital[0].chartdata.splice(ind, 1);
                    }
                    if (chartData.chartDataDigital[0].chartdata.length == 0) {
                        chartData.chartDataDigital = [];
                    }
                    break;
                }
                ind++;
            }
        } else if (signalType == SignalType.Etcs) {
            let ind = 0;
            if (chartData.chartDataEtcs.length < 1) return;
            let chartDataSize = chartData.chartDataEtcs[0].chartdata.length;
            while (ind < chartDataSize) {
                if (chartData.chartDataEtcs[0].chartdata[ind].signal == items[0]) {
                    if (ind != chartDataSize - 1) {
                        chartData.chartDataEtcs[0].chartdata[ind] =
                            chartData.chartDataEtcs[0].chartdata[chartDataSize - 1];
                        chartData.chartDataEtcs[0].chartdata.splice(chartDataSize - 1, 1);
                        chartData.chartDataEtcs[0].chartdata[ind].data.forEach(
                            (item) => (item.y = 2 * ind + (item.y % 2 == 0 ? 0 : 1))
                        );
                    } else {
                        chartData.chartDataEtcs[0].chartdata.splice(ind, 1);
                    }
                    if (chartData.chartDataEtcs[0].chartdata.length == 0) {
                        chartData.chartDataEtcs = [];
                    }
                    break;
                }
                ind++;
            }
        } else if (signalType == SignalType.Event) {
            let ind = 0;
            if (chartData.chartDataEvent.length < 1) return;
            let chartDataSize = chartData.chartDataEvent[0].chartdata.length;
            while (ind < chartDataSize) {
                if (chartData.chartDataEvent[0].chartdata[ind].signal == items[0]) {
                    if (ind != chartDataSize - 1) {
                        chartData.chartDataEvent[0].chartdata[ind] =
                            chartData.chartDataEvent[0].chartdata[chartDataSize - 1];
                        chartData.chartDataEvent[0].chartdata.splice(chartDataSize - 1, 1);
                        chartData.chartDataEvent[0].chartdata[ind].data.forEach(
                            (item) => (item.y = 2 * ind + (item.y % 2 == 0 ? 0 : 1))
                        );
                    } else {
                        chartData.chartDataEvent[0].chartdata.splice(ind, 1);
                    }
                    if (chartData.chartDataEvent[0].chartdata.length == 0) {
                        chartData.chartDataEvent = [];
                    }
                    break;
                }
                ind++;
            }
        } else if (signalType == SignalType.Other) {
            // items = this.itemsRequireMatchOther.filter(el => !this.currItemsRequireMatchOther.includes(el));
        } else {
            // items = this.itemsRequireMatchStanding.filter(el => !this.currItemsRequireMatchStanding.includes(el));
        }
        if (items.length > 0) {
            chartData.tableColumns = chartData.tableColumns.filter((tc) => !tc.name.includes(items[0]));
            chartData.columns = chartData.columns.filter((column) => !column.includes(items[0]));
            chartData.tableDataRaw = chartData.tableDataRaw.filter(
                (row) =>
                    (!row.signal.includes(items[0]) && row.signal != 'EVENT') ||
                    (row.signal == 'EVENT' && !row.event.includes(items[0]))
            );
            if (chartData.chartDataEvent == null || chartData.chartDataEvent.length == 0) {
                chartData.tableColumns = chartData.tableColumns.filter((tc) => tc.name != 'EVENT');
                chartData.columns = chartData.columns.filter((column) => column != 'EVENT');
            }
        }
    }
}

export enum SignalType {
    Analog = 'analog',
    Digital = 'digital',
    Etcs = 'etcs',
    Event = 'event',
    Other = 'other',
    Standing = 'standing',
}
