import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EvaPlusHttpService } from './http/eva-http.service';

@Injectable({ providedIn: 'root' })
export class ProductsService {
    constructor(private http: EvaPlusHttpService) {}

    query(): any {
        return this.http.get('/EvaPlusRest/dashboards/getCardHeadline');
    }

    get(id: string): any {
        return this.http.get('/EvaPlusRest/dashboards/getCardHeadline').pipe(
            map((res: any) => {
                let item: any;
                res.json().forEach((s: any) => {
                    if (s.item_id === id) {
                        item = s;
                    }
                });
                return item;
            })
        );
    }

    landingPageData(): any {
        return this.http.get('/EvaPlusRest/landingpage/allData');
    }

    UserIdentityMgmtData(): any {
        return this.http.get('/EvaPlusRest/landingpage/allData');
    }

    baliseMonitoringData(): any {
        return this.http.get('/EvaPlusRest/baliseMonitoring/allData');
    }

    bybaliseData(errortype: string, status: string): any {
        return this.http.get(
            '/EvaPlusRest/baliseMonitoring/monitoringByBalise?errortype=' +
                errortype +
                '&status=' +
                status
        );
    }

    bybaliseDetailsData(baliseid: string, datefrom: string, dateto: string): any {
        return this.http.get(
            '/EvaPlusRest/baliseMonitoring/monitoringByDetailsBalise?baliseid=' +
                baliseid +
                '&datefrom=' +
                datefrom +
                '&dateto=' +
                dateto
        );
    }

    bybaliseSelectionData(): any {
        return this.http.get('/EvaPlusRest/baliseMonitoring/selectionData');
    }

    bybaliseQueryData(
        errortype: string,
        status: string,
        search: string,
        baliseid: string,
        fromdate: string,
        todate: string
    ): any {
        var uriString =
            '/EvaPlusRest/baliseMonitoring/queryByBalise?type=' +
            errortype +
            '&status=' +
            status +
            '&search=' +
            search +
            '&baliseid=' +
            baliseid +
            '&fromdate=' +
            fromdate +
            '&todate=' +
            todate;
        return this.http.get(uriString);
    }

    bybaliseDetailsQueryData(
        errortype: string,
        status: string,
        search: string,
        baliseid: string
    ): any {
        var uriString =
            '/EvaPlusRest/baliseMonitoring/queryByBaliseDetails?errortype=' +
            errortype +
            '&status=' +
            status +
            '&search=' +
            search +
            '&baliseid=' +
            baliseid;
        return this.http.get(uriString);
    }

    byErrorDasboardData(date: string): any {
        return this.http.get('/EvaPlusRest/baliseMonitoring/byErrorDashboard?date=' + date);
    }

    byErrorQueryData(type: string, search: string, baliseid: string, date: string): any {
        return this.http.get(
            '/EvaPlusRest/baliseMonitoring/byErrorQuery?type=' +
                type +
                '&search=' +
                search +
                '&baliseid=' +
                baliseid +
                '&date=' +
                date
        );
    }

    byErrorDetailQueryData(baliseid: string, date: string): any {
        return this.http.get(
            '/EvaPlusRest/baliseMonitoring/byErrorDetailQuery?baliseid=' +
                baliseid +
                '&date=' +
                date
        );
    }

    /**********************************************
     * Network repairs and network errors
     **********************************************/

    getDataForNetworkRepairs(from: string, to: string): any {
        var uriString =
            '/EvaPlusRest/networkRepairs/getDataForNetworkRepairs?dateFrom=' +
            from +
            '&dateTo=' +
            to;
        return this.http.get(uriString);
    }

    getDataForNetworkErrors(from: string, to: string): any {
        var uriString =
            '/EvaPlusRest/networkRepairs/getDataForNetworkErrors?dateFrom=' +
            from +
            '&dateTo=' +
            to;
        return this.http.get(uriString);
    }
}
